
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";
import Simulation from "@/components/simulation";

@Component({
  components: {},
})
export default class PrintDialog extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly active!: boolean;

  @Prop({ required: true, type: Object })
  readonly simulation!: Simulation;

  @Prop({ default: null, type: Object })
  readonly simulationForComparison!: Simulation | null;

  overlay: boolean = false;

  error: string | null = null;

  showInput: boolean = true;

  showAdministrativeCodes: boolean = true;

  showResult: boolean = true;

  showTotals: boolean = true;

  showCombinedDeclaration: boolean = true;

  showCalculationDetailsText: boolean = false;

  showHousehold: boolean = false;

  showRealEstate: boolean = false;

  showLoans: boolean = false;

  showComments: boolean = false;

  async mounted() {
    this.prepareInitial();
  }

  capitalize(str: string): string {
    return str.replace(/^\w/, (c) => c.toUpperCase());
  }

  print(): void {
    if (!this.showInput && !this.showResult) {
      return;
    }

    if (!this.showResult && this.showComments) {
      this.showResult = true;
      this.showTotals = false;
      this.showCombinedDeclaration = false;
      this.showCalculationDetailsText = false;
      this.showHousehold = false;
      this.showRealEstate = false;
      this.showLoans = false;
    }

    if (!this.showInput) {
      this.showComments = false;
    }

    if (!this.simulation.input.isDoubleReturn) {
      this.showCombinedDeclaration = false;
    }

    this.overlay = true;

    const url = `${this.$config.urls.baseUrl}/${this.$i18n.locale}/print`;
    let params = "?";
    if (this.showInput) {
      if (!this.showAdministrativeCodes) {
        params += "collapseAdministrativeInfo&";
      }
    } else {
      params += "hideInput&";
    }
    if (this.showResult) {
      if (this.showTotals) {
        params += "showTotals&";
      }
      if (this.showCombinedDeclaration) {
        params += "showCombinedDeclarants&";
      }
      if (this.showCalculationDetailsText) {
        params += "showCalculationDetailsText&";
      }
      if (this.showHousehold) {
        params += "showHousehold&";
      }
      if (this.showRealEstate) {
        params += "showRealEstate&";
      }
      if (this.showLoans) {
        params += "showLoans&";
      }
      if (this.showComments) {
        params += "showComments&";
      }
    } else {
      params += "hideResult&";
    }

    // Remove last &
    if (params.length > 1 && params[params.length - 1] === "&") {
      params = params.slice(0, -1);
    }

    const localStorageItems: { pitSimulation: string; pitSimulationForComparison?: string } = {
      pitSimulation: JSON.stringify(this.simulation.toObject()),
    };
    if (this.simulationForComparison) {
      localStorageItems["pitSimulationForComparison"] = JSON.stringify(this.simulationForComparison.toObject());
    }

    const pdfGeneratorUrl = `${this.$config.urls.pdfGeneratorUrl}/pdf`;

    // Do POST to pdf-generator
    fetch(pdfGeneratorUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url: `${url}${params}`,
        localStorageItems,
        displayHeaderFooter: true,
      }),
    })
      .then((response) => response.blob()) // convert the response to a blob
      .then((blob) => {
        // Create a blob URL
        const blobUrl = window.URL.createObjectURL(blob);

        // Create a link and set the URL as the href
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = `simulation-result-${this.simulation.input.taxYear}.pdf`;

        // Append the link to the body, click it, and then remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Revoke the blob URL
        window.URL.revokeObjectURL(blobUrl);

        this.overlay = false;
        this.close();
      })
      .catch((error) => {
        this.error = error;
      })
      .finally(() => {
        this.overlay = false;
      });
  }

  @Watch("active")
  async onActiveChange() {
    if (!this.active) {
      this.close();
    } else {
      this.prepareInitial();
    }
  }

  prepareInitial() {
    this.error = null;
    this.showInput = true;
    this.showAdministrativeCodes = true;
    this.showResult = true;
    this.showTotals = true;
    this.showCombinedDeclaration = this.simulation.input.isDoubleReturn;
    this.showCalculationDetailsText = false;
    this.showHousehold = false;
    this.showRealEstate = false;
    this.showLoans = false;
    this.showComments = false;

    if (this.simulation.result && !this.simulation.result.resultText) {
      this.simulation.updateResultDebounced(undefined, true);
    }
  }

  close(): void {
    this.$emit("close");
  }
}
