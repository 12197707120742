export default class ContractType {
  constructor(number: string | undefined, organisation: string | undefined) {
    this.number = number;
    this.organisation = organisation;
  }

  number: string | undefined;
  organisation: string | undefined;

  toString(): string {
    return this.number || this.organisation ? `${this.number} (${this.organisation})` : "";
  }

  toObject(): Object | undefined {
    if (this.number || this.organisation) {
      return {
        numero: this.number ? this.number?.substring(0, 35) : null,
        organisme: this.organisation ? this.organisation?.substring(0, 35) : null,
      };
    }
    return undefined;
  }

  static fromObject(obj: any): ContractType {
    return new ContractType(obj.numero, obj.organisme);
  }
}
