import RegularCodeStrategy from "@/components/input/validation/regularCodeStrategy";
import MaxCodeReverseStrategy from "@/components/input/validation/maxCodeReverseStrategy";

type CodeInfo = import("@/components/input/codeInfo").default;
type CodeItem = import("@/components/input/codeItem").default;

export default class MaxCodeStrategy extends RegularCodeStrategy {
  validate(primary: CodeItem, state: Map<string, CodeItem>): string | boolean {
    const values = this.necessaryCodes.map((x) =>
      state.has(x.code) && state.get(x.code)!.parsedValue !== null ? state.get(x.code)!.parsedValue : 0
    );
    const sum = values.reduce((a, b) => a + b, 0);
    return primary.parsedValue === null || primary.parsedValue <= sum
      ? true
      : (this.i18n.t("input.validation.maxCode", [this.necessaryCodes.map((x) => x.code).join(" + ")]) as string);
  }

  getReverseRule(primary: CodeInfo): MaxCodeReverseStrategy {
    return new MaxCodeReverseStrategy(this.i18n, primary, this.necessaryCodes);
  }
}
