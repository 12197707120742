import CollectionType from "@/components/input/complexTypes/collectionType";
import ItemType from "@/components/input/complexTypes/debAlimType";

const ITEM_NAME = "debAlim";
const INITIAL_ITEMS = 1;
const MAX_ITEMS = 10;

export default class DebAlimList extends CollectionType<ItemType> {
  constructor(items: ItemType[] | undefined) {
    super(items, ItemType, ITEM_NAME, INITIAL_ITEMS, MAX_ITEMS);
  }

  static fromObject(obj: any): DebAlimList {
    return CollectionType.fromObjectHelper(obj, ItemType, ITEM_NAME, INITIAL_ITEMS, MAX_ITEMS);
  }

  static fixedObject(obj: any): any {
    if (typeof obj === "string" || obj instanceof String) {
      return { debAlim: [obj] };
    }
    return obj;
  }
}
