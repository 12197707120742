import ReverseCodeStrategy from "@/components/input/validation/reverseCodeStrategy";

type CodeItem = import("@/components/input/codeItem").default;

export default class MaxCodeReverseStrategy extends ReverseCodeStrategy {
  validate(primary: CodeItem, state: Map<string, CodeItem>): string | boolean {
    if (!state.has(this.inverseCode.code) || state.get(this.inverseCode.code)!.parsedValue == null) {
      return true;
    }
    const values = this.necessaryCodes.map((x) =>
      state.has(x.code) && state.get(x.code)!.parsedValue !== null ? state.get(x.code)!.parsedValue : 0
    );
    const sum = values.reduce((a, b) => a + b, 0);
    return state.get(this.inverseCode.code)!.parsedValue <= sum
      ? true
      : (this.i18n.t("input.validation.maxCodeReverse", [
          this.inverseCode.code,
          state.get(this.inverseCode.code)!.parsedValue,
          this.necessaryCodes.map((x) => x.code).join(" + "),
        ]) as string);
  }
}
