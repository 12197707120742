import groupBy from "lodash.groupby";
import ResultListItem from "@/components/result/resultListItem";
import ResultListItemReduction from "@/components/result/resultListItemReduction";
import ResultListItemCombined from "@/components/result/resultListItemCombined";
import ResultListItemReductionCombined from "@/components/result/resultListItemReductionCombined";

export default {};

function sumItemList(items: any[] | null): number {
  return items ? items.map((x) => x.value).reduce((a, b) => a + b, 0) : 0;
}

function combineItemLists(
  resultListPrimary: ResultListItem[],
  resultListSecondary: ResultListItem[] | null
): ResultListItemCombined[] {
  if (resultListSecondary && resultListSecondary.length > 0) {
    // first aggregate seperate lists on name
    const itemsPrimGrouped = groupBy(resultListPrimary, (x) => x.name);
    const itemsPrimAgg = Object.keys(itemsPrimGrouped).map(
      (x) => new ResultListItem(x, sumItemList(itemsPrimGrouped[x]))
    );
    const itemsSecGrouped = groupBy(resultListSecondary, (x) => x.name);
    const itemsSecAgg = Object.keys(itemsSecGrouped).map((x) => new ResultListItem(x, sumItemList(itemsSecGrouped[x])));

    // then combine
    const result: any[] = [];
    itemsPrimAgg.forEach((x) => {
      const secValues = itemsSecAgg.filter((y) => y.name === x.name);
      const secValue = secValues.length > 0 ? secValues[0].value : null;
      result.push(new ResultListItemCombined(x.name, [x.value, secValue]));
    });
    itemsSecAgg.forEach((x) => {
      if (itemsPrimAgg.filter((y) => y.name === x.name).length === 0) {
        result.push(new ResultListItemCombined(x.name, [null, x.value]));
      }
    });
    return result;
  }
  return resultListPrimary.map((x) => new ResultListItemCombined(x.name, [x.value, null]));
}

function combineReductionLists(
  resultListPrimary: ResultListItemReduction[],
  resultListSecondary: ResultListItemReduction[] | null
): ResultListItemReductionCombined[] {
  if (resultListSecondary && resultListSecondary.length > 0) {
    // first aggregate seperate lists on name
    const itemsPrimGrouped = groupBy(resultListPrimary, (x) => [x.name, x.rate]);
    const itemsPrimAgg = Object.keys(itemsPrimGrouped).map(
      (x) =>
        new ResultListItemReduction(
          itemsPrimGrouped[x][0].name,
          sumItemList(itemsPrimGrouped[x]),
          sumItemList(itemsPrimGrouped[x]),
          itemsPrimGrouped[x][0].rate
        )
    );
    const itemsSecGrouped = groupBy(resultListSecondary, (x) => [x.name, x.rate]);
    const itemsSecAgg = Object.keys(itemsSecGrouped).map(
      (x) =>
        new ResultListItemReduction(
          itemsSecGrouped[x][0].name,
          sumItemList(itemsSecGrouped[x]),
          sumItemList(itemsSecGrouped[x]),
          itemsSecGrouped[x][0].rate
        )
    );

    // then combine
    const result: any[] = [];
    itemsPrimAgg.forEach((x) => {
      const secValues = itemsSecAgg.filter((y) => y.name === x.name && y.rate === x.rate);
      const secValue = secValues.length > 0 ? secValues[0].value : null;
      const secBasis = secValues.length > 0 ? secValues[0].basis : null;
      result.push(new ResultListItemReductionCombined(x.name, [x.value, secValue], [x.basis, secBasis], x.rate));
    });
    itemsSecAgg.forEach((x) => {
      if (itemsPrimAgg.filter((y) => y.name === x.name && y.rate === x.rate).length === 0) {
        result.push(new ResultListItemReductionCombined(x.name, [null, x.value], [null, x.basis], x.rate));
      }
    });
    return result;
  }
  return resultListPrimary.map(
    (x) => new ResultListItemReductionCombined(x.name, [x.value, null], [x.basis, null], x.rate)
  );
}

export function concatItemLists(
  resultListPrimary: ResultListItem[],
  resultListSecondary: ResultListItem[] | null
): ResultListItem[] {
  const combined = combineItemLists(resultListPrimary, resultListSecondary);
  return combined.map(
    (item) =>
      new ResultListItem(
        item.name,
        item.value.reduce((a: any, b: any) => a + b, 0)
      )
  );
}

export function concatReductionLists(
  resultListPrimary: ResultListItemReduction[],
  resultListSecondary: ResultListItemReduction[] | null
): ResultListItemReduction[] {
  const combined = combineReductionLists(resultListPrimary, resultListSecondary);
  return combined.map(
    (item) =>
      new ResultListItemReduction(
        item.name,
        item.value.reduce((a: any, b: any) => a + b, 0),
        item.basis.reduce((a: any, b: any) => a + b, 0),
        item.rate
      )
  );
}
