import CollectionType from "@/components/input/complexTypes/collectionType";
import ItemType from "@/components/input/complexTypes/foreigninsuranceType";
import Country from "@/components/input/country";

const ITEM_NAME = "assurance";
const INITIAL_ITEMS = 3;
const MAX_ITEMS = 3;

export default class TitulistInsur3Type extends CollectionType<ItemType> {
  constructor(items: ItemType[] | undefined) {
    super(items, ItemType, ITEM_NAME, INITIAL_ITEMS, MAX_ITEMS);
  }

  static fromObject(obj: any, countries?: Map<string, Country>): TitulistInsur3Type {
    return CollectionType.fromObjectHelper(obj, ItemType, ITEM_NAME, INITIAL_ITEMS, MAX_ITEMS, countries);
  }
}
