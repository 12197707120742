import SectionInfo from "@/components/input/sectionInfo";
import CodeInfo from "@/components/input/codeInfo";
import Country from "@/components/input/country";
import City from "@/components/input/city";
import Region from "@/components/input/region";

type CodeItem = import("@/components/input/codeItem").default;

// todo: store these in manual_info
const fiscalLongTermSavingsCodes = ["1353", "2353", "1354", "2354", "contIndivAssVie"];
const householdCodes = [
  "1384",
  "1030",
  "1031",
  "1038",
  "1039",
  "1034",
  "1035",
  "1054",
  "1055",
  "1036",
  "1037",
  "1058",
  "1059",
  "1027",
  "1029",
  "1043",
  "1044",
  "1032",
  "1033",
];
const realEstateAndLoanWizardSections = ["3", "9"];
const realEstateAndLoanWizardsCodesIncluded = [
  "1140",
  "2140",
  "1141",
  "2141",
  "1142",
  "2142",
  "1144",
  "2144",
  "1145",
  "2145",
  "1401",
  "2401",
  "1156",
  "2156",
];
const realEstateAndLoanWizardsCodesExcluded = [
  "1114",
  "2114",
  "1143",
  "2143",
  "1147",
  "2147",
  "3143",
  "3147",
  "debAlimBelgeF403",
  "debAlimBelgeF402",
  "noteCadre3",
  "noteCadre9",
  "immoEtrgExonProgres",
  "immoEtrgDiminImpot",
];

export default class StaticInfo {
  readonly sectionInfo: Map<string, Map<string, SectionInfo>>;

  readonly codeInfo: Map<string, Map<string, CodeInfo>>;

  readonly cities: Map<string, City>;

  readonly regions: Map<string, Region>;

  readonly regularCountries: Map<string, Country>;

  readonly countriesForRealEstate: Map<string, Country>;

  readonly countries: Map<string, Country>;

  readonly module: any;

  constructor(taxYear: number, language: string, staticInfoModule: any, i18n: any) {
    this.cities = StaticInfo.createCities(staticInfoModule);
    this.regions = StaticInfo.createRegions(staticInfoModule);
    this.regularCountries = StaticInfo.createRegularCountries(staticInfoModule);
    this.countriesForRealEstate = StaticInfo.createCountriesForRealEstate(
      this.regularCountries,
      staticInfoModule,
      i18n
    );
    this.countries = new Map([...this.regularCountries, ...this.countriesForRealEstate]);
    this.sectionInfo = StaticInfo.createSectionInfo(taxYear, language, staticInfoModule, i18n);
    this.codeInfo = StaticInfo.createCodeInfo(
      this.sectionInfo,
      this.countries,
      taxYear,
      language,
      staticInfoModule,
      i18n
    );
    this.module = staticInfoModule;
  }

  get cityList(): City[] {
    return Array.from(this.cities.values());
  }

  private static createCities(staticInfoModule: { cities: Map<string, any> }): Map<string, City> {
    return new Map(
      Object.entries(staticInfoModule.cities).map(([postcode, info]) => [
        postcode,
        Object.freeze(new City(postcode, info.region, info.name, info.rate)),
      ])
    );
  }

  private static createRegions(staticInfoModule: { regions: any }): Map<string, Region> {
    return new Map(
      Object.entries(staticInfoModule.regions).map(([id, item]: [string, any]) => [
        item.id,
        Object.freeze(new Region(item.id, id, item.name)),
      ])
    );
  }

  private static createRegularCountries(staticInfoModule: { countries: Map<string, any> }): Map<string, Country> {
    return new Map(
      Object.entries(staticInfoModule.countries).map(([id, name]) => [id, Object.freeze(new Country(id, name))])
    );
  }

  private static createCountriesForRealEstate(
    regularCountries: Map<string, Country>,
    staticInfoModule: { countriesRealEstate: any },
    i18n: any
  ): Map<string, Country> {
    if (!staticInfoModule.countriesRealEstate) {
      return new Map();
    }
    const bothSet = new Set(staticInfoModule.countriesRealEstate.both);
    return new Map(
      staticInfoModule.countriesRealEstate.countries.map((countryId: string) => {
        const regularId = countryId.substring(0, 2);
        const regularName = regularCountries!.has(regularId) ? regularCountries!.get(regularId)!.name : "unknown";
        const suffix =
          countryId.slice(-2) === "_P"
            ? ` (${i18n.t("input.editor.foreign_income_progression")})`
            : ` (${i18n.t("input.editor.foreign_income_deduction")})`;
        return [
          countryId,
          Object.freeze(new Country(countryId, `${regularName}${bothSet.has(regularId) ? suffix : ""}`)),
        ];
      })
    );
  }

  private static createSectionInfo(
    taxYear: number,
    language: string,
    staticInfoModule: { sections: any[] },
    i18n: any
  ): Map<string, Map<string, SectionInfo>> {
    const sectionInfo = new Map(
      Object.entries(staticInfoModule.sections).map(([regionId, sections]) => [
        regionId,
        new Map(
          Object.entries(sections).map(([sectionId, item]): [string, any] => [
            sectionId,
            Object.freeze(
              new SectionInfo(
                i18n,
                taxYear,
                language,
                regionId,
                sectionId,
                // @ts-ignore
                item.chapter,
                // @ts-ignore
                item.group ? item.group : null,
                // @ts-ignore
                item.description,
                // @ts-ignore
                item.label
              )
            ),
          ])
        ),
      ])
    );

    sectionInfo.forEach((_sections, regionId) => {
      if (regionId !== "shared") {
        sectionInfo.set(
          regionId,
          new Map<string, SectionInfo>([...sectionInfo.get(regionId)!, ...sectionInfo.get("shared")!])
        );
      }
    });
    return sectionInfo;
  }

  private static getWizards(codeId: string, sectionId: string) {
    const wizards = [];
    if (
      (realEstateAndLoanWizardSections.includes(sectionId) &&
        !realEstateAndLoanWizardsCodesExcluded.includes(codeId)) ||
      realEstateAndLoanWizardsCodesIncluded.includes(codeId)
    ) {
      wizards.push("real_estate_and_loans");
    }
    if (fiscalLongTermSavingsCodes.includes(codeId)) {
      wizards.push("savings");
    }
    if (householdCodes.includes(codeId)) {
      wizards.push("household");
    }
    return wizards;
  }

  public static createCodeInfo(
    sectionInfo: Map<string, Map<string, SectionInfo>>,
    countries: Map<string, Country>,
    taxYear: number,
    language: string,
    staticInfoModule: any,
    i18n: any
  ): Map<string, Map<string, CodeInfo>> {
    const codeInfoTemp = new Map<string, Map<string, CodeInfo>>();
    Object.entries(staticInfoModule.codes).forEach(([regionId]: any[]) => {
      codeInfoTemp.set(regionId, new Map<string, CodeInfo>());

      Object.entries(staticInfoModule.codes[regionId]).forEach(([codeId, currentCodeInfo]: any[]) => {
        codeInfoTemp
          .get(regionId)!
          .set(
            codeId,
            new CodeInfo(
              i18n,
              taxYear,
              language,
              regionId,
              codeId,
              sectionInfo!.get(regionId)!.get(currentCodeInfo.section_id)!,
              0,
              currentCodeInfo.description,
              currentCodeInfo.label,
              currentCodeInfo.type,
              currentCodeInfo.foreign_possible !== undefined ? currentCodeInfo.foreign_possible : false,
              currentCodeInfo.no_partner !== undefined ? !currentCodeInfo.no_partner : true,
              currentCodeInfo.not_implemented !== undefined ? currentCodeInfo.not_implemented : false,
              currentCodeInfo.invisible !== undefined ? currentCodeInfo.invisible : false,
              !["1061", "1090", "global1", "global2"].includes(codeId),
              currentCodeInfo.foreign_taxed !== undefined ? currentCodeInfo.foreign_taxed : false,
              currentCodeInfo.sum_field_total !== undefined ? currentCodeInfo.sum_field_total : false,
              codeId in staticInfoModule.addendums ? staticInfoModule.addendums[codeId] : "",
              currentCodeInfo.rules !== undefined ? currentCodeInfo.rules : [],
              StaticInfo.getWizards(codeId, currentCodeInfo.section_id)
            )
          );
      });
    });
    const codeInfo = new Map<string, Map<string, CodeInfo>>();
    codeInfoTemp.forEach((_sections, regionId) => {
      if (regionId !== "shared") {
        const orderWithIndex = new Map(
          staticInfoModule.order[regionId].map((elem: string, index: number) => [elem, index])
        );
        codeInfo.set(regionId, new Map<string, CodeInfo>());
        const relevantItems = new Map<string, CodeInfo>([
          ...codeInfoTemp.get(regionId)!,
          ...codeInfoTemp.get("shared")!,
        ]);
        relevantItems.forEach((codeItem, codeId) => {
          const orderNumber = orderWithIndex.has(codeId) ? (orderWithIndex.get(codeId) as number) : 0;
          codeInfo
            .get(regionId)!
            .set(
              codeId,
              new CodeInfo(
                i18n,
                codeItem.taxYear,
                codeItem.language,
                codeItem.region,
                codeItem.code,
                codeItem.section,
                orderNumber,
                codeItem.description,
                codeItem.label,
                codeItem.type,
                codeItem.foreignPossible,
                codeItem.partnerPossible,
                codeItem.notImplemented,
                codeItem.invisible,
                codeItem.removable,
                codeItem.foreignTaxed,
                codeItem.sumFieldTotal,
                codeItem.addendum,
                codeItem.rulesList,
                codeItem.wizards
              )
            );
          if (codeId === "1090") {
            codeInfo
              .get(regionId)!
              .get(codeId)!
              .customRules.push((value: number, codeItems: Map<string, CodeItem>) =>
                [1, 2, 3].includes(value)
                  ? Region.isValidRegionForInput(
                      staticInfoModule.regions[value.toFixed(0)].id,
                      codeItems,
                      staticInfoModule,
                      i18n
                    )
                  : `region should be one of 1, 2, 3: ${value}`
              );
          }
          if (codeId === "unknown") {
            codeInfo
              .get(regionId)!
              .get(codeId)!
              .customRules.push(() => i18n.t("input.view.unknown_code"));
          }
          if (codeItem.partnerPossible) {
            const partnerCode = codeItem.partnerCode;
            codeInfo
              .get(regionId)!
              .set(
                partnerCode,
                new CodeInfo(
                  i18n,
                  codeItem.taxYear,
                  codeItem.language,
                  codeItem.region,
                  partnerCode,
                  codeItem.section,
                  orderNumber + 0.5,
                  codeItem.description,
                  codeItem.label,
                  codeItem.type,
                  codeItem.foreignPossible,
                  codeItem.partnerPossible,
                  codeItem.notImplemented,
                  codeItem.invisible,
                  codeItem.removable,
                  codeItem.foreignTaxed,
                  codeItem.sumFieldTotal,
                  codeItem.addendum,
                  codeItem.rulesList,
                  codeItem.wizards
                )
              );
          }
        });
      }
    });
    codeInfo.forEach((codes) =>
      codes.forEach((info) => {
        info.initRules(codes, countries);
      })
    );
    codeInfo.forEach((codes, region) =>
      codes.forEach((info) => {
        if (info.isSumTerm) {
          codeInfo.get(region)!.get(info.parentSumCode!)!.sumTermCodesInfo.push(info);
        }
      })
    );
    const sharedContainer = codeInfo.get("flanders");
    codeInfo.set(
      "shared",
      new Map(
        Array.from(sharedContainer!.entries()).filter(
          ([, b]) =>
            codeInfoTemp.get("shared")!.has(b.code) ||
            codeInfoTemp.get("shared")!.has(sharedContainer!.get(b.code)!.partnerCode)
        )
      )
    );
    // freeze codeInfo
    return new Map(
      Array.from(codeInfo.entries()).map(([key, values]) => [
        key,
        new Map(Array.from(values.entries()).map(([codeId, codeInfo]) => [codeId, Object.freeze(codeInfo)])),
      ])
    );
  }
}
