import CodeType from "@/components/input/complexTypes/codeType";

export default class NatureCodeType {
  constructor(nature: string | undefined, ipCode: CodeType) {
    this.nature = nature;
    this.ipCode = ipCode;
  }

  nature: string | undefined;

  ipCode: CodeType;

  toString(i18n: any = undefined): string {
    return this.nature || this.ipCode?.toObject()
      ? `${this.ipCode?.toString(i18n)} (${this.nature?.substring(0, 35)})`
      : "";
  }

  toObject(): Object | undefined {
    if (this.nature || this.ipCode?.toObject()) {
      return {
        nature: this.nature ? this.nature?.substring(0, 35) : null,
        ipcode: this.ipCode?.toObject(true),
      };
    }
    return undefined;
  }

  static fromObject(obj: any): NatureCodeType {
    return new NatureCodeType(obj?.nature, CodeType.fromObject(obj?.ipcode));
  }
}
