
import Vue from "vue";
import { Component, Prop, Watch } from "nuxt-property-decorator";
import { mdiMap, mdiArrowRightBottom } from "@mdi/js";
import CodeItem from "@/components/input/codeItem";

@Component
export default class ForeignIncomeTypeView extends Vue {
  @Prop({ required: true, type: Object })
  codeItem!: CodeItem;

  @Prop({ default: false, type: Boolean })
  readonly disabled!: boolean;

  mdiMap = mdiMap;

  mdiArrow = mdiArrowRightBottom;

  toString(): string {
    return this.codeItem.value;
  }

  @Watch("codeItem.value")
  onStringChange(newString: string | null) {
    this.$emit("update", newString);
  }

  focus(_options: object) {}
}
