
import Vue from "vue";
import { Component, Prop, Watch } from "nuxt-property-decorator";
import deburr from "lodash.deburr";
import CodeItem from "@/components/input/codeItem";
import Country from "@/components/input/country";
import BenefRenteAlimType from "@/components/input/complexTypes/benefRenteAlimType";

@Component
export default class BenefRenteAlimTypeView extends Vue {
  @Prop({ required: true, type: Object })
  readonly codeItem!: CodeItem;

  @Prop({ required: true, type: Map })
  readonly countries!: Map<string, Country>;

  @Prop({ default: true, type: Boolean })
  readonly editable!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly disabled!: boolean;

  valueInstance: BenefRenteAlimType = Vue.observable(
    BenefRenteAlimType.fromObject(this.codeItem.value, this.countries)
  );

  get countryListRegular(): Country[] {
    return Array.from(this.countries.values()).filter((c) => c.isRegular);
  }

  filterCountry: Object = (item: Country, queryText: string) =>
    deburr(item.description.toLocaleLowerCase()).includes(deburr(queryText.toLocaleLowerCase()));

  @Watch("codeItem.value", { deep: true })
  onValueChange(newValue: Object) {
    this.valueInstance = Vue.observable(BenefRenteAlimType.fromObject(newValue, this.countries));
  }

  @Watch("valueInstance", { deep: true })
  onValueInstanceChange(newValueInstance: BenefRenteAlimType) {
    const newObject = newValueInstance.toObject();
    const result = newObject || null;
    if (JSON.stringify(result) !== JSON.stringify(this.codeItem.value)) {
      this.$emit("update", result);
    }
  }
}
