export const appendixTypes = [
  "OptionalPart1",
  "OptionalPart2",
  "ElectricChargingInvoice",
  "ElectricChargingCertificate",
  "Delay",
  "ProfessionalRent",
  "LegalConstruction",
];

export default class TowFile {
  // Properties
  filename: string | null;
  data: string | null;
  name: string | null;
  appendixType: string | null;

  get isValid() {
    return (
      this.filename &&
      /^.+(\.(pdf|xlsx|xls))$/.test(this.filename) &&
      this.name &&
      this.name.length > 0 &&
      this.name.length <= 200 &&
      this.appendixType &&
      this.isValidAppendixType(this.appendixType) &&
      this.data &&
      this.data.length > 0 &&
      Buffer.byteLength(this.data, "utf8") <= 2 * 1024 * 1024
    );
  }

  appendixTypeDescription(i18n: any) {
    return this.appendixType ? `${i18n.t(`tow_files.appendix_types.${this.appendixType}`)}` : null;
  }

  constructor(filename?: string, data?: string, name?: string, appendixType?: string) {
    if (filename && !/^[a-zA-Z0-9_\-]{1,250}(\.(pdf|xlsx|xls))$/.test(filename)) {
      throw new Error("Filename must match the specified pattern and be no longer than 250 characters.");
    }

    if (data && Buffer.byteLength(data, "utf8") > 2 * 1024 * 1024) {
      throw new Error("Data must not exceed 2MB.");
    }

    if (name && name.length > 200) {
      throw new Error("Name must not exceed 200 characters.");
    }

    if (appendixType && !this.isValidAppendixType(appendixType)) {
      throw new Error("Appendix type is not valid.");
    }

    this.filename = filename || null;
    this.data = data || null;
    this.name = name || null;
    this.appendixType = appendixType || null;
  }

  isValidAppendixType(appendixType: string): boolean {
    return appendixTypes.includes(appendixType);
  }

  get sanitizedFilename() {
    return this.filename ? TowFile.sanitizedFilename(this.filename) : this.filename;
  }

  toObject(): object {
    return {
      filename: this.sanitizedFilename,
      data: this.data,
      name: this.name,
      type: this.appendixType,
    };
  }

  static sanitizedFilename(filename: string): string {
    const extensionMatch = filename.match(/\.(pdf|xlsx|xls)$/i);
    const extension = extensionMatch ? extensionMatch[0].toLowerCase() : ".pdf";
    const nameWithoutExtension = filename.replace(/\.[^/.]+$/, "");

    const sanitizedBase = nameWithoutExtension.replace(/[^a-zA-Z0-9_-]/g, "").substring(0, 250 - extension.length);

    return sanitizedBase + extension;
  }
}
