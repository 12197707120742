type CapitalNature = import("@/components/input/capitalNature").default;
type Country = import("@/components/input/country").default;

export default class ForeignIncome {
  constructor(
    country: Country | null,
    value: any,
    nature: CapitalNature | undefined = undefined,
    taxed: boolean | undefined = undefined
  ) {
    this.country = country;
    this.value = value;
    this.nature = nature;
    this.taxed = taxed;
  }

  country: Country | null;

  value!: any;

  taxed: boolean | undefined;

  nature: CapitalNature | undefined;

  // duplicate in codeInfo.ts and VNumberField.ts
  convertToFloat(input: string | null): number | undefined {
    if (input === null || input === undefined || input === "") {
      return undefined;
    }

    // Remove spaces as they could be used as thousand separators
    let sanitizedInput = input.replace(/\s/g, "");

    // Check if the input contains both ',' and '.'
    const containsComma = sanitizedInput.includes(",");
    const containsDot = sanitizedInput.includes(".");

    // Determine the last occurrence of comma and dot
    const lastCommaIndex = sanitizedInput.lastIndexOf(",");
    const lastDotIndex = sanitizedInput.lastIndexOf(".");

    if (containsComma && containsDot) {
      if (lastCommaIndex > lastDotIndex) {
        // Comma is the decimal separator, dot is the thousand separator
        sanitizedInput = sanitizedInput.replace(/\./g, "").replace(/,/g, ".");
      } else {
        // Dot is the decimal separator, comma is the thousand separator
        sanitizedInput = sanitizedInput.replace(/,/g, "");
      }
    } else if (containsComma) {
      // If it contains only commas, determine its role
      const parts = sanitizedInput.split(",");
      if (parts.length > 2 || parts[1].length === 3) {
        // Comma is the thousand separator
        sanitizedInput = sanitizedInput.replace(/,/g, "");
      } else {
        // Comma is the decimal separator
        sanitizedInput = sanitizedInput.replace(/,/g, ".");
      }
    } else if (containsDot) {
      // If it contains only dots, determine its role
      const parts = sanitizedInput.split(".");
      if (parts.length > 2 || parts[1].length === 3) {
        // Dot is the thousand separator
        sanitizedInput = sanitizedInput.replace(/\./g, "");
      }
    }

    return parseFloat(sanitizedInput);
  }

  get parsedValue(): number | null {
    if (["", null, undefined].includes(this.value)) return null;
    return typeof this.value === "number" ? this.value : this.convertToFloat(this.value.replace(",", ".")) || null;
  }

  get isComplete(): boolean {
    return ![null, "", NaN].includes(this.value) && this.country !== null;
  }

  get isEmpty(): boolean {
    return this.country === null && [null, ""].includes(this.value);
  }

  clone(): ForeignIncome {
    return new ForeignIncome(this.country, this.value, this.nature, this.taxed);
  }
}
