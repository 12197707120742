
import { Component, Vue, Prop } from "vue-property-decorator";
import { mdiAlert, mdiInformationOutline } from "@mdi/js";
import SimulationResultSingle from "@/components/result/simulationResultSingle";

@Component
export default class SimulationResultRowView extends Vue {
  @Prop({ required: true, type: Array })
  readonly categories!: string[];

  @Prop({ required: true, type: Map })
  readonly simulationResults!: Map<string, SimulationResultSingle>;

  @Prop({ required: true, type: Map })
  readonly cssValueClasses!: Map<string, string[]>;

  @Prop({ required: true, type: Function })
  readonly valueFunc!: (result: SimulationResultSingle) => number | string | null;

  @Prop({ default: true })
  readonly roundToZero!: boolean;

  @Prop({ type: String })
  readonly valueSuffix!: string;

  @Prop({ type: String })
  readonly valuePrefix!: string;

  @Prop({ default: false, type: Boolean })
  readonly isInteger!: boolean;

  @Prop({ default: true, type: Boolean })
  readonly showDiff!: boolean;

  @Prop({ default: null, type: String })
  readonly nameTooltip!: string;

  @Prop({ default: "7em", type: String })
  readonly valueMinWidth!: string;

  mdiAlert = mdiAlert;

  mdiInformation = mdiInformationOutline;

  get values(): Map<string, number | string | null> {
    return new Map(
      Array.from(this.simulationResults.entries()).map(([id, result]) => [id, this.round(this.valueFunc(result))])
    );
  }

  get diff(): number | null {
    if (this.includeDiff && this.visibleResultIds.length >= 2) {
      const value1 = this.values.get(this.visibleResultIds[0])!;
      const value2 = this.values.get(this.visibleResultIds[1])!;
      if (
        !(value1 === null && value2 === null) &&
        (typeof value1 === "number" || value1 === null) &&
        (typeof value2 === "number" || value2 === null)
      ) {
        return (value2 !== null ? value2 : 0.0) - (value1 !== null ? value1 : 0.0);
      }
    }
    return null;
  }

  get visibleResultIds(): string[] {
    return (
      Array.from(this.cssValueClasses.entries())
        // .filter(([, classes]) => classes.filter(((cls) => cls !== '')).length === 1)
        .map(([id]) => id)
    );
  }

  get includeDiff(): boolean {
    return this.visibleResultIds.length === 2;
  }

  round(value: number | null | string): number | null | string {
    if (this.roundToZero && typeof value === "number" && value > -0.015 && value < 0.015) {
      return 0.0;
    }
    return value;
  }

  get formatArgs(): any {
    return [this.$i18n.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }];
  }

  get formatArgs1(): any {
    return [this.$i18n.locale, { minimumFractionDigits: 1, maximumFractionDigits: 1 }];
  }
}
