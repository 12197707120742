
import { mdiCheck, mdiClose, mdiCommentOutline, mdiCommentTextOutline, mdiCommentBookmarkOutline } from "@mdi/js";
import { Component, Vue, Prop, Watch } from "nuxt-property-decorator";
import UserCommentEditorView from "@/components/input/UserCommentEditorView.vue";
import CodeItem from "@/components/input/codeItem";

@Component({
  components: {
    UserCommentEditorView,
  },
})
export default class UserCommentEditorPopupView extends Vue {
  @Prop({ required: true, type: Array })
  readonly codeItems!: CodeItem[];

  @Prop({ required: false, type: String })
  readonly defaultCode: string | undefined;

  @Prop({ default: true, type: Boolean })
  readonly editable!: boolean;

  commentDialog = false;

  commentDialogEditorView = false;

  mdiCheck = mdiCheck;

  mdiClose = mdiClose;

  mdiCommentTextOutline = mdiCommentTextOutline;

  mdiCommentOutline = mdiCommentOutline;

  mdiCommentBookmarkOutline = mdiCommentBookmarkOutline;

  mounted() {}

  get codeItemsWithSomeCommentOrTag(): CodeItem[] {
    return this.codeItems.filter(
      (item) => item.userComment || item.tags.filter((tag) => ["todo"].includes(tag)).length > 0
    );
  }

  get oneHasValue(): boolean {
    return this.codeItemsWithSomeCommentOrTag.length > 0;
  }

  get oneIsTodo(): boolean {
    return this.codeItems.some((item) => item.isTodo);
  }

  get valueString(): string | undefined {
    if (this.codeItems.length === 0) {
      return undefined;
    }
    if (this.codeItems.length === 1) {
      return this.codeItemsWithSomeCommentOrTag[0].userComment
        ? this.codeItemsWithSomeCommentOrTag[0].userComment
        : "TODO";
    }
    if (this.codeItems.length > 1) {
      return this.codeItemsWithSomeCommentOrTag
        .map(
          (item) =>
            `<b>${item.info.code}</b>${item.isTodo ? " (TODO)" : ""}${item.userComment ? ":\n" + item.userComment : ""}`
        )
        .join("\n\n");
    }
  }

  @Watch("commentDialog")
  onCommentDialogChange(enabled: boolean) {
    if (enabled) {
      this.commentDialogEditorView = true;
    } else {
      setTimeout(() => {
        this.commentDialogEditorView = false;
      }, 200);
    }
  }

  close() {
    this.commentDialog = false;
    this.$emit("close");
  }

  save(codeItems: CodeItem[]) {
    this.$emit("save", codeItems);
    this.commentDialog = false;
  }
}
