import Country from "@/components/input/country";

export default class BenefRenteAlimType {
  constructor(nameAddress: string | undefined, country: Country | undefined) {
    this.nameAddress = nameAddress;
    this.country = country;
  }

  nameAddress: string | undefined;

  country: Country | undefined;

  customErrors(i18n: any): string[] {
    const customErrors = [];

    if (this.nameAddress == undefined && this.country != undefined) {
      customErrors.push(`info ${i18n.t("input.validation.required")}`);
    }

    if (this.country == undefined && this.nameAddress != undefined) {
      customErrors.push(
        `${i18n.t("input.complexTypes.benefRenteAlimType.country").toString().toLowerCase()} ${i18n.t(
          "input.validation.required"
        )}`
      );
    }

    return customErrors;
  }

  toString(): string {
    return this.nameAddress || this.country ? `${this.nameAddress} (${this.country?.id})` : "";
  }

  toObject(): Object | undefined {
    if (this.nameAddress || this.country) {
      return {
        nameAddress: this.nameAddress ? this.nameAddress?.substring(0, 120) : null,
        country: this.country ? this.country.id : null,
      };
    }
    return undefined;
  }

  static fromObject(obj: any, countries?: Map<string, Country>): BenefRenteAlimType {
    const country = obj?.country && countries ? countries.get(obj.country) : undefined;
    return new BenefRenteAlimType(obj?.nameAddress, country);
  }

  static fixedObject(obj: any): any {
    if (obj && obj.item && obj.item.length > 0) {
      return obj.item[0];
    }
    return obj;
  }
}
