
import { Component, Prop, Vue } from "nuxt-property-decorator";
import SimulationInput, { ForeignCodeObject } from "~/components/input/simulationInput";
import { mdiLockOutline, mdiLockOpenOutline, mdiFileDocumentEditOutline, mdiArrowRightBottom } from "@mdi/js";
@Component({
  components: {},
})
export default class WizardConfirmationDialog extends Vue {
  @Prop({ required: true, type: Object })
  readonly simulationInput!: SimulationInput;

  @Prop({ required: true, type: String })
  readonly wizardName!: string;

  @Prop({ required: true, type: Array })
  readonly foreignCodesFromWizard!: ForeignCodeObject[];

  @Prop({ required: true, type: Object })
  readonly codesFromWizard!: { [index: string]: any };

  @Prop({ required: false, type: Boolean })
  readonly onlyUpdate!: boolean;

  @Prop({ default: true, type: Boolean })
  readonly includeNoneValues!: boolean;

  mdiLockOutline = mdiLockOutline;

  mdiLockOpenOutline = mdiLockOpenOutline;

  mdiFileDocumentEditOutline = mdiFileDocumentEditOutline;

  mdiArrowRightBottom = mdiArrowRightBottom;

  get updatesPresent(): boolean {
    return this.codeItemsImpacted.some(([code, info, existingValue, wizardValue]) => existingValue !== wizardValue);
  }

  get isAlreadyEnabled(): boolean {
    return this.simulationInput.enabledWizards.includes(this.wizardName);
  }

  get codeItemsImpacted(): any[] {
    if (!this.simulationInput.codeInfoForCurrentRegion || !this.simulationInput.codeItems) {
      return [];
    }

    const possiblyImpactedCodes = Array.from(this.simulationInput.codeInfoForCurrentRegion.entries()).filter(
      ([code, info]) => info.wizards.includes(this.wizardName)
    );
    const x = possiblyImpactedCodes
      .map(([code, info]) => [
        code,
        info,
        this.simulationInput.codeItems!.get(code)?.value,
        this.codesFromWizard[code],
        this.simulationInput.codeItems!.get(code)?.tags.includes(`from_wizard:${this.wizardName}`),
      ])
      .filter(
        ([code, info, existingValue, wizardValue, was_from_wizard]) => !(existingValue == null && wizardValue == null)
      );
    return x;
  }

  get codeItemsImpactedFromWizard(): any[] {
    if (!this.includeNoneValues) {
      return this.codeItemsImpacted;
    }
    return this.codeItemsImpacted.filter(
      ([code, info, existingValue, wizardValue, was_from_wizard]) => wizardValue !== undefined || was_from_wizard
    );
  }

  get codeItemsImpactedNotFromWizard(): any[] {
    if (!this.includeNoneValues) {
      return [];
    }
    return this.codeItemsImpacted.filter(
      ([code, info, existingValue, wizardValue, was_from_wizard]) => wizardValue === undefined && !was_from_wizard
    );
  }

  codeDisplayed(code: string): string {
    return code.match("^\\d{4}$") ? code : "extra";
  }

  confirmEnable() {
    this.$emit("enable");
  }

  cancelEnable() {
    this.$emit("close");
  }
}
