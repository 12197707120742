
import Vue from "vue";
import { Component, Prop, Watch } from "nuxt-property-decorator";
import CodeItem from "@/components/input/codeItem";
import DebAlimList from "@/components/input/complexTypes/debAlimList";
import DebAlimType from "@/components/input/complexTypes/debAlimType";

@Component
export default class DebAlimListView extends Vue {
  @Prop({ required: true, type: Object })
  codeItem!: CodeItem;

  @Prop({ default: false, type: Boolean })
  readonly disabled!: boolean;

  @Prop({ default: true, type: Boolean })
  editable!: boolean;

  valueInstance: DebAlimList = Vue.observable(DebAlimList.fromObject(this.codeItem.value));

  get canAddNewItem() {
    return (
      this.valueInstance.items.length === this.codeItem.value?.debAlim?.length &&
      this.valueInstance.items.length < this.valueInstance.maxItems
    );
  }

  addNewItem() {
    if (this.canAddNewItem) {
      this.valueInstance.items.push(DebAlimType.fromObject(null));
    }
  }

  @Watch("codeItem.value", { deep: true })
  onValueChange(newValue: Object) {
    this.valueInstance = Vue.observable(DebAlimList.fromObject(newValue));
  }

  @Watch("valueInstance", { deep: true })
  onValueInstanceChange(valueInstance: DebAlimList) {
    const newObject = valueInstance.toObject();
    const result = newObject || null;
    if (JSON.stringify(result) !== JSON.stringify(this.codeItem.value)) {
      this.$emit("update", result);
    }
  }

  focus(options: object) {
    (this.$refs!.textField! as any[])[0].focus(options);
  }
}
