/* eslint-disable class-methods-use-this */

import ValidationRule from "@/components/input/validation/validationRule";

type CodeItem = import("@/components/input/codeItem").default;

export default class ForeignCompleteStrategy extends ValidationRule {
  validate(primary: CodeItem, state: Map<string, CodeItem>): string | boolean {
    if (!primary.foreign.every((x) => x.isComplete || x.isEmpty)) {
      return this.i18n.t("input.validation.foreignComplete") as string;
    }
    if (primary.info.foreignHasNature && !primary.foreign.every((x) => x.nature !== undefined)) {
      return this.i18n.t("input.validation.foreignCompleteNature") as string;
    }
    if (primary.info.foreignTaxed && !primary.foreign.every((x) => x.taxed !== undefined)) {
      return this.i18n.t("input.validation.foreignCompleteTaxed") as string;
    }
    return true;
  }
}
