import RealEstatePurpose from "@/components/input/wizards/realEstateAndLoans/realEstate/realEstatePurpose";
import RentalDetails from "@/components/input/wizards/realEstateAndLoans/realEstate/rentalDetails";
import Validatable from "@/components/input/wizards/validatable";
import Assert from "~/components/input/wizards/assert";

export default class PurposePeriod extends Validatable {
  rate: number | undefined = undefined;

  dateFrom: Date | undefined = undefined;
  dateTo: Date | undefined = undefined;

  purpose: RealEstatePurpose | undefined = undefined;
  rentalDetails: RentalDetails | undefined;

  consideredAsOwnHouse: boolean | undefined = undefined;

  constructor(
    rate: number | undefined = undefined,
    dateFrom: Date | undefined = undefined,
    dateTo: Date | undefined = undefined,
    purpose: RealEstatePurpose | undefined = undefined,
    rentalDetails: RentalDetails | undefined = undefined,
    consideredAsOwnHouse: boolean | undefined = undefined
  ) {
    const mandatoryFields = ["rate", "dateFrom", "dateTo", "purpose"];
    const constraints = {
      rate: [() => Assert.posiviteRate(this.rate)],
      dateFrom: [() => Assert.lessOrEqualThanDate(this.dateFrom, this.dateTo)],
      dateTo: [() => Assert.greaterOrEqualThanDate(this.dateTo, this.dateFrom)],
      purpose: [
        () => Assert.isEnum(this.purpose, RealEstatePurpose),
        (vars?: any) => {
          if (this.purpose === RealEstatePurpose.OWN_PROFESSION_PARTNER && !vars?.isDoubleReturn) {
            return "OWN_PROFESSION_PARTNER not possible in single return";
          }
          return true;
        },
      ],
    };
    super(mandatoryFields, constraints, ["rentalDetails"]);
    this.rate = rate;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.purpose = purpose;
    this.rentalDetails = rentalDetails;
    this.consideredAsOwnHouse = consideredAsOwnHouse;

    if (
      (this.purpose === RealEstatePurpose.RENTED_OUT_PRIVATE || RealEstatePurpose.RENTED_OUT_PROFESSIONAL) &&
      !this.rentalDetails
    ) {
      this.rentalDetails = new RentalDetails();
    }
  }

  validate(variables?: any, ignoredConstraints?: string[]): Map<string, string[]> | boolean {
    return super.validate({ ...variables, purpose: this.purpose }, ignoredConstraints);
  }

  get isOwnHouse(): boolean {
    return (
      this.purpose === RealEstatePurpose.OWN_HOUSE ||
      (this.consideredAsOwnHouse !== undefined && this.consideredAsOwnHouse)
    );
  }

  clone() {
    return this.cloneHelper(new PurposePeriod());
  }

  periodDateFrom(taxYear: number): Date | undefined {
    if (this.dateFrom === undefined) {
      return undefined;
    }
    const firstDayInPeriod = new Date(`${taxYear - 1}-01-01`);
    return this.dateFrom.getTime() < firstDayInPeriod.getTime() ? firstDayInPeriod : this.dateFrom;
  }

  periodDateTo(taxYear: number): Date | undefined {
    if (this.dateFrom === undefined) {
      return undefined;
    }
    const firstDayInPeriod = new Date(`${taxYear - 1}-01-01`);
    return this.dateFrom.getTime() < firstDayInPeriod.getTime() ? firstDayInPeriod : this.dateFrom;
  }

  static fromObject(obj: { [key: string]: any }): PurposePeriod {
    const result = Validatable.fromObjectHelper(obj, new this());
    if (obj.rental_details) {
      result.rentalDetails = RentalDetails.fromObject(obj.rental_details);
    }
    if (
      (result.purpose === RealEstatePurpose.RENTED_OUT_PRIVATE || RealEstatePurpose.RENTED_OUT_PROFESSIONAL) &&
      !result.rentalDetails
    ) {
      result.rentalDetails = new RentalDetails();
    }
    return result;
  }

  toObject(removeNulls = true): { [key: string]: any } {
    const result = Validatable.toObjectHelper(this, [], removeNulls);
    if (result.rental_details && Object.keys(result.rental_details).length === 0) {
      delete result.rental_details;
    }
    return result;
  }

  hasSamePeriodDatesAs(other: PurposePeriod, taxYear: number): boolean {
    const periodDateFromSelf = this.periodDateFrom(taxYear)?.getTime();
    const periodDateFromOther = other.periodDateFrom(taxYear)?.getTime();

    const periodDateToSelf = this.periodDateTo(taxYear)?.getTime();
    const periodDateToOther = other.periodDateTo(taxYear)?.getTime();

    return periodDateFromSelf === periodDateFromOther && periodDateToSelf === periodDateToOther;
  }

  hasSamePurposeAs(other: PurposePeriod): boolean {
    return this.purpose === other.purpose;
  }
}
