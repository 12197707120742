
import { Component, Prop, Vue } from "nuxt-property-decorator";
import SimulationResultSingle from "@/components/result/simulationResultSingle";
import SimulationResultRowView from "@/components/result/SimulationResultRowView.vue";

@Component({
  components: {
    SimulationResultRowView,
  },
})
export default class SimulationResultSummary extends Vue {
  @Prop({ required: true, type: Object })
  readonly primary!: SimulationResultSingle;

  @Prop({ default: null, type: Object })
  readonly secondary!: SimulationResultSingle | null;

  get simulationResults(): Map<string, SimulationResultSingle> {
    if (this.secondary) {
      return new Map([
        ["prim", this.primary],
        ["sec", this.secondary],
      ]);
    }
    return new Map([["prim", this.primary]]);
  }

  get allCssValueClasses(): Map<string, string[]> {
    if (this.secondary) {
      return new Map([
        ["prim", ["item-value", this.secondary ? "comparison" : ""]],
        ["sec", ["item-value"]],
      ]);
    }
    return new Map([["prim", ["item-value"]]]);
  }

  get simulationResultDiff(): number | null {
    if (this.simulationResults.size === 2) {
      return (
        Array.from(this.simulationResults.values())[0].taxPayable -
        Array.from(this.simulationResults.values())[1].taxPayable
      );
    }
    return null;
  }

  get formatArgs(): any {
    return [this.$i18n.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }];
  }
}
