export const chargingStationTypes = ["BIDIRECTIONAL", "UNIDIRECTIONAL", "BOTH"];

export default class ElectricChargingStationType {
  constructor(
    type?: string,
    postalCode?: string,
    totalExpenseTit?: number,
    totalExpensePart?: number,
    chargePoint?: number[]
  ) {
    this.postalCode = postalCode;
    this.totalExpenseTit = totalExpenseTit;
    this.totalExpensePart = totalExpensePart;
    this.chargePoint = chargePoint || [];
    this.type = type;
  }

  type?: string;
  postalCode?: string;
  totalExpenseTit?: number;
  totalExpensePart?: number;
  chargePoint: (number | null)[] = [];

  get maxDeclarant(): number | null {
    if (this.totalExpenseTit && this.totalExpenseTit > 0 && this.type) {
      return Math.min(this.totalExpenseTit, this.type === "UNIDIRECTIONAL" ? 1750 : 8000);
    }
    return null;
  }

  get maxPartner(): number | null {
    if (this.totalExpensePart && this.totalExpensePart > 0 && this.type) {
      return Math.min(this.totalExpensePart, this.type === "UNIDIRECTIONAL" ? 1750 : 8000);
    }
    return null;
  }

  customErrors(i18n: any): string[] {
    const customErrors = [];

    if (this.chargePoint.length === 0) {
      return [];
    }

    if (this.type == undefined) {
      customErrors.push(`type ${i18n.t("input.validation.required")}`);
    }

    if (this.type && !chargingStationTypes.includes(this.type)) {
      customErrors.push(`type ${i18n.t("input.validation.pattern", ["BIDIRECTIONAL|UNIDIRECTIONAL|BOTH"])}`);
    }

    if (this.postalCode == undefined) {
      customErrors.push(`postalCode ${i18n.t("input.validation.required")}`);
    }

    if (this.chargePoint && this.chargePoint.length > 10) {
      customErrors.push(`charging stations max ${i18n.t("input.validation.maxValue", [10])}`);
    }

    if (this.chargePoint && this.chargePoint.some((cp) => cp === null)) {
      customErrors.push(`chargepoints ${i18n.t("input.validation.required")}`);
    } else if (this.chargePoint && this.chargePoint.some((cp) => cp && (cp < 1 || cp > 10))) {
      customErrors.push(`chargepoints max ${i18n.t("input.validation.maxValue", [10])}`);
    }

    if (this.totalExpenseTit && (Number.isNaN(Number(this.totalExpenseTit)) || this.totalExpenseTit < 0)) {
      customErrors.push(
        `${i18n.t("input.complexTypes.electricChargingStationType.totalExpenseTit")} ${i18n.t(
          "input.complexTypes.electricChargingStationType.expenseError"
        )}`
      );
    }
    if (this.totalExpensePart && (Number.isNaN(Number(this.totalExpensePart)) || this.totalExpensePart < 0)) {
      customErrors.push(
        `${i18n.t("input.complexTypes.electricChargingStationType.totalExpensePart")} ${i18n.t(
          "input.complexTypes.electricChargingStationType.expenseError"
        )}`
      );
    }

    return customErrors;
  }

  toString(): string {
    return this.type || this.postalCode ? `${this.type} (${this.postalCode})` : "";
  }

  toObject(): Object | undefined {
    if (this.type || this.postalCode || this.totalExpenseTit || this.totalExpensePart || this.chargePoint.length > 0) {
      return {
        type: this.type || null,
        postalCode: this.postalCode || null,
        totalExpenseTit: this.totalExpenseTit ? this.totalExpenseTit : undefined,
        totalExpensePart: this.totalExpensePart ? this.totalExpensePart : undefined,
        chargePoint: [...this.chargePoint] || [],
      };
    }
    return undefined;
  }

  static fromObject(obj: any): ElectricChargingStationType {
    return new ElectricChargingStationType(
      obj?.type,
      obj?.postalCode,
      obj?.totalExpenseTit,
      obj?.totalExpensePart,
      obj?.chargePoint ? [...obj.chargePoint] : []
    );
  }

  static fixedObject(obj: any): any {
    if (obj && obj.item && obj.item.length > 0) {
      return obj.item[0];
    }
    return obj;
  }
}
