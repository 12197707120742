enum Kinship {
  CHILD = "CHILD",
  PARENT = "PARENT",
  FOSTER_PARENT = "FOSTER_PARENT",
  SIBLING = "SIBLING",
  GRANDCHILD = "GRANDCHILD",
  GRANDPARENT = "GRANDPARENT",
  OTHER = "OTHER",
}

export default Kinship;
