export default class Country {
  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }

  id!: string;

  name!: string;

  get description() {
    return `${this.id} - ${this.name}`;
  }

  get isRegular() {
    return this.id.length === 2;
  }

  get isRealEstate() {
    return this.id.endsWith("_P") || this.id.endsWith("_D");
  }

  get prettyId() {
    if (this.id.endsWith("_2")) {
      return this.id.substring(0, this.id.length - 2).concat("*");
    }
    if (this.name.endsWith(")")) {
      if (this.id.endsWith("_P")) {
        return this.id.substring(0, this.id.length - 2);
      }
      if (this.id.endsWith("_D")) {
        return this.id.substring(0, this.id.length - 2).concat("*");
      }
    }
    if (this.id.endsWith("_P") || this.id.endsWith("_D")) {
      return this.id.substring(0, this.id.length - 2);
    }
    return this.id;
  }
}
