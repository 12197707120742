import CollectionType from "@/components/input/complexTypes/collectionType";
import ItemType from "@/components/input/complexTypes/natureCodeType";

const ITEM_NAME = "item";
const INITIAL_ITEMS = 1;
const MAX_ITEMS = 10;

export default class Naturecode10Type extends CollectionType<ItemType> {
  constructor(items: ItemType[] | undefined) {
    super(items, ItemType, ITEM_NAME, INITIAL_ITEMS, MAX_ITEMS);
  }

  static fromObject(obj: any): Naturecode10Type {
    return CollectionType.fromObjectHelper(obj, ItemType, ITEM_NAME, INITIAL_ITEMS, MAX_ITEMS);
  }
}
