import { render, staticRenderFns } from "./debAlimListView.vue?vue&type=template&id=07cbca24&scoped=true&"
import script from "./debAlimListView.vue?vue&type=script&lang=ts&"
export * from "./debAlimListView.vue?vue&type=script&lang=ts&"
import style0 from "./debAlimListView.vue?vue&type=style&index=0&id=07cbca24&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07cbca24",
  null
  
)

export default component.exports