
import { Component, Vue, Prop, Watch } from "nuxt-property-decorator";
import { mdiDelete, mdiMap } from "@mdi/js";
import CodeItem from "./codeItem";
import Country from "@/components/input/country";
import ForeignIncome from "@/components/input/foreignIncome";
import CodeItemEditorForeignInputLineView from "@/components/input/CodeItemEditorForeignInputLineView.vue";

@Component({
  components: {
    CodeItemEditorForeignInputLineView,
  },
})
export default class CodeItemEditorForeignInputView extends Vue {
  @Prop({ required: true, type: Object })
  readonly codeItem!: CodeItem;

  @Prop({ required: false, type: Object })
  readonly codeItemPartner!: CodeItem | null;

  @Prop({ required: true, type: Array })
  readonly lockedByWizards!: Array<string>;

  @Prop({ required: true, type: Map })
  readonly countries!: Map<string, Country>;

  @Prop({ default: true, type: Boolean })
  readonly editable!: boolean;

  allowTransitions = false;

  mdiDelete = mdiDelete;

  mdiMap = mdiMap;

  foreignInputLines: [null | string, number | null, ForeignIncome][] = [];

  mounted() {
    if (this.codeItem.foreign.length === 0 && (!this.codeItemPartner || this.codeItemPartner.foreign.length === 0)) {
      this.addForeignIncome();
    } else {
      const regular: [null | string, null | number, ForeignIncome][] = this.codeItem.foreign.map((i, index) => [
        this.codeItem.info.code,
        index,
        i,
      ]);
      const partner: [null | string, null | number, ForeignIncome][] = this.codeItemPartner
        ? this.codeItemPartner.foreign.map((i, index) => [this.codeItemPartner!.info.code, index, i])
        : [];
      this.foreignInputLines = [...regular, ...partner];
    }
    setTimeout(() => {
      this.allowTransitions = true;
    }, 2000);
  }

  get locked(): boolean {
    return this.lockedByWizards.length > 0;
  }

  get validCodes(): string[] {
    const codes = [];
    if (this.codeItem.value) {
      codes.push(this.codeItem.info.code);
    }
    if (this.codeItemPartner && this.codeItemPartner.value) {
      codes.push(this.codeItemPartner.info.code);
    }
    return codes;
  }

  // refresh the copy of foreign_income
  @Watch("codeItem.foreign")
  onCodeItemForeignChange(newValue: ForeignIncome[]) {
    this.foreignInputLines.forEach((item) => {
      if (item[0] === this.codeItem.info.code && item[1] !== null) {
        item[2] = newValue[item[1]];
      }
    });
  }

  // refresh the copy of foreign_income
  @Watch("codeItemPartner.foreign")
  onCodeItemPartnerForeignChange(newValue: ForeignIncome[]) {
    this.foreignInputLines.forEach((item) => {
      if (this.codeItemPartner && item[0] === this.codeItemPartner.info.code && item[1] !== null) {
        item[2] = newValue[item[1]];
      }
    });
  }

  addForeignIncome() {
    const foreignTaxed = this.codeItem.info.foreignTaxed ? false : undefined;
    this.foreignInputLines.push([null, null, Vue.observable(new ForeignIncome(null, null, undefined, foreignTaxed))]);
    if (this.validCodes.length === 1) {
      this.changeCode(this.validCodes[0], this.foreignInputLines.length - 1);
    }
  }

  removeForeignIncome(index: number) {
    const item = this.foreignInputLines[index];
    this.foreignInputLines.splice(index, 1);
    if (item[1] !== null) {
      if (this.codeItem.info.code === item[0]) {
        this.codeItem.foreign.splice(item[1], 1);
        // need to change all indexes in this.foreignInputlines
        this.foreignInputLines.forEach((i) => {
          if (i[0] === item[0] && i[1] !== null && item[1] !== null && i[1] > item[1]) {
            i[1] -= 1;
          }
        });
        this.onForeignIncomeChange(this.codeItem.info.code);
      } else if (this.codeItemPartner && this.codeItemPartner.info.code === item[0]) {
        this.codeItemPartner.foreign.splice(item[1], 1);
        this.onForeignIncomeChange(this.codeItemPartner.info.code);
        this.foreignInputLines.forEach((i) => {
          if (i[0] === item[0] && i[1] !== null && item[1] !== null && i[1] > item[1]) {
            i[1] -= 1;
          }
        });
      }
    }
    if (this.foreignInputLines.length === 0) {
      this.$emit("toggleForeignIncome");
    }
  }

  onForeignIncomeChange(code: string) {
    if (code) {
      const codeItem = code === this.codeItem.info.code ? this.codeItem : this.codeItemPartner;
      this.$emit("onForeignIncomeChange", codeItem);
    }
  }

  changeCode(code: string, index: number) {
    if (this.foreignInputLines.length > index) {
      // changing to null should be disabled!
      if (code === this.codeItem.info.code) {
        this.foreignInputLines[index][0] = code;
        this.codeItem.foreign.push(this.foreignInputLines[index][2]);
        this.foreignInputLines[index][1] = this.codeItem.foreign.length - 1;
        this.onForeignIncomeChange(this.codeItem.info.code);
      } else if (this.codeItemPartner && code === this.codeItemPartner.info.code) {
        this.foreignInputLines[index][0] = code;
        this.codeItemPartner.foreign.push(this.foreignInputLines[index][2]);
        this.foreignInputLines[index][1] = this.codeItemPartner.foreign.length - 1;
        this.onForeignIncomeChange(this.codeItemPartner.info.code);
      }
    }
  }
}
