type CodeItem = import("@/components/input/codeItem").default;

export default abstract class ValidationRule {
  i18n: any;

  constructor(i18n: any) {
    this.i18n = i18n;
  }

  abstract validate(primary: any, state: Map<string, CodeItem>): string | boolean;

  get formatArgs(): any {
    return [this.i18n.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }];
  }
}
