
import Vue from "vue";
import { Component, Prop, Watch } from "nuxt-property-decorator";
import CodeItem from "@/components/input/codeItem";
import LooseType from "@/components/input/complexTypes/looseType";

@Component
export default class LooseTypeView extends Vue {
  @Prop({ required: true, type: Object })
  readonly codeItem!: CodeItem;

  @Prop({ default: true, type: Boolean })
  readonly editable!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly disabled!: boolean;

  valueInstance: LooseType = Vue.observable(LooseType.fromObject(this.codeItem.value));

  get valueErrorMessages(): string[] {
    const errors = this.codeItem.allErrors;
    if (this.valueInstance.value !== undefined && isNaN(this.valueInstance.value)) {
      errors.push(this.$i18n.t("input.codeInfo.validation_error_float").toString());
    }
    return errors;
  }

  @Watch("codeItem.value", { deep: true })
  onValueChange(newValue: Object) {
    this.valueInstance = Vue.observable(LooseType.fromObject(newValue));
  }

  @Watch("valueInstance", { deep: true })
  onValueInstanceChange(newValueInstance: LooseType) {
    const newObject = newValueInstance.toObject();
    const result = newObject || null;
    if (JSON.stringify(result) !== JSON.stringify(this.codeItem.value)) {
      this.$emit("update", result);
    }
  }

  focus(options: object) {
    (this.$refs.textField as any).$refs.input.focus(options);
  }
}
