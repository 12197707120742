import Validatable from "~/components/input/wizards/validatable";
import Assert from "~/components/input/wizards/assert";

export default class LongTermSavingsWithoutHouse extends Validatable {
  paymentsContractAfter1989: number | undefined;
  paymentsContractBefore1989: number | undefined;
  contractReference: string | undefined;
  institutionName: string | undefined;
  contractReference_2: string | undefined;
  institutionName_2: string | undefined;

  constructor(
    paymentsContractAfter1989: number | undefined = undefined,
    paymentsContractBefore1989: number | undefined = undefined,
    contractReference: string | undefined = undefined,
    institutionName: string | undefined = undefined,
    contractReference_2: string | undefined = undefined,
    institutionName_2: string | undefined = undefined
  ) {
    const mandatoryFields = ["contractReference", "institutionName"];
    const constraints = {
      paymentsContractAfter1989: [
        () => Assert.greaterOrEqualThan(this.paymentsContractAfter1989, 0.0),
        () => this.assertPaymentsDeclared(),
      ],
      paymentsContractBefore1989: [
        () => Assert.greaterOrEqualThan(this.paymentsContractBefore1989, 0.0),
        () => this.assertPaymentsDeclared(),
      ],
    };
    super(mandatoryFields, constraints);
    this.paymentsContractAfter1989 = paymentsContractAfter1989;
    this.paymentsContractBefore1989 = paymentsContractBefore1989;
    this.contractReference = contractReference;
    this.institutionName = institutionName;
    this.contractReference_2 = contractReference_2;
    this.institutionName_2 = institutionName_2;
  }

  assertPaymentsDeclared(): boolean | string {
    if (this.paymentsContractAfter1989 || this.paymentsContractBefore1989) {
      return true;
    }
    return "at least one required";
  }

  isEmpty(): boolean {
    return (
      !(
        (this.paymentsContractAfter1989 && this.paymentsContractAfter1989 > 0) ||
        (this.paymentsContractBefore1989 && this.paymentsContractBefore1989 > 0)
      ) &&
      !this.contractReference &&
      !this.institutionName &&
      !this.contractReference_2 &&
      !this.institutionName_2
    );
  }

  hasValue(): boolean {
    return !!(
      (this.paymentsContractAfter1989 && this.paymentsContractAfter1989 > 0) ||
      (this.paymentsContractBefore1989 && this.paymentsContractBefore1989 > 0)
    );
  }

  clone() {
    return this.cloneHelper(new LongTermSavingsWithoutHouse());
  }

  static fromObject(obj: { [key: string]: any }): LongTermSavingsWithoutHouse {
    return Validatable.fromObjectHelper(obj, new this());
  }
}
