import RegularCodeStrategy from "@/components/input/validation/regularCodeStrategy";
import NonZeroRequiresReverseStrategy from "~/components/input/validation/nonZeroRequiresReverseStrategy";

type CodeInfo = import("@/components/input/codeInfo").default;
type CodeItem = import("@/components/input/codeItem").default;

export default class NonZeroRequiresStrategy extends RegularCodeStrategy {
  validate(primary: CodeItem, state: Map<string, CodeItem>): string | boolean {
    if (primary.parsedValue === null || primary.parsedValue === 0) {
      return true;
    }

    if (this.necessaryCodes.some((x) => state.has(x.code) && state.get(x.code)!.parsedValue !== null)) {
      return true;
    }

    return this.i18n.t("input.validation.requires", [
      this.necessaryCodes.map((x) => x.code).join(` ${this.i18n.t("input.validation.or")} `),
    ]) as string;
  }

  getReverseRule(primary: CodeInfo): NonZeroRequiresReverseStrategy {
    return new NonZeroRequiresReverseStrategy(this.i18n, primary, this.necessaryCodes);
  }
}
