import RegularValueStrategy from "@/components/input/validation/regularValueStrategy";

type CodeItem = import("@/components/input/codeItem").default;

export default class MinValueStrategy extends RegularValueStrategy {
  validate(primary: CodeItem, state: Map<string, CodeItem>): string | boolean {
    const sum = this.values.reduce((a, b) => a + b, 0);
    return primary === null || primary.parsedValue === null || primary.parsedValue >= sum
      ? true
      : (this.i18n.t("input.validation.minValue", [sum.toLocaleString(...this.formatArgs)]) as string);
  }
}
