import CollectionType from "@/components/input/complexTypes/collectionType";
import ItemType from "@/components/input/complexTypes/benefRenteAlimType";
import Country from "@/components/input/country";

const ITEM_NAME = "item";
const INITIAL_ITEMS = 1;
const MAX_ITEMS = 10;

export default class BenefRenteAlimList extends CollectionType<ItemType> {
  constructor(items: ItemType[] | undefined) {
    super(items, ItemType, ITEM_NAME, INITIAL_ITEMS, MAX_ITEMS);
  }

  customErrors(i18n: any): string[] {
    const customErrors: string[] = [];
    this.items.forEach((item) => customErrors.push(...item.customErrors(i18n)));
    return customErrors;
  }

  static fromObject(obj: any, countries?: Map<string, Country>): BenefRenteAlimList {
    return CollectionType.fromObjectHelper(obj, ItemType, ITEM_NAME, INITIAL_ITEMS, MAX_ITEMS, countries);
  }

  static fixedObject(obj: any): any {
    if (obj && obj.nameAddress) {
      return { item: [obj] };
    }
    return obj;
  }
}
