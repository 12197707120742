export default class CalculatorWarning {
  constructor(incomeSourceId: string, warningId: string, message: string, codes: string[]) {
    this.incomeSourceId = incomeSourceId;
    this.warningId = warningId;
    this.message = message;
    this.codes = codes;
  }

  readonly incomeSourceId: string;

  readonly warningId: string;

  readonly message: string;

  readonly codes: string[];

  static fromMapping(mapping: any): CalculatorWarning {
    return new CalculatorWarning(mapping.income_source_id, mapping.warning_id, mapping.message, mapping.codes);
  }

  toMapping(): any {
    return {
      income_source_id: this.incomeSourceId,
      warning_id: this.warningId,
      message: this.message,
      codes: this.codes,
    };
  }
}
