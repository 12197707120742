type CodeItem = import("@/components/input/codeItem").default;

export default class Region {
  constructor(id: string, taxCalcId: string, name: string) {
    this.id = id;
    this.taxCalcId = taxCalcId;
    this.name = name;
  }

  id!: string;

  taxCalcId!: string;

  name!: string;

  public static isValidRegionForInput(
    regionId: string,
    codeItems: Map<string, CodeItem>,
    staticInfo: any,
    i18n: any
  ): boolean | string {
    const regionIds = ["flanders", "wallonia", "brussels", "shared"];
    if (regionIds.includes(regionId)) {
      const filledCodes = Array.from(codeItems.entries()).map(([id, item]) => item.info.regularCode);
      const invalidCodesForRegion = filledCodes.filter(
        (id) => !(id in staticInfo.codes.shared) && !(id in staticInfo.codes[regionId])
      );
      const invalidCodesForRegionForCurrentYear = invalidCodesForRegion.filter((codeId) =>
        regionIds.filter((x) => x !== "shared" && x !== regionId).every((region) => codeId in staticInfo.codes[region])
      );
      return invalidCodesForRegionForCurrentYear.length === 0
        ? true
        : `${i18n.t("input.view.unknown_codes_present_for_region")}: ${invalidCodesForRegionForCurrentYear.join(", ")}`;
    }
    return `region should be flanders, wallonia, brussels or shared: ${regionId}`;
  }

  public static textId(taxCalcId: number, defaultId: undefined | string = undefined): string {
    switch (taxCalcId) {
      case 1:
        return "flanders";
      case 2:
        return "wallonia";
      case 3:
        return "brussels";
      default:
        if (defaultId !== undefined) {
          return defaultId;
        }
        throw Error(`unknown region: ${taxCalcId}`);
    }
  }

  public static taxCalcId(textId: string, defaultId: undefined | number = undefined): number {
    switch (textId) {
      case "flanders":
        return 1;
      case "wallonia":
        return 2;
      case "brussels":
        return 3;
      default:
        if (defaultId !== undefined) {
          return defaultId;
        }
        throw Error(`unknown region: ${textId}`);
    }
  }
}
