export default class DebAlimType {
  constructor(value: string | undefined) {
    this.value = value;
  }

  value: string | undefined;

  toString(): string {
    return this.value ? this.value : "";
  }

  toObject(): string | undefined {
    if (this.value) {
      return this.value.substring(0, 90);
    }
    return undefined;
  }

  static fromObject(value: any): DebAlimType {
    return new DebAlimType(!value || Object.keys(value).length === 0 ? undefined : value);
  }
}
