
import Vue from "vue";
import { Component, Prop } from "nuxt-property-decorator";
import deburr from "lodash.deburr";
import CodeItem from "@/components/input/codeItem";
import Country from "@/components/input/country";
import BenefRenteAlimType from "@/components/input/complexTypes/benefRenteAlimType";

@Component
export default class BenefRenteAlimTypeFromListView extends Vue {
  @Prop({ required: true, type: Object })
  codeItem!: CodeItem;

  @Prop({ required: true, type: Object })
  readonly benefRenteAlim!: BenefRenteAlimType;

  @Prop({ required: true, type: Map })
  readonly countries!: Map<string, Country>;

  @Prop({ default: true, type: Boolean })
  readonly editable!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly disabled!: boolean;

  get customErrors(): string[] {
    return this.benefRenteAlim.customErrors(this.$i18n);
  }

  get countryListRegular(): Country[] {
    return Array.from(this.countries.values()).filter((c) => c.isRegular);
  }

  filterCountry: Object = (item: Country, queryText: string) =>
    deburr(item.description.toLocaleLowerCase()).includes(deburr(queryText.toLocaleLowerCase()));
}
