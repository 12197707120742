import Validatable from "@/components/input/wizards/validatable";
import Payment from "@/components/input/wizards/realEstateAndLoans/loans/payment";
import DeclarantDetails from "@/components/input/wizards/realEstateAndLoans/loans/declarantDetails";
import Loan from "@/components/input/wizards/realEstateAndLoans/loans/loan";
import LifeInsurance from "@/components/input/wizards/realEstateAndLoans/loans/lifeInsurance";
import Assert from "@/components/input/wizards/assert";
import SharedLoanProperties from "~/components/input/wizards/realEstateAndLoans/loans/sharedLoanProperties";
import RefinancingPurpose from "~/components/input/wizards/realEstateAndLoans/loans/refinancingPurpose";

export default class Refinancing extends SharedLoanProperties {
  parent: SharedLoanProperties;
  sameFinancialInstitution: boolean = false;
  replacesOlderLifeInsurance: boolean | undefined;
  outstandingBalance: number | undefined;
  extraForCompletion: number | undefined;
  extraForRebuilding: number | undefined;
  extraForMisc: number | undefined;

  constructor(
    parent: SharedLoanProperties,
    loanId: string,
    loanReference: string | undefined = undefined,
    loanDate: Date | undefined = undefined,
    sameFinancialInstitution: boolean = false,
    replacesOlderLifeInsurance: boolean | undefined = false,
    outstandingBalance: number | undefined = undefined,
    extraForCompletion: number | undefined = undefined,
    extraForRebuilding: number | undefined = undefined,
    extraForMisc: number | undefined = undefined,
    loanAmountMortgage: number | undefined = undefined,
    anticipatedEndDate: Date | undefined = undefined,
    capitalRepayments: number | Payment[] | undefined = undefined,
    interestPayments: number | Payment[] | undefined = undefined,
    detailsDeclarant: DeclarantDetails | undefined = undefined,
    detailsPartner: DeclarantDetails | undefined = undefined,
    propertyShareOfAllBorrowers: number | undefined = undefined,
    rateOfLoanProfessionallyUsed: number | undefined = undefined,
    institutionIsCompanyInEea: boolean = true,
    contractReference: string | undefined = undefined,
    institutionName: string | undefined = undefined,
    refinancing: Refinancing | undefined = undefined,
    lifeInsuranceDeclarant: LifeInsurance | undefined = undefined,
    lifeInsurancePartner: LifeInsurance | undefined = undefined,
    totalRepaymentDate: Date | undefined = undefined,
    renovationAmount: number | undefined = undefined,
    commissioningDate: Date | undefined = undefined,
    covidLoanExtensionMonths: number | undefined = undefined,
    chooseIntegratedHousingBonus: boolean | undefined = undefined,
    chooseRegionalHousingBonus: boolean | undefined = undefined,
    chooseFederalHousingBonus: boolean | undefined = undefined
  ) {
    const extraMandatoryFields: string[] = ["outstandingBalance"];
    const extraConstraints = {
      loanDate: [() => Assert.greaterOrEqualThanDate(this.loanDate, this.parent.loanDate)],
      outstandingBalance: [
        () => Assert.greaterOrEqualThan(this.outstandingBalance, 0.0),
        // @ts-ignore
        () => Assert.lessOrEqualThan(this.outstandingBalance, this.parent["loanAmountTotal"]),
      ],
      extraForCompletion: [() => Assert.greaterOrEqualThan(this.extraForCompletion, 0.0)],
      extraForRebuilding: [() => Assert.greaterOrEqualThan(this.extraForRebuilding, 0.0)],
      extraForMisc: [() => Assert.greaterOrEqualThan(this.extraForMisc, 0.0)],
    };
    super(
      parent._taxYear,
      parent.properties,
      loanId,
      loanReference,
      loanDate,
      loanAmountMortgage,
      anticipatedEndDate,
      capitalRepayments,
      interestPayments,
      detailsDeclarant,
      detailsPartner,
      propertyShareOfAllBorrowers,
      rateOfLoanProfessionallyUsed,
      institutionIsCompanyInEea,
      contractReference,
      institutionName,
      refinancing,
      lifeInsuranceDeclarant,
      lifeInsurancePartner,
      totalRepaymentDate,
      renovationAmount,
      commissioningDate,
      covidLoanExtensionMonths,
      chooseIntegratedHousingBonus,
      chooseRegionalHousingBonus,
      chooseFederalHousingBonus,
      extraMandatoryFields,
      extraConstraints
    );
    this.parent = parent;
    this.sameFinancialInstitution = sameFinancialInstitution;
    this.extraForCompletion = extraForCompletion;
    this.extraForRebuilding = extraForRebuilding;
    this.extraForMisc = extraForMisc;
    this.outstandingBalance = outstandingBalance;
    this.replacesOlderLifeInsurance = replacesOlderLifeInsurance;
  }

  get loanAmountTotal(): number {
    return (
      (this.outstandingBalance || 0) +
      (this.extraForCompletion || 0) +
      (this.extraForRebuilding || 0) +
      (this.extraForMisc || 0)
    );
  }

  get loanedAmountExtra(): number {
    return (this.extraForCompletion || 0) + (this.extraForRebuilding || 0) + (this.extraForMisc || 0);
  }

  set loanAmountTotal(value: number) {}

  get refinancingPurpose(): RefinancingPurpose {
    if (this.extraForCompletion && this.extraForRebuilding) {
      throw EvalError("Refinancing has both extraForCompletion and extraForRebuilding");
    }

    if (this.extraForCompletion) {
      return RefinancingPurpose.COMPLETION;
    }

    if (this.extraForRebuilding) {
      return RefinancingPurpose.RENOVATION;
    }

    return RefinancingPurpose.REGULAR;
  }

  set refinancingPurpose(purpose: RefinancingPurpose) {
    if (purpose === RefinancingPurpose.RENOVATION) {
      this.extraForCompletion = undefined;
    } else if (purpose === RefinancingPurpose.COMPLETION) {
      this.extraForRebuilding = undefined;
    } else {
      this.extraForRebuilding = undefined;
      this.extraForCompletion = undefined;
    }
  }

  clone() {
    const clone = this.cloneHelper(new Refinancing(this.parent, this.loanId), ["parent", "properties"]);
    if (clone.refinancing) {
      clone.refinancing.parent = clone;
    }
    return clone;
  }

  static fromObject(obj: { [key: string]: any }, parent: SharedLoanProperties): Loan {
    const taxYear = parent._taxYear;
    const ignoredKeys = ["properties", "parent"];
    const result = Validatable.fromObjectHelper(obj, new this(parent, ""), ignoredKeys);
    if (Array.isArray(result.capitalRepayments)) {
      result.capitalRepayments = result.capitalRepayments.map((payment: { [key: string]: any }) =>
        Payment.fromObject(payment)
      );
    }
    if (Array.isArray(result.interestPayments)) {
      result.interestPayments = result.interestPayments.map((payment: { [key: string]: any }) =>
        Payment.fromObject(payment)
      );
    }
    if (result.detailsDeclarant) {
      result.detailsDeclarant = DeclarantDetails.fromObject(result.detailsDeclarant);
    }
    if (result.detailsPartner) {
      result.detailsPartner = DeclarantDetails.fromObject(result.detailsPartner);
    }
    if (result.lifeInsuranceDeclarant) {
      result.lifeInsuranceDeclarant = LifeInsurance.fromObject(result.lifeInsuranceDeclarant, taxYear);
    }
    if (result.lifeInsurancePartner) {
      result.lifeInsurancePartner = LifeInsurance.fromObject(result.lifeInsurancePartner, taxYear);
    }
    if (result.refinancing) {
      result.refinancing = Refinancing.fromObject(result.refinancing, result);
    }
    return result;
  }

  toObject(removeNulls: boolean = true): { [key: string]: any } {
    return Validatable.toObjectHelper(this, ["parent", "properties"], removeNulls);
  }
}
