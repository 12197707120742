enum ResultSection {
  totals = 0,
  combinedDeclarants = 1,
  calculationDetailsText = 2,
  household = 6,
  realEstate = 4,
  loans = 5,
  comments = 3,
  todos = 7,
  optimizationOpportunities = 8,
}

export default ResultSection;
