
import { Component, Vue, Prop } from "nuxt-property-decorator";
import CodeItem from "./codeItem";

@Component
export default class CodeItemErrorView extends Vue {
  @Prop({ required: true, type: Object })
  readonly codeItem!: CodeItem;

  @Prop({ required: false, type: Object })
  readonly codeItemPartner!: CodeItem | null;

  @Prop({ default: false, type: Boolean })
  readonly showReverse!: boolean;

  get fatalErrors(): string[] {
    return [...this.codeItem.typeErrors, ...(this.codeItemPartner ? this.codeItemPartner.typeErrors : [])];
  }

  get reverseErrors(): string[] {
    return [...this.codeItem.reverseErrors, ...(this.codeItemPartner ? this.codeItemPartner.reverseErrors : [])];
  }

  get regularErrors(): string[] {
    return [
      ...this.codeItem.regularErrors,
      ...(this.codeItemPartner ? this.codeItemPartner.regularErrors : []),
      ...this.codeItem.customErrors,
      ...(this.codeItemPartner ? this.codeItemPartner.customErrors : []),
    ];
  }
}
