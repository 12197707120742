export default class CollectionType<T> {
  constructor(
    items: T[] | undefined,
    itemClass: { fromObject(obj: any, kwargs?: any): T },
    itemName: string,
    initialItems: number,
    maxItems: number
  ) {
    this.items = [...new Array(Math.max(items ? items.length : 0, initialItems))]
      .map((item, index) => (items && items[index] ? items[index] : itemClass.fromObject({})))
      .slice(0, maxItems);
    this.initialItems = initialItems;
    this.maxItems = maxItems;
    this.itemName = itemName;
  }

  items: T[];

  initialItems: number;
  maxItems: number;
  itemName: string;

  toString(i18n: any = undefined): string {
    return this.items
      .filter((item) => (item as any).toString(i18n) !== undefined && (item as any).toString(i18n) !== "")
      .map((item) => {
        const lines = (item as any).toString(i18n).split("\n");
        return lines.map((line: string, index: number) => (index === 0 ? "• " : "   ") + line).join("\n");
      })
      .join("\n");
  }

  customErrors(i18n: any): string[] {
    return this.items
      .map((i) =>
        // @ts-ignore
        i.customErrors !== undefined ? i.customErrors(i18n).join(";") : []
      )
      .filter((i) => i !== "");
  }

  toObject(): Object | undefined {
    const items = this.items
      .map((item) => (item as any).toObject())
      .filter((item) => item !== undefined)
      .slice(0, this.maxItems);
    return items && items.length > 0 ? { [this.itemName]: items } : undefined;
  }

  static fromObjectHelper(
    obj: any,
    itemClass: { fromObject(obj: any, kwargs?: any): any },
    itemName: string,
    minItems: number,
    maxItems: number,
    kwargs?: any
  ): CollectionType<any> {
    const items = obj !== null && obj !== undefined ? obj[itemName] : undefined;
    return items && items.length > 0
      ? new CollectionType(
          items.map((o: any) => itemClass.fromObject(o, kwargs)),
          itemClass,
          itemName,
          minItems,
          maxItems
        )
      : new CollectionType(undefined, itemClass, itemName, minItems, maxItems);
  }
}
