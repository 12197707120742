
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";
import PITResultGroup from "@/components/result/SimulationResultGroupView.vue";
import SimulationResult from "@/components/result/simulationResult";
import Simulation from "@/components/simulation";
import SimulationInputView from "@/components/input/SimulationInputView.vue";
import ResultSection from "@/components/result/resultSection";
import RealEstateAndLoans from "~/components/input/wizards/realEstateAndLoans/realEstateAndLoans";
import RealEstateSummary from "~/components/result/RealEstateSummary.vue";
import LoansSummary from "~/components/result/LoansSummary.vue";
import OptimizationOpportunitySummary from "~/components/result/OptimizationRetirementSavings.vue";
import OptimizationLongTermSavings from "~/components/result/OptimizationLongTermSavings.vue";

@Component({
  components: {
    OptimizationLongTermSavings,
    OptimizationOpportunitySummary,
    SimulationInputView,
    PITResultGroup,
    RealEstateSummary,
    LoansSummary,
  },
})
export default class SimulationResultView extends Vue {
  @Prop({ default: false, type: Boolean })
  readonly active!: boolean;

  @Prop({ default: 0, type: Number })
  readonly headerHeight!: number;

  @Prop({ required: true, type: Object })
  readonly simulation!: Simulation;

  @Prop({ default: null, type: Object })
  readonly realEstateAndLoans!: RealEstateAndLoans | null;

  @Prop({ default: null, type: Object })
  readonly simulationForComparison!: Simulation | null;

  @Prop({ default: () => [ResultSection.totals, ResultSection.combinedDeclarants], type: Array<ResultSection> })
  readonly openedSections!: ResultSection[];

  @Prop({ default: () => [], type: Array<ResultSection> })
  readonly hiddenSections!: ResultSection[];

  get simulationResult(): SimulationResult | null {
    return this.simulation.result ? this.simulation.result : null;
  }

  getFullText() {
    if (!(this.simulationResult && this.simulationResult.resultText)) {
      this.$emit("get-full-text");
    }
  }

  get hiddenSectionsSet(): Set<ResultSection> {
    return new Set(this.hiddenSections);
  }

  get ResultSection() {
    return ResultSection;
  }

  get prim(): any {
    return this.simulationResult ? this.simulationResult.primaryResult : null;
  }

  get sec(): any {
    return this.simulationResult ? this.simulationResult.secondaryResult : null;
  }

  @Watch("simulationResult.resultText")
  onResultTextChange(newVal: string | null) {
    if (newVal === "" || newVal === null) {
      const index = this.openedSections.indexOf(ResultSection.calculationDetailsText);
      if (index > -1) {
        this.openedSections.splice(index, 1);
      }
    }
  }
}
