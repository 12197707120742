import ForeignInsuranceType from "~/components/input/complexTypes/foreigninsuranceType";
import Country from "@/components/input/country";

export default class ForeignaccountTypeAndCheckBox extends ForeignInsuranceType {
  constructor(holder: string | undefined, country: Country | undefined, reportedToNationalBank: boolean | undefined) {
    super(holder, country);
    this.reportedToNationalBank = reportedToNationalBank;
  }

  reportedToNationalBank: boolean | undefined;

  toString(): string {
    if (this.holder || this.country) {
      const resultString = `${this.holder} (${this.country?.id})`;
      const checked = this.reportedToNationalBank ? "✓" : "✗";
      return `${resultString} ${checked}`;
    }
    return "";
  }

  toObject(): Object | undefined {
    if (this.holder || this.country) {
      const result = {
        titulaire: this.holder ? this.holder?.substring(0, 60) : null,
        pays: this.country ? this.country.id : null,
        nationalBank: this.reportedToNationalBank ? "checked" : undefined,
      };
      if (result.nationalBank === undefined) {
        delete result.nationalBank;
      }
      return result;
    }
    return undefined;
  }

  static fromObject(obj: any, countries?: Map<string, Country>): ForeignaccountTypeAndCheckBox {
    const country = obj?.pays && countries ? countries.get(obj.pays) : undefined;
    return new ForeignaccountTypeAndCheckBox(obj?.titulaire, country, obj?.nationalBank === "checked");
  }
}
