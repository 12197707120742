import SimulationResultSingle from "@/components/result/simulationResultSingle";
import { concatItemLists, concatReductionLists } from "@/components/result/resultListOperations";

export default class SimulationResult extends SimulationResultSingle {
  constructor(
    realTaxTotal: number,
    taxPayable: number,
    double: boolean,
    primaryResult: SimulationResultSingle,
    secondaryResult: SimulationResultSingle | null,
    resultText: string | null,
    communalTaxBalanceTotal: number,
    specialContributionForSocialSecurity: number,
    averageTaxRate: number,
    requestHash: string | null = null
  ) {
    super(
      primaryResult.jointTaxableIncome + (secondaryResult ? secondaryResult.jointTaxableIncome : 0),
      primaryResult.separatelyTaxableIncome + (secondaryResult ? secondaryResult.separatelyTaxableIncome : 0),
      primaryResult.refundableAmount + (secondaryResult ? secondaryResult.refundableAmount : 0),
      realTaxTotal,
      taxPayable,
      primaryResult.prepayments + (secondaryResult ? secondaryResult.prepayments : 0),
      primaryResult.globalized || (secondaryResult !== null && secondaryResult.globalized),
      averageTaxRate,
      primaryResult.realEstateIncome + (secondaryResult ? secondaryResult.realEstateIncome : 0),
      primaryResult.investmentIncome + (secondaryResult ? secondaryResult.investmentIncome : 0),
      primaryResult.otherIncome + (secondaryResult ? secondaryResult.otherIncome : 0),
      primaryResult.professionalIncome + (secondaryResult ? secondaryResult.professionalIncome : 0),
      Math.abs(primaryResult.weddingQuotientUsed),
      Math.abs(primaryResult.contributingSpouseAllotment),
      primaryResult.deductionsOnJointTaxableIncome +
        (secondaryResult ? secondaryResult.deductionsOnJointTaxableIncome : 0),
      primaryResult.taxOnJointTaxableIncome + (secondaryResult ? secondaryResult.taxOnJointTaxableIncome : 0),
      primaryResult.taxOnSeperatelyTaxableIncome + (secondaryResult ? secondaryResult.taxOnSeperatelyTaxableIncome : 0),
      primaryResult.taxFreeSumReductions + (secondaryResult ? secondaryResult.taxFreeSumReductions : 0),
      secondaryResult
        ? concatItemLists(
            primaryResult.reductionsForReplacementIncomes,
            secondaryResult.reductionsForReplacementIncomes
          )
        : primaryResult.reductionsForReplacementIncomes,
      primaryResult.foreignIncomeReductionsExempt +
        (secondaryResult ? secondaryResult.foreignIncomeReductionsExempt : 0),
      primaryResult.foreignIncomeReductionsReduced +
        (secondaryResult ? secondaryResult.foreignIncomeReductionsReduced : 0),
      primaryResult.federalTaxBeforeDeductions + (secondaryResult ? secondaryResult.federalTaxBeforeDeductions : 0),
      primaryResult.regionalTaxBeforeDeductions + (secondaryResult ? secondaryResult.regionalTaxBeforeDeductions : 0),
      secondaryResult
        ? concatReductionLists(primaryResult.federalTaxDeductions, secondaryResult.federalTaxDeductions)
        : primaryResult.federalTaxDeductions,
      secondaryResult
        ? concatReductionLists(primaryResult.regionalTaxDeductions, secondaryResult.regionalTaxDeductions)
        : primaryResult.regionalTaxDeductions,
      secondaryResult
        ? concatItemLists(primaryResult.federalTaxCredit, secondaryResult.federalTaxCredit)
        : primaryResult.federalTaxCredit,
      secondaryResult
        ? concatItemLists(primaryResult.regionalTaxCredit, secondaryResult.regionalTaxCredit)
        : primaryResult.regionalTaxCredit,
      secondaryResult
        ? concatItemLists(primaryResult.nonRefundableItems, secondaryResult.nonRefundableItems)
        : primaryResult.nonRefundableItems,
      secondaryResult
        ? concatItemLists(primaryResult.taxAdditions, secondaryResult.taxAdditions)
        : primaryResult.taxAdditions,
      secondaryResult
        ? concatItemLists(primaryResult.refundableItems, secondaryResult.refundableItems)
        : primaryResult.refundableItems,
      primaryResult.federalTaxBalance + (secondaryResult ? secondaryResult.federalTaxBalance : 0),
      primaryResult.regionalTaxBalance + (secondaryResult ? secondaryResult.regionalTaxBalance : 0),
      communalTaxBalanceTotal,
      specialContributionForSocialSecurity,
      primaryResult.specialContributionForSocialSecurityPaid +
        (secondaryResult ? secondaryResult.specialContributionForSocialSecurityPaid : 0),
      taxPayable,
      taxPayable,
      0.0,
      taxPayable,
      realTaxTotal,
      secondaryResult ? [...primaryResult.warnings, ...secondaryResult.warnings] : primaryResult.warnings
    );

    this.double = double;
    this.primaryResult = primaryResult;
    this.secondaryResult = secondaryResult;
    this.resultText = resultText;
    this.communalTaxBalanceTotal = communalTaxBalanceTotal;
    this.requestHash = requestHash;
  }

  readonly double: boolean;

  readonly primaryResult: SimulationResultSingle;

  readonly secondaryResult: SimulationResultSingle | null;

  readonly resultText: string | null;

  readonly communalTaxBalanceTotal: number;

  readonly requestHash: string | null = null;

  static fromMapping(mapping: any, requestObjectHash: string | null = null): SimulationResult {
    return new SimulationResult(
      mapping.real_tax_total,
      mapping.tax_payable,
      mapping.double,
      SimulationResultSingle.fromMapping(mapping.primary_result),
      mapping.secondary_result ? SimulationResultSingle.fromMapping(mapping.secondary_result) : null,
      mapping.result_text ? mapping.result_text : null,
      mapping.communal_tax_balance_total,
      mapping.special_contribution_for_social_security,
      mapping.average_tax_rate,
      mapping.request_hash ? mapping.request_hash : requestObjectHash
    );
  }

  toMapping(): any {
    return {
      real_tax_total: this.realTaxTotal,
      tax_payable: this.taxPayable,
      double: this.double,
      primary_result: this.primaryResult.toMapping(),
      secondary_result: this.secondaryResult?.toMapping(),
      result_text: this.resultText,
      communal_tax_balance_total: this.communalTaxBalanceTotal,
      special_contribution_for_social_security: this.specialContributionForSocialSecurity,
      average_tax_rate: this.averageTaxRate,
      request_hash: this.requestHash,
    };
  }
}
