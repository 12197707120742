
import { mdiCalendar, mdiCheck, mdiClose, mdiDelete } from "@mdi/js";
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";
import VNumberField from "@/components/input/wizards/VNumberField";
import VDateField from "@/components/input/wizards/VDateField.vue";
import PurposePeriod from "./purposePeriod";
import Validatable from "~/components/input/wizards/validatable";
import RentalDetails from "~/components/input/wizards/realEstateAndLoans/realEstate/rentalDetails";
import DateRange from "~/components/input/wizards/realEstateAndLoans/realEstate/dateRange";
import RealEstatePurpose from "~/components/input/wizards/realEstateAndLoans/realEstate/realEstatePurpose";

@Component({
  components: {
    VNumberField,
    VDateField,
  },
})
export default class RentalDetailsView extends Vue {
  @Prop({ required: true, type: Object })
  readonly rentalDetails!: RentalDetails;

  @Prop({ required: true, type: String })
  readonly purpose!: RealEstatePurpose;

  @Prop({ required: true, type: String })
  readonly minimumDate!: PurposePeriod;

  @Prop({ required: true, type: String })
  readonly maximumDate!: PurposePeriod;

  @Prop({ default: true, type: Boolean })
  readonly isDoubleReturn!: boolean;

  @Prop({ required: true, type: Number })
  readonly taxYear!: number;

  @Prop({ default: true, type: Boolean })
  readonly editable!: boolean;

  mdiCheck = mdiCheck;

  mdiClose = mdiClose;

  mdiDelete = mdiDelete;

  mdiCalendar = mdiCalendar;

  furnituredRent: boolean = !!this.rentalDetails.furnitureRentRate;

  declarantIsCompanyDirectorInRentingCompany: boolean = !!this.rentalDetails.declarantIsCompanyDirectorInRentingCompany;

  partnerIsCompanyDirectorInRentingCompany: boolean = !!this.rentalDetails.partnerIsCompanyDirectorInRentingCompany;

  mounted() {}

  get validOrErrors(): Map<string, string[]> | boolean {
    return this.rentalDetails.validate({ isDoubleReturn: this.isDoubleReturn, purpose: this.purpose });
  }

  errorsForField(fieldName: string): string[] {
    return Validatable.errorsForField(fieldName, this.validOrErrors);
  }

  get incomeYear(): number {
    return this.taxYear - 1;
  }

  get furnitureMaintenanceCostPlaceHolder(): number | null {
    if (this.rentalDetails && this.rentalDetails.grossRent && this.rentalDetails.furnitureRentRate) {
      return this.rentalDetails.grossRent * this.rentalDetails.furnitureRentRate * 0.5;
    }
    return null;
  }

  @Watch("rentalDetails.furnitureRentRate")
  onFurnitureRentRateChange(rate: number) {
    this.furnituredRent = Boolean(rate);
  }

  @Watch("furnituredRent")
  onFurnituredRentRateChange(enabled: boolean) {
    if (enabled) {
      this.rentalDetails.furnitureRentRate = 0.4;
    } else {
      this.rentalDetails.furnitureRentRate = undefined;
      this.rentalDetails.furnitureMaintenanceCost = undefined;
      if (this.purpose === RealEstatePurpose.RENTED_OUT_PRIVATE) {
        this.rentalDetails.grossRent = undefined;
      }
    }
  }

  @Watch("declarantIsCompanyDirectorInRentingCompany")
  onDeclarantIsCompanyDirectorInRentingCompanyChange(enabled: boolean) {
    if (enabled) {
      this.rentalDetails.declarantIsCompanyDirectorInRentingCompany = new DateRange(
        new Date(`${this.incomeYear}-01-01`),
        new Date(`${this.incomeYear}-12-31`)
      );
    } else {
      this.rentalDetails.declarantIsCompanyDirectorInRentingCompany = false;
    }
  }

  @Watch("partnerIsCompanyDirectorInRentingCompany")
  onPartnerIsCompanyDirectorInRentingCompanyChange(enabled: boolean) {
    if (enabled) {
      this.rentalDetails.partnerIsCompanyDirectorInRentingCompany = new DateRange(
        new Date(`${this.incomeYear}-01-01`),
        new Date(`${this.incomeYear}-12-31`)
      );
    } else {
      this.rentalDetails.partnerIsCompanyDirectorInRentingCompany = false;
    }
  }
}
