import Property from "@/components/input/wizards/realEstateAndLoans/realEstate/property";
import RealEstatePurpose from "~/components/input/wizards/realEstateAndLoans/realEstate/realEstatePurpose";

export default class Properties {
  properties: Property[];

  constructor(properties: Property[]) {
    this.properties = properties;
  }

  get isOwnHouse(): boolean {
    return this.properties.some((p) => p.isOwnHouse);
  }

  get incomeShareDeclarant(): number {
    return this.calcPropertiesShare("incomeShareDeclarant") ?? 1.0;
  }

  get incomeSharePartner(): number {
    return this.calcPropertiesShare("incomeSharePartner") ?? 0.0;
  }

  get ownershipShareDeclarant(): number | null {
    return this.calcPropertiesShare("ownershipShareDeclarant");
  }

  get ownershipSharePartner(): number | null {
    return this.calcPropertiesShare("ownershipSharePartner");
  }

  purposesInPeriod(startDate: Date, endDate: Date): RealEstatePurpose[] {
    return this.properties.map((p) => p.purposesInPeriod(startDate, endDate)).reduce((a, b) => a.concat(b), []);
  }

  purposesInPeriodSet(startDate: Date, endDate: Date): Set<RealEstatePurpose> {
    return new Set(
      this.properties.map((p) => p.purposesInPeriod(startDate, endDate)).reduce((a, b) => a.concat(b), [])
    );
  }

  private calcPropertiesShare(varName: string): number | null {
    // @ts-ignore
    if (this.properties.some((p) => p.cadastralIncome === undefined || p[varName] === undefined)) {
      return null;
    }
    const totalCi = this.properties.map((p) => p.cadastralIncome).reduce((a, b) => a! + b!, 0.0);
    if (totalCi === 0) {
      return 0.0;
    }
    return (
      this.properties
        // @ts-ignore
        .map((p) => (p.cadastralIncome * p[varName]) / totalCi!)
        .reduce((a, b) => a + b, 0.0)
    );
  }
}
