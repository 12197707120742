import Validatable from "@/components/input/wizards/validatable";
import TaxReturnCodes from "~/components/input/wizards/realEstateAndLoans/taxReturnCodes";

export default class RealEstateCodesForProperty extends Validatable {
  propertyId: string;
  realEstateCodes: TaxReturnCodes;

  constructor(propertyId: string, realEstateCodes: TaxReturnCodes) {
    super();
    this.propertyId = propertyId;
    this.realEstateCodes = realEstateCodes;
  }
  get hasAtLeastOneValue(): boolean {
    return this.realEstateCodes.hasAtLeastOneValue;
  }

  clone() {
    return this.cloneHelper(new RealEstateCodesForProperty("", new TaxReturnCodes({}, [])));
  }

  static fromObject(obj: { [key: string]: any }): RealEstateCodesForProperty {
    return Validatable.fromObjectHelper(obj, new this("", new TaxReturnCodes({}, [])));
  }
}
