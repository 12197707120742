export default class SectionInfo {
  constructor(
    i18n: any,
    taxYear: number,
    language: string,
    region: string,
    id: string,
    chapter: string,
    group: string,
    description: string,
    label: string
  ) {
    this.i18n = i18n;
    this.taxYear = taxYear;
    this.language = language;
    this.region = region;

    this.id = id;
    this.chapter = chapter;
    this.group = group;
    this.description = description;
    this.label = label;
  }

  readonly i18n!: any;

  readonly taxYear!: number;

  readonly language!: string;

  readonly region!: string;

  readonly id!: string;

  readonly chapter!: string;

  readonly group!: string | null;

  readonly description!: string;

  readonly label!: string;

  get searchBarString() {
    return `${this.i18n.t("input.view.part")} ${this.chapter} | ${this.id} | ${this.label.replace(
      this.taxYear.toString(),
      ""
    )}`;
  }

  get romanId() {
    const idValue = parseInt(this.id, 10);
    return idValue === 0 ? "0" : this.convertToRoman(idValue);
  }

  convertToRoman(n: number): string {
    const decimals = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
    const roman = ["M", "CM", "D", "CD", "C", "XC", "L", "XL", "X", "IX", "V", "IV", "I"];

    for (let i = 0; i < decimals.length; i += 1) {
      if (n < 1) return "";

      if (n >= decimals[i]) {
        return roman[i] + this.convertToRoman(n - decimals[i]);
      }
    }

    return "";
  }
}
