export default class TowFileBefore2024 {
  // Properties
  filename: string | null;
  data: string | null;
  comment: string | null;
  category: number | null; // Belongs to part 1 if no category given

  get isValid() {
    return (
      this.filename &&
      this.filename.length > 0 &&
      this.data &&
      this.data.length > 0 &&
      Buffer.byteLength(this.data, "utf8") <= 2 * 1024 * 1024
    );
  }

  categoryDescription(i18n: any) {
    return this.category
      ? `${i18n.t(`tow_files.categories.cat_${this.category}`)}`
      : `${i18n.t("tow_files.categories.cat_0")}`;
  }

  constructor(filename?: string, data?: string, comment?: string, category?: number) {
    if (filename && !/^[a-zA-Z0-9_\-]{1,250}$/.test(filename)) {
      throw new Error("Filename must match the specified pattern and be no longer than 250 characters.");
    }

    if (data && Buffer.byteLength(data, "utf8") > 2 * 1024 * 1024) {
      throw new Error("Data must not exceed 2MB.");
    }

    if (comment && comment.length > 200) {
      throw new Error("Comment must not exceed 200 characters.");
    }

    if (category !== undefined && (category < 1 || category > 35)) {
      throw new Error("Category must be between 1 and 35.");
    }

    this.filename = filename || null;
    this.data = data || null;
    this.comment = comment || null;
    this.category = category || null;
  }

  get sanitizedFilename() {
    return this.filename ? TowFileBefore2024.sanitizedFilename(this.filename) : this.filename;
  }

  toObject(): object {
    return {
      filename: this.sanitizedFilename,
      data: this.data,
      comment: this.comment,
      category: this.category,
    };
  }

  static sanitizedFilename(filename: string) {
    // Remove the file extension first
    let nameWithoutExtension = filename.replace(/\.[^/.]+$/, "");

    // Replace any character that is not alphanumeric, underscore, or hyphen
    let sanitized = nameWithoutExtension.replace(/[^a-zA-Z0-9_-]/g, "");

    // Trim the filename to 250 characters if longer
    if (sanitized.length > 250) {
      sanitized = sanitized.substring(0, 250);
    }

    return sanitized;
  }
}
