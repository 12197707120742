import Validatable from "@/components/input/wizards/validatable";
import Payment from "@/components/input/wizards/realEstateAndLoans/loans/payment";
import DeclarantDetails from "@/components/input/wizards/realEstateAndLoans/loans/declarantDetails";
import Refinancing from "@/components/input/wizards/realEstateAndLoans/loans/refinancing";
import LifeInsurance from "@/components/input/wizards/realEstateAndLoans/loans/lifeInsurance";
import LoanPurpose from "@/components/input/wizards/realEstateAndLoans/loans/loanPurpose";
import HouseType from "@/components/input/wizards/realEstateAndLoans/loans/houseType";
import Assert from "@/components/input/wizards/assert";
import SharedLoanProperties from "~/components/input/wizards/realEstateAndLoans/loans/sharedLoanProperties";

export default class Loan extends SharedLoanProperties {
  loanAmountTotal: number | undefined;
  loanPurpose: LoanPurpose | undefined;
  houseType: HouseType | undefined;
  firstDateOfOccupation: Date | undefined;

  constructor(
    taxYear: number,
    properties: string[],
    loanId: string,
    loanReference: string | undefined = undefined,
    loanDate: Date | undefined = undefined,
    loanAmountTotal: number | undefined = undefined,
    loanAmountMortgage: number | undefined = undefined,
    anticipatedEndDate: Date | undefined = undefined,
    capitalRepayments: number | Payment[] | undefined = undefined,
    interestPayments: number | Payment[] | undefined = undefined,
    detailsDeclarant: DeclarantDetails | undefined = undefined,
    detailsPartner: DeclarantDetails | undefined = undefined,
    propertyShareOfAllBorrowers: number | undefined = undefined,
    rateOfLoanProfessionallyUsed: number | undefined = undefined,
    institutionIsCompanyInEea = true,
    contractReference: string | undefined = undefined,
    institutionName: string | undefined = undefined,
    refinancing: Refinancing | undefined = undefined,
    lifeInsuranceDeclarant: LifeInsurance | undefined = undefined,
    lifeInsurancePartner: LifeInsurance | undefined = undefined,
    totalRepaymentDate: Date | undefined = undefined,
    loanPurpose: LoanPurpose | undefined = undefined,
    houseType: HouseType | undefined = undefined,
    firstDateOfOccupation: Date | undefined = undefined,
    renovationAmount: number | undefined = undefined,
    commissioningDate: Date | undefined = undefined,
    covidLoanExtensionMonths: number | undefined = undefined,
    chooseIntegratedHousingBonus: boolean | undefined = undefined,
    chooseRegionalHousingBonus: boolean | undefined = undefined,
    chooseFederalHousingBonus: boolean | undefined = undefined
  ) {
    const extraMandatoryFields = ["loanAmountTotal", "loanPurpose"];
    const extraConstraints = {
      loanAmountTotal: [() => Assert.greaterOrEqualThan(this.loanAmountTotal, 0.0)],
      loanPurpose: [() => {
        if (this.loanPurpose === LoanPurpose.RENEWING && this.loanDate) {
          const loanYear = this.loanDate.getFullYear();
          if (loanYear < 2005) {
             // TODO: Maybe it is better to do the translation here in stead of in the template...
            return 'real_estate_and_loans.loans.errors.renewing_purpose_for_loan_before_2005';
          }
        }
        return true;
      }],
    };
    super(
      taxYear,
      properties,
      loanId,
      loanReference,
      loanDate,
      loanAmountMortgage,
      anticipatedEndDate,
      capitalRepayments,
      interestPayments,
      detailsDeclarant,
      detailsPartner,
      propertyShareOfAllBorrowers,
      rateOfLoanProfessionallyUsed,
      institutionIsCompanyInEea,
      contractReference,
      institutionName,
      refinancing,
      lifeInsuranceDeclarant,
      lifeInsurancePartner,
      totalRepaymentDate,
      renovationAmount,
      commissioningDate,
      covidLoanExtensionMonths,
      chooseIntegratedHousingBonus,
      chooseRegionalHousingBonus,
      chooseFederalHousingBonus,
      extraMandatoryFields,
      extraConstraints
    );
    this.loanAmountTotal = loanAmountTotal;
    this.loanPurpose = loanPurpose;
    this.houseType = houseType;
    this.firstDateOfOccupation = firstDateOfOccupation;
  }

  clone() {
    const clone = this.cloneHelper(new Loan(0, [], ""));
    if (clone.refinancing) {
      clone.refinancing.parent = clone;
    }
    return clone;
  }

  static fromObject(obj: { [key: string]: any }, taxYear: number): Loan {
    const result = Validatable.fromObjectHelper(obj, new this(taxYear, [], ""));
    if (Array.isArray(result.capitalRepayments)) {
      result.capitalRepayments = result.capitalRepayments.map((payment: { [key: string]: any }) =>
        Payment.fromObject(payment)
      );
    }
    if (Array.isArray(result.interestPayments)) {
      result.interestPayments = result.interestPayments.map((payment: { [key: string]: any }) =>
        Payment.fromObject(payment)
      );
    }
    if (result.detailsDeclarant) {
      result.detailsDeclarant = DeclarantDetails.fromObject(result.detailsDeclarant);
    }
    if (result.detailsPartner) {
      result.detailsPartner = DeclarantDetails.fromObject(result.detailsPartner);
    }
    if (result.lifeInsuranceDeclarant) {
      result.lifeInsuranceDeclarant = LifeInsurance.fromObject(result.lifeInsuranceDeclarant, taxYear);
    }
    if (result.lifeInsurancePartner) {
      result.lifeInsurancePartner = LifeInsurance.fromObject(result.lifeInsurancePartner, taxYear);
    }
    if (result.refinancing) {
      result.refinancing = Refinancing.fromObject(result.refinancing, result);
    }
    return result;
  }
}
