
import Vue from "vue";
import { Component, Prop } from "nuxt-property-decorator";
import CodeType from "@/components/input/complexTypes/codeType";

@Component
export default class CodeTypeView extends Vue {
  @Prop({ required: true, type: Object })
  readonly codeType!: CodeType;

  @Prop({ default: true, type: Boolean })
  readonly editable!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly disabled!: boolean;

  get valueErrorMessages(): string[] {
    const errors = [];
    if (
      this.codeType.value !== undefined &&
      // @ts-ignore
      (isNaN(this.codeType.value) || !/^\d+$/.test(this.codeType.value))
    ) {
      errors.push(this.$i18n.t("input.codeInfo.validation_error_float").toString());
    }
    return errors;
  }

  get codeErrorMessages(): string[] {
    const errors = [];
    if (this.codeType.code !== undefined && !this.codeType.code.match(/^\d{4}$/)) {
      errors.push(this.$i18n.t("input.codeInfo.validation_error_code").toString());
    }
    return errors;
  }

  focus(options: object) {
    (this.$refs.textField as any).$refs.input.focus(options);
  }
}
