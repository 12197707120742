export default class LooseType {
  constructor(value: number | undefined, nature: string | undefined) {
    this.value = value;
    this.nature = nature;
  }

  value: number | undefined;
  nature: string | undefined;

  set valueNumber(value: string | null) {
    this.value = value === null ? undefined : parseFloat(value);
  }

  get valueNumber(): string | null {
    return this.value ? this.value.toString(10) : null;
  }

  toString(): string {
    return this.value || this.nature ? `${this.value} (${this.nature})` : "";
  }

  toObject(): Object | undefined {
    if (this.value || this.nature) {
      return {
        nature: this.nature ? this.nature?.substring(0, 35) : null,
        valeur: this.value !== undefined ? this.value : null,
      };
    }
    return undefined;
  }

  static fromObject(obj: any): LooseType {
    return new LooseType(obj?.valeur, obj?.nature);
  }
}
