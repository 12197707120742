
import { Component, Prop, Vue } from "nuxt-property-decorator";
import CodeItem from "~/components/input/codeItem";

@Component({
  components: {},
})
export default class ErrorCollectionView extends Vue {
  @Prop({ default: true, type: Boolean })
  readonly active!: boolean;

  @Prop({ type: String })
  readonly remoteError!: string | null;

  @Prop({ default: () => new Map(), type: Map })
  readonly fatalErrors!: Map<string, string[]>;

  @Prop({ type: Map })
  readonly otherErrors!: Map<string, string[]>;

  @Prop({ type: Map })
  readonly warnings!: Map<string, string[]>;

  @Prop({ required: true, type: Map })
  readonly codeItems!: Map<string, CodeItem>;

  formatCode(codeInError: string): string {
    if (codeInError.match(/^\d{4}$/)) {
      return codeInError;
    } else if (["global1", "global2"].includes(codeInError)) {
      return "****";
    } else {
      return "extra";
    }
  }
}
