import Validatable from "@/components/input/wizards/validatable";
import Assert from "~/components/input/wizards/assert";

export default class CadastralIncomeUpdate extends Validatable {
  amount: number | undefined;
  date: Date | undefined;

  constructor(amount: number | undefined = undefined, date: Date | undefined = undefined) {
    const mandatoryFields = ["amount", "date"];
    const constraints = {
      amount: [() => Assert.greaterOrEqualThan(this.amount, 0.0)],
    };
    super(mandatoryFields, constraints);
    this.amount = amount;
    this.date = date;
  }

  get isEmpty(): boolean {
    return this.amount === undefined && this.date === undefined;
  }

  clone() {
    return this.cloneHelper(new CadastralIncomeUpdate());
  }

  static fromObject(obj: { [key: string]: any }): CadastralIncomeUpdate {
    return Validatable.fromObjectHelper(obj, new this());
  }
}
