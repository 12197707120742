
import { Component, Model, Prop, Vue, Watch } from "nuxt-property-decorator";
import { mdiDelete, mdiArrowRightBottom, mdiInformationOutline } from "@mdi/js";
import VNumberField from "@/components/input/wizards/VNumberField";
import Income from "./income";

@Component({
  components: {
    VNumberField,
  },
})
export default class IncomeView extends Vue {
  @Model("update:value", {}) readonly value: Income | undefined | null;

  @Prop({ type: String }) readonly label: string | undefined;
  @Prop({ default: false, type: Boolean }) readonly disabled!: boolean;
  @Prop({ default: false, type: Boolean }) readonly readonly!: boolean;
  @Prop({ default: false, type: Boolean }) readonly hideDetails!: boolean;
  @Prop({ default: false, type: Boolean }) readonly clearable!: boolean;
  @Prop({ default: false, type: Boolean }) readonly error!: boolean;
  @Prop({ type: Array<String> }) readonly errorMessages!: string[] | undefined;
  @Prop({}) readonly rules!: any | undefined;

  // @ts-ignore
  // just take a copy of the value (don't clone)
  localValue: Income | null = this.value !== undefined ? this.value : null;

  // @ts-ignore
  showIncome: boolean = this.value !== undefined;

  mdiDelete = mdiDelete;

  mdiArrowRightBottom = mdiArrowRightBottom;

  mdiInformationOutline = mdiInformationOutline;

  @Watch("showIncome")
  onShowIncomeChange(enabled: boolean) {
    if (enabled) {
      this.localValue = new Income();
    } else {
      this.localValue = null;
    }
  }

  @Watch("value", { deep: true })
  onValueChange(value: Income | undefined | null) {
    this.localValue = value !== undefined ? value : null;
  }

  @Watch("localValue", { deep: true })
  onLocalValueChange(value: Income | null, old: Income | null) {
    this.$emit("update:value", value);
  }
}
