
import { mdiCheck, mdiClose } from "@mdi/js";
import { Component, Vue, Prop } from "nuxt-property-decorator";
import CodeItem from "@/components/input/codeItem";

@Component
export default class UserCommentEditorView extends Vue {
  @Prop({ required: false, type: Array })
  readonly codeItems!: CodeItem[];

  @Prop({ required: false, type: String })
  readonly defaultCode: string | undefined;

  @Prop({ default: true, type: Boolean })
  readonly editable!: boolean;

  codeItemsToEdit: CodeItem[] = [];

  mdiCheck = mdiCheck;

  mdiClose = mdiClose;

  selectedCodeItem: CodeItem | null = null;

  mounted() {
    this.codeItemsToEdit = this.codeItems.map((item) => item.clone());
    if (this.codeItemsToEdit.length > 0) {
      if (this.defaultCode) {
        const selectedList = this.codeItemsToEdit.filter((item) => item.info.code === this.defaultCode);
        if (selectedList.length > 0) {
          this.selectedCodeItem = selectedList[0];
        }
      } else {
        this.selectedCodeItem = this.codeItemsToEdit[0];
      }
    }
  }

  get availableCodes(): string[] {
    return this.codeItemsToEdit.map((codeItem) => codeItem.info.code);
  }

  get selectedCodeName(): string | null {
    if (this.selectedCodeItem) {
      return this.selectedCodeItem.info.code;
    }
    return null;
  }

  set selectedCodeName(name: string | null) {
    if (name !== null) {
      const selectedList = this.codeItemsToEdit.filter((item) => item.info.code === name);
      if (selectedList.length === 0) {
        throw new Error(`code-items do not contain code ${name}`);
      }
      this.selectedCodeItem = selectedList[0];
    } else {
      this.selectedCodeItem = null;
    }
  }

  close() {
    this.$emit("close");
  }

  save() {
    this.$emit("save", this.codeItemsToEdit);
  }
}
