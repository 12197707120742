
import Vue from "vue";
import { Component, Prop, Watch } from "nuxt-property-decorator";
import Naturecode10Type from "@/components/input/complexTypes/naturecode10Type";
import NatureCodeTypeView from "@/components/input/complexTypes/natureCodeTypeView.vue";
import CodeItem from "@/components/input/codeItem";
import NatureCodeType from "~/components/input/complexTypes/natureCodeType";

@Component({
  components: { NatureCodeTypeView },
})
export default class Naturecode10TypeView extends Vue {
  @Prop({ required: true, type: Object })
  codeItem!: CodeItem;

  @Prop({ default: false, type: Boolean })
  readonly disabled!: boolean;

  @Prop({ default: true, type: Boolean })
  editable!: boolean;

  valueInstance: Naturecode10Type = Vue.observable(Naturecode10Type.fromObject(this.codeItem.value));

  get canAddNewItem() {
    return (
      this.valueInstance.items.length === this.codeItem.value?.item?.length &&
      this.valueInstance.items.length < this.valueInstance.maxItems
    );
  }

  addNewItem() {
    if (this.canAddNewItem) {
      this.valueInstance.items.push(NatureCodeType.fromObject({ ipcode: { value: 0.0 } }));
    }
  }

  @Watch("codeItem.value", { deep: true })
  onValueChange(newValue: Object) {
    this.valueInstance = Vue.observable(Naturecode10Type.fromObject(newValue));
  }

  @Watch("valueInstance", { deep: true })
  onValueInstanceChange(valueInstance: Naturecode10Type) {
    const newObject = valueInstance.toObject();
    const result = newObject || null;
    if (JSON.stringify(result) !== JSON.stringify(this.codeItem.value)) {
      this.$emit("update", result);
    }
  }

  focus(options: object) {
    (this.$refs!.natureCodeView! as any[])[0].focus(options);
  }
}
