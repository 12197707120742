
import {
  mdiAlertCircle,
  mdiArrowRightBottom,
  mdiCalendar,
  mdiCheck,
  mdiClose,
  mdiDelete,
  mdiHumanChild,
  mdiHumanCane,
  mdiHuman,
  mdiAlert,
  mdiTextBoxSearchOutline,
} from "@mdi/js";
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";
import Person from "@/components/input/wizards/household/person";
import Kinship from "@/components/input/wizards/household/kinship";
import VNumberField from "@/components/input/wizards/VNumberField";
import VDateField from "@/components/input/wizards/VDateField.vue";
import Validatable from "@/components/input/wizards/validatable";
import Household from "@/components/input/wizards/household/household";
import Period from "~/components/input/wizards/household/period";
import IncomeView from "~/components/input/wizards/household/IncomeView.vue";
import Simulation from "~/components/simulation";
import debounce from "lodash.debounce";

@Component({
  components: {
    VNumberField,
    VDateField,
    IncomeView,
  },
})
export default class PersonView extends Vue {
  @Prop({ required: true, type: Object })
  readonly simulation!: Simulation;

  @Prop({ required: true, type: Object })
  readonly household!: Household;

  @Prop({ required: true, type: Object })
  readonly person!: Person;

  @Prop({ required: true, type: Boolean })
  readonly doubleReturn!: boolean;

  @Prop({ default: true, type: Boolean })
  readonly editable!: boolean;

  @Prop({ default: 12, type: Number })
  readonly taxablePeriodInMonths!: number;

  ready: boolean = false;

  householdEditable: Household = this.createHouseholdClone();

  mdiCheck = mdiCheck;

  mdiClose = mdiClose;

  mdiDelete = mdiDelete;

  mdiCalendar = mdiCalendar;

  mdiAlertCircle = mdiAlertCircle;

  mdiAlert = mdiAlert;

  mdiArrowRightBottom = mdiArrowRightBottom;

  mdiTextBoxSearch = mdiTextBoxSearchOutline;

  dateFormatOptions: any = { month: "2-digit", day: "2-digit", year: "numeric" };

  childCareExpensesEnabled = this.person.childCareExpensesIsEnabled;

  get errors() {
    return this.personEditable.validate();
  }

  codesForSimulationWithChildCareExpenses: { [key: string]: number | null } | null = null;
  codesForSimulationWithoutChildCareExpenses: { [key: string]: number | null } | null = null;
  taxPayableWithChildCareExpenses: number | null = null;
  taxPayableWithoutChildCareExpenses: number | null = null;

  debouncedUpdateTaxPayableForChildCareExpenses = debounce(() => {
    this.updateTaxPayableWithChildCareExpenses();
    this.updateTaxPayableWithoutChildCareExpenses();
  }, 250);

  updateTaxPayableWithChildCareExpenses() {
    const newSimulation = this.simulation.clone();
    const household: Household = this.householdEditable.clone();
    const person = household.people.filter((person) => person.personId === this.person.personId)[0];
    person.forceCode1038 = false;
    const personCodes = person.codes(newSimulation.input.isDoubleReturn, newSimulation.input.codes["1199"] ?? 12);
    if (this.codesForSimulationWithChildCareExpenses != personCodes) {
      household.applyOnSimulationInput(newSimulation.input);
      newSimulation.updateResultDebounced(() => {
        this.taxPayableWithChildCareExpenses = newSimulation.result ? newSimulation.result.taxPayable : null;
      });
    }
  }

  updateTaxPayableWithoutChildCareExpenses() {
    const newSimulation = this.simulation.clone();
    const household: Household = this.householdEditable.clone();
    const person = household.people.filter((person) => person.personId === this.person.personId)[0];
    person.forceCode1038 = true;
    const personCodes = person.codes(newSimulation.input.isDoubleReturn, newSimulation.input.codes["1199"] ?? 12);
    if (this.codesForSimulationWithoutChildCareExpenses != personCodes) {
      household.applyOnSimulationInput(newSimulation.input);
      newSimulation.updateResultDebounced(() => {
        this.taxPayableWithoutChildCareExpenses = newSimulation.result ? newSimulation.result.taxPayable : null;
      });
    }
  }

  get childCareExpensesBenefit(): number | null {
    if (this.taxPayableWithChildCareExpenses !== null && this.taxPayableWithoutChildCareExpenses !== null) {
      return this.taxPayableWithoutChildCareExpenses - this.taxPayableWithChildCareExpenses;
    }
    return null;
  }

  get childCareExpensesNotOptimalWarning(): string | null {
    if (
      this.personEditable.totalChildCareExpensesEvenIfDisabled > 0 &&
      this.personEditable.age !== undefined &&
      this.personEditable.age >= 3
    ) {
      return !this.childCareExpensesEnabled
        ? `${this.$i18n.t("household.person.child_care_expenses_not_optimal_and_disabled_fixed")}`
        : null;
    }
    if (this.childCareExpensesBenefit !== null) {
      const benefitAmount = Math.abs(this.childCareExpensesBenefit).toLocaleString(...this.formatCurrencyArgs2);
      if (this.childCareExpensesBenefit > 0 && !this.childCareExpensesEnabled) {
        return `${this.$i18n.t("household.person.child_care_expenses_not_optimal_and_disabled", {
          amount: benefitAmount,
        })}`;
      } else if (this.childCareExpensesBenefit < 0 && this.childCareExpensesEnabled) {
        return `${this.$i18n.t("household.person.child_care_expenses_not_optimal_and_enabled", {
          amount: benefitAmount,
        })}`;
      }
    }
    return null;
  }

  mounted() {
    this.$nextTick(() => {
      this.ready = true;
    });
  }

  @Watch("personEditable", { deep: true, immediate: true })
  onPersonEditableChanged() {
    if (
      this.showChildCareExpenses &&
      this.personEditable.age != null &&
      this.personEditable.age < 3 &&
      this.personEditable.totalChildCareExpensesEvenIfDisabled > 0.0
    ) {
      this.debouncedUpdateTaxPayableForChildCareExpenses();
    }
  }

  @Watch("personEditable.birthDate")
  onBirthDateChanged() {
    if (this.personEditable.birthDate) {
      if (this.personEditable.dependentFrom && this.personEditable.dependentFrom > this.personEditable.birthDate) {
        this.personEditable.dependentFrom = new Date(this.personEditable.birthDate);
      }
    } else {
      this.personEditable.dependentFrom = undefined;
    }
  }

  @Watch("personEditable.deceaseDate")
  onDeceaseDateChanged() {
    if (this.personEditable.deceaseDate) {
      if (this.personEditable.dependentTo && this.personEditable.dependentTo > this.personEditable.deceaseDate) {
        this.personEditable.dependentTo = new Date(this.personEditable.deceaseDate);
      }
    } else {
      this.personEditable.dependentTo = undefined;
    }
  }

  @Watch("personEditable.hasHandicap")
  onHasHandicapChanged() {
    if (!this.personEditable.hasHandicap) {
      this.personEditable.hasGraveHandicap = undefined;
    }
  }

  get showChildCareExpenses(): boolean {
    return (
      !!this.personEditable.childCareExpenses ||
      !!(
        this.personEditable.kinship &&
        ["CHILD", "GRANDCHILD"].includes(this.personEditable.kinship) &&
        this.personEditable.age !== undefined &&
        this.personEditable.age >= 0 &&
        (this.personEditable.age <= 14 || (this.personEditable.age <= 21 && this.personEditable.hasGraveHandicap))
      )
    );
  }

  @Watch("childCareExpensesEnabled")
  onChildCareExpensesEnabledChanged() {
    if (!this.childCareExpensesEnabled) {
      if (this.personEditable.childCareExpenses && this.personEditable.childCareExpenses.length == 0) {
        this.personEditable.childCareExpenses = undefined;
      }
      this.personEditable.forceCode1038 = true;
    } else {
      this.personEditable.forceCode1038 = false;
      if (!this.personEditable.childCareExpenses) {
        this.personEditable.childCareExpenses = [new Period()];
      }
    }
  }

  addChildCareExpensesPeriod() {
    if (this.personEditable.childCareExpenses) {
      this.personEditable.childCareExpenses.push(new Period());
    }
  }

  removeChildCareExpensesPeriod(period: Period) {
    if (this.personEditable.childCareExpenses && this.personEditable.childCareExpenses?.length > 0) {
      this.personEditable.childCareExpenses = this.personEditable.childCareExpenses.filter(
        (p) => p.uuid !== period.uuid
      );
    }
    if (this.personEditable.childCareExpenses && this.personEditable.childCareExpenses.length === 0) {
      this.personEditable.childCareExpenses = undefined;
      this.childCareExpensesEnabled = false;
    }
  }
  createHouseholdClone(): Household {
    const houseHoldClone = this.household.clone();
    if (!houseHoldClone.people.map((p: Person) => p.personId).includes(this.person.personId)) {
      houseHoldClone.people.push(this.person.clone());
    }
    return houseHoldClone;
  }

  get personEditable(): Person {
    return this.householdEditable.people.filter((person) => person.personId === this.person.personId)[0];
  }

  get validOrErrors(): Map<string, string[]> | boolean {
    return this.personEditable.validate();
  }

  errorsForField(fieldName: string): string[] {
    return Validatable.errorsForField(fieldName, this.validOrErrors);
  }

  get incomeYear(): number {
    return this.personEditable._taxYear - 1;
  }

  get kinships(): any[] {
    const kinships = [
      Kinship.CHILD,
      Kinship.PARENT,
      Kinship.SIBLING,
      Kinship.GRANDPARENT,
      Kinship.GRANDCHILD,
      Kinship.OTHER,
    ];
    return kinships.map((i) => ({
      value: i,
      text: PersonView.capitalize(this.$t(`household.person.kinships.${i}`).toString()),
    }));
  }

  iconForType(kinship: Kinship): string {
    const kinshipIcons = {
      [Kinship.CHILD]: mdiHumanChild,
      [Kinship.GRANDCHILD]: mdiHumanChild,
      [Kinship.PARENT]: mdiHumanCane,
      [Kinship.FOSTER_PARENT]: mdiHumanCane,
      [Kinship.GRANDPARENT]: mdiHumanCane,
      [Kinship.SIBLING]: mdiHuman,
      [Kinship.OTHER]: mdiHuman,
    };
    if (kinship in kinshipIcons) {
      return kinshipIcons[kinship];
    } else {
      throw new Error(`unknown kinship: ${kinship}`);
    }
  }

  close() {
    this.$emit("close");
  }

  save() {
    if (this.validOrErrors === true) {
      this.$emit("save", this.personEditable);
      this.close();
    }
  }

  remove() {
    this.$emit("save", null);
    this.close();
  }

  documentLinkClicked(documentId: string) {
    this.$emit("documentLinkClicked", documentId);
  }

  get formatCurrencyArgs2(): any {
    return [
      this.$i18n.locale,
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: "currency",
        currency: "EUR",
      },
    ];
  }

  private static capitalize(str: string): string {
    return str.replace(/^\w/, (c) => c.toUpperCase());
  }
}
