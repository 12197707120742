import CollectionType from "@/components/input/complexTypes/collectionType";
import ItemType from "@/components/input/complexTypes/foreignaccountTypeAndCheckBox";
import Country from "@/components/input/country";

const ITEM_NAME = "compte";
const INITIAL_ITEMS = 3;
const MAX_ITEMS = 9;

export default class Titulist3TypeWithCheckBox extends CollectionType<ItemType> {
  constructor(items: ItemType[] | undefined) {
    super(items, ItemType, ITEM_NAME, INITIAL_ITEMS, MAX_ITEMS);
  }

  static fromObject(obj: any, countries?: Map<string, Country>): Titulist3TypeWithCheckBox {
    return CollectionType.fromObjectHelper(obj, ItemType, ITEM_NAME, INITIAL_ITEMS, MAX_ITEMS, countries);
  }
}
