
import {
  mdiAlertCircleOutline,
  mdiArrowRightBottom,
  mdiCalendar,
  mdiCashMultiple,
  mdiCheck,
  mdiClose,
  mdiDelete,
  mdiInformationOutline,
  mdiPencilBox,
} from "@mdi/js";
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";
import VNumberField from "~/components/input/wizards/VNumberField";
import VDateField from "~/components/input/wizards/VDateField.vue";
import Validatable from "~/components/input/wizards/validatable";
import Loan from "./loan";
import RealEstateAndLoans from "~/components/input/wizards/realEstateAndLoans/realEstateAndLoans";
import PaymentsView from "~/components/input/wizards/realEstateAndLoans/loans/PaymentsView.vue";
import LifeInsuranceView from "./LifeInsuranceView.vue";
import LoanPurpose from "./loanPurpose";
import HouseType from "~/components/input/wizards/realEstateAndLoans/loans/houseType";
import get from "lodash.get";
import Property from "~/components/input/wizards/realEstateAndLoans/realEstate/property";
import Properties from "../realEstate/properties";
import Refinancing from "~/components/input/wizards/realEstateAndLoans/loans/refinancing";
import VueI18n from "vue-i18n";
import RefinancingPurpose from "~/components/input/wizards/realEstateAndLoans/loans/refinancingPurpose";
import debounce from "lodash.debounce";

@Component({
  components: {
    VNumberField,
    VDateField,
    PaymentsView,
    LifeInsuranceView,
  },
})
export default class LoanView extends Vue {
  @Prop({ required: true, type: Object })
  readonly realEstateAndLoans!: RealEstateAndLoans;

  @Prop({ required: true, type: Object })
  readonly loanOrRefinancing!: Loan | Refinancing;

  @Prop({ default: true, type: Boolean })
  readonly editable!: boolean;

  @Prop({ default: true, type: Boolean })
  readonly hasPartner!: boolean;

  ready: boolean = false;

  realEstateAndLoansEditable: RealEstateAndLoans = this.createRealEstateAndLoansClone();

  totalRepaymentDoneSelected: boolean = false;

  covidLoanExtensionMonthsDoneSelected: boolean = false;

  loanAmountMortgageChanged: boolean = this.loanEditable.loanAmountMortgage !== undefined;

  mdiCheck = mdiCheck;

  mdiClose = mdiClose;

  mdiDelete = mdiDelete;

  mdiCalendar = mdiCalendar;

  mdiAlertCircleOutline = mdiAlertCircleOutline;

  mdiCashMultiple = mdiCashMultiple;

  mdiInformationOutline = mdiInformationOutline;

  mdiArrowRightBottom = mdiArrowRightBottom;

  mdiPencilBox = mdiPencilBox;

  dateFormatOptions: any = { month: "2-digit", day: "2-digit", year: "numeric" };

  chooseRegionalHousingBonusWarning: boolean | null = null;

  chooseFederalHousingBonusWarning: boolean | null = null;

  chooseIntegratedHousingBonusWarning: boolean | null = null;

  mounted() {
    this.$nextTick(() => {
      this.ready = true;
    });
  }

  get isRefinancing(): boolean {
    return this.loanEditable instanceof Refinancing;
  }

  get showPayments(): boolean {
    return this.propertiesListForLoan.some(
      (prop: any) =>
        prop.hasPeriodsWithDifferentPurposesAndDifferentDates || prop.wasBoughtInIncomeYear || prop.wasSoldInIncomeYear
    );
  }

  createRealEstateAndLoansClone(): RealEstateAndLoans {
    const realEstateAndLoans = this.realEstateAndLoans.clone();
    realEstateAndLoans.optimizePartnerDistribution = false;
    if (this.loanOrRefinancing instanceof Loan) {
      if (!realEstateAndLoans.input.loans.map((loan) => loan.loanId).includes(this.loanOrRefinancing.loanId)) {
        realEstateAndLoans.input.loans.push(this.loanOrRefinancing.clone());
      }
    } else {
      const parent = realEstateAndLoans.input.loansAndRefinancings.filter(
        (i) => i.loanId === (this.loanOrRefinancing as Refinancing).parent.loanId
      )[0];
      parent.refinancing = this.loanOrRefinancing.clone() as Refinancing;
    }
    return realEstateAndLoans;
  }

  get loan(): Loan | Refinancing | null {
    const loan = this.realEstateAndLoans.input.loansAndRefinancings.filter(
      (loan) => loan.loanId === this.loanOrRefinancing.loanId
    );
    return loan.length > 0 ? loan[0] : null;
  }

  get loanEditable(): Loan | Refinancing {
    return this.realEstateAndLoansEditable.input.loansAndRefinancings.filter(
      (loan) => loan.loanId === this.loanOrRefinancing.loanId
    )[0];
  }

  updateResultDebounced1000 = debounce(
    (doWhenSuccess: any = undefined, doWhenEnded: any = undefined) =>
      this.realEstateAndLoansEditable.updateResult(doWhenSuccess, doWhenEnded),
    1000
  );

  @Watch("loanEditable", { deep: true })
  onLoanEditableChange(loan: Loan | Refinancing) {
    if (
      document &&
      document.activeElement &&
      ["input", "textarea"].includes(document.activeElement.tagName.toLowerCase()) &&
      document.activeElement.getAttribute("type") === "text"
    ) {
      this.updateResultDebounced1000();
      return;
    }
    this.realEstateAndLoansEditable.updateResult();
  }

  get missingLoanFields(): string[] | null {
    return this.realEstateAndLoansEditable.missingLoanFieldsForLoan(this.loanEditable);
  }

  get endOfIncomeYearOwnHouseOccupiedIsVisible(): boolean {
    return this.showField("endOfIncomeYearOwnHouseOccupied");
  }

  @Watch("endOfIncomeYearOwnHouseOccupiedIsVisible")
  setDefaultValueEndOfIncomeYearOwnHouseOccupied(endOfIncomeYearOwnHouseOccupiedIsVisible: boolean) {
    if (endOfIncomeYearOwnHouseOccupiedIsVisible && this.loanEditable.endOfIncomeYearOwnHouseOccupied === undefined) {
      this.loanEditable.endOfIncomeYearOwnHouseOccupied = true;
    }
  }

  @Watch("missingLoanFields")
  onMissingLoanFieldsChange(fields: string[] | null) {
    if (fields && fields.length > 0) {
      this.$nextTick(() => {
        const elem: any = this.$refs[Validatable.snakeToCamelCase(fields[0])];
        if (elem) {
          if (elem.scrollIntoView) {
            elem.scrollIntoView({ behavior: "smooth" });
            if (elem.$refs && elem.$refs.input && elem.$refs.input.focus) {
              elem.$refs.input.focus();
            }
          } else if (elem.$el && elem.$el.scrollIntoView) {
            elem.$el.scrollIntoView({ behavior: "smooth" });
            if (elem.$refs && elem.$refs.input && elem.$refs.input.focus) {
              elem.$refs.input.focus();
            }
          }
        }
      });
    }
  }

  get purposes(): any[] {
    const allValues = [
      LoanPurpose.BUILD_NEW,
      LoanPurpose.BUY_NEW,
      LoanPurpose.BUY_OLD,
      LoanPurpose.RENOVATION,
      LoanPurpose.RENEWING,
      LoanPurpose.PAY_INHERITANCE_OR_GIFT_TAX,
      LoanPurpose.OTHER,
    ];
    return allValues.map((i) => ({
      value: i,
      text: this.capitalize(this.$t(`real_estate_and_loans.loans.loan_purposes.${i}`).toString()),
    }));
  }

  refinancingPurpose: RefinancingPurpose | null =
    this.loanEditable instanceof Refinancing ? this.loanEditable.refinancingPurpose : null;

  @Watch("refinancingPurpose")
  onRefinancingPurposeChange(refinancingPurpose: RefinancingPurpose) {
    if (this.loanEditable instanceof Refinancing) {
      this.loanEditable.refinancingPurpose = refinancingPurpose;
    } else {
      throw new Error("Refinancing purpose change not implemented for regular loans");
    }
  }

  @Watch("loanEditable.loanAmountMortgage")
  onLoanAmountMortgageChange(amount: number | undefined) {
    if (amount !== undefined) {
      this.loanEditable.addMandatoryField("anticipatedEndDate");
    } else {
      this.loanEditable.removeMandatoryField("anticipatedEndDate");
    }
  }

  @Watch("loanEditable.sameFinancialInstitution")
  onSameFinancialInstitutionChange(sameFinancialInstitution: boolean | undefined) {
    if (this.loanEditable instanceof Refinancing && !this.loanAmountMortgageChanged) {
      if (sameFinancialInstitution) {
        this.loanEditable.loanAmountMortgage = this.loanEditable.parent.loanAmountMortgage;
      } else if (this.loanEditable.loanAmountTotal > 0) {
        this.loanEditable.loanAmountMortgage = this.loanEditable.loanAmountTotal;
      }
    }
  }

  @Watch("loanEditable.loanAmountTotal")
  onLoanAmountTotalChange(amount: number | undefined) {
    if (
      this.loanEditable instanceof Refinancing &&
      !this.loanAmountMortgageChanged &&
      !this.loanEditable.sameFinancialInstitution
    ) {
      this.loanEditable.loanAmountMortgage = amount;
    }
  }

  @Watch("loanEditable.detailsDeclarant.forcedPropertyIncomeShare")
  onForcedPropertyIncomeShareDeclarantChange(forcedPropertyIncomeShare: number | undefined) {
    if (this.isDoubleReturn && this.loanEditable.detailsPartner) {
      if (forcedPropertyIncomeShare === undefined) {
        this.loanEditable.detailsPartner.forcedPropertyIncomeShare = undefined;
      } else {
        const newValue = 1 - Math.max(Math.min(forcedPropertyIncomeShare, 1.0), 0.0);
        if (
          this.loanEditable.detailsPartner.forcedPropertyIncomeShare === undefined ||
          Math.abs(newValue - this.loanEditable.detailsPartner.forcedPropertyIncomeShare) > 0.0001
        ) {
          this.loanEditable.detailsPartner.forcedPropertyIncomeShare = newValue;
        }
      }
    }
  }

  @Watch("loanEditable.detailsPartner.forcedPropertyIncomeShare")
  onForcedPropertyIncomeSharePartnerChange(forcedPropertyIncomeShare: number | undefined) {
    if (this.isDoubleReturn && this.loanEditable.detailsDeclarant) {
      if (forcedPropertyIncomeShare === undefined) {
        this.loanEditable.detailsDeclarant.forcedPropertyIncomeShare = undefined;
      } else {
        const newValue = 1 - Math.max(Math.min(forcedPropertyIncomeShare, 1.0), 0.0);
        if (
          this.loanEditable.detailsDeclarant.forcedPropertyIncomeShare === undefined ||
          Math.abs(newValue - this.loanEditable.detailsDeclarant.forcedPropertyIncomeShare) > 0.0001
        ) {
          this.loanEditable.detailsDeclarant.forcedPropertyIncomeShare = newValue;
        }
      }
    }
  }

  @Watch("loanEditable.chooseFederalHousingBonus")
  onChooseFederalHousingBonusChange(chooseFederalHousingBonus: boolean | undefined) {
    if (chooseFederalHousingBonus !== undefined && this.chooseFederalHousingBonusWarning === null) {
      this.chooseFederalHousingBonusWarning = true;
    }
  }

  @Watch("loanEditable.chooseRegionalHousingBonus")
  onChooseRegionalHousingBonusChange(chooseRegionalHousingBonus: boolean | undefined) {
    if (chooseRegionalHousingBonus !== undefined && this.chooseRegionalHousingBonusWarning === null) {
      this.chooseRegionalHousingBonusWarning = true;
    }
  }

  @Watch("loanEditable.chooseIntegratedHousingBonus")
  chooseIntegratedHousingBonus(chooseIntegratedHousingBonus: boolean | undefined) {
    if (chooseIntegratedHousingBonus === true && this.chooseIntegratedHousingBonusWarning === null) {
      this.chooseIntegratedHousingBonusWarning = true;
    }
    if (
      this.loan &&
      this.loan.chooseIntegratedHousingBonus &&
      chooseIntegratedHousingBonus === false &&
      this.chooseIntegratedHousingBonusWarning === null
    ) {
      this.chooseIntegratedHousingBonusWarning = true;
    }
  }

  get showChooseIntegratedHousingBonus(): boolean {
    return (
      (this.realEstateAndLoansEditable.input.loans.filter((l) =>
        l.onOwnHouse({ properties: this.realEstateAndLoansEditable.input.realEstate })
      ).length > 1 &&
        (this.loanEditable.loanDate?.getFullYear() ?? 0) >= 2016 &&
        this.realEstateAndLoansEditable.input.currentReturn.codes["1090"] === 1) ||
      this.errorsForField("chooseIntegratedHousingBonus").length > 0
      || this.loanEditable.chooseIntegratedHousingBonus !== undefined
    )
  }

  get refinancingPurposes(): any[] {
    const allValues = [RefinancingPurpose.REGULAR, RefinancingPurpose.COMPLETION, RefinancingPurpose.RENOVATION];
    return allValues.map((i) => ({
      value: i,
      text: this.capitalize(this.$t(`real_estate_and_loans.refinancing.refinancing_purposes.${i}`).toString()),
    }));
  }

  get houseTypes(): any[] {
    const allValues = [HouseType.MEDIUM_SIZED_HOUSE, HouseType.LARGE_HOUSE, HouseType.SOCIAL_HOUSING];
    return allValues.map((i) => ({
      value: i,
      text: this.capitalize(this.$t(`real_estate_and_loans.loans.house_types.${i}`).toString()),
    }));
  }

  get isDoubleReturn(): boolean {
    return this.realEstateAndLoansEditable.input.currentReturn.isDoubleReturn;
  }

  get validOrErrors(): Map<string, string[]> | boolean {
    let errors = this.loanEditable.validate({
      properties: this.realEstateAndLoansEditable.input.realEstate,
      loans: this.realEstateAndLoansEditable.input.loans,
      isDoubleReturn: this.realEstateAndLoansEditable.input.currentReturn.isDoubleReturn,
    });
    if (this.missingLoanFields) {
      this.missingLoanFields.forEach((fieldName) => {
        const fieldNameCamel = Validatable.snakeToCamelCase(fieldName);
        if (get(this.loanEditable, fieldNameCamel) === undefined) {
          if (typeof errors === "boolean") {
            errors = new Map();
          }
          errors.set(
            fieldNameCamel,
            errors.get(fieldNameCamel) ? [...errors.get(fieldNameCamel)!, "required"] : ["required"]
          );
        }
      });
    }
    return errors;
  }

  get validOrErrorsWithoutRefinancingErrors(): Map<string, string[]> | boolean {
    let errors = this.validOrErrors;
    if (errors instanceof Map) {
      errors = new Map(Array.from(errors).filter(([key]) => !key.startsWith("refinancing")));
      errors = errors.size > 0 ? errors : true;
    }
    return errors;
  }

  errorsForField(fieldName: string): string[] {
    const errors = Validatable.errorsForField(fieldName, this.validOrErrors);
    if (
      this.missingLoanFields?.includes(Validatable.camelToSnakeCase(fieldName)) &&
      get(this.loanEditable, fieldName) === undefined
    ) {
      errors.push("required");
    }
    return errors;
  }

  getI18nErrorsForField(fieldName: string): string[] {
    return this.errorsForField(fieldName).map((e) => this.getI18nText(e));
  }

  getI18nText(text: string): string {
    return this.$t(text).toString();
  }

  get incomeYear(): number {
    return this.loanEditable._taxYear - 1;
  }

  get lifeInsuranceDeclarant(): boolean {
    return !!this.loanEditable.lifeInsuranceDeclarant;
  }

  get totalRepaymentDone(): boolean {
    return !!this.loanEditable.totalRepaymentDate || this.totalRepaymentDoneSelected;
  }

  set totalRepaymentDone(done: boolean) {
    if (!done) {
      this.totalRepaymentDoneSelected = false;
      this.loanEditable.totalRepaymentDate = undefined;
    } else {
      this.totalRepaymentDoneSelected = true;
    }
  }

  get showCovidLoanExtensionMonths(): boolean {
    return !!!(this.loanEditable.loanDate && this.loanEditable.loanDate >= new Date("2020-03-30"));
  }

  get covidLoanExtensionMonthsDone(): boolean {
    return !!this.loanEditable.covidLoanExtensionMonths || this.covidLoanExtensionMonthsDoneSelected;
  }

  set covidLoanExtensionMonthsDone(done: boolean) {
    if (!done) {
      this.covidLoanExtensionMonthsDoneSelected = false;
      this.loanEditable.covidLoanExtensionMonths = undefined;
    } else {
      this.covidLoanExtensionMonthsDoneSelected = true;
    }
  }

  get propertiesListForLoan(): Property[] {
    return this.realEstateAndLoansEditable.input.realEstate.filter((p) =>
      this.loanEditable.properties.includes(p.propertyId!)
    );
  }

  get propertiesObjectForLoan(): Properties {
    return new Properties(this.propertiesListForLoan);
  }

  get activeWarning() {
    return (
      !!this.chooseFederalHousingBonusWarning ||
      !!this.chooseRegionalHousingBonusWarning ||
      !!this.chooseIntegratedHousingBonusWarning
    );
  }

  set activeWarning(enabled: boolean) {
    if (!enabled) {
      this.closeWarning();
    }
  }

  closeWarning() {
    if (this.chooseRegionalHousingBonusWarning) {
      this.chooseRegionalHousingBonusWarning = false;
    }
    if (this.chooseFederalHousingBonusWarning) {
      this.chooseFederalHousingBonusWarning = false;
    }
    if (this.chooseIntegratedHousingBonusWarning) {
      this.chooseIntegratedHousingBonusWarning = false;
    }
  }

  close() {
    this.$emit("close");
  }

  save() {
    if (this.validOrErrorsWithoutRefinancingErrors === true) {
      this.$emit("save", this.loanEditable.clone());
      this.close();
    }
  }

  showField(fieldName: string): boolean {
    let hasFocus = false;
    const focussedElement = document?.activeElement;
    if (focussedElement && focussedElement.id) {
      const fieldElement: any = this.$refs[Validatable.snakeToCamelCase(fieldName)];
      if (focussedElement.id === fieldElement?.$el?.id) {
        hasFocus = true;
      } else if (focussedElement.id === fieldElement?.$refs["input"]?.id) {
        hasFocus = true;
      }
    }

    // @ts-ignore
    return hasFocus || this.loanEditable[fieldName] !== undefined || this.errorsForField(fieldName).length > 0;
  }

  fieldIsConfirmed(fieldName: string): boolean {
    // @ts-ignore
    return !(this.loanEditable[fieldName] === undefined);
  }

  confirmField(fieldName: string) {
    // @ts-ignore
    if (!this.fieldIsConfirmed(fieldName)) {
      // @ts-ignore
      this.loanEditable[fieldName] = false;
    }
  }

  showDetail(detailName: string): boolean {
    return (
      // @ts-ignore
      this.loanEditable.detailsDeclarant[detailName] !== undefined ||
      // @ts-ignore
      (this.loanEditable.detailsPartner && this.loanEditable.detailsPartner[detailName] !== undefined) ||
      this.errorsForField(`detailsDeclarant.${detailName}`).length > 0 ||
      this.errorsForField(`detailsPartner.${detailName}`).length > 0
    );
  }

  detailIsConfirmed(detailName: string): boolean {
    // @ts-ignore
    const detailDeclarant = this.loanEditable.detailsDeclarant[detailName];
    return !(
      detailDeclarant === undefined ||
      (this.isDoubleReturn &&
        this.loanEditable.detailsPartner &&
        // @ts-ignore
        this.loanEditable.detailsPartner[detailName] === undefined)
    );
  }

  confirmDetail(detailName: string) {
    if (!this.detailIsConfirmed(detailName)) {
      // @ts-ignore
      if (this.loanEditable.detailsDeclarant[detailName] === undefined) {
        // @ts-ignore
        this.loanEditable.detailsDeclarant[detailName] = false;
      }
      if (
        this.isDoubleReturn &&
        this.loanEditable.detailsPartner &&
        // @ts-ignore
        this.loanEditable.detailsPartner[detailName] === undefined
      ) {
        // @ts-ignore
        this.loanEditable.detailsPartner[detailName] = false;
      }
    }
  }

  remove() {
    this.$emit("save", null);
    this.close();
  }

  formatNumber(num: number | null | undefined, multiplier: number = 1.0): string | null {
    if (num === null || num === undefined) {
      return null;
    }
    return (num * multiplier).toLocaleString(this.$i18n.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  private capitalize(str: string | VueI18n.TranslateResult): string {
    return `${str}`.replace(/^\w/, (c) => c.toUpperCase());
  }
}
