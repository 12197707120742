
import Vue from "vue";
import { Component, Prop, Watch } from "nuxt-property-decorator";
import Contracts2Type from "@/components/input/complexTypes/contracts2Type";
import CodeItem from "@/components/input/codeItem";

@Component
export default class Contracts2TypeView extends Vue {
  @Prop({ required: true, type: Object })
  codeItem!: CodeItem;

  @Prop({ default: true, type: Boolean })
  editable!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly disabled!: boolean;

  valueInstance: Contracts2Type = Vue.observable(Contracts2Type.fromObject(this.codeItem.value));

  @Watch("codeItem.value", { deep: true })
  onValueChange(newValue: Object) {
    this.valueInstance = Vue.observable(Contracts2Type.fromObject(newValue));
  }

  @Watch("valueInstance", { deep: true })
  onValueInstanceChange(valueInstance: Contracts2Type) {
    const newObject = valueInstance.toObject();
    const result = newObject || null;
    if (JSON.stringify(result) !== JSON.stringify(this.codeItem.value)) {
      this.$emit("update", result);
    }
  }

  focus(options: object) {
    ((this.$refs!.textField! as any[])[0].$refs.input as HTMLElement).focus(options);
  }
}
