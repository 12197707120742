import ResultListItem from "@/components/result/resultListItem";
import ResultListItemReduction from "@/components/result/resultListItemReduction";
import CalculatorWarning from "~/components/result/calculatorWarning";

export default class SimulationResultSingle {
  constructor(
    jointTaxableIncome: number,
    separatelyTaxableIncome: number,
    refundableAmount: number,
    realTaxTotal: number,
    taxPayable: number,
    prepayments: number,
    globalized: boolean,
    averageTaxRate: number,
    realEstateIncome: number,
    investmentIncome: number,
    otherIncome: number,
    professionalIncome: number,
    weddingQuotientUsed: number,
    contributingSpouseAllotment: number,
    deductionsOnJointTaxableIncome: number,
    taxOnJointTaxableIncome: number,
    taxOnSeperatelyTaxableIncome: number,
    taxFreeSumReductions: number,
    reductionsForReplacementIncomes: ResultListItem[],
    foreignIncomeReductionsExempt: number,
    foreignIncomeReductionsReduced: number,
    federalTaxBeforeDeductions: number,
    regionalTaxBeforeDeductions: number,
    federalTaxDeductions: ResultListItemReduction[],
    regionalTaxDeductions: ResultListItemReduction[],
    federalTaxCredit: ResultListItem[],
    regionalTaxCredit: ResultListItem[],
    nonRefundableItems: ResultListItem[],
    taxAdditions: ResultListItem[],
    refundableItems: ResultListItem[],
    federalTaxBalance: number,
    regionalTaxBalance: number,
    communalTax: number,
    specialContributionForSocialSecurity: number,
    specialContributionForSocialSecurityPaid: number,
    taxPayableFinal: number,
    taxPayableOwnShareProvisional: number,
    ownShareCorrections: number,
    taxPayableOwnShare: number,
    realTaxTotalOwnShare: number,
    warnings: CalculatorWarning[]
  ) {
    this.jointTaxableIncome = jointTaxableIncome;
    this.separatelyTaxableIncome = separatelyTaxableIncome;
    this.refundableAmount = refundableAmount;
    this.realTaxTotal = realTaxTotal;
    this.taxPayable = taxPayable;
    this.prepayments = prepayments;
    this.globalized = globalized;
    this.averageTaxRate = averageTaxRate;
    this.realEstateIncome = realEstateIncome;
    this.investmentIncome = investmentIncome;
    this.otherIncome = otherIncome;
    this.professionalIncome = professionalIncome;
    this.weddingQuotientUsed = weddingQuotientUsed;
    this.contributingSpouseAllotment = contributingSpouseAllotment;
    this.deductionsOnJointTaxableIncome = deductionsOnJointTaxableIncome;
    this.taxOnJointTaxableIncome = taxOnJointTaxableIncome;
    this.taxOnSeperatelyTaxableIncome = taxOnSeperatelyTaxableIncome;
    this.taxFreeSumReductions = taxFreeSumReductions;
    this.reductionsForReplacementIncomes = reductionsForReplacementIncomes;
    this.foreignIncomeReductionsExempt = foreignIncomeReductionsExempt;
    this.foreignIncomeReductionsReduced = foreignIncomeReductionsReduced;
    this.federalTaxBeforeDeductions = federalTaxBeforeDeductions;
    this.regionalTaxBeforeDeductions = regionalTaxBeforeDeductions;
    this.federalTaxDeductions = federalTaxDeductions;
    this.regionalTaxDeductions = regionalTaxDeductions;
    this.federalTaxCredit = federalTaxCredit;
    this.regionalTaxCredit = regionalTaxCredit;
    this.nonRefundableItems = nonRefundableItems;
    this.taxAdditions = taxAdditions;
    this.refundableItems = refundableItems;
    this.federalTaxBalance = federalTaxBalance;
    this.regionalTaxBalance = regionalTaxBalance;
    this.communalTax = communalTax;
    this.specialContributionForSocialSecurity = specialContributionForSocialSecurity;
    this.specialContributionForSocialSecurityPaid = specialContributionForSocialSecurityPaid;

    this.fedRegTax = this.realTaxTotal - this.communalTax - this.specialContributionForSocialSecurity;
    this.paidTax = this.refundableAmount + this.specialContributionForSocialSecurityPaid;
    this.totalIncome = this.jointTaxableIncome + this.separatelyTaxableIncome;
    this.deductionsOnIncome =
      this.weddingQuotientUsed + this.contributingSpouseAllotment + this.deductionsOnJointTaxableIncome;

    this.taxPayableFinal = taxPayableFinal;
    this.taxPayableOwnShareProvisional = taxPayableOwnShareProvisional;
    this.ownShareCorrections = ownShareCorrections;
    this.taxPayableOwnShare = taxPayableOwnShare;
    this.realTaxTotalOwnShare = realTaxTotalOwnShare;
    this.warnings = warnings;
  }

  readonly jointTaxableIncome: number;

  readonly separatelyTaxableIncome: number;

  readonly refundableAmount: number;

  readonly realTaxTotal: number;

  readonly taxPayable: number;

  readonly prepayments: number;

  readonly globalized: boolean;

  readonly averageTaxRate: number;

  readonly realEstateIncome: number;

  readonly investmentIncome: number;

  readonly otherIncome: number;

  readonly professionalIncome: number;

  readonly weddingQuotientUsed: number;

  readonly contributingSpouseAllotment: number;

  readonly deductionsOnJointTaxableIncome: number;

  readonly taxOnJointTaxableIncome: number;

  readonly taxOnSeperatelyTaxableIncome: number;

  readonly taxFreeSumReductions: number;

  readonly reductionsForReplacementIncomes: ResultListItem[];

  readonly foreignIncomeReductionsExempt: number;

  readonly foreignIncomeReductionsReduced: number;

  readonly federalTaxBeforeDeductions: number;

  readonly regionalTaxBeforeDeductions: number;

  readonly federalTaxDeductions: ResultListItemReduction[];

  readonly regionalTaxDeductions: ResultListItemReduction[];

  readonly federalTaxCredit: ResultListItem[];

  readonly regionalTaxCredit: ResultListItem[];

  readonly nonRefundableItems: ResultListItem[];

  readonly taxAdditions: ResultListItem[];

  readonly refundableItems: ResultListItem[];

  readonly federalTaxBalance: number;

  readonly regionalTaxBalance: number;

  readonly communalTax: number;

  readonly specialContributionForSocialSecurity: number;

  readonly specialContributionForSocialSecurityPaid: number;

  readonly fedRegTax: number;

  readonly paidTax: number;

  readonly totalIncome: number;

  readonly deductionsOnIncome: number;

  readonly taxPayableFinal: number;

  readonly taxPayableOwnShareProvisional: number;

  readonly ownShareCorrections: number;

  readonly taxPayableOwnShare: number;

  readonly realTaxTotalOwnShare: number;

  readonly warnings: CalculatorWarning[];

  get taxDeductions(): ResultListItemReduction[] {
    return this.federalTaxDeductions.concat(this.regionalTaxDeductions);
  }

  get taxCredit(): ResultListItem[] {
    return this.federalTaxCredit.concat(this.regionalTaxCredit);
  }

  getResultListItemsSum(resultListName: string): number {
    const resultList = this.getResultListItems(resultListName);
    if (resultList) {
      return resultList.map((item) => item.value).reduce((a, b) => a + b, 0.0);
    }
    return 0.0;
  }

  getResultListItems(resultListName: string): ResultListItem[] | null {
    if (resultListName in this) {
      const resultList_ = this[resultListName as keyof SimulationResultSingle];
      if (resultList_ instanceof Array) {
        return resultList_ as ResultListItem[];
      }
    }
    return null;
  }

  getResultListItem(resultListName: string, itemName: string): number | null {
    const resultList = this.getResultListItems(resultListName);
    if (resultList) {
      const resultListMap = new Map();
      resultList.forEach((i: ResultListItem) => {
        if (resultListMap.has(i.name)) {
          resultListMap.set(i.name, resultListMap.get(i.name) + i.value);
        } else {
          resultListMap.set(i.name, i.value);
        }
      });
      if (resultListMap.has(itemName)) {
        return resultListMap.get(itemName)!;
      }
    }
    return null;
  }

  static getResultListItemNames(resultLists: ResultListItem[][]): string[] {
    return Array.from(new Set(resultLists.flatMap((i) => i.map((i) => i.name))));
  }

  get taxDeductionsTotal(): number {
    return (
      this.taxFreeSumReductions +
      this.getResultListItemsSum("reductionsForReplacementIncomes") +
      this.foreignIncomeReductionsExempt +
      this.foreignIncomeReductionsReduced +
      this.getResultListItemsSum("federalTaxDeductions") +
      this.getResultListItemsSum("regionalTaxDeductions") +
      this.getResultListItemsSum("federalTaxCredit") +
      this.getResultListItemsSum("regionalTaxCredit") +
      this.getResultListItemsSum("nonRefundableItems")
    );
  }

  static fromMapping(mapping: any): SimulationResultSingle {
    return new SimulationResultSingle(
      mapping.joint_taxable_income,
      mapping.separately_taxable_income,
      mapping.refundable_amount,
      mapping.real_tax_total,
      mapping.tax_payable,
      mapping.prepayments,
      mapping.globalized,
      mapping.average_tax_rate,
      mapping.real_estate_income,
      mapping.investment_income,
      mapping.other_income,
      mapping.professional_income,
      mapping.wedding_quotient_used,
      mapping.contributing_spouse_allotment,
      mapping.deductions_on_joint_taxable_income,
      mapping.tax_on_joint_taxable_income,
      mapping.tax_on_seperately_taxable_income,
      mapping.tax_free_sum_reductions,
      mapping.reductions_for_replacement_incomes.map((item: any) => ResultListItem.fromMapping(item)),
      mapping.foreign_income_reductions_exempt,
      mapping.foreign_income_reductions_reduced,
      mapping.federal_tax_before_deductions,
      mapping.regional_tax_before_deductions,
      mapping.federal_tax_deductions.map((item: any) => ResultListItemReduction.fromMapping(item)),
      mapping.regional_tax_deductions.map((item: any) => ResultListItemReduction.fromMapping(item)),
      mapping.federal_tax_credit.map((item: any) => ResultListItem.fromMapping(item)),
      mapping.regional_tax_credit.map((item: any) => ResultListItem.fromMapping(item)),
      mapping.non_refundable_items.map((item: any) => ResultListItem.fromMapping(item)),
      mapping.tax_additions.map((item: any) => ResultListItem.fromMapping(item)),
      mapping.refundable_items.map((item: any) => ResultListItem.fromMapping(item)),
      mapping.federal_tax_balance,
      mapping.regional_tax_balance,
      mapping.communal_tax,
      mapping.special_contribution_for_social_security,
      mapping.special_contribution_for_social_security_paid,
      mapping.tax_payable_final,
      mapping.tax_payable_own_share_provisional,
      mapping.own_share_corrections,
      mapping.tax_payable_own_share,
      mapping.real_tax_total_own_share,
      mapping.warnings ? mapping.warnings.map((item: any) => CalculatorWarning.fromMapping(item)) : []
    );
  }

  toMapping(): any {
    return {
      joint_taxable_income: this.jointTaxableIncome,
      separately_taxable_income: this.separatelyTaxableIncome,
      refundable_amount: this.refundableAmount,
      real_tax_total: this.realTaxTotal,
      tax_payable: this.taxPayable,
      prepayments: this.prepayments,
      globalized: this.globalized,
      average_tax_rate: this.averageTaxRate,
      real_estate_income: this.realEstateIncome,
      investment_income: this.investmentIncome,
      other_income: this.otherIncome,
      professional_income: this.professionalIncome,
      wedding_quotient_used: this.weddingQuotientUsed,
      contributing_spouse_allotment: this.contributingSpouseAllotment,
      deductions_on_joint_taxable_income: this.deductionsOnJointTaxableIncome,
      tax_on_joint_taxable_income: this.taxOnJointTaxableIncome,
      tax_on_seperately_taxable_income: this.taxOnSeperatelyTaxableIncome,
      tax_free_sum_reductions: this.taxFreeSumReductions,
      reductions_for_replacement_incomes: this.reductionsForReplacementIncomes.map((item) => item.toMapping()),
      foreign_income_reductions_exempt: this.foreignIncomeReductionsExempt,
      foreign_income_reductions_reduced: this.foreignIncomeReductionsReduced,
      federal_tax_before_deductions: this.federalTaxBeforeDeductions,
      regional_tax_before_deductions: this.regionalTaxBeforeDeductions,
      federal_tax_deductions: this.federalTaxDeductions.map((item) => item.toMapping()),
      regional_tax_deductions: this.regionalTaxDeductions.map((item) => item.toMapping()),
      federal_tax_credit: this.federalTaxCredit.map((item) => item.toMapping()),
      regional_tax_credit: this.regionalTaxCredit.map((item) => item.toMapping()),
      non_refundable_items: this.nonRefundableItems.map((item) => item.toMapping()),
      tax_additions: this.taxAdditions.map((item) => item.toMapping()),
      refundable_items: this.refundableItems.map((item) => item.toMapping()),
      federal_tax_balance: this.federalTaxBalance,
      regional_tax_balance: this.regionalTaxBalance,
      communal_tax: this.communalTax,
      special_contribution_for_social_security: this.specialContributionForSocialSecurity,
      special_contribution_for_social_security_paid: this.specialContributionForSocialSecurityPaid,
      tax_payable_final: this.taxPayableFinal,
      tax_payable_own_share_provisional: this.taxPayableOwnShareProvisional,
      own_share_corrections: this.ownShareCorrections,
      tax_payable_own_share: this.taxPayableOwnShare,
      real_tax_total_own_share: this.realTaxTotalOwnShare,
      warnings: this.warnings.map((item) => item.toMapping()),
    };
  }
}
