import { v4 as uuidv4 } from "uuid";
import Validatable from "@/components/input/wizards/validatable";
import Payment from "@/components/input/wizards/realEstateAndLoans/loans/payment";
import DeclarantDetails from "@/components/input/wizards/realEstateAndLoans/loans/declarantDetails";
import Refinancing from "@/components/input/wizards/realEstateAndLoans/loans/refinancing";
import LifeInsurance from "@/components/input/wizards/realEstateAndLoans/loans/lifeInsurance";
import Assert from "@/components/input/wizards/assert";
import Properties from "@/components/input/wizards/realEstateAndLoans/realEstate/properties";
import Property from "@/components/input/wizards/realEstateAndLoans/realEstate/property";
import RealEstatePurpose from "~/components/input/wizards/realEstateAndLoans/realEstate/realEstatePurpose";

export default abstract class SharedLoanProperties extends Validatable {
  _taxYear: number;
  properties: string[];

  loanId: string;
  loanReference: string | undefined;

  loanDate: Date | undefined;
  loanAmountMortgage: number | undefined;
  anticipatedEndDate: Date | undefined;
  capitalRepayments: number | Payment[] | undefined;
  interestPayments: number | Payment[] | undefined;

  detailsDeclarant: DeclarantDetails;
  detailsPartner: DeclarantDetails | undefined;

  propertyShareOfAllBorrowers: number | undefined;

  rateOfLoanProfessionallyUsed: number | undefined;

  institutionIsCompanyInEea: boolean;

  contractReference: string | undefined;
  institutionName: string | undefined;

  refinancing: Refinancing | undefined;

  lifeInsuranceDeclarant: LifeInsurance | undefined;
  lifeInsurancePartner: LifeInsurance | undefined;

  totalRepaymentDate: Date | undefined;

  renovationAmount: number | undefined;
  commissioningDate: Date | undefined;

  covidLoanExtensionMonths: number | undefined;

  chooseIntegratedHousingBonus: boolean | undefined;
  chooseRegionalHousingBonus: boolean | undefined;
  chooseFederalHousingBonus: boolean | undefined;

  endOfIncomeYearOwnHouseOccupied: boolean | undefined;

  constructor(
    taxYear: number,
    properties: string[],
    loanId: string,
    loanReference: string | undefined = undefined,
    loanDate: Date | undefined = undefined,
    loanAmountMortgage: number | undefined = undefined,
    anticipatedEndDate: Date | undefined = undefined,
    capitalRepayments: number | Payment[] | undefined = undefined,
    interestPayments: number | Payment[] | undefined = undefined,
    detailsDeclarant: DeclarantDetails | undefined = undefined,
    detailsPartner: DeclarantDetails | undefined = undefined,
    propertyShareOfAllBorrowers: number | undefined = undefined,
    rateOfLoanProfessionallyUsed: number | undefined = undefined,
    institutionIsCompanyInEea: boolean = true,
    contractReference: string | undefined = undefined,
    institutionName: string | undefined = undefined,
    refinancing: Refinancing | undefined = undefined,
    lifeInsuranceDeclarant: LifeInsurance | undefined = undefined,
    lifeInsurancePartner: LifeInsurance | undefined = undefined,
    totalRepaymentDate: Date | undefined = undefined,
    renovationAmount: number | undefined = undefined,
    commissioningDate: Date | undefined = undefined,
    covidLoanExtensionMonths: number | undefined = undefined,
    chooseIntegratedHousingBonus: boolean | undefined = undefined,
    chooseRegionalHousingBonus: boolean | undefined = undefined,
    chooseFederalHousingBonus: boolean | undefined = undefined,
    extraMandatoryFields: string[] = [],
    extraConstraints: { [key: string]: any } = {},
    extraNestedValidatables: string[] = [],
    endOfIncomeYearOwnHouseOccupied: boolean | undefined = undefined
  ) {
    const mandatoryFields = [
      "properties",
      "loanId",
      "loanDate",
      "loanAmountMortgage",
      "detailsDeclarant",
      ...extraMandatoryFields,
    ];
    const constraints = {
      properties: [() => Assert.notEmpty(this.properties)],
      loanAmountMortgage: [() => Assert.greaterOrEqualThan(this.loanAmountMortgage, 0.0)],
      anticipatedEndDate: [() => Assert.greaterOrEqualThanDate(this.anticipatedEndDate, this.loanDate)],
      capitalRepayments: [
        (vars: any) => this.validateCapitalOrInterestPayments(this.capitalRepayments, vars),
        (vars: any) => Assert.paymentsIsProperType(this.capitalRepayments, vars),
      ],
      interestPayments: [
        (vars: any) => this.validateCapitalOrInterestPayments(this.interestPayments, vars),
        (vars: any) => {
          return Assert.paymentsIsProperType(this.interestPayments, vars);
        },
      ],
      propertyShareOfAllBorrowers: [
        () => Assert.posiviteRate(this.propertyShareOfAllBorrowers),
        (vars: any) => this.validatePropertyShareOfAllBorrowers(vars),
      ],
      rateOfLoanProfessionallyUsed: [
        () => Assert.posiviteRate(this.rateOfLoanProfessionallyUsed),
        (vars: any) => this.validateRateOfLoanProfessionallyUsed(vars),
      ],
      totalRepaymentDate: [() => Assert.greaterOrEqualThanDate(this.totalRepaymentDate, this.loanDate)],
      renovationAmount: [() => Assert.greaterOrEqualThan(this.renovationAmount, 0.0)],
      commissioningDate: [() => Assert.greaterOrEqualThanDate(this.commissioningDate, this.loanDate)],
      covidLoanExtensionMonths: [() => Assert.greaterOrEqualThan(this.covidLoanExtensionMonths, 0.0)],
      detailsDeclarant: [
        (vars: any) => this.validateOwnershipRatesInDetails(vars),
        (vars: any) => this.validateIncomeRatesInDetails(vars),
        () =>
          this.detailsDeclarant.includedInLoan || this.detailsPartner?.includedInLoan
            ? true
            : "declarant or partner need to be included_in_loan",
      ],
      detailsPartner: [
        (vars: any) => this.validateOwnershipRatesInDetails(vars),
        (vars: any) => this.validateIncomeRatesInDetails(vars),
        () =>
          this.detailsDeclarant.includedInLoan || this.detailsPartner?.includedInLoan
            ? true
            : "declarant or partner need to be included_in_loan",
      ],
      ...extraConstraints,
    };
    super(mandatoryFields, constraints, [
      "capitalRepayments",
      "interestPayments",
      "detailsDeclarant",
      "detailsPartner",
      "refinancing",
      "lifeInsuranceDeclarant",
      "lifeInsurancePartner",
      ...extraNestedValidatables,
    ]);
    this._taxYear = taxYear;
    this.properties = properties;
    this.loanId = loanId ? loanId : uuidv4();
    this.loanReference = loanReference;
    this.loanDate = loanDate;
    this.loanAmountMortgage = loanAmountMortgage;
    this.anticipatedEndDate = anticipatedEndDate;
    this.capitalRepayments = capitalRepayments;
    this.interestPayments = interestPayments;
    this.detailsDeclarant = detailsDeclarant ? detailsDeclarant : new DeclarantDetails();
    this.detailsPartner = detailsPartner;
    this.propertyShareOfAllBorrowers = propertyShareOfAllBorrowers;
    this.rateOfLoanProfessionallyUsed = rateOfLoanProfessionallyUsed;
    this.institutionIsCompanyInEea = institutionIsCompanyInEea;
    this.contractReference = contractReference;
    this.institutionName = institutionName;
    this.refinancing = refinancing;
    this.lifeInsuranceDeclarant = lifeInsuranceDeclarant;
    this.lifeInsurancePartner = lifeInsurancePartner;
    this.totalRepaymentDate = totalRepaymentDate;
    this.renovationAmount = renovationAmount;
    this.commissioningDate = commissioningDate;
    this.covidLoanExtensionMonths = covidLoanExtensionMonths;
    this.chooseIntegratedHousingBonus = chooseIntegratedHousingBonus;
    this.chooseRegionalHousingBonus = chooseRegionalHousingBonus;
    this.chooseFederalHousingBonus = chooseFederalHousingBonus;
    this.endOfIncomeYearOwnHouseOccupied = endOfIncomeYearOwnHouseOccupied;
  }

  isValidWithoutRefinancing(variables?: any): boolean {
    let errors = this.validate(variables);
    if (errors instanceof Map) {
      errors = new Map(Array.from(errors).filter(([key]) => !key.startsWith("refinancing")));
      errors = errors.size > 0 ? errors : true;
    }
    return errors === true;
  }

  validate(facts: Object): Map<string, string[]> | boolean {
    return super.validate({ ...facts, loan: this });
  }

  get loanDateNotUndefined(): Date {
    if (!this.loanDate) {
      return new Date(new Date(8640000000000000));
    }
    return this.loanDate;
  }

  get declare_as_professional_expenses(): number {
    if (
      this.rateOfLoanProfessionallyUsed &&
      this.interestPayments &&
      this.rateOfLoanProfessionallyUsed > 0.0 &&
      this.rateOfLoanProfessionallyUsed <= 1.0
    ) {
      return this.getTotalPaymentsForTaxYear(this.interestPayments) * this.rateOfLoanProfessionallyUsed;
    }
    return 0.0;
  }

  getTotalPaymentsForTaxYear(payments: Payment[] | number | undefined): number {
    if (payments) {
      return typeof payments === "number"
        ? payments
        : payments
            .filter((p: Payment) => p.date?.getFullYear() === this._taxYear - 1)
            .map((p: Payment) => p.amount ?? 0)
            .reduce((a: number, b: number) => a + b, 0);
    } else {
      return 0.0;
    }
  }

  protected validateIncomeRatesInDetails(variables?: any): boolean | string {
    const properties =
      variables && variables.properties
        ? new Properties(variables.properties.filter((p: Property) => this.properties.includes(p.propertyId!)))
        : undefined;

    const isDoubleReturn =
      variables && variables.isDoubleReturn !== undefined
        ? variables.isDoubleReturn
        : this.detailsDeclarant && this.detailsPartner;
    if (isDoubleReturn && !(this.detailsDeclarant && this.detailsPartner)) {
      throw EvalError("detailsDeclarant & detailsPartner required for double return");
    }
    const propertyIncomeShareDeclarant =
      this.detailsDeclarant?.forcedPropertyIncomeShare ?? properties?.incomeShareDeclarant;
    const propertyIncomeSharePartner = this.detailsPartner?.forcedPropertyIncomeShare ?? properties?.incomeSharePartner;

    if (isDoubleReturn) {
      if (
        propertyIncomeShareDeclarant != null &&
        propertyIncomeSharePartner != null &&
        Math.abs(1 - (propertyIncomeShareDeclarant + propertyIncomeSharePartner)) > 0.0001
      ) {
        return "total income_share should be == 1";
      }
      return true;
    }
    return true;
  }

  public onOwnHouse(variables?: any): boolean | null {
    const properties =
      variables && variables.properties
        ? new Properties(variables.properties.filter((p: Property) => this.properties.includes(p.propertyId!)))
        : undefined;
    return properties ? properties.isOwnHouse : null;
  }

  protected validatePropertyShareOfAllBorrowers(variables?: any): boolean | string {
    if (this.propertyShareOfAllBorrowers !== undefined) {
      const ownershipInLoanDeclarant = this.detailsDeclarant.includedInLoan
        ? this.propertyOwnershipShareDeclarant(variables) ?? 0.0
        : 0.0;
      const ownershipInLoanPartner = this.detailsPartner?.includedInLoan
        ? this.propertyOwnershipSharePartner(variables) ?? 0.0
        : 0.0;
      // debugger;
      if (this.propertyShareOfAllBorrowers < ownershipInLoanDeclarant + ownershipInLoanPartner) {
        return "propertyShareOfAllBorrowers should be >= sum of ownership shares of declarant/partner included in the loan";
      }
    }
    return true;
  }

  private propertyOwnershipShareDeclarant(variables: any): number | undefined {
    const properties =
      variables && variables.properties
        ? new Properties(variables.properties.filter((p: Property) => this.properties.includes(p.propertyId!)))
        : undefined;
    const propertyOwnershipShareDeclarant =
      this.detailsDeclarant.forcedPropertyOwnershipShare != null
        ? this.detailsDeclarant.forcedPropertyOwnershipShare
        : properties?.ownershipShareDeclarant;
    return propertyOwnershipShareDeclarant === null ? undefined : propertyOwnershipShareDeclarant;
  }

  private propertyOwnershipSharePartner(variables: any): number | undefined {
    const properties =
      variables && variables.properties
        ? new Properties(variables.properties.filter((p: Property) => this.properties.includes(p.propertyId!)))
        : undefined;
    const isDoubleReturn =
      variables && variables.isDoubleReturn !== undefined
        ? variables.isDoubleReturn
        : this.detailsDeclarant && this.detailsPartner;
    if (isDoubleReturn && !(this.detailsDeclarant && this.detailsPartner)) {
      throw EvalError("detailsDeclarant & detailsPartner required for double return");
    }
    if (!isDoubleReturn) {
      return 0.0;
    }
    const propertyOwnershipSharePartner =
      this.detailsPartner?.forcedPropertyOwnershipShare != null
        ? this.detailsPartner.forcedPropertyOwnershipShare
        : properties?.ownershipSharePartner;
    return propertyOwnershipSharePartner === null ? undefined : propertyOwnershipSharePartner;
  }

  protected validateOwnershipRatesInDetails(variables?: any): boolean | string {
    const isDoubleReturn =
      variables && variables.isDoubleReturn !== undefined
        ? variables.isDoubleReturn
        : this.detailsDeclarant && this.detailsPartner;
    const propertyOwnershipShareDeclarant = this.propertyOwnershipShareDeclarant(variables);
    const propertyOwnershipSharePartner = this.propertyOwnershipSharePartner(variables);

    if (isDoubleReturn) {
      if (
        propertyOwnershipShareDeclarant != null &&
        propertyOwnershipSharePartner != null &&
        propertyOwnershipShareDeclarant + propertyOwnershipSharePartner > 1
      ) {
        return "total ownership_share should be <= 1";
      }
      return true;
    }
    return true;
  }

  canHaveRateOfLoanProfessionallyUsed(properties: Property[]): boolean {
    if (!this.loanDate || !this.anticipatedEndDate) {
      return false;
    }
    if (properties) {
      const purposes = new Properties(properties).purposesInPeriodSet(this.loanDate, this.anticipatedEndDate);
      if (
        !(
          purposes.has(RealEstatePurpose.OWN_PROFESSION_DECLARANT) ||
          purposes.has(RealEstatePurpose.OWN_PROFESSION_PARTNER)
        )
      ) {
        return false;
      }
    }
    return true;
  }

  protected validateRateOfLoanProfessionallyUsed(variables?: any): boolean | string {
    const result = this.canHaveRateOfLoanProfessionallyUsed(variables?.properties);
    if (!result && this.rateOfLoanProfessionallyUsed) {
      return "loan is not used for professional purposes";
    }
    return true;
  }

  validateCapitalOrInterestPayments(payments: Payment[] | number | undefined, variables: any): boolean | string {
    if (!this.refinancing && !Payment.containsDeclaredPaymentsInYear(payments, this._taxYear - 1)) {
      return "required";
    }

    if (this.refinancing && Payment.containsPositivePaymentsInYear(payments, this._taxYear - 1)) {
      // does not check for payments made after income year
      if (
        this.refinancing.loanDate?.getFullYear() !== this._taxYear - 1 ||
        (Array.isArray(payments) &&
          this.refinancing!.loanDate &&
          payments.some((payment) => payment.date && payment.date > this.refinancing!.loanDate!))
      ) {
        return "should not have payments made after the refinancing date";
      }
    }

    return true;
  }

  static cloneObjectWithSwappedCodes(obj: { [key: string]: any }): { [key: string]: any } {
    const result = JSON.parse(JSON.stringify(obj));
    const detailsDeclarant = obj["details_declarant"];
    const detailsPartner = obj["details_partner"];
    const lifeInsuranceDeclarant = obj["life_insurance_declarant"];
    const lifeInsurancePartner = obj["life_insurance_partner"];
    result["details_declarant"] = detailsPartner;
    result["details_partner"] = detailsDeclarant;
    result["life_insurance_declarant"] = lifeInsurancePartner;
    result["life_insurance_partner"] = lifeInsuranceDeclarant;
    return result;
  }
}
