
import { mdiAlertCircle, mdiInformationOutline, mdiCheck, mdiClose, mdiHandCoin } from "@mdi/js";
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";
import CodeItem from "~/components/input/codeItem";
import Simulation from "~/components/simulation";
import Savings from "~/components/input/wizards/savings/savings";
import LongTermSavingsWithoutHouseView from "~/components/input/wizards/savings/LongTermSavingsWithoutHouseView.vue";
import CodeChangesView from "~/components/input/wizards/CodeChangesView.vue";

@Component({
  components: {
    CodeChangesView,
    LongTermSavingsWithoutHouseView,
  },
})
export default class SavingsWizardView extends Vue {
  @Prop({ required: true, type: Object })
  readonly savings!: Savings;

  @Prop({ required: true, type: Object })
  readonly simulation!: Simulation;

  @Prop({ default: true, type: Boolean })
  readonly editable!: boolean;

  savingsEditable: Savings = this.savings.clone();

  mdiAlertCircle = mdiAlertCircle;

  mdiCheck = mdiCheck;

  mdiClose = mdiClose;

  mdiHandCoin = mdiHandCoin;

  mdiInformationOutline = mdiInformationOutline;

  showCodeChangesView: boolean = false;

  get validOrErrors(): Map<string, string[]> | boolean {
    return this.savingsEditable.validate();
  }

  get codeItems(): Map<string, CodeItem> {
    return this.simulation.input.codeItems!;
  }

  @Watch("savings", { deep: true })
  onSavingsChange(savings: Savings) {
    this.savingsEditable = savings.clone();
  }

  confirmEnable() {
    this.showCodeChangesView = false;
    this.save();
  }

  cancelEnable() {
    this.showCodeChangesView = false;
  }

  close() {
    this.$emit("close");
  }

  trySave() {
    if (this.validOrErrors !== true) {
      return;
    }
    if (JSON.stringify(this.savingsEditable.codes) !== JSON.stringify(this.savings.codes)) {
      this.showCodeChangesView = true;
      return;
    } else if (JSON.stringify(this.savingsEditable.hash !== JSON.stringify(this.savings.hash))) {
      this.save();
    }
    this.close();
  }

  save() {
    const newSimulationInput = this.simulation.input.clone();
    this.savingsEditable.applyOnSimulationInput(newSimulationInput);
    this.$emit("save-simulation-input", newSimulationInput);
    this.close();
  }
}
