import Validatable from "@/components/input/wizards/validatable";
import Assert from "@/components/input/wizards/assert";
import { Locale } from "@nuxtjs/i18n";

export default class Payment extends Validatable {
  amount: number | undefined;
  date: Date | undefined;

  constructor(amount: number | undefined = undefined, date: Date | undefined = undefined) {
    const mandatoryFields = ["amount", "date"];
    const constraints = {
      amount: [() => Assert.greaterOrEqualThan(this.amount, 0.0)],
    };
    super(mandatoryFields, constraints);
    this.amount = amount;
    this.date = date;
  }

  clone() {
    return this.cloneHelper(new Payment());
  }

  static fromObject(obj: { [key: string]: any }): Payment {
    return Validatable.fromObjectHelper(obj, new this());
  }

  static containsDeclaredPaymentsInYear(payments: Payment[] | number | undefined, year: number): boolean {
    if (typeof payments === "number") {
      return payments >= 0;
    }
    if (Array.isArray(payments)) {
      return payments.some((payment: Payment) => payment.date?.getFullYear() === year);
    }
    return false;
  }

  static containsPositivePaymentsInYear(payments: Payment[] | number | undefined, year: number): boolean {
    if (typeof payments === "number") {
      return payments > 0;
    }
    if (Array.isArray(payments)) {
      return payments.some(
        (payment: Payment) => payment.date?.getFullYear() === year && payment.amount && payment.amount > 0
      );
    }
    return false;
  }

  print(locale: string): string {
    if (!this.amount) {
      return "";
    }

    const amountStr = this.amount.toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: "currency",
      currency: "EUR",
    });

    if (!this.date) {
      return amountStr;
    }

    return `${amountStr} (${this.date.toISOString().substring(0, 10)})`;
  }
}
