import Validatable from "@/components/input/wizards/validatable";

export default class LoanCodesForLoan extends Validatable {
  loanId: string;
  loanCodes: { [index: string]: any };

  constructor(loanId: string, loanCodes: { [index: string]: any }) {
    super();
    this.loanId = loanId;
    this.loanCodes = loanCodes;
  }
  get hasAtLeastOneValue(): boolean {
    return Object.keys(this.loanCodes).length > 0;
  }

  clone() {
    return this.cloneHelper(new LoanCodesForLoan("", {}));
  }

  static fromObject(obj: { [key: string]: any }): LoanCodesForLoan {
    return Validatable.fromObjectHelper(obj, new this("", {}));
  }
}
