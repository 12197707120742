
import Vue from "vue";
import { Component, Prop } from "nuxt-property-decorator";
import NatureCodeType from "@/components/input/complexTypes/natureCodeType";

@Component
export default class NatureCodeTypeView extends Vue {
  @Prop({ required: true, type: Object })
  readonly codeType!: NatureCodeType;

  @Prop({ default: true, type: Boolean })
  readonly editable!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly disabled!: boolean;

  get valueErrorMessages(): string[] {
    const errors = [];
    if (
      this.codeType.ipCode.value !== undefined &&
      this.codeType.ipCode.value !== null &&
      isNaN(this.codeType.ipCode.value)
    ) {
      errors.push(this.$i18n.t("input.codeInfo.validation_error_float").toString());
    }
    return errors;
  }

  get codeErrorMessages(): string[] {
    const errors = [];
    if (
      this.codeType.ipCode.code !== undefined &&
      this.codeType.ipCode.code !== null &&
      !this.codeType.ipCode.code.match(/^\d{4}$/)
    ) {
      errors.push(this.$i18n.t("input.codeInfo.validation_error_code").toString());
    }
    return errors;
  }

  focus(options: object) {
    (this.$refs.textField as any).$refs.input.focus(options);
  }
}
