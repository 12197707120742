import Validatable from "@/components/input/wizards/validatable";
import { ForeignCodeObject } from "@/components/input/simulationInput";
import { isDoubleReturn } from "@/components/input/simulationInput";

export default class TaxReturnCodes extends Validatable {
  codes: { [index: string]: any };
  // every code/value in foreignCodes should also be included in codes
  foreignCodes: ForeignCodeObject[];

  constructor(codes: object, foreignCodes: ForeignCodeObject[]) {
    super();
    this.codes = codes;
    this.foreignCodes = foreignCodes;
  }

  get isDoubleReturn(): boolean {
    return isDoubleReturn(this.codes);
  }

  get hasAtLeastOneValue(): boolean {
    return Object.keys(this.codes).length > 0 || this.foreignCodes.length > 0;
  }

  clone() {
    return this.cloneHelper(new TaxReturnCodes({}, []));
  }

  static fromObject(obj: { [key: string]: any }): TaxReturnCodes {
    return Validatable.fromObjectHelper(obj, new this({}, []));
  }
}
