import Validatable from "@/components/input/wizards/validatable";

export default class LoanCodesForPropertySet extends Validatable {
  propertySetId: string;
  loanCodes: { [index: string]: any };

  constructor(propertySetId: string, loanCodes: { [index: string]: any }) {
    super();
    this.propertySetId = propertySetId;
    this.loanCodes = loanCodes;
  }
  get hasAtLeastOneValue(): boolean {
    return Object.keys(this.loanCodes).length > 0;
  }

  clone() {
    return this.cloneHelper(new LoanCodesForPropertySet("", {}));
  }

  static fromObject(obj: { [key: string]: any }): LoanCodesForPropertySet {
    return Validatable.fromObjectHelper(obj, new this("", {}));
  }
}
