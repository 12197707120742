import RegularCodeStrategy from "@/components/input/validation/regularCodeStrategy";
import RequiresReverseStrategy from "@/components/input/validation/requiresReverseStrategy";

type CodeInfo = import("@/components/input/codeInfo").default;
type CodeItem = import("@/components/input/codeItem").default;

export default class RequiresStrategy extends RegularCodeStrategy {
  validate(primary: CodeItem, state: Map<string, CodeItem>): string | boolean {
    // als alle necessaryCodes extra-info zijn: vereist bijkomende informatie
    if (
      primary.parsedValue === null ||
      this.necessaryCodes.some((x) => state.has(x.code) && state.get(x.code)!.parsedValue !== null)
    )
      return true;

    if (this.necessaryCodes.every((x: CodeInfo) => x.isComplexCode)) {
      return this.i18n.t("input.validation.requires_extra") as string;
    }
    return this.i18n.t("input.validation.requires", [
      this.necessaryCodes.map((x) => x.code).join(` ${this.i18n.t("input.validation.or")} `),
    ]) as string;
  }

  getReverseRule(primary: CodeInfo): RequiresReverseStrategy {
    return new RequiresReverseStrategy(this.i18n, primary, this.necessaryCodes);
  }
}
