
import { Vue, Component, Prop, Emit, Watch } from "nuxt-property-decorator";
import TowFileBefore2024 from "~/components/input/towGeneration/towFileBefore2024";
import { mdiDelete, mdiFileDocument } from "@mdi/js";
import { appendixTypes } from "~/components/input/towGeneration/towFile";
import TowFile from "~/components/input/towGeneration/towFile";

@Component
export default class TowFileListView extends Vue {
  @Prop({ type: Number, required: true })
  readonly taxYear!: number;

  @Prop({ type: Array, default: () => [] })
  readonly towFiles!: TowFileBefore2024[] | TowFile[];

  @Prop({ type: Boolean, default: false })
  readonly flat!: boolean;

  dialog = false;
  selectedIndex: number | null = null;
  editedFile: TowFileBefore2024 | TowFile = this.generateNewTow();
  currentFile: File | null = null;

  rules = {
    required: (value: any) => !!value || `${this.$i18n.t("tow_files.required")}.`,
    fileSize: (value: File | null) =>
      !value || value.size < 2 * 1024 * 1024 || `${this.$i18n.t("tow_files.max_file_size_error")}.`,
    maxLength: (value: string) => (value || "").length <= 200 || `${this.$i18n.t("tow_files.comment_size_error")}.`,
  };

  mdiDelete = mdiDelete;
  mdiFileDocument = mdiFileDocument;

  generateNewTow() {
    return this.taxYear < 2024 ? new TowFileBefore2024("", "") : new TowFile("", "");
  }

  editFile(index: number | null) {
    this.selectedIndex = index;
    this.editedFile = index === null ? this.generateNewTow() : this.towFiles[index];
    this.dialog = true;
  }

  @Watch("dialog")
  onDialogChange() {
    if (!this.dialog) {
      this.selectedIndex = null;
      this.editedFile = this.generateNewTow();
      this.currentFile = null;
    } else {
      if (this.editedFile.filename) {
        this.currentFile = new File([], this.editedFile.filename + ".pdf", { type: "application/pdf" });
      } else {
        this.$nextTick(() => {
          if (this.$refs.fileInput) {
            (this.$refs.fileInput as any).$refs.input.click();
          }
        });
      }
    }
  }

  @Emit("update:towFiles")
  updateFiles(updatedFiles: (TowFileBefore2024 | TowFile)[]) {
    return updatedFiles;
  }

  get categories() {
    return Array.from({ length: 35 }, (_, i) => ({
      id: i + 1,
      name: `${this.$i18n.t(`tow_files.categories.cat_${i + 1}`)}`,
    })).filter((category) => !category.name.startsWith("tow_files.categories.cat_"));
  }

  get appendixTypesList() {
    return appendixTypes.map((appendixType) => ({
      id: appendixType,
      name: this.$i18n.t(`tow_files.appendix_types.${appendixType}`),
    }));
  }

  saveFile() {
    let updatedFiles = [...this.towFiles];
    if (this.selectedIndex === null) {
      updatedFiles.push(this.editedFile);
    } else {
      updatedFiles[this.selectedIndex] = this.editedFile;
    }
    this.updateFiles(updatedFiles);
    this.dialog = false;
  }

  removeFile(index: number) {
    let updatedFiles = [...this.towFiles];
    updatedFiles.splice(index, 1);
    this.updateFiles(updatedFiles);
  }

  handleFileUpload(file: File | null) {
    if (file) {
      const reader = new FileReader();
      if (this.taxYear < 2024) {
        this.editedFile.filename = file.name.replace(/\.[^/.]+$/, "");
      } else {
        this.editedFile.filename = file.name;
      }
      reader.onloadend = () => {
        this.editedFile.data = (reader.result as string).split(",")[1];
      };
      reader.readAsDataURL(file);
    } else {
      this.editedFile.filename = "";
      this.editedFile.data = "";
    }
  }
}
