import Validatable from "@/components/input/wizards/validatable";
import Assert from "@/components/input/wizards/assert";

export default class DeclarantDetails extends Validatable {
  includedInLoan: boolean | undefined;
  momentOfLoanOwnHouse: boolean | undefined;
  momentOfLoanOnlyHouse: boolean | undefined;
  yearOfLoanOwnHouse: boolean | undefined;
  yearOfLoanOnlyHouse: boolean | undefined;
  yearAfterLoanNumberOfChildren: number | undefined;
  endOfIncomeYearOnlyHouse: boolean | undefined;
  alwaysWasOnlyHouseSinceLoanDate: boolean | undefined;
  hasBeenOwnHouseBefore2016: boolean | undefined;
  ownHouseDesignationUnchangedSince2016: boolean | undefined;
  forcedPropertyIncomeShare: number | undefined;
  forcedPropertyOwnershipShare: number | undefined;

  constructor(
    includedInLoan: boolean | undefined = undefined,
    momentOfLoanOnlyHouse: boolean | undefined = undefined,
    momentOfLoanOwnHouse: boolean | undefined = undefined,
    yearOfLoanOwnHouse: boolean | undefined = undefined,
    yearOfLoanOnlyHouse: boolean | undefined = undefined,
    yearAfterLoanNumberOfChildren: number | undefined = undefined,
    endOfIncomeYearOnlyHouse: boolean | undefined = undefined,
    alwaysWasOnlyHouseSinceLoanDate: boolean | undefined = undefined,
    hasBeenOwnHouseBefore2016: boolean | undefined = undefined,
    ownHouseDesignationUnchangedSince2016: boolean | undefined = undefined,
    forcedPropertyIncomeShare: number | undefined = undefined,
    forcedPropertyOwnershipShare: number | undefined = undefined
  ) {
    const mandatoryFields: string[] = ["includedInLoan"];
    const constraints = {
      yearAfterLoanNumberOfChildren: [
        (vars: any) => Assert.greaterOrEqualThan(this.yearAfterLoanNumberOfChildren, 0.0),
      ],
      forcedPropertyIncomeShare: [() => Assert.posiviteRate(this.forcedPropertyIncomeShare)],
      forcedPropertyOwnershipShare: [() => Assert.posiviteRate(this.forcedPropertyOwnershipShare)],
    };
    super(mandatoryFields, constraints);
    this.includedInLoan = includedInLoan;
    this.momentOfLoanOwnHouse = momentOfLoanOwnHouse;
    this.momentOfLoanOnlyHouse = momentOfLoanOnlyHouse;
    this.yearOfLoanOwnHouse = yearOfLoanOwnHouse;
    this.yearOfLoanOnlyHouse = yearOfLoanOnlyHouse;
    this.yearAfterLoanNumberOfChildren = yearAfterLoanNumberOfChildren;
    this.endOfIncomeYearOnlyHouse = endOfIncomeYearOnlyHouse;
    this.alwaysWasOnlyHouseSinceLoanDate = alwaysWasOnlyHouseSinceLoanDate;
    this.hasBeenOwnHouseBefore2016 = hasBeenOwnHouseBefore2016;
    this.ownHouseDesignationUnchangedSince2016 = ownHouseDesignationUnchangedSince2016;
    this.forcedPropertyIncomeShare = forcedPropertyIncomeShare;
    this.forcedPropertyOwnershipShare = forcedPropertyOwnershipShare;
  }

  clone() {
    return this.cloneHelper(new DeclarantDetails());
  }

  static fromObject(obj: { [key: string]: any }): DeclarantDetails {
    return Validatable.fromObjectHelper(obj, new this());
  }
}
