
import { Component, Vue, Prop } from "nuxt-property-decorator";
import { mdiCalendar, mdiMap, mdiPlus, mdiCalculator } from "@mdi/js";
import CodeItem from "./codeItem";
import CalendarField from "@/components/input/CalendarField.vue";
import VDateField from "~/components/input/wizards/VDateField.vue";
import Calculator from "~/components/input/Calculator.vue";

@Component({
  components: { VDateField, CalendarField, Calculator },
})
export default class CodeItemEditorSimpleInputFieldView extends Vue {
  @Prop({ required: true, type: Object })
  readonly codeItem!: CodeItem;

  @Prop({ default: true, type: Boolean })
  readonly editable!: boolean;

  @Prop({ required: true, type: Array })
  readonly lockedByWizards!: Array<string>;

  @Prop({ default: false, type: Boolean })
  readonly foreignActivated!: boolean;

  @Prop({ type: String })
  readonly value!: string | null | undefined;

  @Prop({ type: [String, Boolean] })
  readonly checkBoxLabel!: string | boolean;

  mdiPlus = mdiPlus;

  mdiMap = mdiMap;

  mdiCalendar = mdiCalendar;

  mdiCalculator = mdiCalculator;

  calculatorDialog = false;

  get locked(): boolean {
    return this.lockedByWizards.length > 0;
  }

  get inError(): boolean {
    return this.codeItem.typeCustomRegularErrors.length > 0;
  }

  get disabled(): boolean {
    return (this.value !== undefined && this.value !== null) || this.codeItem.info.sumFieldTotal || this.locked;
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.key === "+") {
      event.preventDefault();
      this.openCalculator();
    }
  }

  get canHaveCalculator(): boolean {
    return this.codeItem.info.type == "float" && !this.disabled && this.editable;
  }

  openCalculator() {
    if (this.canHaveCalculator) {
      this.calculatorDialog = true;
    }
  }

  onCodeItemChange() {
    this.$emit("update:codeItem", this.codeItem);
  }

  toggleForeignIncome() {
    this.$emit("toggleForeignIncome");
  }

  updateFromCalculator(result: number | string) {
    this.codeItem.value = result;
    this.onCodeItemChange();

    // Focus the input field and set the cursor position at the end
    this.$nextTick(() => {
      const inputField = this.$refs.inputField as HTMLInputElement | null;
      if (inputField) {
        inputField.focus();
      }
    });
  }
}
