
import {
  mdiArrowRightBottom,
  mdiCalendar,
  mdiCheck,
  mdiClose,
  mdiDelete,
  mdiInformationOutline,
  mdiAlertOutline,
} from "@mdi/js";
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";
import PurposePeriod from "./purposePeriod";
import VNumberField from "@/components/input/wizards/VNumberField";
import VDateField from "@/components/input/wizards/VDateField.vue";
import RealEstatePurpose from "@/components/input/wizards/realEstateAndLoans/realEstate/realEstatePurpose";
import Validatable from "~/components/input/wizards/validatable";
import RentalDetailsView from "~/components/input/wizards/realEstateAndLoans/realEstate/RentalDetailsView.vue";
import RentalDetails from "~/components/input/wizards/realEstateAndLoans/realEstate/rentalDetails";

@Component({
  components: {
    VNumberField,
    VDateField,
    RentalDetailsView,
  },
})
export default class PurposePeriodView extends Vue {
  @Prop({ required: true, type: Object })
  readonly purposePeriod!: PurposePeriod;

  @Prop({ required: true, type: String })
  readonly minimumDate!: string;

  @Prop({ required: true, type: String })
  readonly maximumDate!: string;

  @Prop({ default: true, type: Boolean })
  readonly isDoubleReturn!: boolean;

  @Prop({ required: true, type: Number })
  readonly taxYear!: number;

  @Prop({ default: true, type: Boolean })
  readonly editable!: boolean;

  mdiCheck = mdiCheck;

  mdiClose = mdiClose;

  mdiDelete = mdiDelete;

  mdiCalendar = mdiCalendar;

  mdiArrowRightBottom = mdiArrowRightBottom;

  mdiInformationOutline = mdiInformationOutline;

  mdiAlertOutline = mdiAlertOutline;

  purposePeriodEditable: PurposePeriod = this.purposePeriod;

  mounted() {}

  get validOrErrors(): Map<string, string[]> | boolean {
    return this.purposePeriodEditable.validate({ isDoubleReturn: this.isDoubleReturn });
  }

  errorsForField(fieldName: string): string[] {
    return Validatable.errorsForField(fieldName, this.validOrErrors);
  }

  get incomeYear(): number {
    return this.taxYear - 1;
  }

  @Watch("purposePeriodEditable.purpose")
  onPurposeChange(purpose: RealEstatePurpose | null | undefined) {
    if (
      ![
        RealEstatePurpose.OWN_HOUSE,
        RealEstatePurpose.OWN_PROFESSION_DECLARANT,
        RealEstatePurpose.OWN_PROFESSION_PARTNER,
        RealEstatePurpose.EXCLUSION_PERIOD,
        null,
        undefined,
      ].includes(purpose)
    ) {
      this.purposePeriodEditable.consideredAsOwnHouse = false;
    } else {
      this.purposePeriodEditable.consideredAsOwnHouse = undefined;
    }
    if (purpose === RealEstatePurpose.RENTED_OUT_PROFESSIONAL || purpose === RealEstatePurpose.RENTED_OUT_PRIVATE) {
      this.purposePeriodEditable.rentalDetails = new RentalDetails();
    } else {
      this.purposePeriodEditable.rentalDetails = undefined;
    }
  }

  get purposes(): any[] {
    const allValues = [
      RealEstatePurpose.OWN_HOUSE,
      RealEstatePurpose.RENTED_OUT_PRIVATE,
      RealEstatePurpose.RENTED_OUT_PROFESSIONAL,
      RealEstatePurpose.OWN_PROFESSION_DECLARANT,
      RealEstatePurpose.OWN_PROFESSION_PARTNER,
      RealEstatePurpose.NOT_RENTED_OUT,
      RealEstatePurpose.LEASED_AGRICULTURAL,
      RealEstatePurpose.RENTED_OUT_ON_NON_PROFIT_BASIS,
      RealEstatePurpose.EXCLUSION_PERIOD,
    ];
    return allValues.map((i) => {
      return {
        value: i,
        text: this.capitalize(
          this.$t(
            `real_estate_and_loans.real_estate.purpose_period.purposes.${i}`
          ).toString()
        ),
        tooltip: i === RealEstatePurpose.EXCLUSION_PERIOD ?  this.$t(
          `real_estate_and_loans.real_estate.purpose_period.purposes.${i}$info`
        ).toString() : undefined,
        disabled:
          !this.isDoubleReturn && i === RealEstatePurpose.OWN_PROFESSION_PARTNER,
      };
    });
  }

  close() {
    this.$emit("close");
  }

  save() {
    this.$emit("save", this.purposePeriodEditable.clone());
    this.close();
  }

  remove() {
    this.$emit("remove");
    this.close();
  }

  private capitalize(str: string): string {
    return str.replace(/^\w/, (c) => c.toUpperCase());
  }
}
