import ValidationRule from "@/components/input/validation/validationRule";
import ReverseCodeStrategy from "@/components/input/validation/reverseCodeStrategy";

type CodeInfo = import("@/components/input/codeInfo").default;

export default abstract class RegularCodeStrategy extends ValidationRule {
  constructor(i18n: any, necessaryCodes: CodeInfo[]) {
    super(i18n);
    this.necessaryCodes = necessaryCodes;
  }

  necessaryCodes: CodeInfo[];

  abstract getReverseRule(primary: CodeInfo): ReverseCodeStrategy;
}
