
import { Component, Model, Prop, Vue, Watch } from "nuxt-property-decorator";
import {
  mdiAlertCircle,
  mdiCalendar,
  mdiCashMultiple,
  mdiCheck,
  mdiClose,
  mdiDelete,
  mdiArrowRightBottom,
} from "@mdi/js";
import VNumberField from "@/components/input/wizards/VNumberField";
import VDateField from "@/components/input/wizards/VDateField.vue";
import Validatable from "~/components/input/wizards/validatable";
import LifeInsurance from "~/components/input/wizards/realEstateAndLoans/loans/lifeInsurance";
import PaymentsView from "~/components/input/wizards/realEstateAndLoans/loans/PaymentsView.vue";
import RealEstateAndLoans from "~/components/input/wizards/realEstateAndLoans/realEstateAndLoans";
import Loan from "~/components/input/wizards/realEstateAndLoans/loans/loan";

@Component({
  components: {
    VNumberField,
    VDateField,
    PaymentsView,
  },
})
export default class LifeInsuranceView extends Vue {
  @Model("update:value", {}) readonly value: LifeInsurance | undefined;

  @Prop({ required: true, type: Number }) readonly taxYear: number | undefined;
  @Prop({ type: Boolean }) readonly initialShowPayments: boolean | undefined;
  @Prop({ type: Object }) readonly realEstateAndLoans!: RealEstateAndLoans | undefined;
  @Prop({ type: Object }) readonly loan!: Loan | undefined;
  @Prop({ type: String }) readonly label: string | undefined;
  @Prop({ default: false, type: Boolean }) readonly disabled!: boolean;
  @Prop({ default: false, type: Boolean }) readonly autofocus!: boolean;
  @Prop({ default: false, type: Boolean }) readonly readonly!: boolean;
  @Prop({ default: false, type: Boolean }) readonly hideDetails!: boolean;
  @Prop({ default: false, type: Boolean }) readonly clearable!: boolean;
  @Prop({ default: false, type: Boolean }) readonly error!: boolean;
  @Prop({ type: Array<String> }) readonly errorMessages!: string[] | undefined;
  @Prop({}) readonly rules!: any | undefined;

  // @ts-ignore
  // just take a copy of the value (don't clone)
  localValue: LifeInsurance | null = this.value !== undefined ? this.value : null;

  mdiCalendar = mdiCalendar;

  mdiDelete = mdiDelete;

  mdiArrowRightBottom = mdiArrowRightBottom;

  get validOrErrors(): Map<string, string[]> | boolean {
    return this.localValue
      ? this.localValue.validate({
          properties: this.realEstateAndLoans ? this.realEstateAndLoans.input.realEstate : undefined,
          isDoubleReturn: this.realEstateAndLoans
            ? this.realEstateAndLoans.input.currentReturn.isDoubleReturn
            : undefined,
          loan: this.loan,
        })
      : true;
  }

  errorsForField(fieldName: string): string[] {
    return Validatable.errorsForField(fieldName, this.validOrErrors);
  }

  @Watch("value", { deep: true })
  onValueChange(value: LifeInsurance | undefined) {
    this.localValue = value !== undefined ? value : null;
  }

  @Watch("localValue", { deep: true })
  onLocalValueChange(value: LifeInsurance | null) {
    this.$emit("update:value", value !== null ? value : undefined);
  }

  get enabled(): boolean {
    return !!this.localValue;
  }

  set enabled(enable: boolean) {
    if (enable) {
      this.localValue = new LifeInsurance(this.taxYear!);
    } else {
      this.localValue = null;
    }
  }
}
