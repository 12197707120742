type CodeInfo = import("@/components/input/codeInfo").default;
type ForeignIncome = import("@/components/input/foreignIncome").default;

export default class CodeItem {
  constructor(
    info: CodeInfo,
    value: any,
    foreign: ForeignIncome[],
    userComment: string | undefined = undefined,
    tags: string[] | undefined = undefined
  ) {
    this.info = info;
    this.value = value;
    this.foreign = foreign;
    this.userComment = userComment;
    this.tags = tags !== undefined ? tags : [];
  }

  info!: CodeInfo;

  value: any;

  foreign!: ForeignIncome[];

  userComment: string | undefined = undefined;

  tags: string[] = [];

  typeErrors: string[] = [];

  customErrors: string[] = [];

  regularErrors: string[] = [];

  reverseErrors: string[] = [];

  get hasSomeValue() {
    return this.parsedValue !== null || ![undefined, ""].includes(this.userComment) || this.tags.length > 0;
  }

  get isTodo() {
    return this.tags.includes("todo");
  }

  set isTodo(enabled: boolean) {
    if (enabled) {
      if (!this.tags.includes("todo")) {
        this.tags.push("todo");
      }
    } else if (this.tags.includes("todo")) {
      this.tags = this.tags.filter((tag) => tag !== "todo");
    }
  }

  get parsedValue() {
    return this.value !== null && this.value !== "" ? this.info.parse(this.value) : null;
  }

  get valueFormatted() {
    return this.info.format(this.parsedValue);
  }

  get typeCustomRegularErrors() {
    return this.typeErrors.concat(this.customErrors, this.regularErrors);
  }

  get allErrors() {
    return this.typeErrors.concat(this.customErrors, this.regularErrors, this.reverseErrors);
  }

  get regularReverseErrors() {
    return this.typeErrors.concat(this.regularErrors, this.reverseErrors);
  }

  get fromWizards(): string[] {
    return this.tags.filter((tag) => tag.startsWith("from_wizard:")).map((tag) => tag.replace("from_wizard:", ""));
  }

  fromEnabledWizards(enabledWizards: string[]): string[] {
    return this.tags
      .filter((tag) => tag.startsWith("from_wizard:"))
      .map((tag) => tag.replace("from_wizard:", ""))
      .filter((tag) => enabledWizards.includes(tag));
  }

  removeFromWizardTags(): void {
    this.tags = this.tags.filter((tag) => !tag.startsWith("from_wizard:"));
  }

  errors(
    includeTypeErrors = true,
    includeCustomErrors = true,
    includeRegularErrors = true,
    includeReverseErrors = true
  ): string[] {
    const result: string[] = [];
    if (includeTypeErrors) result.push(...this.typeErrors);
    if (includeCustomErrors) result.push(...this.customErrors);
    if (includeRegularErrors) result.push(...this.regularErrors);
    if (includeReverseErrors) result.push(...this.reverseErrors);
    return result;
  }

  validate(
    state: Map<string, CodeItem>,
    includeReverse: boolean = true,
    validateRequiredAdditionalInfo: boolean = false
  ): string[] {
    const isEqual = (array1: string[], array2: string[]) =>
      array1.length === array2.length && array1.every((value, index) => value === array2[index]);

    const typeErrors = this.info.validateType(this);
    if (!isEqual(typeErrors, this.typeErrors)) this.typeErrors = typeErrors;
    if (typeErrors.length === 0) {
      const customErrors = this.info.validateCustomRules(this, state);
      if (!isEqual(customErrors, this.customErrors)) this.customErrors = customErrors;
      const regularErrors = this.info.validateRegularRules(this, state, validateRequiredAdditionalInfo);
      if (!isEqual(regularErrors, this.regularErrors)) this.regularErrors = regularErrors;
      const reverseErrors = includeReverse
        ? this.info.validateReverseRules(this, state, validateRequiredAdditionalInfo)
        : [];
      if (!isEqual(reverseErrors, this.reverseErrors)) this.reverseErrors = reverseErrors;
    } else {
      this.customErrors = [];
      this.regularErrors = [];
      this.reverseErrors = [];
    }
    return this.allErrors;
  }

  clone(): CodeItem {
    return new CodeItem(
      this.info,
      this.value ? JSON.parse(JSON.stringify(this.value)) : this.value,
      this.foreign.map((item) => item.clone()),
      this.userComment,
      [...this.tags]
    );
  }

  public static foreignIncomeEquals(array1: ForeignIncome[], array2: ForeignIncome[]): boolean {
    if (array1.length === array2.length) {
      const array1str = array1.map((x) => `${x.country?.id}${x.parsedValue}${x.nature}${x.taxed}`).sort();
      const array2str = array2.map((x) => `${x.country?.id}${x.parsedValue}${x.nature}${x.taxed}`).sort();
      for (let i = 0; i < array1str.length; i += 1) {
        if (array1str[i] !== array2str[i]) {
          return false;
        }
      }
      return true;
    }
    return false;
  }
}
