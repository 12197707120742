export default class ResultListItemCombined {
  constructor(name: string, value: [number | null, number | null]) {
    this.name = name;
    this.value = value;
  }

  readonly name: string;

  readonly value: [number | null, number | null];
}
