
import { Component, Prop, Vue } from "nuxt-property-decorator";
import RealEstateAndLoans from "@/components/input/wizards/realEstateAndLoans/realEstateAndLoans";
import Loan from "~/components/input/wizards/realEstateAndLoans/loans/loan";
import CodesTable from "~/components/result/CodesTable.vue";
import Simulation from "~/components/simulation";

@Component({
  components: { CodesTable },
})
export default class LoansSummary extends Vue {
  @Prop({ required: true, type: Object })
  readonly simulation!: Simulation;

  @Prop({ required: true, type: Object })
  readonly realEstateAndLoans!: RealEstateAndLoans;

  codesForLoan(loanId: string | undefined): { [index: string]: any } | null {
    if (!loanId) {
      return null;
    }
    const codes = this.realEstateAndLoans.result?.loanCodesPerLoanMap.get(loanId);
    return codes && codes.loanCodes ? codes.loanCodes : null;
  }

  yesOrNoOrNothing(value: boolean | null | undefined): string {
    if (value === true) {
      return this.$i18n.t("real_estate_and_loans.loans.yes").toString();
    } else if (value === false) {
      return this.$i18n.t("real_estate_and_loans.loans.no").toString();
    } else {
      return "";
    }
  }

  get isDoubleReturn(): boolean {
    return this.realEstateAndLoans.input.currentReturn.isDoubleReturn;
  }

  showDetail(detailName: string, loan: Loan): boolean {
    return !!(
      // @ts-ignore
      (loan.detailsDeclarant[detailName] != null || (loan.detailsPartner && loan.detailsPartner[detailName] != null))
    );
  }

  printAsCurrency(value: number) {
    return value.toLocaleString(this.$i18n.locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: "currency",
      currency: "EUR",
    });
  }

  printAsDate(value: Date, delimiter = "/") {
    const localeDateString = value.toLocaleDateString(this.$i18n.locale, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    return localeDateString.replace(/[-.]/g, delimiter);
  }
}
