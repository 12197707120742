import ValidationRule from "@/components/input/validation/validationRule";

export default abstract class RegularValueStrategy extends ValidationRule {
  constructor(i18n: any, values: number[]) {
    super(i18n);
    this.values = values;
  }

  values: number[];
}
