
import {
  mdiAlertCircle,
  mdiCalendar,
  mdiCashMultiple,
  mdiCheck,
  mdiClose,
  mdiDelete,
  mdiHammerWrench,
  mdiHome,
  mdiLandPlots,
  mdiOfficeBuilding,
  mdiArrowRightBottom,
  mdiHelp,
} from "@mdi/js";
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";
import Property from "@/components/input/wizards/realEstateAndLoans/realEstate/property";
import PropertyType from "@/components/input/wizards/realEstateAndLoans/realEstate/propertyType";
import Country from "@/components/input/country";
import City from "@/components/input/city";
import deburr from "lodash.deburr";
import VNumberField from "@/components/input/wizards/VNumberField";
import VDateField from "@/components/input/wizards/VDateField.vue";
import PurposePeriodView from "@/components/input/wizards/realEstateAndLoans/realEstate/PurposePeriodView.vue";
import PurposePeriod from "@/components/input/wizards/realEstateAndLoans/realEstate/purposePeriod";
import Validatable from "@/components/input/wizards/validatable";
import CountryTreaty from "@/components/input/wizards/realEstateAndLoans/realEstate/countryTreaty";
import Loan from "../loans/loan";
import RealEstatePurpose from "@/components/input/wizards/realEstateAndLoans/realEstate/realEstatePurpose";
import RealEstateAndLoans from "@/components/input/wizards/realEstateAndLoans/realEstateAndLoans";
import LoanView from "@/components/input/wizards/realEstateAndLoans/loans/LoanView.vue";
import { v4 as uuidv4 } from "uuid";
import DeclarantDetails from "~/components/input/wizards/realEstateAndLoans/loans/declarantDetails";
import CadastralIncomeUpdate from "~/components/input/wizards/realEstateAndLoans/realEstate/cadastralIncomeUpdate";
import Refinancing from "~/components/input/wizards/realEstateAndLoans/loans/refinancing";

@Component({
  components: {
    VNumberField,
    VDateField,
    PurposePeriodView,
    LoanView,
  },
})
export default class PropertyView extends Vue {
  @Prop({ required: true, type: Object })
  readonly realEstateAndLoans!: RealEstateAndLoans;

  @Prop({ required: true, type: Object })
  readonly property!: Property;

  @Prop({ required: true, type: Map })
  readonly countries!: Map<string, Country>;

  @Prop({ type: Map })
  readonly cities!: Map<string, City>;

  // == undefined if not first property
  @Prop({ type: City })
  readonly currentCity: City | undefined;

  @Prop({ default: true, type: Boolean })
  readonly editable!: boolean;

  ready: boolean = false;

  realEstateAndLoansEditable: RealEstateAndLoans = this.createRealEstateAndLoansClone();

  mdiCheck = mdiCheck;

  mdiClose = mdiClose;

  mdiDelete = mdiDelete;

  mdiCalendar = mdiCalendar;

  mdiAlertCircle = mdiAlertCircle;

  mdiCashMultiple = mdiCashMultiple;

  mdiArrowRightBottom = mdiArrowRightBottom;

  mdiHelp = mdiHelp;

  selectedCity: City | null = null;

  changesInIncomeYearEnabled: boolean = this.changesInIncomeYear;

  loanView: boolean = false;

  loanOrRefinancingToEdit: Loan | Refinancing | null = null;

  savedLoans: Loan[] = [];

  removedLoans: string[] = [];

  get errors() {
    return this.propertyEditable.validate();
  }

  mounted() {
    if (this.propertyEditable.address && this.propertyEditable.address.countryCode === "BE") {
      if (this.propertyEditable.address.postCode) {
        if (this.citiesExtended.has(this.propertyEditable.address.postCode)) {
          this.selectedCity = this.citiesExtended.get(this.propertyEditable.address.postCode)!;
        }
      } else if (this.propertyEditable.address.city) {
        this.selectedCity = this.citiesExtended.get("")!;
      }
    }

    this.$nextTick(() => {
      this.ready = true;
    });

    setTimeout(() => {
      if (this.propertyEditable.isValid() && this.missingLoanFields) {
        (this.$refs.loans as HTMLElement).scrollIntoView({ behavior: "smooth" });
      }
    }, 50);

    if (this.propertyEditable.isValid() && !this.loansForProperty.every((loan) => loan.isValid())) {
      (this.$refs.loans as HTMLElement).scrollIntoView({ behavior: "smooth" });
    }
  }

  get validateVars(): any {
    return {
      properties: this.realEstateAndLoansEditable.input.realEstate,
      loans: this.realEstateAndLoansEditable.input.loans,
      isDoubleReturn: this.realEstateAndLoansEditable.input.currentReturn.isDoubleReturn,
    };
  }

  createRealEstateAndLoansClone(): RealEstateAndLoans {
    const realEstateAndLoans = this.realEstateAndLoans.clone();
    realEstateAndLoans.optimizePartnerDistribution = false;
    if (
      !realEstateAndLoans.input.realEstate.map((property) => property.propertyId).includes(this.property.propertyId)
    ) {
      realEstateAndLoans.input.realEstate.push(this.property.clone());
    }
    return realEstateAndLoans;
  }

  get propertyEditable(): Property {
    return this.realEstateAndLoansEditable.input.realEstate.filter(
      (property) => property.propertyId === this.property.propertyId
    )[0];
  }

  get missingLoanFields(): Map<string, string[]> | null {
    return this.realEstateAndLoansEditable.missingLoanFieldsForProperty(this.propertyEditable.propertyId!);
  }

  get loansForProperty(): Loan[] {
    return this.realEstateAndLoansEditable.input.loansForPropertyId(this.propertyEditable.propertyId!);
  }

  get loansAndRefinancingsForProperty(): (Loan | Refinancing)[] {
    return this.realEstateAndLoansEditable.input.loansAndRefinancingsForPropertyId(this.propertyEditable.propertyId!);
  }

  get loansAndRefinancingsForPropertyThatQualifyForRefinancing(): (Loan | Refinancing)[] {
    return this.loansAndRefinancingsForProperty.filter((loan) => loan.refinancing === undefined);
  }

  get minimumDateForPeriod(): string {
    if (this.propertyEditable.purchaseDate && this.propertyEditable.purchaseDate.getFullYear() === this.incomeYear) {
      return this.propertyEditable.purchaseDate.toISOString().substring(0, 10);
    }
    return `${this.incomeYear}-01-01`;
  }

  get maximumDateForPeriod(): string {
    if (this.propertyEditable.saleDate && this.propertyEditable.saleDate.getFullYear() === this.incomeYear) {
      return this.propertyEditable.saleDate.toISOString().substring(0, 10);
    }
    return `${this.incomeYear}-12-31`;
  }

  clearIncomeShare(partner: boolean = false) {
    setTimeout(() => {
      if (!partner) {
        this.propertyEditable.incomeShareDeclarant = 0.0;
      } else {
        this.propertyEditable.incomeShareDeclarant = undefined;
      }
    });
  }

  clearOwnershipShare(partner: boolean = false) {
    setTimeout(() => {
      if (!partner) {
        this.propertyEditable.ownershipShareDeclarant = 0.0;
      } else if (this.isDoubleReturn) {
        this.propertyEditable.ownershipSharePartner = 0.0;
      }
    });
  }

  get isDoubleReturn(): boolean {
    return this.realEstateAndLoansEditable.input.currentReturn.isDoubleReturn;
  }

  get validateProperty(): Map<string, string[]> | boolean {
    return this.propertyEditable.validate(this.validateVars);
  }

  get validOrErrors(): Map<string, string[]> | boolean {
    return (
      this.propertyEditable.validate(this.validateVars) === true &&
      this.loansForProperty.every((loan) => loan.isValid(this.validateVars)) &&
      !this.missingLoanFields
    );
  }

  errorsForField(fieldName: string): string[] {
    return Validatable.errorsForField(fieldName, this.validateProperty);
  }

  get validatePurposes() {
    return this.propertyEditable.validatePurposes();
  }

  get incomeYear(): number {
    return this.propertyEditable._taxYear - 1;
  }

  get propertyTypes(): any[] {
    return [PropertyType.HOUSE, PropertyType.BUILDING, PropertyType.LAND, PropertyType.MATERIAL_AND_EQUIPMENT].map(
      (i) => ({
        value: i,
        text: PropertyView.capitalize(this.$t(`real_estate_and_loans.real_estate.property_type.${i}`).toString()),
      })
    );
  }

  get treaties(): any[] {
    return [CountryTreaty.ABROAD_WITH_TAX_AGREEMENT, CountryTreaty.ABROAD_WITHOUT_TAX_AGREEMENT].map((i) => ({
      value: i,
      text: PropertyView.capitalize(this.$t(`real_estate_and_loans.real_estate.treaties.${i}`).toString()),
      disabled:
        ((!this.propertyEditable.address.countryCode ||
          !this.countries.has(`${this.propertyEditable.address.countryCode.substring(0, 2)}_D`)) &&
          i === CountryTreaty.ABROAD_WITHOUT_TAX_AGREEMENT) ||
        ((!this.propertyEditable.address.countryCode ||
          !this.countries.has(`${this.propertyEditable.address.countryCode.substring(0, 2)}_P`)) &&
          i === CountryTreaty.ABROAD_WITH_TAX_AGREEMENT),
    }));
  }

  get countriesList(): Country[] {
    return Array.from(this.countries.values()).filter((c) => c.isRegular);
  }

  filterCountry: Object = (item: Country, queryText: string) =>
    deburr(item.description.toLocaleLowerCase()).includes(deburr(queryText.toLocaleLowerCase()));

  iconForType(propertyType: PropertyType): string {
    switch (propertyType) {
      case PropertyType.HOUSE: {
        return mdiHome;
      }
      case PropertyType.BUILDING: {
        return mdiOfficeBuilding;
      }
      case PropertyType.LAND: {
        return mdiLandPlots;
      }
      case PropertyType.MATERIAL_AND_EQUIPMENT: {
        return mdiHammerWrench;
      }
      default: {
        return mdiHelp;
      }
    }
  }

  get citiesExtended(): Map<string, City> {
    if (this.propertyEditable.address) {
      if (this.propertyEditable.address.postCode) {
        if (!this.cities.has(this.propertyEditable.address.postCode)) {
          const result = new Map(Array.from(this.cities));
          result.set(
            this.propertyEditable.address.postCode,
            new City(this.propertyEditable.address.postCode, "", this.propertyEditable.address.city!, 0.0)
          );
          return result;
        }
      }
      if (this.propertyEditable.address.city) {
        const result = new Map(Array.from(this.cities));
        result.set("", new City("", "", this.propertyEditable.address.city!, 0.0));
        return result;
      }
    }
    return this.cities;
  }

  get cityList(): City[] {
    return Array.from(this.citiesExtended.values());
  }

  filterCity: Object = (_item: City, queryText: string, itemText: string) =>
    deburr(itemText.toLocaleLowerCase()).includes(deburr(queryText.toLocaleLowerCase()));

  @Watch("selectedCity")
  onSelectedCityChange(city: City | null) {
    if (city) {
      this.propertyEditable.address!.postCode = city.postcode;
      this.propertyEditable.address!.city = city.name;
    } else if (this.propertyEditable.address!.countryCode == "BE") {
      this.propertyEditable.address!.postCode = undefined;
      this.propertyEditable.address!.city = undefined;
    }
  }

  @Watch("propertyEditable.address.countryCode")
  onCountryCodeChange(countryCode: string | null) {
    this.propertyEditable.address.postCode = undefined;
    this.propertyEditable.address.city = undefined;
    this.selectedCity = null;
    if (countryCode !== "BE" && countryCode !== null) {
      if (this.countries.has(`${countryCode.substring(0, 2)}_P`)) {
        this.propertyEditable.treaty = CountryTreaty.ABROAD_WITH_TAX_AGREEMENT;
      } else if (this.countries.has(`${countryCode.substring(0, 2)}_D`)) {
        this.propertyEditable.treaty = CountryTreaty.ABROAD_WITHOUT_TAX_AGREEMENT;
      } else {
        this.propertyEditable.treaty = undefined;
      }
    } else {
      this.propertyEditable.treaty = undefined;
    }
  }

  bothTreatiesPossible(countryCode: string | null): boolean {
    if (!countryCode) {
      return false;
    }
    const regularId = countryCode.substring(0, 2);
    return this.countries.has(`${regularId}_P`) && this.countries.has(`${regularId}_D`);
  }

  get cadastralIncomeChangedInIncomeYear(): boolean {
    return this.propertyEditable.cadastralIncomeUpdate.date?.getFullYear() === this.propertyEditable._taxYear - 1;
  }
  get purchasedInIncomeYear(): boolean {
    return this.propertyEditable.purchaseDate?.getFullYear() === this.propertyEditable._taxYear - 1;
  }
  get saleDateChangedInIncomeYear(): boolean {
    return this.propertyEditable.saleDate?.getFullYear() === this.propertyEditable._taxYear - 1;
  }
  get forSaleDateChangedInIncomeYear(): boolean {
    return this.propertyEditable.forSaleDate?.getFullYear() === this.propertyEditable._taxYear - 1;
  }
  get changesInIncomeYear(): boolean {
    return (
      this.cadastralIncomeChangedInIncomeYear ||
      this.purchasedInIncomeYear ||
      this.saleDateChangedInIncomeYear ||
      this.forSaleDateChangedInIncomeYear
    );
  }
  get multipleChangesInIncomeYear(): boolean {
    return (
      [
        this.cadastralIncomeChangedInIncomeYear,
        this.purchasedInIncomeYear,
        this.saleDateChangedInIncomeYear,
        this.forSaleDateChangedInIncomeYear,
      ].filter((item) => item).length > 1
    );
  }

  @Watch("changesInIncomeYearEnabled")
  onChangesInIncomeYearEnabled(enabled: boolean) {
    if (!enabled) {
      this.propertyEditable.purchaseDate = undefined;
      this.propertyEditable.saleDate = undefined;
      this.propertyEditable.cadastralIncomeUpdate = new CadastralIncomeUpdate();
    }
  }

  @Watch("changesInIncomeYear")
  onChangesInIncomeYear(hasChanges: boolean) {
    this.changesInIncomeYearEnabled = hasChanges;
  }

  addPurposePeriod() {
    let currentRate = this.propertyEditable.purposes
      .map((purpose) => (purpose.rate && !isNaN(purpose.rate) ? purpose.rate : 0.0))
      .reduce((a, b) => a + b, 0.0);
    let minDate = new Date(this.minimumDateForPeriod);
    if (
      this.propertyEditable.purposes.length === 1 &&
      this.propertyEditable.purposes[0].rate === 1 &&
      this.propertyEditable.purposes[0].dateTo &&
      this.propertyEditable.purposes[0].dateTo < new Date(this.maximumDateForPeriod)
    ) {
      const day = 60 * 60 * 24 * 1000;
      minDate = new Date(this.propertyEditable.purposes[0].dateTo!.getTime() + day);
      currentRate = 0;
    }
    this.propertyEditable.purposes.push(
      Vue.observable(
        new PurposePeriod(
          currentRate < 1.0 ? 1.0 - currentRate : undefined,
          minDate,
          new Date(this.maximumDateForPeriod),
          this.currentCity && this.propertyEditable.purposes.length === 0 ? RealEstatePurpose.OWN_HOUSE : undefined
        )
      )
    );
  }

  removePurposePeriod(index: number) {
    this.propertyEditable.purposes.splice(index, 1);
  }

  close() {
    this.$emit("close");
  }

  save() {
    if (this.validOrErrors === true) {
      this.$emit("save", this.propertyEditable, this.savedLoans, this.removedLoans);
      this.close();
    }
  }

  remove() {
    this.$emit("save", null);
    this.close();
  }

  openLoanView(loan: Loan | Refinancing) {
    this.loanOrRefinancingToEdit = loan;
    this.loanView = true;
  }

  closeLoanView() {
    this.loanView = false;
    this.loanOrRefinancingToEdit = null;
  }

  editLoanOrRefinancing(loan: Loan | Refinancing) {
    this.openLoanView(loan);
  }

  addLoan() {
    this.loanOrRefinancingToEdit = new Loan(
      this.realEstateAndLoans.input._taxYear,
      [this.property.propertyId!],
      uuidv4(),
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      new DeclarantDetails(true),
      new DeclarantDetails(this.realEstateAndLoans.input.currentReturn.isDoubleReturn)
    );
    this.loanView = true;
  }

  addRefinancing(loan: Loan | Refinancing) {
    this.loanOrRefinancingToEdit = new Refinancing(
      loan,
      uuidv4(),
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      new DeclarantDetails(true),
      new DeclarantDetails(this.realEstateAndLoans.input.currentReturn.isDoubleReturn)
    );
    this.loanView = true;
  }

  saveLoanOrRefinancing(loan: Loan | Refinancing | null) {
    if (!loan) {
      return null;
    }
    if (loan instanceof Loan) {
      this.saveLoan(loan);
    } else {
      let parent = loan.parent;
      parent.refinancing = loan;
      while (parent instanceof Refinancing) {
        parent = parent.parent;
      }
      this.saveLoan(parent as Loan);
    }

    // todo: might not need this if we get the state from saved LoanView
    if (this.missingLoanFields && this.missingLoanFields.has(loan.loanId)) {
      this.realEstateAndLoansEditable.updateResult();
    }
  }

  saveLoan(loan: Loan | null) {
    if (loan) {
      this.savedLoans = this.savedLoans.filter((i) => i.loanId !== loan.loanId);
      this.realEstateAndLoansEditable.input.loans = this.realEstateAndLoansEditable.input.loans.filter(
        (l) => l.loanId !== loan.loanId
      );
      this.savedLoans.push(loan);
      this.realEstateAndLoansEditable.input.loans.push(loan);
    } else {
      this.removeLoanOrRefinancing(this.loanOrRefinancingToEdit!);
    }
    this.closeLoanView();
  }

  removeLoanOrRefinancing(loan: Loan | Refinancing) {
    if (loan instanceof Loan) {
      this.removeLoan(loan);
    } else {
      loan.parent.refinancing = undefined;
      // @ts-ignore
      this.saveLoanOrRefinancing(loan.parent);
    }
  }

  removeLoan(loan: Loan) {
    if (this.loansForProperty.filter((l) => l.loanId === loan.loanId).length === 1) {
      this.removedLoans.push(loan.loanId);
    }
    this.savedLoans = this.savedLoans.filter((l) => l.loanId !== loan.loanId);
    this.realEstateAndLoansEditable.input.loans = this.realEstateAndLoansEditable.input.loans.filter(
      (l) => l.loanId !== loan.loanId
    );
  }

  get formatCurrencyArgs2(): any {
    return [
      this.$i18n.locale,
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: "currency",
        currency: "EUR",
      },
    ];
  }

  private static capitalize(str: string): string {
    return str.replace(/^\w/, (c) => c.toUpperCase());
  }
}
