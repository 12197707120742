import ValidationRule from "@/components/input/validation/validationRule";

type CodeItem = import("@/components/input/codeItem").default;

export default class PatternStringStrategy extends ValidationRule {
  constructor(i18n: any, patternInfo: any) {
    super(i18n);
    this.regex = new RegExp(patternInfo.regex);
    this.description = patternInfo.description;
  }

  regex: RegExp;

  description: string | undefined;

  validate(primary: CodeItem, state: Map<string, CodeItem>): string | boolean {
    return primary === null ||
      primary.parsedValue === null ||
      (typeof primary.parsedValue === "string" && primary.parsedValue.match(this.regex))
      ? true
      : (this.i18n.t("input.validation.pattern", [this.description]) as string);
  }
}
