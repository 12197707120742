var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.valueInstance)?_c('div',{staticClass:"electric-charging-station-type"},[_c('v-select',{style:({
        backgroundColor: _vm.numberOfChargingStations ? '#FFFFEE' : '',
        width: '8em',
        'max-width': '12em',
      }),attrs:{"items":[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],"dense":"","outlined":"","clearable":"","hide-details":"","label":_vm.$t('input.complexTypes.electricChargingStationType.numberOfChargingStations'),"readonly":!_vm.editable,"disabled":_vm.disabled,"error":_vm.allInError},model:{value:(_vm.numberOfChargingStations),callback:function ($$v) {_vm.numberOfChargingStations=$$v},expression:"numberOfChargingStations"}}),_vm._v(" "),(_vm.numberOfChargingStations)?_c('v-select',{style:({
        backgroundColor: _vm.valueInstance.type ? '#FFFFEE' : '',
        width: '12em',
      }),attrs:{"items":_vm.chargingStationTypes,"dense":"","hide-details":"","outlined":"","item-text":"text","item-value":"value","label":_vm.$t('input.complexTypes.electricChargingStationType.type'),"readonly":!_vm.editable,"disabled":_vm.disabled,"error":!_vm.valueInstance.type || _vm.allInError},model:{value:(_vm.valueInstance.type),callback:function ($$v) {_vm.$set(_vm.valueInstance, "type", $$v)},expression:"valueInstance.type"}}):_vm._e(),_vm._v(" "),(_vm.numberOfChargingStations)?_c('v-autocomplete',{style:(_vm.valueInstance.postalCode ? 'background-color: #FFFFEE' : ''),attrs:{"dense":"","outlined":"","disabled":_vm.disabled,"readonly":!_vm.editable,"items":_vm.cityList,"item-text":"description","item-value":"postcode","auto-select-first":true,"filter":_vm.filterCity,"label":_vm.$t('input.complexTypes.electricChargingStationType.city'),"allow-overflow":true,"hide-details":"","error":!_vm.valueInstance.postalCode || _vm.allInError},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.valueInstance.postalCode),callback:function ($$v) {_vm.$set(_vm.valueInstance, "postalCode", $$v)},expression:"valueInstance.postalCode"}}):_vm._e(),_vm._v(" "),_vm._l((_vm.valueInstance.chargePoint),function(chargePoint,index){return _c('v-text-field',{key:index,style:({
        backgroundColor: chargePoint ? '#FFFFEE' : '',
        width: '30%',
        'max-width': '30%',
      }),attrs:{"value":chargePoint,"outlined":"","label":`${_vm.$t('input.complexTypes.electricChargingStationType.chargingStation')} ${index + 1}: ${_vm.$t(
        'input.complexTypes.electricChargingStationType.chargePoints'
      )}`,"dense":"","disabled":_vm.disabled,"readonly":!_vm.editable,"hide-details":"","error":!chargePoint || _vm.allInError},on:{"input":function($event){return _vm.updateChargePoint(index, $event)}}})}),_vm._v(" "),(_vm.numberOfChargingStations)?_c('v-divider',{staticStyle:{"flex-basis":"100%"}}):_vm._e(),_vm._v(" "),(_vm.numberOfChargingStations)?_c('v-number-field',{staticClass:"item-field",style:(_vm.valueInstance.totalExpenseTit ? 'background-color: #FFFFEE' : ''),attrs:{"outlined":"","dense":"","label":_vm.$t('input.complexTypes.electricChargingStationType.totalExpenseTit'),"disabled":_vm.disabled,"readonly":!_vm.editable,"prefix":"€","hide-details":!_vm.valueInstance.maxDeclarant,"messages":_vm.valueInstance.maxDeclarant ? `1365: ${_vm.valueInstance.maxDeclarant.toLocaleString(..._vm.formatCurrencyArgs2)}` : '',"error":_vm.allInError ||
        Number.isNaN(_vm.valueInstance.totalExpenseTit) ||
        (_vm.valueInstance.totalExpenseTit && _vm.valueInstance.totalExpenseTit < 0)},model:{value:(_vm.valueInstance.totalExpenseTit),callback:function ($$v) {_vm.$set(_vm.valueInstance, "totalExpenseTit", $$v)},expression:"valueInstance.totalExpenseTit"}}):_vm._e(),_vm._v(" "),(_vm.isDoubleReturn && _vm.numberOfChargingStations)?_c('v-number-field',{staticClass:"item-field",style:(_vm.valueInstance.totalExpensePart ? 'background-color: #FFFFEE' : ''),attrs:{"outlined":"","label":_vm.$t('input.complexTypes.electricChargingStationType.totalExpensePart'),"dense":"","prefix":"€","disabled":_vm.disabled,"readonly":!_vm.editable,"hide-details":!_vm.valueInstance.maxPartner,"messages":_vm.valueInstance.maxPartner ? `2365: ${_vm.valueInstance.maxPartner.toLocaleString(..._vm.formatCurrencyArgs2)}` : '',"error":_vm.allInError ||
        Number.isNaN(_vm.valueInstance.totalExpensePart) ||
        (_vm.valueInstance.totalExpensePart && _vm.valueInstance.totalExpensePart < 0)},model:{value:(_vm.valueInstance.totalExpensePart),callback:function ($$v) {_vm.$set(_vm.valueInstance, "totalExpensePart", $$v)},expression:"valueInstance.totalExpensePart"}}):_vm._e()],2):_vm._e(),_vm._v(" "),(_vm.numberOfChargingStations)?_c('div',{staticStyle:{"margin-bottom":"20px"},domProps:{"innerHTML":_vm._s(_vm.$t('input.complexTypes.electricChargingStationType.requiredAppendicesMessages'))}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }