
import { Component, Vue, Prop } from "vue-property-decorator";
import { Watch } from "nuxt-property-decorator";

interface ICalculatorInput {
  current: string;
  operator: string;
  prev: string;
}

@Component
export default class Calculator extends Vue {
  @Prop({ default: "" })
  value!: number | string;

  @Prop({ default: false })
  active!: boolean;

  delimiter = ",";

  input: ICalculatorInput = {
    current: `${this.value}`,
    operator: "",
    prev: "",
  };

  buttons = [
    { key: "ce", label: "CE", color: "grey lighten-2", class: "" },
    { key: "c", label: "C", color: "grey lighten-2", class: "" },
    {
      key: "back",
      label: "⌫",
      color: "grey lighten-2",
      class: "",
    },
    { key: "÷", label: "÷", color: "grey lighten-2", class: "" },
    { key: "7", label: "7", color: "", class: "headline" },
    { key: "8", label: "8", color: "", class: "headline" },
    { key: "9", label: "9", color: "", class: "headline" },
    { key: "×", label: "×", color: "grey lighten-2", class: "" },
    { key: "4", label: "4", color: "", class: "headline" },
    { key: "5", label: "5", color: "", class: "headline" },
    { key: "6", label: "6", color: "", class: "headline" },
    { key: "-", label: "-", color: "grey lighten-2", class: "" },
    { key: "1", label: "1", color: "", class: "headline" },
    { key: "2", label: "2", color: "", class: "headline" },
    { key: "3", label: "3", color: "", class: "headline" },
    { key: "+", label: "+", color: "grey lighten-2", class: "" },
    { key: "firstnull", class: "empty" },
    { key: "0", label: "0", color: "", class: "headline" },
    { key: this.delimiter, label: this.delimiter, color: "grey lighten-2", class: "" },
    { key: "=", label: "=", color: "grey lighten-2", class: "" },
  ];

  get displayFormula(): string {
    return `${this.input.prev} ${this.input.operator}`;
  }

  InputKey(key: string): void {
    const isNumber = /^\d$/.test(key);
    if (isNumber) {
      if (this.input.current.length >= 12) {
        return;
      }
      if (this.input.current !== "0") {
        this.input.current += key;
      } else {
        this.input.current = key;
      }
      return;
    }

    const isOperator = /^[÷×\-+]$/.test(key);
    if (isOperator) {
      if (this.input.current) {
        this.input.prev = this.Calc(this.input) || this.input.current;
      }
      this.input.operator = key;
      this.input.current = "";
      return;
    }

    switch (key) {
      case "=": {
        if (this.input.operator) {
          const result = this.Calc(this.input) || "";
          this.input.prev = `${this.input.prev.replace(".", this.delimiter)} ${
            this.input.operator
          } ${this.input.current.replace(".", this.delimiter)} =`;
          this.input.current = result.replace(".", this.delimiter);
          this.input.operator = "";
        }
        return;
      }

      case ".":
      case ",":
        if (!this.input.current.includes(".") && !this.input.current.includes(",")) {
          this.input.current += this.delimiter;
        }
        return;
      case "back":
        this.input.current = this.input.current.substring(0, this.input.current.length - 1) || "0";
        return;
      case "c":
        this.input.current = "0";
        this.input.operator = "";
        this.input.prev = "";
        return;
      case "ce":
        this.input.current = "0";
        return;
    }
  }

  handleKeydown(event: KeyboardEvent): void {
    const allowedKeys = /^[\d÷×\-+.,=⌫c/*x]$/;
    let key = event.key;

    if (key === "Enter") {
      if (this.input.operator) {
        this.InputKey("=");
      } else {
        this.commit();
      }
      event.preventDefault();
    } else if (key === "Backspace") {
      this.InputKey("back");
      event.preventDefault();
    } else if (key === ",") {
      this.InputKey(".");
      event.preventDefault();
    } else if (key === "*") {
      this.InputKey("×");
      event.preventDefault();
    } else if (key === "/") {
      this.InputKey("÷");
      event.preventDefault();
    } else if (key.toLowerCase() === "x") {
      this.InputKey("×");
      event.preventDefault();
    } else if (allowedKeys.test(key)) {
      this.InputKey(key);
      event.preventDefault();
    } else {
      event.preventDefault();
    }
  }

  handleButtonClick(key: string): void {
    this.InputKey(key);
    this.refocusInputField();
  }

  Calc(input: ICalculatorInput): string | null {
    const a = Number(input.prev.replace(",", "."));
    const b = Number(input.current.replace(",", "."));
    switch (input.operator) {
      case "÷": {
        const result = a / b;
        const resultString = result.toString();
        if (resultString.split(".")[1]?.length > 5) {
          return result.toFixed(5);
        }
        return resultString;
      }
      case "×":
        return (a * b).toString();
      case "-":
        return (a - b).toString();
      case "+":
        return (a + b).toString();
    }
    return null;
  }

  get roundedResult(): number {
    return Math.round((Number(this.input.current.replace(",", ".")) + Number.EPSILON) * 100) / 100;
  }

  commit(): void {
    this.InputKey("=");
    this.$emit("result", this.roundedResult.toFixed(2));
    this.$emit("close");
  }

  mounted() {
    this.$nextTick(() => {
      (this.$refs.inputField as HTMLInputElement).focus();
      this.input = {
        current: `${this.value != null ? this.value : 0}`,
        operator: "",
        prev: "",
      };
    });
  }

  refocusInputField(): void {
    this.$nextTick(() => {
      (this.$refs.inputField as HTMLInputElement).focus();
    });
  }

  @Watch("active")
  onActiveChange() {
    if (this.active) {
      this.input = {
        current: `${this.value != null ? this.value : 0}`,
        operator: "",
        prev: "",
      };
      this.refocusInputField();
    }
  }
}
