
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";
import Simulation from "~/components/simulation";
import OptimizationOpportunityLinesView from "~/components/result/OptimizationOpportunityLinesView.vue";

@Component({
  components: { OptimizationOpportunityLinesView }
})
export default class OptimizationOpportunitySummary extends Vue {
  @Prop({ required: true, type: Object })
  readonly simulation!: Simulation;

  private cachedValues = new Map<string, number>();
  @Watch("simulation", { immediate: true, deep: true })
  onSimulationChanged() {
    this.cachedValues.clear();
  }

  get isForDoubleReturn() {
    return this.simulation.result!.double;
  }

  get longTermSavingsLines() {
    return [
      { key: "1", label: "result.long_term_savings_optimization.regional" },
      {
        key: "2",
        label: "result.long_term_savings_optimization.maximum_exemptible",
        value: this.maximumExemptibleForRegionalFiscalKorfDeclarant,
        partnerValue: this.maximumExemptibleForRegionalFiscalKorfPartner,
      },
      {
        key: "3",
        label: "result.long_term_savings_optimization.utilized_for_regional_reduction_housing_bonus",
        value: this.usedForRegionalReductionHousingBonusDeclarant,
        partnerValue: this.usedForRegionalReductionHousingBonusPartner,
        indent: true,
      },
      { key: "4",
        label: "result.long_term_savings_optimization.remaining_balance",
        value: this.remainingBalanceRegionalAfterHousingBonusDeclarant,
        partnerValue: this.remainingBalanceRegionalAfterHousingBonusPartner,
      },
      {
        key: "6",
        label: "result.long_term_savings_optimization.average_marginal_rate",
        value: this.sumRegionalBuildingSavingsAlreadyExemptedDeclarant,
        partnerValue: this.sumRegionalBuildingSavingsAlreadyExemptedPartner,
        prefix: "1. ",
        indent: true,
      },
      {
        key: "6.1",
        label: "result.long_term_savings_optimization.fixed_rate_savings",
        value: this.sumRegionalBuildingSavingsAlreadyExemptedFixedRateDeclarant,
        partnerValue: this.sumRegionalBuildingSavingsAlreadyExemptedFixedRatePartner,
        prefix: "2. ",
        hide: this.getValue("1090") === 1,
        indent: true,
      },
      {
        key: "8",
        label: "result.long_term_savings_optimization.fixed_rate_long_term_savings",
        value: this.sumRegionalLongTermSavingsAlreadyExemptedDeclarant,
        partnerValue: this.sumRegionalLongTermSavingsAlreadyExemptedPartner,
        prefix: this.getValue("1090") === 1 ? "2. " : "3. ",
        indent: true,
      },
      { key: "9", label: "result.long_term_savings_optimization.remaining_balance",
        value: this.remainingBalanceRegionalDeclarant,
        partnerValue: this.remainingBalanceRegionalPartner,
      },

      { key: "10", label: "result.long_term_savings_optimization.federal", paddingTop: true },
      { key: "11", label: "result.long_term_savings_optimization.maximum_exemptible",
        value: this.maximumExemptibleForFederalFiscalKorfDeclarant,
        partnerValue: this.maximumExemptibleForFederalFiscalKorfPartner,
         },
      {
        key: "12",
        label: "result.long_term_savings_optimization.already_utilized_for_regional_reduction_housing_bonus",
        value: this.usedForFederalReductionHousingBonusDeclarant,
        partnerValue: this.usedForFederalReductionHousingBonusPartner,
        indent: true,
      },
      {
        key: "13",
        label: "result.long_term_savings_optimization.already_utilized_for_regional_reduction_savings",
        value: this.sumRegionalBuildingSavingsAlreadyExemptedTotalDeclarant,
        partnerValue: this.sumRegionalBuildingSavingsAlreadyExemptedTotalPartner,
        indent: true,
      },
      {
        key: "14",
        label: "result.long_term_savings_optimization.already_utilized_for_regional_reduction_long_term_savings",
        value: this.sumRegionalLongTermSavingsAlreadyExemptedDeclarant,
        partnerValue: this.sumRegionalLongTermSavingsAlreadyExemptedPartner,
        indent: true,
      },
      {
        key: "15",
        label: "result.long_term_savings_optimization.utilized_for_reduction_federal_housing_bonus",
        value: this.usedForFederalReductionHousingBonusDeclarant,
        partnerValue: this.usedForFederalReductionHousingBonusPartner,
        indent: true,
      },

      {
        key: "16",
        label: "result.long_term_savings_optimization.remaining_balance",
        value: this.remainingBalanceFederalAfterHousingBonusDeclarant,
        partnerValue: this.remainingBalanceFederalAfterHousingBonusPartner,
      },
      {
        key: "18",
        label: "result.long_term_savings_optimization.average_marginal_rate",
        value: this.sumFederalBuildingSavingsAlreadyExemptedDeclarant,
        partnerValue: this.sumFederalBuildingSavingsAlreadyExemptedPartner,
        prefix: "1. ",
        indent: true,
      },
      {
        key: "20",
        label: "result.long_term_savings_optimization.fixed_rate_long_term_savings",
        value: this.sumFederalLongTermSavingsAlreadyExemptedDeclarant,
        partnerValue: this.sumFederalLongTermSavingsAlreadyExemptedPartner,
        prefix: "2. ",
        indent: true,
      },
      { key: "22", label: "result.long_term_savings_optimization.extra_premium",
        value: this.remainingBalanceFederalDeclarant,
        partnerValue: this.remainingBalanceFederalPartner,
        paddingTop: true,
      },
      {
        key: "23",
        label: "result.long_term_savings_optimization.supplementary_tax_savings",
        value: this.savingsForFederalDeclarant,
        partnerValue: this.savingsForFederalPartner,
        tooltip: "result.extra_tax_saving_tooltip",
      },

    ];
  }

  getValue(code: string): number {
    if (!this.cachedValues.has(code)) {
      const value = this.simulation?.input?.codeItems?.get(code)?.value ?? 0.0;
      this.cachedValues.set(code, value);
    }
    return this.cachedValues.get(code)!;
  }

  get maximumExemptibleForRegionalFiscalKorfDeclarant() {
    const { max, extra } = this.getStaticInfoFiscaleKorfRegional();
    return this.min(max, ((this.simulation?.result?.primaryResult?.jointTaxableIncome ?? 0) * 0.06) + extra)
  }

  get maximumExemptibleForRegionalFiscalKorfPartner() {
    const { max, extra } = this.getStaticInfoFiscaleKorfRegional();
    return this.min(max, ((this.simulation?.result?.secondaryResult?.jointTaxableIncome ?? 0) * 0.06) + extra)
  }

  get usedForRegionalReductionHousingBonusDeclarant() {
    const thereIsALoanWhichIsRecentEnough = this.getValue("3372") === 1;
    const extraForOnlyHouse = this.getValue("3344") === 1 || (thereIsALoanWhichIsRecentEnough && this.getValue("3374") === 1);
    const extraForChildren = this.getValue("3346") >= 3 || (thereIsALoanWhichIsRecentEnough && this.getValue("3373") >= 3);
    const rawValues = this.getValue("3360") + this.getValue("3361") + this.getValue("3370") + this.getValue("3371");

    // TODO: get the extra values from the rules (or a better dynamic way)
    const result = rawValues - (extraForOnlyHouse ? (this.getValue("1090") !== 3 ? 760 : 940) : 0) - (extraForChildren ? (this.getValue("1090") !== 3 ? 80 : 90) : 0);
    return this.min(this.max(result, 0), this.maximumExemptibleForRegionalFiscalKorfDeclarant);
  }

  get usedForRegionalReductionHousingBonusPartner() {
    const thereIsALoanWhichIsRecentEnough = this.getValue("4372") === 1;
    const extraForOnlyHouse = this.getValue("4344") === 1 || (thereIsALoanWhichIsRecentEnough && this.getValue("4374") === 1);
    const extraForChildren = this.getValue("4346") >= 3 || (thereIsALoanWhichIsRecentEnough && this.getValue("4373") >= 3);
    const rawValues = this.getValue("4360") + this.getValue("4361") + this.getValue("4370") + this.getValue("4371");

    // TODO: get the extra values from the rules (or a better dynamic way)
    const result = rawValues - (extraForOnlyHouse ? (this.getValue("1090") !== 3 ? 760 : 940) : 0) - (extraForChildren ? (this.getValue("1090") !== 3 ? 80 : 90) : 0);
    return this.min(this.max(result, 0), this.maximumExemptibleForRegionalFiscalKorfPartner);
  }

  get remainingBalanceRegionalAfterHousingBonusDeclarant() {
    return this.max(0, this.maximumExemptibleForRegionalFiscalKorfDeclarant - this.usedForRegionalReductionHousingBonusDeclarant);
  }

  get remainingBalanceRegionalAfterHousingBonusPartner() {
    return this.max(0, this.maximumExemptibleForRegionalFiscalKorfPartner - this.usedForRegionalReductionHousingBonusPartner);
  }

  get usedForFederalReductionHousingBonusDeclarant() {
    return this.min(this.getValue("1370") + this.getValue("1371"), this.maximumExemptibleForFederalFiscalKorfDeclarant);
  }

  get usedForFederalReductionHousingBonusPartner() {
    return this.min(this.getValue("2370") + this.getValue("2371"), this.maximumExemptibleForFederalFiscalKorfPartner);
  }


  get maximumExemptibleForFederalFiscalKorfDeclarant() {
    const { max, extra } = this.getStaticInfoFiscaleKorfFederal();
    return this.min(max, ((this.simulation?.result?.primaryResult?.jointTaxableIncome ?? 0) * 0.06) + extra)
  }

  get maximumExemptibleForFederalFiscalKorfPartner() {
    const { max, extra } = this.getStaticInfoFiscaleKorfFederal();
    return this.min(max, ((this.simulation?.result?.secondaryResult?.jointTaxableIncome ?? 0) * 0.06) + extra)
  }

  get sumRegionalBuildingSavingsAlreadyExemptedDeclarant() {
    return this.min(this.getValue("3355") + this.getValue("3356") + this.getValue("3351") + this.getValue("3352"), this.maximumExemptibleForRegionalFiscalKorfDeclarant);
  }

  get sumRegionalBuildingSavingsAlreadyExemptedPartner() {
    return this.min(this.getValue("4355") + this.getValue("4356") + this.getValue("4351") + this.getValue("4352"), this.maximumExemptibleForRegionalFiscalKorfPartner);
  }

  get sumRegionalBuildingSavingsAlreadyExemptedFixedRateDeclarant() {
    return this.min(this.getValue("3359") + this.getValue("3350"), this.maximumExemptibleForRegionalFiscalKorfDeclarant);
  }

  get sumRegionalBuildingSavingsAlreadyExemptedFixedRatePartner() {
    return this.min(this.getValue("4359") + this.getValue("4350"), this.maximumExemptibleForRegionalFiscalKorfPartner);
  }

  get sumRegionalBuildingSavingsAlreadyExemptedTotalDeclarant() {
    return this.min(this.sumRegionalBuildingSavingsAlreadyExemptedDeclarant + this.sumRegionalBuildingSavingsAlreadyExemptedFixedRateDeclarant, this.maximumExemptibleForRegionalFiscalKorfDeclarant);
  }

  get sumRegionalBuildingSavingsAlreadyExemptedTotalPartner() {
    return this.min(this.sumRegionalBuildingSavingsAlreadyExemptedPartner + this.sumRegionalBuildingSavingsAlreadyExemptedFixedRatePartner, this.maximumExemptibleForRegionalFiscalKorfPartner);
  }

  get sumRegionalLongTermSavingsAlreadyExemptedDeclarant() {
    return this.min(this.getValue("3358") + this.getValue("3353") + this.getValue("3354"), this.maximumExemptibleForRegionalFiscalKorfDeclarant);
  }

  get sumRegionalLongTermSavingsAlreadyExemptedPartner() {
    return this.min(this.getValue("4358") + this.getValue("4353") + this.getValue("4354"), this.maximumExemptibleForRegionalFiscalKorfPartner);
  }

  get sumFederalBuildingSavingsAlreadyExemptedDeclarant() {
    return this.min(this.getValue("1355") + this.getValue("1351"), this.maximumExemptibleForFederalFiscalKorfDeclarant);
  }

  get sumFederalBuildingSavingsAlreadyExemptedPartner() {
    return this.min(this.getValue("2355") + this.getValue("2351"), this.maximumExemptibleForFederalFiscalKorfPartner);
  }

  get sumFederalLongTermSavingsAlreadyExemptedDeclarant() {
    return this.min(this.getValue("1358") + this.getValue("1359") + this.getValue("1353") + this.getValue("1354"), this.maximumExemptibleForFederalFiscalKorfDeclarant);
  }

  get sumFederalLongTermSavingsAlreadyExemptedPartner() {
    return this.min(this.getValue("2358") + this.getValue("2359") + this.getValue("2353") + this.getValue("2354"), this.maximumExemptibleForFederalFiscalKorfPartner);
  }

  get remainingBalanceRegionalDeclarant() {
    return this.max(0, this.remainingBalanceRegionalAfterHousingBonusDeclarant - this.sumRegionalBuildingSavingsAlreadyExemptedDeclarant - this.sumRegionalBuildingSavingsAlreadyExemptedFixedRateDeclarant - this.sumRegionalLongTermSavingsAlreadyExemptedDeclarant);
  }

  get remainingBalanceRegionalPartner() {
    return this.max(0, this.remainingBalanceRegionalAfterHousingBonusPartner - this.sumRegionalBuildingSavingsAlreadyExemptedPartner - this.sumRegionalBuildingSavingsAlreadyExemptedFixedRatePartner - this.sumRegionalLongTermSavingsAlreadyExemptedPartner);
  }

  get remainingBalanceFederalAfterHousingBonusDeclarant() {
    return this.max(0, this.maximumExemptibleForFederalFiscalKorfDeclarant - this.usedForRegionalReductionHousingBonusDeclarant - this.sumRegionalBuildingSavingsAlreadyExemptedDeclarant - this.sumRegionalBuildingSavingsAlreadyExemptedFixedRateDeclarant - this.sumRegionalLongTermSavingsAlreadyExemptedDeclarant - this.usedForFederalReductionHousingBonusDeclarant);
  }

  get remainingBalanceFederalAfterHousingBonusPartner() {
    return this.max(0, this.maximumExemptibleForFederalFiscalKorfPartner - this.usedForRegionalReductionHousingBonusPartner - this.sumRegionalBuildingSavingsAlreadyExemptedPartner - this.sumRegionalBuildingSavingsAlreadyExemptedFixedRatePartner - this.sumRegionalLongTermSavingsAlreadyExemptedPartner - this.usedForFederalReductionHousingBonusPartner);
  }

  get remainingBalanceFederalDeclarant() {
    return this.max(0, this.remainingBalanceFederalAfterHousingBonusDeclarant - this.sumFederalBuildingSavingsAlreadyExemptedDeclarant - this.sumFederalLongTermSavingsAlreadyExemptedDeclarant);
  }

  get remainingBalanceFederalPartner() {
    return this.max(0, this.remainingBalanceFederalAfterHousingBonusPartner - this.sumFederalBuildingSavingsAlreadyExemptedPartner - this.sumFederalLongTermSavingsAlreadyExemptedPartner);
  }

  get savingsForFederalDeclarant() {
    return this.computeSavings(this.remainingBalanceFederalDeclarant);
  }

  get savingsForFederalPartner() {
    return this.computeSavings(this.remainingBalanceFederalPartner);
  }

  get municipalTaxRate() {
    return this.getValue("1061");
  }

  computeSavings(stillAvailable: number): number {
    const taxAdjustedMultiplier = this.municipalTaxRate > 0 ? 1 + this.municipalTaxRate / 100 : 1;
    return stillAvailable * 0.30 * taxAdjustedMultiplier;
  }

  getStaticInfoFiscaleKorfRegional() {
    let max = 0;
    let extra = 0;
    let keyMax = "";
    let keyExtra = "";

    // Determine the correct keys based on the value of "1090"
    switch (this.getValue("1090")) {
      case 1:
        // vl
        keyMax = "fiscale_korf_max_vl";
        keyExtra = "fiscale_korf_extra_vl";
        break;
      case 2:
        // wa
        keyMax = "fiscale_korf_max_wa";
        keyExtra = "fiscale_korf_extra_wa";
        break;
      case 3:
        // br
        keyMax = "fiscale_korf_max_br";
        keyExtra = "fiscale_korf_extra_br";
        break;
      default:
        return { max, extra }; // Return default values if no match
    }

    // Cache the maximum value if not already cached
    if (!this.cachedValues.has(keyMax)) {
      const rulesList = this.getRulesForCode("3355", "wallonia"); // same info will be in vl and br
      const rule = rulesList.find((r) => r["front_end_static_info"]);
      const info = rule?.front_end_static_info ?? "";



      // Parse the string to find the corresponding max and extra values
      const infoPairs = info.split("|").reduce((acc: Record<string, number>, pair: string) => {
        const [key, value] = pair.split(":").map(s => s.trim());
        acc[key] = parseFloat(value);
        return acc;
      }, {} as Record<string, number>);

      const valueMax = isNaN(infoPairs[keyMax]) ? 0 : infoPairs[keyMax];
      const valueExtra = isNaN(infoPairs[keyExtra]) ? 0 : infoPairs[keyExtra];

      // Cache the values
      this.cachedValues.set(keyMax, valueMax);
      this.cachedValues.set(keyExtra, valueExtra);
    }

    // Retrieve the cached values
    max = this.cachedValues.get(keyMax)!;
    extra = this.cachedValues.get(keyExtra)!;

    return { max, extra };
  }

  getStaticInfoFiscaleKorfFederal() {
    let max = 0;
    let extra = 0;
    const keyMax = "fiscale_korf_max";
    const keyExtra = "fiscale_korf_extra";

    // Cache the values if not already cached
    if (!this.cachedValues.has(keyMax)) {
      const rulesList = this.getRulesForCode('1355'); // Assuming this method fetches the rules for the given code
      const rule = rulesList.find((r) => r["front_end_static_info"]);
      const info = rule?.front_end_static_info ?? "";

      // Parse the string to find the corresponding max and extra values
      const infoPairs = info.split("|").reduce((acc: Record<string, number>, pair: string) => {
        const [key, value] = pair.split(":").map(s => s.trim());
        acc[key] = parseFloat(value);
        return acc;
      }, {} as Record<string, number>);

      const valueMax = isNaN(infoPairs[keyMax]) ? 0 : infoPairs[keyMax];
      const valueExtra = isNaN(infoPairs[keyExtra]) ? 0 : infoPairs[keyExtra];

      // Cache the values
      this.cachedValues.set(keyMax, valueMax);
      this.cachedValues.set(keyExtra, valueExtra);
    }

    // Retrieve the cached values
    max = this.cachedValues.get(keyMax)!;
    extra = this.cachedValues.get(keyExtra)!;

    return { max, extra };
  }


  private getRulesForCode(code: string, group: string = "shared") {
    return this.simulation?.input?.staticInfo?.codeInfo.get(group)?.get(code)?.rulesList ?? [];
  }

  min(a: number, b: number) {
    return Math.min(a, b);
  }

  max(a: number, b: number) {
    return Math.max(a, b);
  }
}
