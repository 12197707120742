
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";
import axios from "axios";
import SimulationInput from "@/components/input/simulationInput";
import Simulation from "@/components/simulation";
import TowFileListView from "~/components/input/towGeneration/TowFileListView.vue";
import TowFileBefore2024 from "~/components/input/towGeneration/towFileBefore2024";
import TowFile from "~/components/input/towGeneration/towFile";
import { AuthInfo } from "~/components/authInfo";

@Component({
  components: { TowFileListView },
})
export default class SaveInputDialog extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly active!: boolean;

  @Prop({ required: true, type: Object })
  readonly simulation!: Simulation;

  @Prop({ default: false, type: Boolean })
  readonly hideMenuItemExportTow!: boolean;

  readonly towSupportedTaxYears: number[] = SimulationInput.towSupportedYears;

  saveInputOption = "regular";

  snackbar = false;

  loading = false;

  mounted() {
    this.prepareInitial();
  }

  capitalize(str: string): string {
    return str.replace(/^\w/, (c) => c.toUpperCase());
  }

  save(): void {
    if (this.saveable) {
      if (this.saveInputOption === "regular") {
        this.saveCurrentsimulationInput();
        this.close();
      } else {
        this.generateTow();
      }
    }
  }

  get towFileName(): string {
    if (this.nationalIdNumber) {
      return `${this.nationalIdNumber.replaceAll(".", "").replaceAll("-", "")}.tow`;
    }
    return "output.tow";
  }

  get saveable(): boolean {
    if (this.saveInputOption === "regular") {
      return !!this.regularFileName;
    } else {
      return this.nationalIdNumberIsValid && this.senderEmailIsValid;
    }
  }

  @Watch("active")
  onActiveChange() {
    if (!this.active) {
      this.close();
    } else {
      this.prepareInitial();
    }
  }

  prepareInitial() {
    this.regularFileName = "output.be.pit";

    this.towUrl = null;
    this.towError = null;
    this.towFiles = [];
    this.nationalIdNumber = this.simulation.input.nationalIdNumberDeclarant;
    try {
      this.senderEmail = localStorage.towSenderEmail ? localStorage.towSenderEmail : null;
    } catch (_) {
      this.senderEmail = null;
    }
  }

  close(): void {
    try {
      if (this.senderEmail !== localStorage.towSenderEmail && !["", undefined, null].includes(this.senderEmail)) {
        localStorage.towSenderEmail = this.senderEmail;
      }
    } catch (_) {}
    this.$emit("close");
    // avoid pressing enter again on uploading screen (after instant prepareInitial and focus on upload field) when pressing enter elsewhere
    setTimeout(() => {
      this.prepareInitial();
    }, 500);
  }

  /*
   * SAVE FILE TO DISK
   */

  regularFileName: string | null = null;

  saveCurrentsimulationInput() {
    if (this.simulation !== null && this.regularFileName !== null && this.regularFileName.length > 0) {
      const blob = new Blob([JSON.stringify(this.simulation.toObject())], {
        type: "text/plain;charset=utf-8",
      });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = `${this.regularFileName.replace(/.be.pit$/g, "")}.be.pit`;
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  }

  /*
   * TAX ON WEB ARCHIVE GENERATION
   */

  towUrl: string | null = null;

  towError: string | null = null;

  documentHandlingEntry = this.$config.urls.api!.concat("document-handling/v1/");

  nationalIdNumber: string | null = null;

  senderEmail: string | null = null;

  towFiles: TowFileBefore2024[] | TowFile[] = [];

  async generateTow() {
    if (this.nationalIdNumberIsValid) {
      this.simulation.input.nationalIdNumberDeclarant = this.nationalIdNumber;
    }
    if (
      this.simulation !== null &&
      this.towSupportedTaxYears.includes(this.simulation.input.taxYear) &&
      this.senderEmailIsValid &&
      this.nationalIdNumberIsValid
    ) {
      const dt: { [key: string]: any } = {
        filename: `${this.towFileName.replace(/.tow$/g, "")}.tow`,
        sender_email: this.senderEmail,
        declarations: [
          {
            declaration_type: "IPP",
            tax_year: this.simulation.input.taxYear,
            codes: this.simulation.input.codes,
            foreign_codes: this.simulation.input.foreignCodes!,
            declarant_info: {
              national_id_number: this.nationalIdNumber!.replaceAll(".", "").replaceAll("-", ""),
            },
          },
        ],
      };

      if (this.towFiles.length > 0) {
        dt["declarations"][0]["files"] = this.towFiles.map((f) => f.toObject());
      }

      const headers = await AuthInfo.getAuthHeaders();

      this.loading = true;

      axios
        .post(this.documentHandlingEntry.concat("tow"), dt, {
          timeout: 10000,
          headers: headers,
        })
        .then((response) => {
          this.towUrl = response.data.url;
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.href = this.towUrl!;
          a.click();
          document.body.removeChild(a);
          this.close();
        })
        .catch((error) => {
          console.error(error);
          this.towError =
            error.response && error.response.data && "message" in error.response.data
              ? error.response.data.message
              : error.toString();
          this.snackbar = true;
          setTimeout(() => {
            this.snackbar = false;
            this.towError = null;
          }, 5000);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  get nationalIdNumberIsValid(): boolean {
    return (
      this.nationalIdNumber !== null &&
      this.nationalIdNumber.length > 0 &&
      SaveInputDialog.checkBelgianNationalIdNumberChecksum(
        this.nationalIdNumber.replaceAll(".", "").replaceAll("-", "")
      )
    );
  }

  get senderEmailIsValid(): boolean {
    // from https://www.w3resource.com/javascript/form/email-validation.php
    return (
      this.senderEmail !== null &&
      this.senderEmail.length > 0 &&
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.senderEmail)
    );
  }

  // from https://sandervandevelde.wordpress.com/2020/08/13/belgische-rijksregisternummer-checksum-testen-dutch/
  static checkBelgianNationalIdNumberChecksum(rrn: string): boolean {
    const rrnChecksum = parseInt(rrn.substring(9, 11), 10);

    const partToCalculate = rrn.substring(0, 9);
    const rrnInt = parseInt(partToCalculate, 10);
    const checksum = 97 - (rrnInt % 97);

    if (rrnChecksum === checksum) {
      return true;
    }

    // Checksum not yet ok. We check for a possible 1900/2000 situation;
    // we repeat the same test but now with the extra '2' added to the part
    const partToCalculate2 = "2" + partToCalculate;
    const rrnInt2 = parseInt(partToCalculate2, 10);
    const checksum2 = 97 - (rrnInt2 % 97);

    return rrnChecksum === checksum2;
  }
}
