import SimulationInput from "@/components/input/simulationInput";
import SimulationResult from "@/components/result/simulationResult";
import debounce from "lodash.debounce";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { migrate } from "~/components/simulationMigrator";
import { AuthInfo } from "~/components/authInfo";

export default class Simulation {
  constructor(input: SimulationInput, result: SimulationResult | null, id: string | null = null) {
    this.input = input;
    this.result = result;
    this.id = id === null ? uuidv4() : id;
  }

  id: string;

  input: SimulationInput;

  result: SimulationResult | null;

  apiAddress = process.env.apiUrl!.concat("personal-income-tax/v1/");

  apiRequestsPending: number = 0;

  apiSyntaxException: string | null = null;

  apiOtherException: string | null = null;

  updateResultDebounced = debounce(
    (doWhenSuccess: any = undefined, includeFullText: boolean = false) =>
      this.updateResult(doWhenSuccess, includeFullText),
    20
  );

  get apiRequestPending(): boolean {
    return this.apiRequestsPending !== 0;
  }

  get apiExceptions(): boolean {
    return !!(this.apiOtherException || this.apiSyntaxException);
  }

  get isUpToDate(): boolean {
    return this.result ? this.input.requestHash === this.result.requestHash : false;
  }

  get isValid(): boolean {
    return this.input.isValid && !this.apiExceptions;
  }

  get isValidAndUpToDate(): boolean {
    return this.input.isValid && !this.apiExceptions && this.isUpToDate;
  }

  updateResult(doWhenSuccess: any = undefined, includeFullText: boolean = false, validate: boolean = true): void {
    if ((!validate || this.input.isValid) && window?.navigator?.onLine) {
      this.apiRequestsPending += 1;

      this.getResultPromise(this.input, includeFullText, validate)
        .then((result: SimulationResult) => {
          if (this.apiRequestsPending > 0) {
            this.apiRequestsPending -= 1;
          }
          // if no new requests
          if (result.requestHash === this.input.requestHash) {
            this.result = result;
            this.apiRequestsPending = 0;
            this.apiOtherException = null;
            this.apiSyntaxException = null;

            if (doWhenSuccess) {
              doWhenSuccess();
            }
          }
        })
        .catch((error: any) => {
          if (this.apiRequestsPending > 0) {
            this.apiRequestsPending -= 1;
          }
          if (!this.isUpToDate) {
            if (!error.response) {
              this.apiOtherException = "message" in error ? error.message : error.toString();
            } else if (error.response.status === 403) {
              this.apiOtherException = "Access forbidden";
            } else if (error.response.status > 499) {
              this.apiOtherException =
                error.response.data && error.response.data.length > 0 ? error.response.data : "Internal Server Error";
            } else {
              this.apiSyntaxException =
                error.response && error.response.data && "message" in error.response.data
                  ? error.response.data.message
                  : error.toString();
            }
          }
        });
    }
  }

  async getResultPromise(
    input: SimulationInput,
    includeFullText: boolean = false,
    validate: boolean = true
  ): Promise<SimulationResult> {
    const data = {
      codes: input.codesForCalculation,
      foreign_codes: input.foreignCodes,
      level_of_detail: "medium",
      include_full_text: includeFullText,
      result_text_language: input.locale,
      validate: validate,
    };

    const headers = await AuthInfo.getAuthHeaders();

    const resultObject = await axios.post(`${this.apiAddress}${input.taxYear}/calculate`, data, {
      timeout: 20000,
      headers: headers,
    });

    return SimulationResult.fromMapping(resultObject.data, input.requestHash);
  }

  get isReady(): boolean {
    return !this?.apiExceptions && !this?.apiRequestPending;
  }

  static fromInput(input: SimulationInput): Simulation {
    return new Simulation(input, null, null);
  }

  clone(): Simulation {
    return Simulation.fromObject(JSON.parse(JSON.stringify(this.toObject())));
  }

  cloneWithSwappedCodes(): Simulation {
    if (!this.input.isDoubleReturn) {
      return this.clone();
    }
    return new Simulation(this.input.cloneWithSwappedCodes(), null, null);
  }

  static fromObject(obj: any): Simulation {
    if (!(obj.id && obj.input)) {
      throw Error(`not a valid simulation: ${JSON.stringify(obj)}`);
    }
    obj = migrate(obj);
    return new Simulation(
      SimulationInput.fromObject(obj.input),
      obj.result ? SimulationResult.fromMapping(obj.result, null) : null,
      obj.id
    );
  }

  toObject(includeOnlyUpToDatResult: boolean = true): any {
    return {
      id: this.id,
      input: this.input.toObject(),
      result: this.result && (!includeOnlyUpToDatResult || this.isUpToDate) ? this.result.toMapping() : null,
    };
  }

  toLocalStorage(filename: string = "savedReturn_v1") {
    try {
      localStorage[filename] = JSON.stringify(this.toObject());
    } catch (e) {}
  }

  static fromLocalStorage(filename: string = "savedReturn_v1"): Simulation {
    const obj = JSON.parse(localStorage[filename]);

    if (obj.taxYear) {
      // deprecated
      return Simulation.fromInput(SimulationInput.fromQueryObject(obj));
    }
    return Simulation.fromObject(JSON.parse(localStorage[filename]));
  }

  removeFromLocalStorage(filename: string = "savedReturn_v1") {
    try {
      localStorage.removeItem(filename);
    } catch (e) {}
  }

  toPostMessageState() {
    return {
      simulation: this.toObject(),
      validationErrors: this.input.validationErrors,
      apiSyntaxError: this.apiSyntaxException,
      apiOtherError: this.apiOtherException,
      isUpToDate: this.isUpToDate,
    };
  }
}
