import Validatable from "@/components/input/wizards/validatable";

export default class Address extends Validatable {
  countryCode: string = "BE";
  streetAndNumber: string | undefined;
  postCode: string | undefined;
  city: string | undefined;

  constructor(
    countryCode: string = "BE",
    streetAndNumber: string | undefined = undefined,
    postCode: string | undefined = undefined,
    city: string | undefined = undefined
  ) {
    super(["countryCode", "city", "streetAndNumber"]);
    if (countryCode.length > 2) {
      throw EvalError("countryCode should be exactly 2 characters long");
    }
    this.countryCode = countryCode;
    this.streetAndNumber = streetAndNumber;
    this.postCode = postCode;
    this.city = city;
  }

  text() {
    const beAddress = `${this.streetAndNumber}, ${this.postCode} ${this.city}`;
    return this.countryCode === "BE" ? beAddress : `${beAddress} (${this.countryCode})`;
  }

  clone() {
    return this.cloneHelper(new Address());
  }

  static fromObject(obj: { [key: string]: any }): Address {
    return Validatable.fromObjectHelper(obj, new this());
  }
}
