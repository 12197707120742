import { render, staticRenderFns } from "./CodeItemEditorSimpleInputFieldView.vue?vue&type=template&id=3bc831da&scoped=true&"
import script from "./CodeItemEditorSimpleInputFieldView.vue?vue&type=script&lang=ts&"
export * from "./CodeItemEditorSimpleInputFieldView.vue?vue&type=script&lang=ts&"
import style0 from "./CodeItemEditorSimpleInputFieldView.vue?vue&type=style&index=0&id=3bc831da&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bc831da",
  null
  
)

export default component.exports