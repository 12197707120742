import PropertyType from "@/components/input/wizards/realEstateAndLoans/realEstate/propertyType";
import CountryTreaty from "@/components/input/wizards/realEstateAndLoans/realEstate/countryTreaty";
import CadastralIncomeUpdate from "@/components/input/wizards/realEstateAndLoans/realEstate/cadastralIncomeUpdate";
import PurposePeriod from "@/components/input/wizards/realEstateAndLoans/realEstate/purposePeriod";
import Validatable from "@/components/input/wizards/validatable";
import Address from "@/components/input/wizards/realEstateAndLoans/realEstate/address";
import Assert from "@/components/input/wizards/assert";
import { v4 as uuidv4 } from "uuid";
import RealEstatePurpose from "~/components/input/wizards/realEstateAndLoans/realEstate/realEstatePurpose";

export default class Property extends Validatable {
  _taxYear: number;
  propertyType: PropertyType | undefined;

  propertyId: string | undefined;

  cadastralIncome: number | undefined;

  incomeShareDeclarant: number | undefined;
  ownershipShareDeclarant: number | undefined;

  purposes: PurposePeriod[] = [];

  address: Address;

  ownershipSharePartner: number | undefined;

  treaty: CountryTreaty | undefined;

  cadastralIncomeUpdate: CadastralIncomeUpdate;
  purchaseDate: Date | undefined;
  saleDate: Date | undefined;

  forSaleDate: Date | undefined;
  bareOwnership: boolean = false;
  coOwnershipByInheritance: boolean = false;

  constructor(
    taxYear: number,
    propertyType: PropertyType | undefined = undefined,
    propertyId: string | undefined = undefined,
    cadastralIncome: number | undefined = undefined,
    incomeShareDeclarant: number | undefined = undefined,
    ownershipShareDeclarant: number | undefined = undefined,
    purposes: PurposePeriod[] = [],
    address: Address | undefined = undefined,
    ownershipSharePartner: number | undefined = undefined,
    incomeSharePartner: number | undefined = undefined,
    treaty: CountryTreaty | undefined = undefined,
    cadastralIncomeUpdate: CadastralIncomeUpdate | undefined = undefined,
    purchaseDate: Date | undefined = undefined,
    saleDate: Date | undefined = undefined,
    forSaleDate: Date | undefined = undefined,
    bareOwnership: boolean = false,
    coOwnershipByInheritance: boolean = false
  ) {
    const mandatoryFields = [
      "propertyType",
      "propertyId",
      "cadastralIncome",
      "ownershipShareDeclarant",
      "address",
      "purposes",
    ];
    const constraints = {
      propertyType: [() => Assert.isEnum(this.propertyType, PropertyType)],
      cadastralIncome: [() => Assert.greaterOrEqualThan(this.cadastralIncome, 0.0)],
      incomeShareDeclarant: [
        () => Assert.posiviteRate(this.incomeShareDeclarant),
        (vars?: any) =>
          Assert.posiviteRate(this.incomeSharePartner) === true && vars?.isDoubleReturn
            ? Assert.exactSum(this.incomeShareDeclarant, 1.0, this.incomeSharePartner, undefined, 100)
            : false,
      ],
      incomeSharePartner: [
        () => Assert.posiviteRate(this.incomeSharePartner),
        (vars?: any) =>
          Assert.posiviteRate(this.incomeShareDeclarant) === true && vars?.isDoubleReturn
            ? Assert.exactSum(this.incomeSharePartner, 1.0, this.incomeShareDeclarant, undefined, 100)
            : true,
      ],
      ownershipShareDeclarant: [
        () => Assert.posiviteRate(this.ownershipShareDeclarant),
        (vars?: any) =>
          Assert.posiviteRate(this.ownershipSharePartner) === true && vars?.isDoubleReturn
            ? Assert.maximumTogether(this.ownershipShareDeclarant, 1.0, this.ownershipSharePartner, undefined, 100)
            : false,
      ],
      ownershipSharePartner: [
        () => Assert.posiviteRate(this.ownershipSharePartner),
        (vars?: any) =>
          Assert.posiviteRate(this.ownershipShareDeclarant) === true && vars?.isDoubleReturn
            ? Assert.maximumTogether(this.ownershipSharePartner, 1.0, this.ownershipShareDeclarant, undefined, 100)
            : true,
      ],
      treaty: [
        () => Assert.isEnum(this.treaty, CountryTreaty),
        () => {
          if (this.address && this.address.countryCode !== "BE") {
            if (this.treaty === undefined) {
              return `required`;
            } else if (this.treaty === CountryTreaty.STATE) {
              return `should not be STATE`;
            }
          }
          return true;
        },
      ],
      purposes: [() => this.validatePurposes()],
      purchaseDate: [() => Assert.lessOrEqualThanDate(this.purchaseDate, this.saleDate)],
      saleDate: [() => Assert.greaterOrEqualThanDate(this.saleDate, this.purchaseDate)],
      forSaleDate: [
        () => Assert.greaterOrEqualThanDate(this.forSaleDate, this.purchaseDate),
        () => Assert.lessOrEqualThanDate(this.forSaleDate, this.saleDate),
      ],
    };
    super(mandatoryFields, constraints, ["purposes", "cadastralIncomeUpdate", "address"]);
    this._taxYear = taxYear;
    this.propertyType = propertyType;
    this.propertyId = propertyId ? propertyId : uuidv4();
    this.cadastralIncome = cadastralIncome;
    this.incomeShareDeclarant = incomeShareDeclarant;
    this.ownershipShareDeclarant = ownershipShareDeclarant;
    this.purposes = purposes;
    this.address = address ? address : new Address("BE");
    this.ownershipSharePartner = ownershipSharePartner;
    this.treaty = treaty;
    this.cadastralIncomeUpdate = cadastralIncomeUpdate ? cadastralIncomeUpdate : new CadastralIncomeUpdate();
    this.purchaseDate = purchaseDate;
    this.saleDate = saleDate;
    this.forSaleDate = forSaleDate;
    this.bareOwnership = bareOwnership;
    this.coOwnershipByInheritance = coOwnershipByInheritance;
  }

  public yearRateList(includeOwnHouse = true, includeNotOwnHouse: boolean = true, incomeYear?: number): number[] {
    const isLeapYear = (year: number) => year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
    const daysIntoYear = (date: Date) =>
      (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) /
      (24 * 60 * 60 * 1000);

    const incomeYear_ = incomeYear ?? this._taxYear - 1;
    const daysInYear = isLeapYear(incomeYear_) ? 366 : 365;
    const yearRateList: number[] = new Array(daysInYear).fill(0.0);
    for (const period of this.purposes) {
      if (period.dateFrom && period.dateTo && period.rate !== undefined && period.purpose !== undefined) {
        if ((includeOwnHouse && period.isOwnHouse) || (includeNotOwnHouse && !period.isOwnHouse)) {
          if (period.dateFrom.getFullYear() <= incomeYear_ || period.dateTo.getFullYear() >= incomeYear_) {
            const firstDateOfIncomeYear = new Date(incomeYear_, 0, 1);
            const lastDateOfIncomeYear = new Date(incomeYear_, 11, 31);
            const dateFrom: Date = period.dateFrom < firstDateOfIncomeYear ? firstDateOfIncomeYear : period.dateFrom;
            const dateTo: Date = period.dateTo > lastDateOfIncomeYear ? lastDateOfIncomeYear : period.dateTo;
            const numberOfDays = Math.round((dateTo.getTime() - dateFrom.getTime()) / (1000 * 60 * 60 * 24)) + 1;
            const startYday = daysIntoYear(dateFrom);
            for (let i = startYday; i <= startYday + numberOfDays - 1; i++) {
              yearRateList[i - 1] += period.rate;
            }
          }
        }
      }
    }

    const ownedFromYd =
      this.purchaseDate && this.purchaseDate.getFullYear() === incomeYear_ ? daysIntoYear(this.purchaseDate) - 1 : 0;
    const notOwnedFromYd =
      this.saleDate && this.saleDate.getFullYear() === incomeYear_ ? daysIntoYear(this.saleDate) - 1 : daysInYear;

    return [
      ...Array(ownedFromYd).fill(0),
      ...yearRateList.slice(ownedFromYd, notOwnedFromYd),
      ...Array(daysInYear - notOwnedFromYd).fill(0),
    ];
  }

  public validatePurposes(): boolean | string {
    if (!this.hasPurposeEveryDayOfTheYear(true, true)) {
      return "the entire property should have a purpose on every moment of the income year in which the property is owned";
    }
    return true;
  }

  get incomeSharePartner(): number | undefined {
    if (this.incomeShareDeclarant === undefined) {
      return undefined;
    }
    return Math.round((1.0 - Math.max(0.0, Math.min(this.incomeShareDeclarant, 1.0))) * 1000000) / 1000000;
  }

  set incomeSharePartner(incomeShare: number | undefined) {
    if (incomeShare === undefined) {
      this.incomeShareDeclarant = undefined;
    } else {
      const result = 1.0 - Math.max(0.0, Math.min(incomeShare, 1.0));
      if (Math.abs(result - (this.incomeShareDeclarant ?? 1.0)) > 0.000001) {
        this.incomeShareDeclarant = result;
      }
    }
  }

  get isOwnHouse(): boolean {
    return this.yearRateList(true, false).some((rate) => rate > 0);
  }

  hasPurposeEveryDayOfTheYear(includeOwnHouse = true, includeNotOwnHouse: boolean = true): boolean {
    const daysIntoYear = (date: Date) =>
      (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) /
      (24 * 60 * 60 * 1000);

    const incomeYear = this._taxYear - 1;
    const yearRateList: number[] = this.yearRateList(includeOwnHouse, includeNotOwnHouse);
    const daysInYear = yearRateList.length;
    const ownedFromYd =
      this.purchaseDate && this.purchaseDate.getFullYear() === incomeYear ? daysIntoYear(this.purchaseDate) - 1 : 0;
    const notOwnedFromYd =
      this.saleDate && this.saleDate.getFullYear() === incomeYear ? daysIntoYear(this.saleDate) - 1 : daysInYear;
    return yearRateList.slice(ownedFromYd, notOwnedFromYd).every((rate) => Math.round(rate * 1000) / 1000 === 1);
  }

  canMigratePurposePeriodsToCurrentTaxYear(includeOwnHouse = true, includeNotOwnHouse: boolean = true): boolean {
    if (!this.hasPurposeEveryDayOfTheYear(includeOwnHouse && includeNotOwnHouse)) {
      const yearRateList: number[] = this.yearRateList(includeOwnHouse, includeNotOwnHouse, this._taxYear - 2);
      const maxDate = this.purposes
        .map((p) => p.dateTo)
        .filter((d) => d !== undefined)
        .reduce((a, b) => ((a ?? 0) > (b ?? 0) ? a : b), new Date());
      if (yearRateList[yearRateList.length - 1] === 1 && maxDate?.getFullYear() === this._taxYear - 2) {
        return true;
      }
    }
    return false;
  }

  migratePurposePeriodsToCurrentTaxYear(includeOwnHouse = true, includeNotOwnHouse: boolean = true): void {
    if (this.canMigratePurposePeriodsToCurrentTaxYear(includeOwnHouse, includeNotOwnHouse)) {
      for (const purposePeriod of this.purposes) {
        if (
          purposePeriod.dateTo &&
          purposePeriod.dateTo.getFullYear() == this._taxYear - 2 &&
          purposePeriod.dateTo.getMonth() == 11 &&
          purposePeriod.dateTo.getDate() == 31
        ) {
          purposePeriod.dateTo = new Date(`${this._taxYear - 1}-12-31`);
        }
      }
    }
  }

  get hasPeriodsWithDifferentPurposesAndDifferentDates(): boolean {
    return (
      this.purposes.length > 1 &&
      !this.purposes
        .filter((purposePeriod) => purposePeriod.isValid())
        .every(
          (purposePeriod) =>
            this.purposes[0].hasSamePurposeAs(purposePeriod) ||
            this.purposes[0].hasSamePeriodDatesAs(purposePeriod, this._taxYear)
        )
    );
  }

  get wasBoughtInIncomeYear(): boolean {
    return this.purchaseDate?.getFullYear() === this._taxYear - 1;
  }

  get wasSoldInIncomeYear(): boolean {
    return this.saleDate?.getFullYear() === this._taxYear - 1;
  }

  get purposesSet(): Set<RealEstatePurpose> {
    // @ts-ignore
    return new Set(this.purposes.map((p) => p.purpose).filter((p) => p !== undefined));
  }

  purposesInPeriod(startDate: Date, endDate: Date): RealEstatePurpose[] {
    if (startDate > endDate) {
      return [];
    }
    return this.purposes
      .filter((p) => {
        const startDateOwned = this.purchaseDate && this.purchaseDate > startDate ? this.purchaseDate : startDate;
        const endDateOwned = this.saleDate && this.saleDate < endDate ? this.saleDate : endDate;
        return (
          startDateOwned <= endDateOwned &&
          p.dateFrom &&
          p.dateFrom <= endDateOwned &&
          p.dateTo &&
          p.dateTo >= startDateOwned
        );
      })
      .filter((i) => i !== undefined)
      .map((i) => i.purpose!);
  }

  clone() {
    return this.cloneHelper(new Property(0));
  }

  toObject(removeNulls: boolean = true): { [key: string]: any } {
    const obj = Validatable.toObjectHelper(this, [], removeNulls);
    if (this.cadastralIncomeUpdate.date === undefined && this.cadastralIncomeUpdate.amount === undefined) {
      delete obj.cadastral_income_update;
    }
    return obj;
  }

  static fromObject(obj: { [key: string]: any }, taxYear: number): Property {
    const result = Validatable.fromObjectHelper(obj, new this(taxYear));
    if (result.cadastralIncomeUpdate) {
      result.cadastralIncomeUpdate = CadastralIncomeUpdate.fromObject(result.cadastralIncomeUpdate);
    }
    if (result.address) {
      result.address = Address.fromObject(result.address);
    }
    result.purposes = result.purposes.map((purpose: { [key: string]: any }) => PurposePeriod.fromObject(purpose));
    return result;
  }

  static cloneObjectWithSwappedCodes(obj: { [key: string]: any }): { [key: string]: any } {
    const result = JSON.parse(JSON.stringify(obj));
    const incomeShareDeclarant = obj["income_share_declarant"];
    const incomeSharePartner = obj["income_share_partner"];
    const ownershipShareDeclarant = obj["ownership_share_declarant"];
    const ownershipSharePartner = obj["ownership_share_partner"];
    result["income_share_declarant"] = incomeSharePartner;
    result["income_share_partner"] = incomeShareDeclarant;
    result["ownership_share_declarant"] = ownershipSharePartner;
    result["ownership_share_partner"] = ownershipShareDeclarant;
    return result;
  }
}
