import StaticInfo from "@/components/staticInfo";

export default class StaticInfoContainer {
  private static _staticInfoModules: any = {};

  private static _staticInfoObjects: any = {};

  public static container(): any {
    return StaticInfoContainer._staticInfoModules;
  }

  public static async getInfoModule(taxYear: number, language: string): Promise<any> {
    if (!(taxYear in this._staticInfoModules)) {
      this._staticInfoModules[taxYear] = {};
    }
    if (!(language in this._staticInfoModules[taxYear])) {
      this._staticInfoModules[taxYear][language] = await import(`../assets/${taxYear}/pitincludes${language}.js`);
    }
    return this._staticInfoModules[taxYear][language];
  }

  public static getAvailableStaticInfo(taxYear: number, language: string): any | null {
    if (taxYear in this._staticInfoObjects && language in this._staticInfoObjects[taxYear]) {
      return this._staticInfoObjects[taxYear][language];
    }
    return null;
  }

  public static async getStaticInfo(taxYear: number, language: string, i18n: any): Promise<any> {
    const staticInfoModule = await StaticInfoContainer.getInfoModule(taxYear, language);
    if (!(taxYear in this._staticInfoObjects)) {
      this._staticInfoObjects[taxYear] = {};
    }
    if (!(language in this._staticInfoObjects[taxYear])) {
      this._staticInfoObjects[taxYear][language] = Object.freeze(
        new StaticInfo(taxYear, language, staticInfoModule, i18n)
      );
    }
    return this._staticInfoObjects[taxYear][language];
  }
}
