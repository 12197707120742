
import {
  mdiAlertCircle,
  mdiInformationOutline,
  mdiCheck,
  mdiClose,
  mdiDelete,
  mdiHammerWrench,
  mdiHome,
  mdiLandPlots,
  mdiOfficeBuilding,
  mdiPlus,
  mdiHelp,
} from "@mdi/js";
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";
import RealEstateAndLoans from "@/components/input/wizards/realEstateAndLoans/realEstateAndLoans";
import PropertyView from "@/components/input/wizards/realEstateAndLoans/realEstate/PropertyView.vue";
import Property from "./realEstate/property";
import Country from "~/components/input/country";
import City from "~/components/input/city";
import PropertyType from "~/components/input/wizards/realEstateAndLoans/realEstate/propertyType";
import Address from "./realEstate/address";
import { v4 as uuidv4 } from "uuid";
import CodeItem from "~/components/input/codeItem";
import Simulation from "~/components/simulation";
import Loan from "./loans/loan";
import Refinancing from "~/components/input/wizards/realEstateAndLoans/loans/refinancing";
import CodeChangesView from "~/components/input/wizards/CodeChangesView.vue";

@Component({
  components: {
    CodeChangesView,
    PropertyView,
  },
})
export default class RealEstateAndLoansWizardView extends Vue {
  @Prop({ required: true, type: Object })
  readonly realEstateAndLoans!: RealEstateAndLoans;

  @Prop({ required: true, type: Object })
  readonly simulation!: Simulation;

  @Prop({ default: true, type: Boolean })
  readonly editable!: boolean;

  realEstateAndLoansEditable: RealEstateAndLoans = this.realEstateAndLoans.clone();

  realEstateAndLoansEditableEnabled: RealEstateAndLoans | null = null;

  mdiAlertCircle = mdiAlertCircle;

  mdiCheck = mdiCheck;

  mdiClose = mdiClose;

  mdiOfficeBuilding = mdiOfficeBuilding;

  mdiHome = mdiHome;

  mdiLandPlots = mdiLandPlots;

  mdiHammerWrench = mdiHammerWrench;

  mdiDelete = mdiDelete;

  mdiPlus = mdiPlus;

  mdiInformationOutline = mdiInformationOutline;

  mdiHelp = mdiHelp;

  propertyView: boolean = false;

  propertyToEdit: Property | null = null;

  showCodeChangesView: boolean = false;

  mounted() {
    this.realEstateAndLoansEditable = this.realEstateAndLoans.clone();
  }

  get countries(): Map<string, Country> {
    return this.simulation.input.staticInfo!.countries;
  }
  get cities(): Map<string, City> {
    return this.simulation.input.staticInfo!.cities;
  }
  get currentCity(): City | undefined {
    return this.simulation.input.cityObject!;
  }
  get codeItems(): Map<string, CodeItem> {
    return this.simulation.input.codeItems!;
  }

  get loansAndRefinancings(): (Loan | Refinancing)[] {
    return this.realEstateAndLoansEditable.input.loansAndRefinancings;
  }

  loansAndRefinancingsForPropertyId(propertyId: string): (Loan | Refinancing)[] {
    return this.loansAndRefinancings.filter((l) => l.properties.includes(propertyId));
  }

  @Watch("realEstateAndLoans", { deep: true })
  onRealEstateAndLoansChange(realEstateAndLoans: RealEstateAndLoans) {
    this.realEstateAndLoansEditable = realEstateAndLoans.clone();
  }

  get missingLoanFieldsForProperty(): Map<string, Map<string, string[]> | null> {
    return new Map(
      this.realEstateAndLoansEditable.input.realEstate.map((property) => [
        property.propertyId!,
        this.realEstateAndLoansEditable.missingLoanFieldsForProperty(property.propertyId!),
      ])
    );
  }

  addProperty() {
    const isDoubleReturn = this.realEstateAndLoansEditable.input.currentReturn.isDoubleReturn;
    const declarantShare = isDoubleReturn ? 0.5 : 1.0;
    const partnerShare = isDoubleReturn ? 0.5 : undefined;

    const currentCity =
      this.realEstateAndLoansEditable.input.realEstate.length === 0 ? this.currentCity?.name : undefined;
    const currentPostcode =
      this.realEstateAndLoansEditable.input.realEstate.length === 0 ? this.currentCity?.postcode : undefined;

    this.propertyToEdit = new Property(
      this.realEstateAndLoansEditable.input._taxYear,
      PropertyType.HOUSE,
      uuidv4(),
      undefined,
      partnerShare,
      declarantShare,
      [],
      new Address("BE", undefined, currentPostcode, currentCity),
      partnerShare,
      partnerShare
    );
    this.propertyView = true;
  }

  disableWizard() {
    this.realEstateAndLoansEditable.disable();
    this.saveSimulation();
  }

  enableWizard() {
    this.realEstateAndLoansEditable.input.enabled = true;
    this.attemptSave();
  }

  onWizardEnabledChange() {
    if (this.realEstateAndLoansEditable.input.enabled) {
      this.disableWizard();
    } else {
      this.enableWizard();
    }
  }

  openPropertyView(property: Property) {
    this.propertyToEdit = property;
    this.propertyView = true;
  }

  closePropertyView() {
    this.propertyView = false;
    this.propertyToEdit = null;
  }

  saveProperty(property: Property | null, savedLoans: Loan[] | undefined, removedLoans: string[] | undefined) {
    if (property === null) {
      if (this.propertyToEdit) {
        this.removeProperty(this.propertyToEdit);
      }
    } else {
      this.realEstateAndLoansEditable.savePropertyAndLoans(property, savedLoans, removedLoans);
      this.attemptSave();
    }
  }

  removeProperty(property: Property) {
    this.realEstateAndLoansEditable.removeProperty(property);
    this.attemptSave();
  }

  close() {
    this.$emit("close");
  }

  attemptSave() {
    if (this.realEstateAndLoansEditable.input.isValid()) {
      this.askToSave();
    } else {
      this.confirmSaveInputAndResult();
    }
  }

  askToSave() {
    // if there is an input change
    if (!this.realEstateAndLoansEditable.isUpToDate) {
      this.showCodeChangesView = true;
      this.realEstateAndLoansEditableEnabled = this.realEstateAndLoansEditable.clone();
      this.realEstateAndLoansEditableEnabled.input.enabled = true;
      this.realEstateAndLoansEditableEnabled.result = null;
      this.realEstateAndLoansEditableEnabled.updateResultDebounced(undefined, () => {
        if (this.realEstateAndLoansEditableEnabled?.apiExceptions) {
          this.showCodeChangesView = false;
          this.realEstateAndLoansEditableEnabled.input.enabled = false;
          this.realEstateAndLoansEditable = this.realEstateAndLoansEditableEnabled;
          this.realEstateAndLoansEditableEnabled = null;
          this.saveSimulation();
        }
      });

      // if above closure never ends
      setTimeout(() => {
        if (this.realEstateAndLoansEditableEnabled && !this.realEstateAndLoansEditableEnabled.result) {
          this.realEstateAndLoansEditable.apiOtherException = "timed out";
          this.showCodeChangesView = false;
          this.realEstateAndLoansEditable.input.enabled = false;
          this.saveSimulation();
        }
      }, 10000);
    }
  }

  confirmSaveInputAndResult() {
    if (this.realEstateAndLoansEditableEnabled) {
      this.realEstateAndLoansEditable = this.realEstateAndLoansEditableEnabled;
      this.realEstateAndLoansEditableEnabled = null;
    }
    this.showCodeChangesView = false;
    this.saveSimulation();
  }

  onlySaveInput() {
    this.realEstateAndLoansEditable.input.enabled = false;
    this.showCodeChangesView = false;
    this.saveSimulation();
  }

  private saveSimulation() {
    const newSimulationInput = this.simulation.input.clone();
    this.realEstateAndLoansEditable.applyOnSimulationInput(newSimulationInput);
    if (
      this.realEstateAndLoansEditable.input.enabled &&
      this.realEstateAndLoansEditable.input.realEstate.length === 0
    ) {
      this.realEstateAndLoansEditable.input.enabled = false;
      newSimulationInput.disableWizard("real_estate_and_loans");
    }
    this.$emit("save-simulation-input", newSimulationInput, this.realEstateAndLoansEditable);
  }
}
