import Validatable from "@/components/input/wizards/validatable";
import Person from "~/components/input/wizards/household/person";
import Simulation from "~/components/simulation";
import SimulationInput from "~/components/input/simulationInput";

export default class Household extends Validatable {
  _taxYear: number;
  people: Person[];
  enabled: boolean;

  constructor(taxYear: number, people: Person[], enabled: boolean) {
    super(["_taxYear", "people"], {}, ["people"]);

    this._taxYear = taxYear;
    this.people = people;
    this.enabled = enabled;
  }

  get hasValue(): boolean {
    return this.people.length > 0;
  }

  codes(doubleReturn: boolean, taxablePeriodInMonths: number = 12): { [code: string]: number | null } {
    const result: { [code: string]: number | null } = {};

    for (const person of this.people) {
      const personCodes = person.codes(doubleReturn, taxablePeriodInMonths);
      for (const code in personCodes) {
        const value = personCodes[code];
        if (value !== null) {
          result[code] = (result[code] ?? 0) + value;
        } else {
          result[code] = result[code] ?? null;
        }
      }
    }

    return result;
  }

  summary(doubleReturn: boolean, taxablePeriodInMonths: number = 12): { [personId: string]: any } {
    const result: { [personId: string]: any } = {};

    for (const person of this.people) {
      if (person.personId) {
        result[person.personId] = {
          codes: person.codes(doubleReturn, taxablePeriodInMonths),
          netMeansOfSubsistence: person.netMeansOfSubsistence(taxablePeriodInMonths),
          maxNetMeansOfSubsistence: person.maxNetMeansOfSubsistence(doubleReturn, taxablePeriodInMonths),
        };
      }
    }

    return result;
  }

  savePerson(person: Person) {
    const indexOfPerson = this.people.map((item) => item.personId).indexOf(person.personId);
    if (indexOfPerson > -1) {
      this.people[indexOfPerson] = person;
    } else {
      this.people.push(person);
    }
  }

  removePerson(person: Person) {
    const indexOfPerson = this.people.map((item) => item.personId).indexOf(person.personId);
    if (indexOfPerson > -1) {
      this.people.splice(indexOfPerson, 1);
    }
    if (this.people.length === 0) {
      this.enabled = false;
    }
  }

  applyOnSimulationInput(simulationInput: SimulationInput): void {
    const inputObject = this.toObject();
    simulationInput.household = {
      people: inputObject.people,
    };
    if (this.enabled) {
      simulationInput.enableWizard("household");
      if (simulationInput.codeItems) {
        // removes existing codes
        const newCodeItems = Array.from(simulationInput.codeItems.values());
        let updated = false;
        newCodeItems.forEach((item) => {
          if (item.tags.includes("from_wizard:household")) {
            simulationInput.removeCode(item.info.code, false);
            simulationInput.removeTagForCode(item.info.code, "from_wizard:household");
            updated = true;
          }
        });
        if (updated) {
          simulationInput.update();
        }
      }

      const resultCodes = this.codes(simulationInput.isDoubleReturn, simulationInput.codes["1199"] ?? 12);

      if (resultCodes && simulationInput.codeItems) {
        Object.entries(resultCodes).forEach(([codeId, value]) => {
          if (value != null) {
            simulationInput.setCode(codeId, value, true);
            simulationInput.addTagForCode(codeId, "from_wizard:household");
          } else {
            simulationInput.removeCode(codeId, true);
            simulationInput.addTagForCode(codeId, "from_wizard:household");
          }
        });
      }
    } else {
      simulationInput.disableWizard("household");
      simulationInput.update();
    }
  }

  clone() {
    return this.cloneHelper(new Household(this._taxYear, [], false));
  }

  static fromObject(obj: { [key: string]: any }, taxYear: number): Household {
    const result = Validatable.fromObjectHelper(obj, new this(taxYear, [], false));
    result.people = result.people.map((person: { [key: string]: any }) => Person.fromObject(person, taxYear));
    return result;
  }

  static fromSimulation(simulation: Simulation): Household {
    const household = Household.fromObject(
      simulation.input.household ? simulation.input.household : {},
      simulation.input.taxYear
    );
    household.enabled = simulation.input.enabledWizards.includes("household");
    return household;
  }

  static fromSimulationInput(simulationInput: SimulationInput): Household {
    const household = Household.fromObject(
      simulationInput.household ? simulationInput.household : {},
      simulationInput.taxYear
    );
    household.enabled = simulationInput.enabledWizards.includes("household");
    return household;
  }
}
