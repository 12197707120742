export default class City {
  constructor(postcode: string, region: string, name: string, rate: number) {
    this.postcode = postcode;
    this.region = region;
    this.name = name;
    this.rate = rate;
  }

  postcode!: string;

  region!: string;

  name!: string;

  rate!: number;

  get description(): string {
    return `${this.postcode} - ${this.name}`;
  }
}
