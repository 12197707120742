import CollectionType from "@/components/input/complexTypes/collectionType";
import ItemType from "@/components/input/complexTypes/contractType";

const ITEM_NAME = "contrat";
const INITIAL_ITEMS = 2;
const MAX_ITEMS = 2;

export default class Contracts2Type extends CollectionType<ItemType> {
  constructor(items: ItemType[] | undefined) {
    super(items, ItemType, ITEM_NAME, INITIAL_ITEMS, MAX_ITEMS);
  }

  static fromObject(obj: any): Contracts2Type {
    return CollectionType.fromObjectHelper(obj, ItemType, ITEM_NAME, INITIAL_ITEMS, MAX_ITEMS);
  }
}
