export default class CodeType {
  constructor(code: string | undefined, value: number | undefined) {
    this.code = code;
    this.value = value;
  }

  // 4 long, all digits
  code: string | undefined;

  value: number | undefined;

  set valueNumber(value: string | null) {
    this.value = value === null ? undefined : parseFloat(value);
  }

  get valueNumber(): string | null {
    return this.value ? this.value.toString(10) : null;
  }

  toString(i18n: any = undefined): string {
    return this.code || this.value ? `${this.code}: ${this.value?.toLocaleString(...this.formatArgs(i18n))}` : "";
  }

  toObject(createEmpty: boolean = false): Object | undefined {
    if (this.code || this.value || createEmpty) {
      return {
        code: this.code ? this.code?.substring(0, 4) : null,
        valeur: this.value,
      };
    }
    return undefined;
  }

  static fromObject(obj: any): CodeType {
    return new CodeType(obj?.code, obj?.valeur);
  }

  formatArgs(i18n: any): any {
    return [i18n.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }];
  }
}
