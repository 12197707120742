
import { Component, Model, Prop, Vue, Watch } from "nuxt-property-decorator";
import { mdiInformationOutline } from "@mdi/js";

@Component({})
export default class VDateField extends Vue {
  @Model("changeValue", { type: Date }) readonly valueDate: Date | undefined | null;

  @Prop({ type: String }) readonly label: string | undefined;
  @Prop({ type: String }) readonly tooltip: string | undefined;
  @Prop({ type: String }) readonly activePicker: string | undefined; // DAY, MONTH, YEAR
  @Prop({ type: String }) readonly prependInnerIcon: string | undefined;
  @Prop({ type: String }) readonly initialPickerDate: string | undefined;
  @Prop({ type: String }) readonly placeholder: string | undefined;
  @Prop({ type: String }) readonly min: string | undefined;
  @Prop({ type: String }) readonly max: string | undefined;
  @Prop({ default: false, type: Boolean }) readonly disabled!: boolean;
  @Prop({ default: false, type: Boolean }) readonly persistentPlaceholder!: boolean;
  @Prop({ default: false, type: Boolean }) readonly autofocus!: boolean;
  @Prop({ default: false, type: Boolean }) readonly readonly!: boolean;
  @Prop({ default: false, type: Boolean }) readonly dense!: boolean;
  @Prop({ default: false, type: Boolean }) readonly filled!: boolean;
  @Prop({ default: false, type: Boolean }) readonly hideDetails!: boolean;
  @Prop({ default: false, type: Boolean }) readonly clearable!: boolean;
  @Prop({ default: false, type: Boolean }) readonly error!: boolean;
  @Prop({ type: Array<String> }) readonly errorMessages!: string[] | undefined;
  @Prop({}) readonly rules!: any | undefined;

  valueStringInField: string | null = this.isoToPit(this.valueString);

  valueStringInPicker: string | null =
    // @ts-ignore
    this.valueString || !this.initialPickerDate ? this.valueString : this.initialPickerDate;

  pickerEnabled: boolean = false;

  mdiInformationOutline = mdiInformationOutline;

  get valueString(): string | null {
    return this.valueDate == null ? null : this.valueDate.toISOString().substring(0, 10);
  }

  @Watch("valueString")
  onValueDateChanged() {
    this.valueStringInField = this.isoToPit(this.valueString);
    this.valueStringInPicker = this.valueString;
  }

  updateValueString(valueString: string | null, closePicker: boolean = false) {
    if (closePicker) {
      this.pickerEnabled = false;
    }
    const fixedValueString = this.dateStringToIso(valueString);
    this.valueStringInField = this.isoToPit(fixedValueString);
    this.valueStringInPicker = fixedValueString;
    if (!this.readonly) {
      this.$emit("changeValue", fixedValueString ? new Date(fixedValueString) : undefined);
    }
  }

  @Watch("pickerEnabled")
  onMenuFounded(enabled: boolean) {
    if (enabled && this.activePicker) {
      this.$nextTick(() => {
        (this.$refs.datePicker as any).activePicker = this.activePicker;
      });
      // sometimes previous line doesn't work, so we try again after 100ms
      setTimeout(() => {
        (this.$refs.datePicker as any).activePicker = this.activePicker;
      }, 100);
    } else {
      this.$nextTick(() => {
        this.updateValueString(this.valueStringInField, false);
        (this.$refs.datePicker as any).activePicker = undefined;
      });
    }
  }

  isoToPit(isoDate: string | null): string | null {
    return isoDate ? isoDate.replace(/^(\d{4})-(\d{2})-(\d{2})$/, "$3/$2/$1") : null;
  }

  dateStringToIso(inputDate: string | null): string | null {
    if (inputDate === null) {
      return null;
    }

    // output date: 2020-01-01
    // valid input dates: 01/01/2020, 2020/01/01, 2020-01-01, 01-01-2020, 112020
    const validDateDayFirstRegex = /^(0?[1-9]|[1-2][0-9]|3[0-1])([\s\/\.-]?)(0?[1-9]|1[0-2])\2((?:19|20|21)\d{2})$/;
    const validDateYearFirstRegex = /^((?:19|20|21)\d{2})([\s\/\.-]?)(0?[1-9]|1[0-2])\2(0?[1-9]|[1-2][0-9]|3[0-1])$/;

    const dayFirstMatch = inputDate.match(validDateDayFirstRegex);
    if (dayFirstMatch) {
      const [_, day, separator, month, year] = dayFirstMatch;
      const isoDate = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      return new Date(isoDate).toISOString().substring(0, 10) === isoDate ? isoDate : null;
    }

    const yearFirstMatch = inputDate.match(validDateYearFirstRegex);
    if (yearFirstMatch) {
      const [_, year, separator, month, day] = yearFirstMatch;
      const isoDate = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      return new Date(isoDate).toISOString().substring(0, 10) === isoDate ? isoDate : null;
    }

    return null;
  }

  dateIsComplete(inputDate: string | null): boolean {
    // todo: make it als work on dates that don't contain 8 characters, but are still complete
    return !!(inputDate && /(\d.*){8}/.test(inputDate) && this.dateStringToIso(inputDate));
  }

  focus() {
    this.pickerEnabled = true;
  }
}
