import { v4 as uuidv4 } from "uuid";

const _ignoredFields = new Set([
  "_mandatoryFields",
  "_constraints",
  "_nestedValidatables",
  "_taxYear",
  "_internal_uuid",
]);

export default abstract class Validatable {
  protected _internal_uuid: string;
  protected _mandatoryFields: string[];
  protected _constraints: Map<string, (() => boolean | string)[]>;
  protected _nestedValidatables: string[];

  protected constructor(
    mandatoryFields: string[] = [],
    constraints: { [key: string]: ((vars?: any) => boolean | string) | ((vars?: any) => boolean | string)[] } = {},
    nestedValidatables: string[] = []
  ) {
    this._internal_uuid = uuidv4();
    this._mandatoryFields = mandatoryFields;
    this._constraints = new Map(
      Object.entries(constraints).map(([key, value]) => [key, Array.isArray(value) ? value : [value]])
    );
    this._nestedValidatables = nestedValidatables;
  }

  public rulesForField(name: string): (() => boolean | string)[] {
    const constraints = this._constraints.has(name) ? [...this._constraints.get(name)!] : [];
    if (this._mandatoryFields.includes(name)) {
      // @ts-ignore;
      constraints.push(() => (this[name] == null || this[name] == undefined ? "required" : true));
    }
    return constraints;
  }

  public addMandatoryField(name: string): void {
    if (name.trim() === '') {
      throw new Error('name must be a non-empty string');
    }
    if (!this._mandatoryFields.includes(name)) {
      this._mandatoryFields.push(name);
    }
  }

  public removeMandatoryField(name: string): void {
    if (name.trim() === '') {
      throw new Error('name must be a non-empty string');
    }
    this._mandatoryFields = this._mandatoryFields.filter((field) => field !== name);
  }

  public rulesForFieldWithoutMessage(name: string): (() => boolean)[] {
    return this.rulesForField(name).map((func) => () => func() === true);
  }

  localErrorsForField(fieldName: string): string[] {
    return Validatable.errorsForField(fieldName, this.validate());
  }

  public static errorsForField(fieldName: string, errors: Map<string, string[]> | boolean): string[] {
    if (
      errors instanceof Map &&
      errors.has(fieldName) &&
      errors.get(fieldName) !== undefined &&
      errors.get(fieldName)!.length > 0
    ) {
      return errors.get(fieldName)!;
    }
    return [];
  }

  get uuid(): string {
    return this._internal_uuid;
  }

  get hash(): string {
    return Validatable.hashObject(this.toObject());
  }

  isValid(variables?: any, ignoredConstraints?: string[]): boolean {
    return this.validate(variables, ignoredConstraints) === true;
  }

  validate(variables?: any, ignoredConstraints?: string[]): Map<string, string[]> | boolean {
    const result = new Map();

    this.undefinedMandatoryFields().forEach((field) => result.set(field, ["required"]));

    Array.from(this._constraints.entries())
      .filter(([field, constraints]) => !ignoredConstraints?.includes(field))
      .forEach(([field, constraints]) =>
        constraints.forEach((constraint: (vars?: any) => boolean | string) => {
          const res = constraint(variables);
          if (typeof res === "string") {
            result.set(field, result.has(field) ? [...result.get(field), res] : [res]);
          }
        })
      );

    for (let nestedField of this._nestedValidatables) {
      // @ts-ignore
      const nestedItem = this[nestedField];
      if (nestedItem !== undefined && nestedItem !== null && !ignoredConstraints?.includes(nestedField)) {
        let nestedArray = Array.isArray(nestedItem) ? nestedItem : [nestedItem];
        for (let item of nestedArray) {
          if (item && typeof (item as Validatable).validate === "function" && item.isEmpty !== true) {
            const nestedResult: Map<string, string[]> | boolean = item.validate(variables);
            if (nestedResult instanceof Map) {
              Array.from(nestedResult.entries()).forEach(([field, errors]) =>
                result.set(`${nestedField}.${field}`, errors)
              );
            }
          }
        }
      }
    }

    return result.size > 0 ? result : true;
  }

  undefinedMandatoryFields(): string[] {
    return this._mandatoryFields.filter(
      // @ts-ignore
      (field: string) => this[field] === undefined || this[field] === null || this[field] === ""
    );
  }

  protected abstract clone(): Validatable;

  protected cloneHelper(newClass: any, ignoredFields?: string[]): any {
    const obj = Object.fromEntries(
      Object.entries(this).map(([key, value]) => [
        key,
        ignoredFields && ignoredFields.includes(key) ? value : Validatable.cloneField(value),
      ])
    );
    delete obj["_constraints"];
    Object.assign(newClass, obj);
    return newClass;
  }

  static fromObjectHelper(obj: { [key: string]: any } | null | undefined, result: any, ignoredKeys?: string[]): any {
    const isDateRegex = new RegExp(/^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/);
    if (obj != null) {
      Object.entries(obj).forEach(([key, value]) => {
        const keyCamelCase = Validatable.snakeToCamelCase(key);
        if (!(ignoredKeys && ignoredKeys.includes(keyCamelCase)) && keyCamelCase in result) {
          if (
            (key.includes("date") || key.endsWith("from") || key.endsWith("to")) &&
            typeof value === "string" &&
            isDateRegex.test(value)
          ) {
            result[keyCamelCase] = new Date(value);
          } else {
            result[keyCamelCase] = value; // eslint-disable-line no-param-reassign
          }
        }
      });
    }
    return result;
  }

  protected static toObjectHelper(
    obj: Validatable,
    ignoredKeys?: string[],
    removeNulls?: boolean
  ): { [key: string]: any } {
    const result = {};
    Object.entries(obj).forEach(([key, value]) => {
      const keySnakeCase = Validatable.camelToSnakeCase(key);
      if (
        !_ignoredFields.has(key) &&
        !(ignoredKeys && ignoredKeys.includes(keySnakeCase)) &&
        (!removeNulls || ![null, undefined].includes(value))
      ) {
        // @ts-ignore
        result[keySnakeCase] = Validatable.fieldToObject(value, removeNulls);
      }
    });
    return result;
  }

  protected static cloneField(value: any): any {
    if (value instanceof Date) {
      return new Date(value);
    } else if (value instanceof Validatable) {
      return value.clone(); // eslint-disable-line no-param-reassign
    } else if (Array.isArray(value)) {
      return value.map((val: any) => Validatable.cloneField(val));
    } else {
      return value;
    }
  }

  protected static fieldToObject(value: any, removeNulls?: boolean): any {
    if (value instanceof Date) {
      return value.toISOString().substring(0, 10);
    } else if (value instanceof Validatable) {
      return value.toObject(removeNulls); // eslint-disable-line no-param-reassign
    } else if (Array.isArray(value)) {
      return value.map((val: any) => Validatable.fieldToObject(val, removeNulls));
    } else {
      return value;
    }
  }

  public static camelToSnakeCase(str: string): string {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`).replace(/[0-9]+/g, (letter) => `_${letter}`);
  }

  public static snakeToCamelCase(str: string): string {
    return str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());
  }

  protected static removeNulls(obj: { [key: string]: any }): { [key: string]: any } {
    return Object.fromEntries(Object.entries(obj).filter(([, v]) => v !== null && v !== undefined));
  }

  protected static hashObject(obj: any): string {
    // https://stackoverflow.com/a/15710692
    const hashCode = (s: string) =>
      s.split("").reduce((a, b) => {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
      }, 0); // eslint-disable-line no-bitwise, no-param-reassign
    const jsonStr = JSON.stringify(obj);
    const first = hashCode(jsonStr).toString();
    return hashCode(jsonStr.concat(first)).toString();
  }

  toObject(removeNulls: boolean = true): { [key: string]: any } {
    return Validatable.toObjectHelper(this, [], removeNulls);
  }
}
