
import { Component, Model, Prop, Vue, Watch } from "nuxt-property-decorator";
import { mdiCalendar, mdiDelete, mdiArrowRightBottom } from "@mdi/js";
import VNumberField from "@/components/input/wizards/VNumberField";
import Validatable from "~/components/input/wizards/validatable";
import LongTermSavingsWithoutHouse from "~/components/input/wizards/savings/longTermSavingsWithoutHouse";

@Component({
  components: {
    VNumberField,
  },
})
export default class LongTermSavingsWithoutHouseView extends Vue {
  @Model("update:value", {}) readonly value: LongTermSavingsWithoutHouse | undefined;

  @Prop({ type: String }) readonly label: string | undefined;
  @Prop({ default: false, type: Boolean }) readonly readonly!: boolean;
  @Prop({ default: false, type: Boolean }) readonly error!: boolean;
  @Prop({ type: Array<String> }) readonly errorMessages!: string[] | undefined;
  @Prop({}) readonly rules!: any | undefined;

  // @ts-ignore
  // just take a copy of the value (don't clone)
  localValue: LongTermSavingsWithoutHouse | null = this.value !== undefined ? this.value : null;

  mdiCalendar = mdiCalendar;

  mdiDelete = mdiDelete;

  mdiArrowRightBottom = mdiArrowRightBottom;

  get validOrErrors(): Map<string, string[]> | boolean {
    return this.localValue ? this.localValue.validate() : true;
  }

  errorsForField(fieldName: string): string[] {
    return Validatable.errorsForField(fieldName, this.validOrErrors);
  }

  @Watch("value", { deep: true })
  onValueChange(value: LongTermSavingsWithoutHouse | undefined) {
    this.localValue = value !== undefined ? value : null;
  }

  @Watch("localValue", { deep: true })
  onLocalValueChange(value: LongTermSavingsWithoutHouse | null) {
    this.$emit("update:value", value !== null ? value : undefined);
  }

  get enabled(): boolean {
    return !!this.localValue;
  }

  set enabled(enable: boolean) {
    if (enable) {
      this.localValue = new LongTermSavingsWithoutHouse();
    } else {
      this.localValue = null;
    }
  }
}
