
import Vue from "vue";
import { Component, Prop, Watch } from "nuxt-property-decorator";
import CodeItem from "@/components/input/codeItem";
import ElectricChargingStationType from "@/components/input/complexTypes/electricChargingStationType";
import { chargingStationTypes } from "@/components/input/complexTypes/electricChargingStationType";
import deburr from "lodash.deburr";
import City from "~/components/input/city";
import VNumberField from "~/components/input/wizards/VNumberField";

@Component({
  components: { VNumberField },
})
export default class ElectricChargingStationTypeView extends Vue {
  @Prop({ required: true, type: Object })
  readonly codeItem!: CodeItem;

  @Prop({ default: true, type: Boolean })
  readonly editable!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly disabled!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly isDoubleReturn!: boolean;

  @Prop({ required: true, type: Map })
  readonly cities!: Map<string, City>;

  valueInstance: ElectricChargingStationType = Vue.observable(
    ElectricChargingStationType.fromObject(this.codeItem.value)
  );

  get allInError(): boolean {
    return this.codeItem.regularErrors.length > 0;
  }

  get chargingStationTypes() {
    return chargingStationTypes.map((type) => ({
      text: this.$t(`input.complexTypes.electricChargingStationType.chargingStationTypes.${type}`).toString(),
      value: type,
    }));
  }

  get numberOfChargingStations(): number {
    return this.valueInstance.chargePoint.length;
  }

  set numberOfChargingStations(value: number) {
    if (value <= 0) {
      this.valueInstance.type = undefined;
      this.valueInstance.chargePoint = [];
      this.valueInstance.postalCode = undefined;
      this.valueInstance.totalExpenseTit = undefined;
      this.valueInstance.totalExpensePart = undefined;
    } else {
      if (value > this.valueInstance.chargePoint.length) {
        for (let i = this.valueInstance.chargePoint.length; i < value; i++) {
          this.valueInstance.chargePoint.push(null);
        }
      } else if (value < this.valueInstance.chargePoint.length) {
        this.valueInstance.chargePoint = this.valueInstance.chargePoint.slice(0, value);
      }
    }
  }

  updateChargePoint(index: number, newValue: number | null) {
    this.$set(this.valueInstance.chargePoint, index, Number(newValue));
  }

  @Watch("codeItem.value", { deep: true })
  onValueChange(newValue: Object) {
    this.valueInstance = Vue.observable(ElectricChargingStationType.fromObject(newValue));
  }

  @Watch("valueInstance", { deep: true })
  onValueInstanceChange(newValueInstance: ElectricChargingStationType) {
    const newObject = newValueInstance.toObject();
    const result = newObject || null;
    if (JSON.stringify(result) !== JSON.stringify(this.codeItem.value)) {
      this.$emit("update", result);
    }
  }

  get cityList(): City[] {
    return Array.from(this.cities.values());
  }

  filterCity: Object = (item: City, queryText: string) =>
    deburr(item.description.toLocaleLowerCase()).includes(deburr(queryText.toLocaleLowerCase()));

  get formatCurrencyArgs2(): any {
    return [
      this.$i18n.locale,
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: "currency",
        currency: "EUR",
      },
    ];
  }
}
