import ValidationRule from "@/components/input/validation/validationRule";

type CodeItem = import("@/components/input/codeItem").default;

export default class MaxLengthStringStrategy extends ValidationRule {
  constructor(i18n: any, maxLength: number) {
    super(i18n);
    this.maxLength = maxLength;
  }

  maxLength: number;

  validate(primary: CodeItem, state: Map<string, CodeItem>): string | boolean {
    return primary === null ||
      primary.parsedValue === null ||
      (typeof primary.parsedValue === "string" && primary.parsedValue.length <= this.maxLength)
      ? true
      : (this.i18n.t("input.validation.maxLength", [this.maxLength]) as string);
  }
}
