import ResultListItemCombined from "@/components/result/resultListItemCombined";

export default class ResultListItemReductionCombined extends ResultListItemCombined {
  constructor(
    name: string,
    value: [number | null, number | null],
    basis: [number | null, number | null],
    rate: number
  ) {
    super(name, value);
    this.basis = basis;
    this.rate = rate;
  }

  readonly basis: [number | null, number | null];

  readonly rate: number;
}
