import { render, staticRenderFns } from "./foreignIncomeTypeView.vue?vue&type=template&id=1aaf9710&scoped=true&"
import script from "./foreignIncomeTypeView.vue?vue&type=script&lang=ts&"
export * from "./foreignIncomeTypeView.vue?vue&type=script&lang=ts&"
import style0 from "./foreignIncomeTypeView.vue?vue&type=style&index=0&id=1aaf9710&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1aaf9710",
  null
  
)

export default component.exports