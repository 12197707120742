
import { Component, Prop, Vue } from "nuxt-property-decorator";
import SimulationInput, { ForeignCodeObject } from "~/components/input/simulationInput";
import { mdiArrowRightBottom } from "@mdi/js";
@Component
export default class WizardConfirmationDialog extends Vue {
  @Prop({ required: true, type: Object })
  readonly simulationInput!: SimulationInput;

  @Prop({ default: () => [], type: Array })
  readonly foreignCodesFromWizard!: ForeignCodeObject[];

  @Prop({ required: true, type: Object })
  readonly codesToShow!: { [index: string]: any };

  @Prop({ default: true, type: Boolean })
  readonly includeNoneValues!: boolean;

  mdiArrowRightBottom = mdiArrowRightBottom;

  get hasCodesToShow(): boolean {
    return Object.keys(this.codesToShow).length > 0;
  }

  get codeItemsToShow(): any[] {
    if (!this.simulationInput.codeInfoForCurrentRegion || !this.simulationInput.codeItems) {
      return [];
    }
    return Array.from(this.simulationInput.codeInfoForCurrentRegion.entries())
      .filter(([code, codeInfo]) => {
        if (this.codesToShow[code] === undefined) {
          return false;
        }
        if (this.codesToShow[code] === null) {
          return this.includeNoneValues;
        }
        return true;
      })
      .map(([code, codeInfo]) => {
        return [codeInfo, this.codesToShow[code]];
      });
  }

  codeDisplayed(code: string): string {
    if (code == null) {
      return "";
    }
    return code.match("^\\d{4}$") ? code : "extra";
  }
}
