import Payment from "@/components/input/wizards/realEstateAndLoans/loans/payment";

export default class Assert {
  static isNumber(value: any) {
    if (value === undefined || (typeof value === "number" && !isNaN(value))) {
      return true;
    }
    return "should be number";
  }

  static isDate(value: any) {
    // todo
    return true;
  }

  static isEnum(value: string | undefined, enumType: any) {
    if (value !== undefined && !Object.values(enumType).includes(value)) {
      return `not valid ${enumType}`;
    }
    return true;
  }

  static inYear(value: Date | undefined, year: number): boolean | string {
    if (value !== undefined && value.getFullYear() !== year) {
      return `should be in year ${year}`;
    }
    return true;
  }

  static minimum(value: number | undefined, minimum: number): boolean | string {
    if (typeof Assert.isNumber(value) === "string") {
      return Assert.isNumber(value);
    }
    if (value !== undefined && value < minimum) {
      return `should be >= ${minimum}`;
    }
    return true;
  }

  static maximum(value: number | undefined, maximum: number): boolean | string {
    if (typeof Assert.isNumber(value) === "string") {
      return Assert.isNumber(value);
    }
    if (value !== undefined && value > maximum) {
      return `should be >= ${maximum}`;
    }
    return true;
  }

  static maximumTogether(
    value1: number | undefined,
    maximum: number,
    value2: number | undefined,
    value2Name: string | undefined = undefined,
    factor: number = 1
  ): boolean | string {
    if (value1 === undefined && value2 === undefined) {
      return true;
    }
    if (typeof Assert.isNumber(value1) === "string") {
      return Assert.isNumber(value1);
    }
    if (typeof Assert.isNumber(value2) === "string") {
      return Assert.isNumber(value2);
    }
    const sum = (value1 !== undefined ? value1 : 0.0) + (value2 !== undefined ? value2 : 0.0);
    if (sum > maximum) {
      return `sum ${value2Name ? `with ${value2Name}` : ""} should be <= ${maximum * factor}`;
    }
    return true;
  }

  static exactSum(
    value1: number | undefined,
    sum: number,
    value2: number | undefined,
    value2Name: string | undefined = undefined,
    factor: number = 1
  ): boolean | string {
    if (value1 === undefined && value2 === undefined) {
      return true;
    }
    if (typeof Assert.isNumber(value1) === "string") {
      return Assert.isNumber(value1);
    }
    if (typeof Assert.isNumber(value2) === "string") {
      return Assert.isNumber(value2);
    }
    const localSum = (value1 !== undefined ? value1 : 0.0) + (value2 !== undefined ? value2 : 0.0);
    if (Math.abs(sum - localSum) > 0.005) {
      return `sum ${value2Name ? `with ${value2Name}` : ""} should be == ${sum * factor}`;
    }
    return true;
  }

  static notEmpty(value: any[] | undefined): boolean | string {
    if (value && value.length === 0) {
      return "should not be empty";
    }
    return true;
  }

  static posiviteRate(value: number | undefined, isPercentage: boolean = true): boolean | string {
    if (typeof Assert.isNumber(value) === "string") {
      return Assert.isNumber(value);
    }
    if (value !== undefined && value < 0) {
      return "should be >= 0";
    } else if (value !== undefined && value > 1) {
      return `should be <= 1${isPercentage ? "00" : ""}`;
    }
    return true;
  }

  static lessOrEqualThan(value1: number | undefined, value2: number | undefined): boolean | string {
    if (typeof Assert.isNumber(value1) === "string") {
      return Assert.isNumber(value1);
    }
    if (typeof Assert.isNumber(value2) === "string") {
      return Assert.isNumber(value2);
    }
    if (value1 !== undefined && value2 !== undefined && value1 > value2) {
      return `should be <= ${value2}`;
    }
    return true;
  }

  static greaterOrEqualThan(value1: number | undefined, value2: number | undefined): boolean | string {
    if (typeof Assert.isNumber(value1) === "string") {
      return Assert.isNumber(value1);
    }
    if (typeof Assert.isNumber(value2) === "string") {
      return Assert.isNumber(value2);
    }
    if (value1 !== undefined && value2 !== undefined && value1 < value2) {
      return `should be >= ${value2}`;
    }
    return true;
  }

  static lessOrEqualThanDate(value1: Date | undefined, value2: Date | undefined): boolean | string {
    if (typeof Assert.isDate(value1) === "string") {
      return Assert.isDate(value1);
    }
    if (typeof Assert.isDate(value2) === "string") {
      return Assert.isDate(value2);
    }
    if (value1 !== undefined && value2 !== undefined && value1 > value2) {
      return `should be <= ${value2.toISOString().substring(0, 10)}`;
    }
    return true;
  }

  static greaterOrEqualThanDate(value1: Date | undefined, value2: Date | undefined): boolean | string {
    if (typeof Assert.isDate(value1) === "string") {
      return Assert.isDate(value1);
    }
    if (typeof Assert.isDate(value2) === "string") {
      return Assert.isDate(value2);
    }
    if (value1 !== undefined && value2 !== undefined && value1 < value2) {
      return `should be >= ${value2.toISOString().substring(0, 10)}`;
    }
    return true;
  }

  static requires(value: any): boolean | string {
    return value == null ? "required" : true;
  }

  static hasPayments(value: number | Payment[] | undefined, year: number | undefined): boolean | string {
    if (value === undefined) {
      return true;
    }
    if (Array.isArray(value)) {
      if (value.length === 0) {
        return "required";
      } else if (year !== undefined && value.filter((i) => i.date && i.date.getFullYear() === year).length === 0) {
        return `requires payments in ${year}`;
      }
      return true;
    }
    // @ts-ignore
    return Assert.greaterOrEqualThan(value, 0.0);
  }

  static paymentsIsProperType(value: number | Payment[] | undefined, variables: any): boolean | string {
    if (value === undefined) {
      return true;
    }

    if (
      value !== 0 &&
      variables &&
      variables.loan &&
      variables.properties &&
      variables.properties.some(
        (prop: any) =>
          variables.loan.properties.includes(prop.propertyId) &&
          (prop.hasPeriodsWithDifferentPurposesAndDifferentDates || prop.wasBoughtInIncomeYear || prop.wasSoldInIncomeYear) &&
          !Array.isArray(value)
      )
    ) {
      return "needs to be list";
    }
    return true;
  }
}
