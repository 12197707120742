
import Vue from "vue";
import { Component, Prop, Watch } from "nuxt-property-decorator";
import CodeItem from "@/components/input/codeItem";
import JuridiqueType from "@/components/input/complexTypes/juridiqueType";
import JuridiqueTypeOld from "@/components/input/complexTypes/juridiqueTypeOld";

@Component
export default class JuridiqueTypeView extends Vue {
  @Prop({ required: true, type: Object })
  codeItem!: CodeItem;

  @Prop({ default: false, type: Boolean })
  readonly disabled!: boolean;

  @Prop({ default: true, type: Boolean })
  editable!: boolean;

  valueInstance: JuridiqueType | JuridiqueTypeOld = this.useJuridiqueTypeOld
    ? Vue.observable(JuridiqueTypeOld.fromObject(this.codeItem.value))
    : Vue.observable(JuridiqueType.fromObject(this.codeItem.value));

  get useJuridiqueTypeOld(): boolean {
    return this.codeItem.info.taxYear < 2023;
  }

  @Watch("codeItem.value", { deep: true })
  onValueChange(newValue: Object) {
    this.valueInstance = this.useJuridiqueTypeOld
      ? Vue.observable(JuridiqueTypeOld.fromObject(newValue))
      : Vue.observable(JuridiqueType.fromObject(newValue));
  }

  @Watch("valueInstance", { deep: true })
  onValueInstanceChange(valueInstance: JuridiqueType | JuridiqueTypeOld) {
    const newObject = valueInstance.toObject();
    const result = newObject || null;
    if (JSON.stringify(result) !== JSON.stringify(this.codeItem.value)) {
      this.$emit("update", result);
    }
  }

  focus(options: object) {
    ((this.$refs!.textField! as any[])[0].$refs.input as HTMLElement).focus(options);
  }
}
