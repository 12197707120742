import CodeItem from "@/components/input/codeItem";
import { Vue } from "nuxt-property-decorator";
import ForeignIncome from "@/components/input/foreignIncome";
import CapitalNature from "@/components/input/capitalNature";
import CodeInfo from "@/components/input/codeInfo";
import City from "@/components/input/city";
import deburr from "lodash.deburr";
import StaticInfoContainer from "@/components/staticInfoContainer";
import StaticInfo from "@/components/staticInfo";
import SectionInfo from "@/components/input/sectionInfo";
import Region from "@/components/input/region";
import isEqual from "lodash.isequal";
import Property from "~/components/input/wizards/realEstateAndLoans/realEstate/property";
import Loan from "~/components/input/wizards/realEstateAndLoans/loans/loan";
import Savings from "~/components/input/wizards/savings/savings";
import BenefRenteAlimType from "~/components/input/complexTypes/benefRenteAlimType";
import DebAlimList from "~/components/input/complexTypes/debAlimList";
import JuridiqueType from "~/components/input/complexTypes/juridiqueType";
import BenefRenteAlimList from "~/components/input/complexTypes/benefRenteAlimList";
import SharedLoanProperties from "~/components/input/wizards/realEstateAndLoans/loans/sharedLoanProperties";
import RealEstateAndLoans from "~/components/input/wizards/realEstateAndLoans/realEstateAndLoans";

const VALID_YEARS = [2025, 2024, 2023, 2022, 2021, 2020, 2019, 2018];

const INCOMPLETE_YEARS = [2025];

const TOW_SUPPORTED_YEARS = [2024, 2023, 2022, 2021, 2020, 2019, 2018];

export type ForeignCodeObject = {
  code: string;
  country: string | undefined;
  value: number | undefined;
  nature: string | undefined;
  taxed: boolean | undefined;
};

export function isDoubleReturn(codes: { [p: string]: any }): boolean {
  const isTrue = (codeId: string) => codeId in codes && [true, 1, "1"].includes(codes[codeId]);

  return (
    (!isTrue("1001") && !isTrue("1002") && !isTrue("1010") && !isTrue("1018") && !isTrue("1022")) ||
    (isTrue("1002") && !isTrue("1003") && !(isTrue("1018") && !isTrue("1019"))) ||
    (isTrue("1010") && isTrue("1012")) ||
    (isTrue("1022") && (isTrue("1023") || isTrue("1025")))
  );
}

export default class SimulationInput {
  public static defaultI18n: any = null;

  public static readonly validYears: number[] = VALID_YEARS;

  public static readonly incompleteYears: number[] = INCOMPLETE_YEARS;

  public static readonly towSupportedYears: number[] = TOW_SUPPORTED_YEARS;

  private _taxYear!: number;

  private _city: string | null;

  private _codes!: { [index: string]: any };

  private _foreignCodes!: ForeignCodeObject[];

  private _userComments: { [index: string]: string } | null;

  private _codeTags: { [index: string]: string[] } | null;

  private _realEstate: { [index: string]: any }[] | null;

  private _loans: { [index: string]: any }[] | null;

  private _savings: { [index: string]: any } | null;

  private _household: { [index: string]: any } | null;

  private _enabledWizards!: string[];

  private _realEstateAndLoansResult!: { [index: string]: any } | null;

  private _nationalIdNumberDeclarant!: string | null;

  public staticInfo: StaticInfo | null = null;

  private readonly _i18n: any = null;

  public _validateRequiredAdditionalInfo: boolean;

  public codeItems: Map<string, CodeItem> | null = null;

  public validationErrors: Map<string, string[]> | null = null;

  constructor(
    taxYear: number,
    city: string | null | undefined,
    codes: { [index: string]: any },
    foreignCodes: ForeignCodeObject[] | undefined,
    nationalIdNumberDeclarant: string | null | undefined,
    userComments: { [index: string]: string } | undefined | null,
    codeTags: { [index: string]: string[] } | undefined | null,
    realEstate: { [index: string]: any }[] | undefined | null,
    loans: { [index: string]: any }[] | undefined | null,
    savings: { [index: string]: any } | undefined | null,
    household: { [index: string]: any } | undefined | null,
    enabledWizards: string[] | undefined,
    realEstateAndLoansResult: { [index: string]: any } | undefined | null,
    i18n: any = undefined,
    validateRequiredAdditionalInfo: boolean = false
  ) {
    this._i18n = i18n === undefined || i18n === null ? SimulationInput.defaultI18n : i18n;
    this._taxYear = taxYear;
    this._city = city === undefined ? null : `${city}`;
    this._codes = codes;
    this._foreignCodes = foreignCodes === undefined ? [] : foreignCodes;
    this._nationalIdNumberDeclarant = nationalIdNumberDeclarant === undefined ? null : nationalIdNumberDeclarant;
    this._userComments = userComments === undefined ? null : userComments;
    this._codeTags = codeTags === undefined ? null : codeTags;
    this._realEstate = realEstate === undefined ? null : realEstate;
    this._loans = loans === undefined ? null : loans;
    this._savings = savings === undefined ? null : savings;
    this._household = household === undefined ? null : household;
    this._enabledWizards = enabledWizards === undefined ? [] : enabledWizards;
    this._realEstateAndLoansResult = realEstateAndLoansResult === undefined ? null : realEstateAndLoansResult;

    this._validateRequiredAdditionalInfo = validateRequiredAdditionalInfo;

    this.update();
  }

  get taxYear(): number {
    return this._taxYear;
  }

  set taxYear(value: number) {
    this._taxYear = value;
    this.update(() => this.correctComplexTypeValue());
  }

  get city(): string | null {
    return this._city;
  }

  set city(value: string | null) {
    this._city = value;
    this.update();
  }

  get codes(): { [p: string]: any } {
    return this._codes;
  }

  get codesWithoutSumField(): { [p: string]: any } {
    return Object.fromEntries(Object.entries(this._codes).filter(([code]) => !/^\d{4}_[0-9]+$/.test(code)));
  }

  set codes(value: { [p: string]: any }) {
    this._codes = value;
    this.update();
  }

  setCode(codeId: string, value: any, update: boolean = true) {
    this._codes[codeId] = value;
    if (["1061", "1090"].includes(codeId)) {
      this.city = null;
    }
    if (update) {
      this.update();
    }
  }

  removeCode(codeId: string, update: boolean = true) {
    if (codeId in this._codes) {
      delete this._codes[codeId];
      // remove all sum terms
      if (/^\d{4}$/.test(codeId)) {
        const regex = new RegExp(`^${codeId}_[0-9]+$`);
        Object.entries(this._codes).forEach(([code]) => {
          if (regex.test(code)) {
            delete this._codes[code];
          }
        });
      }
      this._foreignCodes = this.foreignCodes.filter((x) => x.code !== codeId);
      if (update) {
        this.update();
      }
    }
  }

  newInputFromCollection(collection: CodeItem[]): SimulationInput {
    const newState = this.clone();
    collection.forEach((item) => {
      if (this.codeItems!.has(item.info.code)) {
        if (!item.hasSomeValue) {
          newState.removeCode(item.info.code, false);
        } else {
          const existingItem = this.codeItems!.get(item.info.code)!;
          if (existingItem.parsedValue !== item.parsedValue) {
            newState.setCode(item.info.code, item.parsedValue, false);
          }
          if (existingItem.userComment !== item.userComment) {
            newState.setUserCommentForCode(item.info.code, item.userComment, false);
          }
          if (JSON.stringify(existingItem.tags) !== JSON.stringify(item.tags)) {
            newState.setTagsForCode(item.info.code, item.tags, false);
          }
        }
      } else {
        if (item.parsedValue !== null) {
          newState.setCode(item.info.code, item.parsedValue, false);
        }
        if (item.userComment) {
          newState.setUserCommentForCode(item.info.code, item.userComment, false);
        }
        if (item.tags.length > 0) {
          newState.setTagsForCode(item.info.code, item.tags, false);
        }
      }

      // update foreign if necessary
      const currentForeignForItem = this.codeItems!.has(item.info.code)
        ? this.codeItems!.get(item.info.code)!.foreign
        : [];
      const newForeignForItem = item.value ? item.foreign.filter((x) => !x.isEmpty) : [];
      if (!CodeItem.foreignIncomeEquals(currentForeignForItem, newForeignForItem)) {
        newState.foreignCodes = newState.foreignCodes
          .filter((x) => x.code !== item.info.code)
          .concat(
            newForeignForItem.map((x) => ({
              code: item.info.code,
              country: x.country ? x.country!.id : undefined,
              value: x.parsedValue!,
              nature: x.nature?.toString(),
              taxed: x.taxed,
            }))
          );
      }
    });
    newState.update();
    return newState;
  }

  get foreignCodes(): ForeignCodeObject[] {
    return this._foreignCodes;
  }

  set foreignCodes(value: ForeignCodeObject[]) {
    this._foreignCodes = value;
    this.update();
  }

  get nationalIdNumberDeclarant(): string | null {
    return this._nationalIdNumberDeclarant;
  }

  set nationalIdNumberDeclarant(value: string | null) {
    this._nationalIdNumberDeclarant = value;
  }

  setUserCommentForCode(code: string, comment: string | undefined, update: boolean = true): void {
    if (comment === undefined) {
      if (this._userComments && code in this._userComments) {
        delete this._userComments[code];
        if (Object.keys(this._userComments).length === 0) {
          this._userComments = null;
        }
      }
    } else {
      if (!this._userComments) {
        this._userComments = {};
      }
      this._userComments[code] = comment;
    }
    if (update) {
      this.update();
    }
  }

  get userComments(): { [p: string]: string } | null {
    return this._userComments;
  }

  get nonTodoComments(): { [index: string]: string } | null {
    if (this.userComments == null) {
      return null;
    }

    const nonTodoComments: { [index: string]: string } = {};

    if (this._userComments) {
      for (const key in this._userComments) {
        if (!this._codeTags || !this._codeTags[key] || !this._codeTags[key].includes("todo")) {
          nonTodoComments[key] = this._userComments[key];
        }
      }
    }

    return nonTodoComments;
  }

  set userComments(value: { [p: string]: string } | null) {
    this._userComments = value;
  }

  get codeTags(): { [p: string]: string[] } | null {
    return this._codeTags;
  }

  set codeTags(value: { [p: string]: string[] } | null) {
    this._codeTags = value;
  }
  get realEstate(): { [p: string]: any }[] | null {
    return this._realEstate;
  }

  set realEstate(value: { [p: string]: any }[] | null) {
    this._realEstate = value;
  }

  enableWizard(name: string) {
    if (!this._enabledWizards.includes(name)) {
      this._enabledWizards.push(name);
    }
  }

  disableWizard(name: string) {
    if (this._enabledWizards.includes(name)) {
      this._enabledWizards = this._enabledWizards.filter((i) => i !== name);
    }
    // removes tag 'from_wizard:${name}' from codeTags if code contains no value
    if (this._codeTags) {
      this._codeTags = Object.fromEntries(
        Object.entries(this._codeTags)
          .map(([code, tags]) => [code, tags.filter((tag) => tag !== `from_wizard:${name}` || code in this._codes)])
          .filter(([, tags]) => tags.length > 0)
      );
    }
  }

  get enabledWizards(): string[] {
    return this._enabledWizards;
  }

  set enabledWizards(value: string[]) {
    this._enabledWizards = value;
  }

  get realEstateAndLoansResult(): { [p: string]: any } | null {
    return this._realEstateAndLoansResult;
  }

  set realEstateAndLoansResult(value: { [p: string]: any } | null) {
    this._realEstateAndLoansResult = value;
  }

  get loans(): { [p: string]: any }[] | null {
    return this._loans;
  }

  set loans(value: { [p: string]: any }[] | null) {
    this._loans = value;
  }

  get savings(): { [p: string]: any } | null {
    return this._savings;
  }

  set savings(value: { [p: string]: any } | null) {
    this._savings = value;
  }

  get household(): { [p: string]: any } | null {
    return this._household;
  }

  set household(value: { [p: string]: any } | null) {
    this._household = value;
  }

  addTagForCode(code: string, tag: string, update: boolean = true): void {
    if (!this._codeTags) {
      this._codeTags = {};
    }
    if (!(code in this._codeTags)) {
      this._codeTags[code] = [];
    }
    if (!this._codeTags[code].includes(tag)) {
      this._codeTags[code].push(tag);
    }
    if (update) {
      this.update();
    }
  }

  removeTagForCode(code: string, tag: string, update: boolean = true): void {
    if (this._codeTags && code in this._codeTags) {
      this._codeTags[code] = this._codeTags[code].filter((t) => t !== tag);
      if (this._codeTags[code].length === 0) {
        delete this._codeTags[code];
      }
    }
    if (update) {
      this.update();
    }
  }

  removeFromWizardTagsForCode(code: string, update: boolean = true): void {
    if (this._codeTags && code in this._codeTags) {
      this._codeTags[code] = this._codeTags[code].filter((t) => !t.startsWith("from_wizard:"));
      if (this._codeTags[code].length === 0) {
        delete this._codeTags[code];
      }
    }
    if (update) {
      this.update();
    }
  }

  setTagsForCode(code: string, tags: string[], update: boolean = true): void {
    if (tags.length === 0) {
      if (this._codeTags && code in this._codeTags) {
        delete this._codeTags[code];
        if (Object.keys(this._codeTags).length === 0) {
          this._codeTags = null;
        }
      }
    } else {
      if (!this._codeTags) {
        this._codeTags = {};
      }
      this._codeTags[code] = [...tags];
    }
    if (update) {
      this.update();
    }
  }

  get codeItemsWithValue(): Map<string, CodeItem> | null {
    if (this.codeItems) {
      return new Map(Array.from(this.codeItems.entries()).filter(([code]) => this.codes[code] !== undefined));
    }
    return null;
  }

  get codeItemsWithValueOrCommentOrTodo(): Map<string, CodeItem> | null {
    if (this.codeItems) {
      return new Map(
        Array.from(this.codeItems.entries()).filter(
          ([code]) =>
            this.codes[code] !== undefined ||
            (this.userComments && this.userComments[code] !== undefined) ||
            (this.codeTags && this.codeTags[code]?.includes("todo"))
        )
      );
    }
    return null;
  }

  get locale(): string {
    return this._i18n ? this._i18n.locale : "nl";
  }

  update(func: CallableFunction | undefined = undefined): any {
    const availableStaticInfo = StaticInfoContainer.getAvailableStaticInfo(this.taxYear, this._i18n.locale);
    if (availableStaticInfo) {
      this.staticInfo = availableStaticInfo;
      this.codeItems = this.getCodeItems();
      this.validationErrors = this.codeItems ? SimulationInput.calculateValidationErrors(this.codeItems) : null;
      if (func) {
        return func();
      }
    } else {
      if (this._i18n) {
        StaticInfoContainer.getStaticInfo(this.taxYear, this._i18n.locale, this._i18n).then((info) => {
          this.staticInfo = info;
          this.codeItems = this.getCodeItems();
          this.validationErrors = this.codeItems ? SimulationInput.calculateValidationErrors(this.codeItems) : null;
          if (func) {
            return func();
          }
        });
      }
    }
  }

  applyFixes(force: boolean = true): [[string, any] | null, [string, any] | null][] | null {
    if (this.codeItems) {
      const changes: [[string, any] | null, [string, any] | null][] = [];

      // set correct 1061 and 1090 according to city
      const cityObject = this.cityObject;
      if (cityObject) {
        const taxCalcId = Region.taxCalcId(cityObject.region);
        if (!("1090" in this.codes) || taxCalcId.toString() !== this.codes["1090"].toString()) {
          this._codes["1090"] = taxCalcId;
        }
        if (!("1061" in this.codes) || cityObject.rate !== this.codes["1061"]) {
          this._codes["1061"] = cityObject.rate;
        }
      }

      // if code noEntA or noEntB consists of a number or a string only digits, but less than 10
      // -> convert it to a string with leading zeros
      for (const code of ["noEntA", "noEntB"]) {
        if (this.codes[code] && /^[0-9]{1,9}$/.test(this.codes[code].toString())) {
          changes.push([
            [code, this.codes[code]],
            [code, this.codes[code].toString().padStart(10, "0")],
          ]);
          this._codes[code] = this.codes[code].toString().padStart(10, "0");
        }
      }

      // removes prepayments totals
      if (this.codes["1570"]) {
        changes.push([["1570", this.codes["1570"]], null]);
        this.removeCode("1570", false);
      }
      if (this.codes["2570"]) {
        changes.push([["2570", this.codes["2570"]], null]);
        this.removeCode("2570", false);
      }

      // corrects sum terms
      if (this.codeItems) {
        const sumParentCodes = new Set(
          Array.from(this.codeItems!.entries())
            .filter(([, item]) => item.info.sumFieldTotal || item.info.isSumTerm)
            .map(([code, item]) => item.info.parentSumCode!)
        );

        sumParentCodes.forEach((parentCodeId: string) => {
          const parentList = Array.from(this.codeItems!.entries())
            .filter(([, item]) => item.info.code == parentCodeId)
            .map(([, item]) => item);
          const parent = parentList.length > 0 ? parentList[0] : null;
          const terms = Array.from(this.codeItems!.entries())
            .filter(([, item]) => item.info.isSumTerm && item.info.parentSumCode == parentCodeId)
            .map(([code, item]) => item);
          const termsSum = terms.map((item: CodeItem) => item.value).reduce((a: any, b: any) => a + b, 0);
          if (!parent && terms.length > 0) {
            changes.push([null, [parentCodeId, termsSum]]);
            this.setCode(parentCodeId, termsSum, false);
          } else if (parent) {
            if (terms.length === 0) {
              changes.push([null, [`${parentCodeId}_1`, parent.value]]);
              this.setCode(`${parentCodeId}_1`, parent.value, false);
            } else if (parent.value > termsSum) {
              const indices = terms.map((item) => item.info.sumTermIndex!).sort();
              const latestIndex = indices[indices.length - 1];
              const newTermId = `${parentCodeId}_${latestIndex + 1}`;
              if (this.staticInfo!.codeInfo.has(newTermId)) {
                changes.push([null, [newTermId, parent.value - termsSum]]);
                this.setCode(newTermId, parent.value - termsSum, false);
              } else {
                if (force) {
                  const existingTermId = `${parentCodeId}_${latestIndex}`;
                  const newValue = this.codes[existingTermId] + (parent.value - termsSum);
                  changes.push([
                    [existingTermId, this.codes[existingTermId]],
                    [existingTermId, newValue],
                  ]);
                  this.setCode(existingTermId, newValue, false);
                } else {
                  throw EvalError(`parent sum code has a larger value then its terms for parent, and
                  no later term is available to store the difference ${parentCodeId}: ${parent.value} < ${termsSum}`);
                }
              }
            } else if (parent.value < termsSum) {
              if (force) {
                const newValue = this.codes[parentCodeId] + (termsSum - parent.value);
                changes.push([
                  [parentCodeId, this.codes[parentCodeId]],
                  [parentCodeId, newValue],
                ]);
                this.setCode(parentCodeId, this.codes[parentCodeId] + (termsSum - parent.value));
              } else {
                throw EvalError(`parent sum code has a smaller value then its terms
                  for parent ${parentCodeId}: ${parent.value} < ${termsSum}`);
              }
            }
          }
        });
      }

      // corrects old locking system to new system with tags
      if (this.enabledWizards.includes("real_estate_and_loans") || this.enabledWizards.includes("savings")) {
        if (
          !this.codeTags ||
          !Array.from(Object.values(this.codeTags)).some((tagList: string[]) =>
            tagList.some((tag: string) => tag.startsWith("from_wizard:"))
          )
        ) {
          const realEstateAndLoans = RealEstateAndLoans.fromSimulationInput(this, null);
          realEstateAndLoans.updateResult(() => realEstateAndLoans.applyOnSimulationInput(this));
        }
      }

      const correctedComplexTypes = this.correctComplexTypeValue(false);
      if (correctedComplexTypes) {
        changes.push(...this.correctComplexTypeValue()!);
      }
      this.update();
      return changes;
    }
    return null;
  }

  correctComplexTypeValue(update: boolean = true): [[string, any] | null, [string, any] | null][] | null {
    // this functions corrects any complex types that might have changed after a taxYear change
    if (this.codeItems) {
      const changes: [[string, any] | null, [string, any] | null][] = [];
      this.codeItems.forEach((item) => {
        if (item.value) {
          if (item.info.type === "benefRenteAlimType") {
            const fixed = BenefRenteAlimType.fixedObject(item.value);
            if (JSON.stringify(item.value) !== JSON.stringify(fixed)) {
              changes.push([
                [item.info.code, item.value],
                [item.info.code, fixed],
              ]);
              this.setCode(item.info.code, fixed, false);
            }
          } else if (item.info.type === "benefRenteAlimList") {
            const fixed = BenefRenteAlimList.fixedObject(item.value);
            if (JSON.stringify(item.value) !== JSON.stringify(fixed)) {
              changes.push([
                [item.info.code, item.value],
                [item.info.code, fixed],
              ]);
              this.setCode(item.info.code, fixed, false);
            }
          } else if (item.info.type === "string" && typeof item.value === "object") {
            if (Object.keys(item.value).length === 1) {
              if (Array.isArray(Object.values(item.value)[0])) {
                const fixed = (Object.values(item.value)[0] as Array<string>)[0];
                changes.push([
                  [item.info.code, item.value],
                  [item.info.code, fixed],
                ]);
                this.setCode(item.info.code, fixed, false);
              }
            }
          } else if (item.info.type === "debAlimList") {
            const fixed = DebAlimList.fixedObject(item.value);
            if (JSON.stringify(item.value) !== JSON.stringify(fixed)) {
              changes.push([
                [item.info.code, item.value],
                [item.info.code, fixed],
              ]);
              this.setCode(item.info.code, fixed, false);
            }
          } else if (item.info.type === "juridiqueType" && item.info.taxYear > 2022) {
            const fixed = JuridiqueType.fixedObject(item.value);
            if (JSON.stringify(item.value) !== JSON.stringify(fixed)) {
              changes.push([
                [item.info.code, item.value],
                [item.info.code, fixed],
              ]);
              this.setCode(item.info.code, fixed, false);
            }
          }
        }
      });
      if (update && changes.length > 0) {
        this.update();
      }
      return changes;
    }
    return null;
  }

  get codesForCalculation() {
    return Object.keys(this.codes)
      .filter((key) => key.match(/^\d{4}$/))
      .reduce((obj, key) => {
        return { ...obj, [key]: this!.codes[key] };
      }, {});
  }

  get isDoubleReturn(): boolean {
    return isDoubleReturn(this.codes);
  }

  getCodeItems(): Map<string, CodeItem> | null {
    if (!this.staticInfo) {
      return null;
    }
    const items = new Map(
      Object.entries(this.codes).map(([codeId, codeValue]: any) => {
        const foreignCodes = this.foreignCodes
          .filter((elem) => elem.code === codeId)
          .map((item) =>
            Vue.observable(
              new ForeignIncome(
                item.country ? this.staticInfo!.countries!.get(item.country.toUpperCase())! : null,
                item.value,
                item.nature ? (item.nature as CapitalNature) : undefined,
                item.taxed
              )
            )
          );
        let codeInfoForItem = this.codeInfoForCurrentRegion!.has(codeId)
          ? this.codeInfoForCurrentRegion!.get(codeId)!
          : this.codeInfoForCurrentRegion!.get("unknown")!;
        if (codeInfoForItem.code === "unknown") {
          codeInfoForItem = Object.assign(Object.create(Object.getPrototypeOf(codeInfoForItem)), codeInfoForItem);
          codeInfoForItem.code = codeId;
        }
        const userComment = this._userComments && codeId in this._userComments ? this._userComments[codeId] : undefined;
        const codeTags = this._codeTags && codeId in this._codeTags ? [...this._codeTags[codeId]] : undefined;
        return [codeId, Vue.observable(new CodeItem(codeInfoForItem, codeValue, foreignCodes, userComment, codeTags))];
      })
    );
    items.set("global1", Vue.observable(new CodeItem(this.codeInfoForCurrentRegion!.get("global1")!, 1, [])));
    items.set("global2", Vue.observable(new CodeItem(this.codeInfoForCurrentRegion!.get("global2")!, 1, [])));
    if (this._userComments) {
      Object.entries(this._userComments)
        .filter(([codeId]: any) => !items.has(codeId))
        .forEach(([codeId, userComment]: any) => {
          let codeInfoForItem = this.codeInfoForCurrentRegion!.has(codeId)
            ? this.codeInfoForCurrentRegion!.get(codeId)!
            : this.codeInfoForCurrentRegion!.get("unknown")!;
          if (codeInfoForItem.code === "unknown") {
            codeInfoForItem = Object.assign(Object.create(Object.getPrototypeOf(codeInfoForItem)), codeInfoForItem);
            codeInfoForItem.code = codeId;
          }
          const codeTags = this._codeTags && codeId in this._codeTags ? [...this._codeTags[codeId]] : undefined;
          items.set(codeId, Vue.observable(new CodeItem(codeInfoForItem, null, [], userComment, codeTags)));
        });
    }
    if (this._codeTags) {
      Object.entries(this._codeTags)
        .filter(([codeId]: any) => !items.has(codeId))
        .forEach(([codeId, codeTags]: any) => {
          let codeInfoForItem = this.codeInfoForCurrentRegion!.has(codeId)
            ? this.codeInfoForCurrentRegion!.get(codeId)!
            : this.codeInfoForCurrentRegion!.get("unknown")!;
          if (codeInfoForItem.code === "unknown") {
            codeInfoForItem = Object.assign(Object.create(Object.getPrototypeOf(codeInfoForItem)), codeInfoForItem);
            codeInfoForItem.code = codeId;
          }
          const userComment =
            this._userComments && codeId in this._userComments ? this._userComments[codeId] : undefined;
          items.set(codeId, Vue.observable(new CodeItem(codeInfoForItem, null, [], userComment, codeTags)));
        });
    }

    items.forEach((item) => item.validate(items, true, this._validateRequiredAdditionalInfo));
    return items;
  }

  get regionId(): string {
    return "1090" in this.codes && [1, 2, 3].includes(this.codes["1090"])
      ? Region.textId(this.codes["1090"])
      : "shared";
  }

  get cityObject(): City | null {
    if (this.city && this.staticInfo?.cityList) {
      const cityList = this.staticInfo.cityList;
      let candidateCities = cityList.filter((x) => x.postcode === this.city);
      if (candidateCities.length >= 1) {
        return candidateCities[0];
      }

      candidateCities = cityList.filter(
        (x) =>
          x.name === this.city ||
          deburr(x.name.toLocaleLowerCase().replace(/ *\([^)]*\) */g, "")) === deburr(this.city!.toLocaleLowerCase())
      );
      if (candidateCities.length >= 1) {
        return candidateCities[0];
      }

      candidateCities = cityList.filter((x) =>
        deburr(x.name.toLocaleLowerCase()).includes(`(${deburr(this.city!.toLocaleLowerCase())})`)
      );
      if (candidateCities.length >= 1) {
        return candidateCities[0];
      }
    }
    return null;
  }

  set cityObject(cityObject: City | null) {
    this._city = cityObject ? cityObject.postcode : null;
    if (cityObject) {
      const taxCalcId = Region.taxCalcId(cityObject.region);
      if (!("1090" in this.codes) || taxCalcId.toString() !== this.codes["1090"].toString()) {
        this._codes["1090"] = taxCalcId;
      }
      if (!("1061" in this.codes) || cityObject.rate !== this.codes["1061"]) {
        this._codes["1061"] = cityObject.rate;
      }
    }
    this.update();
  }

  get cityInError(): boolean | null {
    if (!this.codeItems) {
      return null;
    }
    return !(
      this.codeItems.has("1090") &&
      this.codeItems.get("1090")!.allErrors.length === 0 &&
      this.codeItems.has("1061") &&
      this.codeItems.get("1061")!.allErrors.length === 0
    );
  }

  get isValid(): boolean {
    return !this.validationErrors || this.validationErrors.size === 0;
  }

  public static calculateValidationErrors(
    codeItems: Map<string, CodeItem>,
    sectionId: string | null = null
  ): Map<string, string[]> {
    return new Map(
      // @ts-ignore
      Array.from(codeItems.entries())
        .filter(([id, item]) => !sectionId || item.info.section.id === sectionId)
        .map(([id, item]) => [id, [...item.typeErrors, ...item.customErrors, ...item.regularErrors]])
        .filter(([id, errors]) => errors.length > 0)
    );
  }

  get sectionInfoForCurrentRegion(): Map<string, SectionInfo> | null {
    return this.staticInfo ? this.staticInfo.sectionInfo.get(this.regionId)! : null;
  }

  get codeInfoForCurrentRegion(): Map<string, CodeInfo> | null {
    return this.staticInfo ? this.staticInfo.codeInfo.get(this.regionId)! : null;
  }

  equals(otherInput: SimulationInput) {
    return (
      otherInput.taxYear === this.taxYear &&
      otherInput.city === this.city &&
      isEqual(otherInput.codes, this.codes) &&
      isEqual(otherInput.userComments, this.userComments) &&
      isEqual(otherInput.codeTags, this.codeTags) &&
      isEqual(otherInput.realEstate, this.realEstate) &&
      isEqual(otherInput.loans, this.loans) &&
      isEqual(otherInput.savings, this.savings) &&
      isEqual(otherInput.household, this.household) &&
      isEqual(new Set(otherInput.enabledWizards), new Set(this.enabledWizards)) &&
      isEqual(otherInput.realEstateAndLoansResult, this.realEstateAndLoansResult) &&
      SimulationInput.foreignIncomeEquals(otherInput.foreignCodes, this.foreignCodes)
    );
  }

  private static foreignIncomeEquals(array1: ForeignCodeObject[], array2: ForeignCodeObject[]): boolean {
    if (array1.length === array2.length) {
      const array1str = array1.map((x) => `${x.code}${x.country}${x.value}${x.nature}${x.taxed}`).sort();
      const array2str = array2.map((x) => `${x.code}${x.country}${x.value}${x.nature}${x.taxed}`).sort();
      for (let i = 0; i < array1str.length; i += 1) {
        if (array1str[i] !== array2str[i]) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  get requestHash(): string {
    return SimulationInput.hashObject(this.toObjectForCalculation());
  }

  static hashObject(obj: any): string {
    // https://stackoverflow.com/a/15710692
    const hashCode = (s: string) =>
      s.split("").reduce((a, b) => {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
      }, 0); // eslint-disable-line no-bitwise, no-param-reassign
    const jsonStr = JSON.stringify(obj);
    const first = hashCode(jsonStr).toString();
    return hashCode(jsonStr.concat(first)).toString();
  }

  cloneWithSwappedCodes(): SimulationInput {
    function swapCode(code: string): string {
      if (codeInfo.has(code)) {
        return codeInfo.get(code)!.partnerCode;
      }
      throw Error(`code does not exist in info: ${code}`);
    }

    function swapCodes(obj: { [index: string]: any }): { [index: string]: any } {
      return Object.fromEntries(Object.keys(obj).map((key) => [swapCode(key), obj[key]]));
    }

    if (!this.isDoubleReturn) {
      return this.clone();
    }
    if (!this.codeInfoForCurrentRegion) {
      throw Error("codeInfo not initialized");
    }
    const codeInfo = this.codeInfoForCurrentRegion!;

    return new SimulationInput(
      this.taxYear,
      this._city,
      JSON.parse(JSON.stringify(swapCodes(this._codes))),
      this._foreignCodes.map((item) => ({
        code: swapCode(item.code),
        country: item.country,
        value: item.value,
        nature: item.nature,
        taxed: item.taxed,
      })),
      null,
      JSON.parse(JSON.stringify(this._userComments ? swapCodes(this._userComments) : null)),
      JSON.parse(JSON.stringify(this._codeTags ? swapCodes(this._codeTags) : null)),
      JSON.parse(
        JSON.stringify(this._realEstate ? this._realEstate.map((p) => Property.cloneObjectWithSwappedCodes(p)) : null)
      ),
      JSON.parse(
        JSON.stringify(this._loans ? this._loans.map((l) => SharedLoanProperties.cloneObjectWithSwappedCodes(l)) : null)
      ),
      JSON.parse(JSON.stringify(this._savings ? Savings.cloneObjectWithSwappedCodes(this._savings) : null)),
      JSON.parse(JSON.stringify(this._household ? this._household.clone() : null)),
      [...this._enabledWizards],
      JSON.parse(JSON.stringify(this._realEstateAndLoansResult)),
      this._i18n,
      this._validateRequiredAdditionalInfo
    );
  }

  clone(): SimulationInput {
    return new SimulationInput(
      this.taxYear,
      this._city,
      { ...this._codes },
      this._foreignCodes.map((item) => ({ ...item })),
      this._nationalIdNumberDeclarant,
      JSON.parse(JSON.stringify(this._userComments)),
      JSON.parse(JSON.stringify(this._codeTags)),
      JSON.parse(JSON.stringify(this._realEstate)),
      JSON.parse(JSON.stringify(this._loans)),
      JSON.parse(JSON.stringify(this._savings)),
      JSON.parse(JSON.stringify(this._household)),
      [...this._enabledWizards],
      JSON.parse(JSON.stringify(this._realEstateAndLoansResult)),
      this._i18n,
      this._validateRequiredAdditionalInfo
    );
  }

  toObject(): any {
    return {
      tax_year: this._taxYear,
      city: this._city,
      codes: JSON.parse(JSON.stringify(this._codes)),
      foreign_codes: JSON.parse(JSON.stringify(this._foreignCodes)),
      national_id_number_declarant: this._nationalIdNumberDeclarant ? this._nationalIdNumberDeclarant : undefined,
      user_comments: this._userComments && Object.keys(this._userComments).length > 0 ? this._userComments : undefined,
      code_tags: this._codeTags && Object.keys(this._codeTags).length > 0 ? this._codeTags : undefined,
      real_estate: this._realEstate && this._realEstate.length > 0 ? this._realEstate : undefined,
      loans: this._loans && this._loans.length > 0 ? this._loans : undefined,
      savings: this._savings && Object.keys(this._savings).length > 0 ? this._savings : undefined,
      household: this._household && Object.keys(this._household).length > 0 ? this._household : undefined,
      enabled_wizards: this._enabledWizards.length > 0 ? JSON.parse(JSON.stringify(this._enabledWizards)) : undefined,
      real_estate_and_loans_result: this._realEstateAndLoansResult ? this._realEstateAndLoansResult : undefined,
    };
  }

  toObjectForCalculation(): any {
    return {
      tax_year: this._taxYear,
      codes: JSON.parse(JSON.stringify(this._codes)),
      foreign_codes: JSON.parse(JSON.stringify(this._foreignCodes)),
    };
  }

  static fromObject(obj: any): SimulationInput {
    if (!(obj.tax_year && obj.codes)) {
      throw Error(`not a valid simulation-input: ${JSON.stringify(obj)}`);
    }
    return new SimulationInput(
      obj.tax_year,
      obj.city,
      obj.codes,
      obj.foreign_codes,
      obj.national_id_number_declarant,
      obj.user_comments,
      obj.code_tags,
      obj.real_estate,
      obj.loans,
      obj.savings,
      obj.household,
      obj.enabled_wizards,
      obj.real_estate_and_loans_result
    );
  }

  static fromQueryObject(obj: any): SimulationInput {
    if (!(obj.taxYear && obj.codes)) {
      throw Error(`not a valid simulation-input: ${JSON.stringify(obj)}`);
    }
    return new SimulationInput(
      obj.taxYear,
      obj.city,
      obj.codes,
      obj.foreignCodes,
      obj.nationalIdNumberDeclarant,
      obj.userComments,
      obj.codeTags,
      obj.realEstate,
      obj.loans,
      obj.savings,
      obj.household,
      obj.enabled_wizards,
      obj.real_estate_and_loans_result
    );
  }
}
