
import Vue from "vue";
import { Component, Prop, Watch } from "nuxt-property-decorator";
import BenefRenteAlimList from "@/components/input/complexTypes/benefRenteAlimList";
import BenefRenteAlimTypeFromListView from "@/components/input/complexTypes/benefRenteAlimTypeFromListView.vue";
import BenefRenteAlimType from "@/components/input/complexTypes/benefRenteAlimType";
import CodeItem from "@/components/input/codeItem";
import Country from "~/components/input/country";

@Component({
  components: { BenefRenteAlimTypeFromListView },
})
export default class BenefRenteAlimListView extends Vue {
  @Prop({ required: true, type: Object })
  codeItem!: CodeItem;

  @Prop({ required: true, type: Map })
  readonly countries!: Map<string, Country>;

  @Prop({ default: false, type: Boolean })
  readonly disabled!: boolean;

  @Prop({ default: true, type: Boolean })
  editable!: boolean;

  valueInstance: BenefRenteAlimList = Vue.observable(
    BenefRenteAlimList.fromObject(this.codeItem.value, this.countries)
  );

  get canAddNewItem() {
    return (
      this.valueInstance.items.length === this.codeItem.value?.item?.length &&
      this.valueInstance.items.length < this.valueInstance.maxItems
    );
  }

  addNewItem() {
    if (this.canAddNewItem) {
      this.valueInstance.items.push(BenefRenteAlimType.fromObject({}, this.countries));
    }
  }

  @Watch("codeItem.value", { deep: true })
  onValueChange(newValue: Object) {
    this.valueInstance = Vue.observable(BenefRenteAlimList.fromObject(newValue, this.countries));
  }

  @Watch("valueInstance", { deep: true })
  onValueInstanceChange(valueInstance: BenefRenteAlimList) {
    const newObject = valueInstance.toObject();
    const result = newObject || null;
    if (JSON.stringify(result) !== JSON.stringify(this.codeItem.value)) {
      this.$emit("update", result);
    }
  }

  focus(options: object) {
    // (this.$refs!.benefRenteAlimType! as any[])[0].focus(options);
  }
}
