import TaxReturnCodes from "@/components/input/wizards/realEstateAndLoans/taxReturnCodes";
import Validatable from "@/components/input/wizards/validatable";
import RealEstateCodesForProperty from "~/components/input/wizards/realEstateAndLoans/realEstate/realEstateCodesForProperty";
import LoanCodesForPropertySet from "~/components/input/wizards/realEstateAndLoans/loans/loanCodesForPropertySet";
import LoanCodesForLoan from "~/components/input/wizards/realEstateAndLoans/loans/loanCodesForLoan";

export default class RealEstateAndLoansResult extends Validatable {
  realEstateCodes: TaxReturnCodes;
  loanCodes: { [index: string]: any };
  realEstateCodesPerProperty: RealEstateCodesForProperty[];
  loanCodesPerLoan: LoanCodesForLoan[];
  loanCodesPerPropertySet: LoanCodesForPropertySet[];

  requestHash: string;

  constructor(
    realEstateCodes: TaxReturnCodes,
    loanCodes: { [index: string]: any },
    realEstateCodesPerProperty: RealEstateCodesForProperty[],
    loanCodesPerLoan: LoanCodesForLoan[],
    loanCodesPerPropertySet: LoanCodesForPropertySet[],
    requestHash: string
  ) {
    super(undefined, undefined, ["loanCodes"]);
    this.realEstateCodes = realEstateCodes;
    this.loanCodes = loanCodes;
    this.realEstateCodesPerProperty = realEstateCodesPerProperty;
    this.loanCodesPerLoan = loanCodesPerLoan;
    this.loanCodesPerPropertySet = loanCodesPerPropertySet;

    this.requestHash = requestHash;
  }

  get realEstateCodesPerPropertyMap(): Map<string, RealEstateCodesForProperty> {
    return new Map(this.realEstateCodesPerProperty.map((x) => [x.propertyId, x]));
  }

  get loanCodesPerLoanMap(): Map<string, LoanCodesForLoan> {
    return new Map(this.loanCodesPerLoan.map((x) => [x.loanId, x]));
  }

  get loanCodesPerPropertySetMap(): Map<string, LoanCodesForPropertySet> {
    return new Map(this.loanCodesPerPropertySet.map((x) => [x.propertySetId, x]));
  }

  get codes(): { [index: string]: any } {
    return { ...this.realEstateCodes.codes, ...this.loanCodes };
  }

  get hasResultCodes(): boolean {
    return Object.keys(this.realEstateCodes).length > 0 || this.loanCodes.hasAtLeastOneValue;
  }

  clone() {
    return this.cloneHelper(new RealEstateAndLoansResult(this.realEstateCodes, {}, [], [], [], ""));
  }

  static fromObject(obj: { [key: string]: any }, requestHash: string): RealEstateAndLoansResult {
    const result = Validatable.fromObjectHelper(obj, new this(new TaxReturnCodes({}, []), {}, [], [], [], ""));
    if (result.realEstateCodes) {
      result.realEstateCodes = TaxReturnCodes.fromObject(result.realEstateCodes);
    }
    if (result.realEstateCodesPerProperty) {
      result.realEstateCodesPerProperty = result.realEstateCodesPerProperty.map((x: any) =>
        RealEstateCodesForProperty.fromObject(x)
      );
    }
    if (result.loanCodesPerLoan) {
      result.loanCodesPerLoan = result.loanCodesPerLoan.map((x: any) => LoanCodesForLoan.fromObject(x));
    }
    if (result.loanCodesPerPropertySet) {
      result.loanCodesPerPropertySet = result.loanCodesPerPropertySet.map((x: any) =>
        LoanCodesForPropertySet.fromObject(x)
      );
    }
    result.requestHash = requestHash;
    return result;
  }
}
