
import { Component, Prop, Vue } from "nuxt-property-decorator";
import SimulationInputView from "@/components/input/SimulationInputView.vue";
import Simulation from "@/components/simulation";
import SimulationInput from "~/components/input/simulationInput";

@Component({
  components: {
    SimulationInputView,
  },
})
export default class SimpleSimulationEditorDialog extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly active!: boolean;

  @Prop({ required: true, type: Object })
  readonly simulation!: Simulation;

  ready: boolean = false;

  mounted() {
    this.simulation.input.update(() => this.applyFixesOnInput(this.simulation!.input));
    this.simulation.input._validateRequiredAdditionalInfo = false;
    if (!this.simulation.isUpToDate) {
      this.simulation.input.update(() => this.simulation!.updateResult());
    }
    setTimeout(() => {
      this.ready = true;
    }, 1000);
  }

  updateInput(input: SimulationInput) {
    this.simulation.input = input;
    if (!this.simulation.isUpToDate) {
      this.simulation.updateResultDebounced();
    }
  }

  close() {
    this.$emit("close");
  }

  save() {
    this.$emit("save", this.simulation);
  }

  applyFixesOnInput(input: SimulationInput) {
    const changes = input.applyFixes(true);
    if (this.simulation && changes) {
      // console.log(changes)
      if (!this.simulation.isUpToDate) {
        this.simulation.updateResultDebounced();
      }
    }
  }
}
