export default class ResultListItem {
  constructor(name: string, value: number) {
    this.name = name;
    this.value = value;
  }

  readonly name: string;

  readonly value: number;

  static fromMapping(mapping: any): ResultListItem {
    return new ResultListItem(mapping.name, mapping.value);
  }

  toMapping(): any {
    return {
      name: this.name,
      value: this.value,
    };
  }
}
