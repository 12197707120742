export default class JuridType {
  constructor(
    nameFounder: string | undefined,
    name: string | undefined,
    constructionType: string | undefined,
    address1: string | undefined,
    idNumber: string | undefined,
    managerAddress1: string | undefined,
    isArt513Construction: boolean | undefined
  ) {
    this.nameFounder = nameFounder;
    this.name = name;
    this.constructionType = constructionType;
    this.address1 = address1;
    this.idNumber = idNumber;
    this.managerAddress1 = managerAddress1;
    this.isArt513Construction = isArt513Construction;
  }

  nameFounder: string | undefined;
  name: string | undefined;
  constructionType: string | undefined;
  address1: string | undefined;
  idNumber: string | undefined;
  managerAddress1: string | undefined;
  isArt513Construction: boolean | undefined;

  toString(i18n: any | undefined = undefined): string {
    if (!this.hasValue()) {
      return "";
    }

    const parts = [
      `${i18n.t("input.complexTypes.juridType.nameFounderShort")}: ${this.nameFounder ?? ""}`,
      `${i18n.t("input.complexTypes.juridType.nameConstructionShort")}: ${this.name ?? ""} ${
        this.constructionType ?? ""
      } (${this.idNumber})`,
      `${i18n.t("input.complexTypes.juridType.addressConstructionShort")}: ${this.address1 ?? ""}`,
      `${i18n.t("input.complexTypes.juridType.nameManagerShort")}: ${this.managerAddress1 ?? ""}`,
    ];

    if (this.isArt513Construction) {
      parts.push(i18n.t("input.complexTypes.juridType.isArt513ConstructionShort"));
    }

    return parts.join("\n");
  }

  hasValue(): boolean {
    const hasVal = (item: string | undefined) => item !== undefined && item.trim().length > 0;
    return (
      hasVal(this.nameFounder) ||
      hasVal(this.name) ||
      hasVal(this.constructionType) ||
      hasVal(this.address1) ||
      hasVal(this.idNumber) ||
      hasVal(this.managerAddress1)
    );
  }

  toObject(): Object | undefined {
    if (this.hasValue()) {
      const result = {
        nomPrenomFondateur: this.nameFounder ? this.nameFounder?.substring(0, 60) : "",
        nomComplet: this.name ? this.name?.substring(0, 60) : "",
        forme: this.constructionType ? this.constructionType?.substring(0, 60) : "",
        adresse1: this.address1 ? this.address1?.substring(0, 60) : "",
        numIdent: this.idNumber ? this.idNumber?.substring(0, 60) : "",
        adresseRespJurCons1: this.managerAddress1 ? this.managerAddress1?.substring(0, 60) : "",
        personneMoraleCheckBox: this.isArt513Construction ? "checked" : undefined,
      };
      if (result.personneMoraleCheckBox === undefined) {
        delete result.personneMoraleCheckBox;
      }
      return result;
    }
    return undefined;
  }

  static fromObject(obj: any): JuridType {
    return new JuridType(
      obj?.nomPrenomFondateur,
      obj?.nomComplet,
      obj?.forme,
      obj?.adresse1,
      obj?.numIdent,
      obj?.adresseRespJurCons1,
      obj?.personneMoraleCheckBox
    );
  }

  static fixedObject(obj: any): any {
    if (obj && (obj.adresse2 || obj.nomRespJurCons || obj.adresseRespJurCons2)) {
      const result = JSON.parse(JSON.stringify(obj));
      if (result.adresse2) {
        result.adresse1 = `${result.adresse1 ? `${result.adresse1} ; ` : ""}${result.adresse2}`;
        result.adresse1 = result.adresse1?.substring(0, 60);
      }
      if (result.nomRespJurCons) {
        result.adresseRespJurCons1 = `${result.nomRespJurCons}${
          result.adresseRespJurCons1 ? ` ; ${result.adresseRespJurCons1}` : ""
        }`;
        result.adresseRespJurCons1 = result.adresseRespJurCons1?.substring(0, 60);
      }
      if (result.adresseRespJurCons2) {
        result.adresseRespJurCons1 = `${result.adresseRespJurCons1 ? `${result.adresseRespJurCons1} ; ` : ""}${
          result.adresseRespJurCons2
        }`;
        result.adresseRespJurCons1 = result.adresseRespJurCons1?.substring(0, 60);
      }
      delete result.adresse2;
      delete result.nomRespJurCons;
      delete result.adresseRespJurCons2;
      return result;
    }
    return obj;
  }
}
