export const migrate = (obj: any): any => {
  if (!obj.version) {
    obj = migrateFromPreVersionToCurrentVersion(obj);
  }
  return obj;
};

const migrateFromPreVersionToCurrentVersion = (obj: any): any => {
  obj = migrateRealEstatePurposeNotOwnHouseReasonsToConsideredAsOwnHouse(obj);
  obj = updateFrom_has_been_own_house_before_2016_but_not_after(obj);
  obj = updateLoanPurposes(obj);
  return obj;
};

const forEachLoan = (obj: any, callback: (loan: any) => void): void => {
  if (obj.input && Array.isArray(obj.input.loans)) {
    obj.input.loans.forEach(callback);
  }
};

const updateFrom_has_been_own_house_before_2016_but_not_after = (obj: any): any => {
  const updateDetails = (details: any) => {
    const originalValue = details.has_been_own_house_before_2016_but_not_after;
    details.has_been_own_house_before_2016 = originalValue;
    details.own_house_designation_unchanged_since_2016 = originalValue;
    delete details.has_been_own_house_before_2016_but_not_after;
  };
  forEachLoan(obj, (loan: any) => {
    if (loan.details_declarant?.has_been_own_house_before_2016_but_not_after !== undefined) {
      updateDetails(loan.details_declarant);
    }
    if (loan.details_partner?.has_been_own_house_before_2016_but_not_after !== undefined) {
      updateDetails(loan.details_partner);
    }
  });
  return obj;
};

const updateLoanPurposes = (obj: any): any => {
  const purposeMapping: { [key: string]: string } = {
    BUILD_NEW_HOUSE: "BUILD_NEW",
    BUY_NEW_HOUSE: "BUY_NEW",
    BUY_OLD_HOUSE: "BUY_OLD",
  };

  forEachLoan(obj, (loan: any) => {
    const newPurpose = purposeMapping[loan.loan_purpose];
    if (newPurpose) {
      loan.loan_purpose = newPurpose;
    }
  });

  return obj;
};
const migrateRealEstatePurposeNotOwnHouseReasonsToConsideredAsOwnHouse = (obj: any): any => {
  if (obj.input && Array.isArray(obj.input.real_estate)) {
    obj.input.real_estate.forEach((realEstateItem: any, index: number) => {
      // Assuming each real estate item might have multiple purposes
      if (Array.isArray(realEstateItem.purposes)) {
        realEstateItem.purposes.forEach((purpose: any) => {
          // Apply the migration logic for purposes here
          // Including the consideredAsOwnHouse mapping
          const consideredAsOwnHouseReasons = ["PROFESSIONAL", "SOCIAL", "CONSTRUCTION", "CONTRACTUAL"];
          if (purpose.not_own_house_reason) {
            purpose.considered_as_own_house = consideredAsOwnHouseReasons.includes(purpose.not_own_house_reason);
            delete purpose.not_own_house_reason;
          }
        });
      }
    });
  }
  return obj;
};
