import RegularCodeStrategy from "@/components/input/validation/regularCodeStrategy";
import RequiresNullReverseStrategy from "@/components/input/validation/requiresNullReverseStrategy";

type CodeInfo = import("@/components/input/codeInfo").default;
type CodeItem = import("@/components/input/codeItem").default;

export default class RequiresNullOrZeroStrategy extends RegularCodeStrategy {
  validate(primary: CodeItem, state: Map<string, CodeItem>): string | boolean {
    return primary.parsedValue === null ||
      this.necessaryCodes.some((x) => !state.has(x.code) || [0.0, null].includes(state.get(x.code)!.parsedValue))
      ? true
      : (this.i18n.t("input.validation.requiresNullOrZero", [
          this.necessaryCodes.map((x) => x.code).join(` ${this.i18n.t("input.validation.or")} `),
        ]) as string);
  }

  getReverseRule(primary: CodeInfo): RequiresNullReverseStrategy {
    return new RequiresNullReverseStrategy(this.i18n, primary, this.necessaryCodes);
  }
}
