
import { mdiArrowRightBoldBox, mdiCalendar, mdiAlert } from "@mdi/js";
import { Component, Vue, Prop } from "nuxt-property-decorator";

@Component
export default class SimulationInputYearView extends Vue {
  @Prop({ required: true, type: Number })
  readonly taxYear!: number;

  @Prop({ default: null, type: Number })
  readonly taxYearForComparing!: number | null;

  @Prop({ required: true, type: Array })
  readonly validTaxYears!: number[];

  @Prop({ required: true, type: Array })
  readonly incompleteYears!: number[];

  @Prop({ default: true, type: Boolean })
  readonly editable!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly showDiff!: boolean;

  editMode = false;

  mdiCalendar = mdiCalendar;

  mdiArrow = mdiArrowRightBoldBox;

  mdiAlert = mdiAlert;

  mounted() {
    // no clue what this is...
    this.$nextTick(() => {
      const searchBarYear = this.$el.querySelector(".search-bar-year input");
      if (searchBarYear) {
        searchBarYear.setAttribute("inputmode", "none");
      }
    });
  }

  get diff(): number {
    return this.taxYear - (this.taxYearForComparing ? this.taxYearForComparing : this.taxYear);
  }

  enableEditMode() {
    this.editMode = true;
    if (this.$refs.autocomplete) {
      this.$nextTick(() => {
        (this.$refs.autocomplete as any).focus({});
        (this.$refs.autocomplete as any).isMenuActive = true;
      });
    }
  }

  updateTaxYear(newYear: number) {
    this.$emit("update:taxYear", newYear);
    (this.$refs.autocomplete as any).isMenuActive = false;
    (this.$refs.autocomplete as any).blur();
  }
}
