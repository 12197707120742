var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.valueInstance)?_c('div',{staticClass:"titulist-3-type-with-checkbox"},[_vm._l((_vm.valueInstance.items),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('v-text-field',{ref:"textField",refInFor:true,staticClass:"item-field holder-name",style:(item.holder ? 'background-color: #FFFFEE' : ''),attrs:{"outlined":"","dense":"","disabled":_vm.disabled || (index > 0 && _vm.valueInstance.items[index - 1].toObject() === undefined),"readonly":!_vm.editable,"hide-details":_vm.valueInstance.items[index].isEmpty() ||
        !(
          (item.holder && _vm.codeItem.regularReverseErrors.length > 0) ||
          (!item.holder && _vm.codeItem.customErrors.length > 0)
        ),"error-messages":!_vm.valueInstance.items[index].isEmpty()
          ? item.holder
            ? _vm.codeItem.regularReverseErrors
            : _vm.codeItem.customErrors
          : [],"label":_vm.$t('input.complexTypes.foreignaccountTypeAndCheckBox.holder')},model:{value:(item.holder),callback:function ($$v) {_vm.$set(item, "holder", $$v)},expression:"item.holder"}}),_vm._v(" "),_c('v-autocomplete',{ref:`codeInputField_${_vm.codeItem.info.code}_country`,refInFor:true,staticClass:"holder-country",style:(item.country ? 'background-color: #FFFFEE' : ''),attrs:{"dense":"","outlined":"","readonly":!_vm.editable,"items":_vm.countryListRegular,"item-text":"name","auto-select-first":true,"disabled":_vm.disabled || (index > 0 && _vm.valueInstance.items[index - 1].toObject() === undefined),"return-object":true,"filter":_vm.filterCountry,"hide-details":_vm.valueInstance.items[index].isEmpty() ||
        !(
          (item.country && _vm.codeItem.regularReverseErrors.length > 0) ||
          (!item.country && _vm.codeItem.customErrors.length > 0)
        ),"error-messages":!_vm.valueInstance.items[index].isEmpty()
          ? item.country
            ? _vm.codeItem.regularReverseErrors
            : _vm.codeItem.customErrors
          : [],"label":_vm.$t('input.complexTypes.foreignaccountTypeAndCheckBox.country'),"clearable":"","allow-overflow":true},on:{"focus":function($event){return $event.target.select()}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("\n            "+_vm._s(data.item.name)+"\n          ")])],1)]}}],null,true),model:{value:(item.country),callback:function ($$v) {_vm.$set(item, "country", $$v)},expression:"item.country"}}),_vm._v(" "),_c('v-checkbox',{staticStyle:{"margin-top":"4px","margin-bottom":"4px"},attrs:{"dense":"","disabled":_vm.disabled || (index > 0 && _vm.valueInstance.items[index - 1].toObject() === undefined),"hide-details":"","label":_vm.$t('input.complexTypes.foreignaccountTypeAndCheckBox.reportedToNationalBank')},model:{value:(item.reportedToNationalBank),callback:function ($$v) {_vm.$set(item, "reportedToNationalBank", $$v)},expression:"item.reportedToNationalBank"}})],1)}),_vm._v(" "),_c('div',{class:_vm.canAddNewItem ? 'new-item-enabled' : 'new-item-disabled',on:{"click":_vm.addNewItem}},[_vm._v("\n    "+_vm._s(_vm.$t("input.complexTypes.collectionType.add_item"))+"\n  ")])],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }