/* eslint-disable class-methods-use-this */

import ValidationRule from "@/components/input/validation/validationRule";

type CodeItem = import("@/components/input/codeItem").default;

export default class ForeignMaxStrategy extends ValidationRule {
  validate(primary: CodeItem, state: Map<string, CodeItem>): string | boolean {
    const sum = primary.foreign
      .map((x) => x.parsedValue)
      .reduce((a, b) => (a !== null ? a : 0) + (b !== null ? b : 0), 0);
    return primary.parsedValue >= sum! ? true : (this.i18n.t("input.validation.foreignMax") as string);
  }
}
