import { render, staticRenderFns } from "./ErrorCollectionView.vue?vue&type=template&id=36bfe9f3&scoped=true&"
import script from "./ErrorCollectionView.vue?vue&type=script&lang=ts&"
export * from "./ErrorCollectionView.vue?vue&type=script&lang=ts&"
import style0 from "./ErrorCollectionView.vue?vue&type=style&index=0&id=36bfe9f3&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36bfe9f3",
  null
  
)

export default component.exports