import JuridType from "~/components/input/complexTypes/juridType";

export default class JuridTypeOld extends JuridType {
  constructor(
    nameFounder: string | undefined,
    name: string | undefined,
    constructionType: string | undefined,
    address1: string | undefined,
    address2: string | undefined,
    idNumber: string | undefined,
    managerName: string | undefined,
    managerAddress1: string | undefined,
    managerAddress2: string | undefined,
    isArt513Construction: boolean | undefined
  ) {
    super(nameFounder, name, constructionType, address1, idNumber, managerAddress1, isArt513Construction);
    this.address2 = address2;
    this.managerName = managerName;
    this.managerAddress2 = managerAddress2;
  }

  address2: string | undefined;
  managerName: string | undefined;
  managerAddress2: string | undefined;

  toString(i18n: any | undefined = undefined): string {
    if (!this.hasValue()) {
      return "";
    }

    const addressPart = `${this.address1 ?? ""}, ${this.address2 ?? ""}`;
    const managerPart = `${this.managerName ?? ""}, ${this.managerAddress1 ?? ""}, ${this.managerAddress2 ?? ""}`;

    const parts = [
      `${i18n.t("input.complexTypes.juridType.nameFounderShort")}: ${this.nameFounder ?? ""}`,
      `${i18n.t("input.complexTypes.juridType.nameConstructionShort")}: ${this.name ?? ""} ${
        this.constructionType ?? ""
      } (${this.idNumber ?? ""})`,
      `${i18n.t("input.complexTypes.juridType.addressConstructionShort")}: ${addressPart}`,
      `${i18n.t("input.complexTypes.juridType.nameManagerShort")}: ${managerPart}`,
    ];

    if (this.isArt513Construction) {
      parts.push(i18n.t("input.complexTypes.juridType.isArt513ConstructionShort"));
    }

    return parts.join("\n");
  }

  hasValue(): boolean {
    const hasVal = (item: string | undefined) => item !== undefined && item.trim().length > 0;
    return (
      hasVal(this.nameFounder) ||
      hasVal(this.name) ||
      hasVal(this.constructionType) ||
      hasVal(this.address1) ||
      hasVal(this.address2) ||
      hasVal(this.idNumber) ||
      hasVal(this.managerName) ||
      hasVal(this.managerAddress1) ||
      hasVal(this.managerAddress2)
    );
  }

  toObject(): Object | undefined {
    if (this.hasValue()) {
      const result = {
        nomPrenomFondateur: this.nameFounder ? this.nameFounder?.substring(0, 60) : "",
        nomComplet: this.name ? this.name?.substring(0, 60) : "",
        forme: this.constructionType ? this.constructionType?.substring(0, 60) : "",
        adresse1: this.address1 ? this.address1?.substring(0, 60) : "",
        adresse2: this.address2 ? this.address2?.substring(0, 60) : "",
        numIdent: this.idNumber ? this.idNumber?.substring(0, 60) : "",
        nomRespJurCons: this.managerName ? this.managerName?.substring(0, 60) : "",
        adresseRespJurCons1: this.managerAddress1 ? this.managerAddress1?.substring(0, 60) : "",
        adresseRespJurCons2: this.managerAddress2 ? this.managerAddress2?.substring(0, 60) : "",
        personneMoraleCheckBox: this.isArt513Construction ? "checked" : undefined,
      };
      if (result.personneMoraleCheckBox === undefined) {
        delete result.personneMoraleCheckBox;
      }
      return result;
    }
    return undefined;
  }

  static fromObject(obj: any): JuridTypeOld {
    return new JuridTypeOld(
      obj?.nomPrenomFondateur,
      obj?.nomComplet,
      obj?.forme,
      obj?.adresse1,
      obj?.adresse2,
      obj?.numIdent,
      obj?.nomRespJurCons,
      obj?.adresseRespJurCons1,
      obj?.adresseRespJurCons2,
      obj?.personneMoraleCheckBox
    );
  }
}
