enum RealEstatePurpose {
  OWN_HOUSE = "OWN_HOUSE",

  OWN_PROFESSION_DECLARANT = "OWN_PROFESSION_DECLARANT",
  OWN_PROFESSION_PARTNER = "OWN_PROFESSION_PARTNER",

  NOT_RENTED_OUT = "NOT_RENTED_OUT",

  RENTED_OUT_PRIVATE = "RENTED_OUT_PRIVATE",
  RENTED_OUT_PROFESSIONAL = "RENTED_OUT_PROFESSIONAL",
  LEASED_AGRICULTURAL = "LEASED_AGRICULTURAL",
  RENTED_OUT_ON_NON_PROFIT_BASIS = "RENTED_OUT_ON_NON_PROFIT_BASIS",
  EXCLUSION_PERIOD = "EXCLUSION_PERIOD",
}

export default RealEstatePurpose;
