import { VTextField } from "vuetify/lib";
import { Component, Model, Prop, Watch } from "nuxt-property-decorator";

const isNumberRegex = /^-?(?:\d*[.  ])*\d*[.,]?\d*$/;

function fromNumber(value: number | undefined | null, locale: string, localeStringOptions: object): string | null {
  if (value === null || value === undefined) {
    return null;
  }
  return value.toLocaleString(locale, localeStringOptions);
}

// duplicate in codeInfo.ts and foreignIncome.ts: ALMOST
function convertToFloat(input: string | null): number | undefined {
  if (input === null || input === undefined || input === "") {
    return undefined;
  }

  // Remove spaces as they could be used as thousand separators
  let sanitizedInput = input.replace(/\s/g, "");

  // Check if the input contains both ',' and '.'
  const containsComma = sanitizedInput.includes(",");
  const containsDot = sanitizedInput.includes(".");

  // Determine the last occurrence of comma and dot
  const lastCommaIndex = sanitizedInput.lastIndexOf(",");
  const lastDotIndex = sanitizedInput.lastIndexOf(".");

  if (containsComma && containsDot) {
    if (lastCommaIndex > lastDotIndex) {
      // Comma is the decimal separator, dot is the thousand separator
      sanitizedInput = sanitizedInput.replace(/\./g, "").replace(/,/g, ".");
    } else {
      // Dot is the decimal separator, comma is the thousand separator
      sanitizedInput = sanitizedInput.replace(/,/g, "");
    }
  } else if (containsComma) {
    // If it contains only commas, determine its role
    const parts = sanitizedInput.split(",");
    if (parts.every((part, index) => index === 0 || part.length === 3)) {
      // Comma is the thousand separator
      sanitizedInput = sanitizedInput.replace(/,/g, "");
    } else {
      // Comma is the decimal separator
      sanitizedInput = sanitizedInput.replace(/,/g, ".");
    }
  } else if (containsDot) {
    // If it contains only dots, determine its role
    const parts = sanitizedInput.split(".");
    // all parts except the first are 3 long
    if (parts.every((part, index) => index === 0 || part.length === 3)) {
      // Dot is the thousand separator
      sanitizedInput = sanitizedInput.replace(/\./g, "");
    }
  }

  const num = parseFloat(sanitizedInput);

  return isNaN(num) ? NaN : num;
}

@Component
export default class VNumberField extends VTextField {
  @Model("changeNumber", { type: Number })
  readonly number!: number;

  @Prop({ default: 1, type: Number })
  readonly multiplier!: number;

  @Prop({ default: () => ({ minimumFractionDigits: 2, maximumFractionDigits: 2 }), type: Object })
  readonly localeStringOptions!: object;

  get multipliedInputValue(): number | undefined {
    return this.number == null ? this.number : this.multiplier * this.number;
  }

  mounted() {
    // @ts-ignore
    this.lazyValue = fromNumber(this.multipliedInputValue, this.$i18n.locale, this.localeStringOptions);
  }

  @Watch("number")
  onNumberChange(value: number | undefined) {
    const lazyValueNumber =
      // @ts-ignore
      convertToFloat(this.lazyValue) !== undefined ? convertToFloat(this.lazyValue)! / this.multiplier : undefined;
    if (value !== lazyValueNumber && (value === undefined || !isNaN(value))) {
      // @ts-ignore
      this.lazyValue = fromNumber(this.multipliedInputValue, this.$i18n.locale, this.localeStringOptions);
    }
  }

  @Watch("lazyValue")
  onLazyValueChange(value: string | null) {
    this.$emit(
      "changeNumber",
      convertToFloat(value) !== undefined ? convertToFloat(value)! / this.multiplier : undefined
    );
  }

  @Watch("isFocused")
  onIsFocussedChange() {
    // @ts-ignore
    this.lazyValue = fromNumber(this.multipliedInputValue, this.$i18n.locale, this.localeStringOptions);
  }

  @Watch("value")
  onValueChange(value: string | null) {
    // @ts-ignore
    if (!this.isFocussed) {
      // @ts-ignore
      this.lazyValue = fromNumber(this.multipliedInputValue, this.$i18n.locale, this.localeStringOptions);
    }
  }
}
