enum LoanPurpose {
  BUILD_NEW = "BUILD_NEW",
  BUY_NEW = "BUY_NEW",
  BUY_OLD = "BUY_OLD",
  RENOVATION = "RENOVATION",
  RENEWING = "RENEWING",
  PAY_INHERITANCE_OR_GIFT_TAX = "PAY_INHERITANCE_OR_GIFT_TAX",
  OTHER = "OTHER",
}

export default LoanPurpose;
