export class AuthInfo {
  private static _apiKey = "";
  private static _caseIdEncrypted: string | undefined = undefined;
  private static _token: string | undefined = undefined;
  private static _awaitToken = false;

  static getApiKey(): string {
    return this._apiKey;
  }

  static setApiKey(apiKey: string):
    void {
    this._apiKey = apiKey;
  }

  static setToken(token: string): void {
    this._token = token;
    this._awaitToken = false;
  }

  static startAwaitToken(): void {
    this._awaitToken = true;
  }

  static getCaseIdEncrypted(): string | undefined {
    return this._caseIdEncrypted;
  }

  static setCaseIdEncrypted(caseIdEncrypted: string | undefined): void {
    this._caseIdEncrypted = caseIdEncrypted;
  }

  static async getAuthHeaders(): Promise<{ [key: string]: string }> {
    const headers: { [key: string]: string } = {
      "x-api-key": AuthInfo.getApiKey(),
    };
    if (AuthInfo.getCaseIdEncrypted()) {
      headers["X-Case-Id-Encrypted"] = AuthInfo.getCaseIdEncrypted() as string;
    }

    const token = await AuthInfo.getToken();
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    return headers;
  }

  static async getToken(maxWait = 5000): Promise<string | undefined> {
    if (this._token || !this._awaitToken) {
      return this._token;
    }

    const startTime = Date.now();
    while (!this._token && Date.now() - startTime < maxWait) {
      await new Promise((resolve) => setTimeout(resolve, 50));
    }

    if (this._token) {
      return this._token;
    } else {
      throw new Error(`Timeout: Token was not set within ${maxWait}ms`);
    }
  }

}
