
import Vue from "vue";
import { Component, Prop, Watch } from "nuxt-property-decorator";
import CodeItem from "@/components/input/codeItem";

@Component
export default class TextTypeView extends Vue {
  @Prop({ required: true, type: Object })
  codeItem!: CodeItem;

  @Prop({ default: false, type: Boolean })
  readonly disabled!: boolean;

  toString(): string {
    return this.codeItem.value;
  }

  capitalize(str: string): string {
    return str.replace(/^\w/, (c) => c.toUpperCase());
  }

  @Watch("codeItem.value")
  onStringChange(newString: string | null) {
    this.$emit("update", newString);
  }

  focus(options: object) {
    ((this.$refs!.textarea! as any).$refs.input as HTMLElement).focus(options);
  }
}
