
import { Component, Vue, Prop } from "nuxt-property-decorator";
import deburr from "lodash.deburr";
import { mdiArrowRightBoldBox, mdiCity } from "@mdi/js";
import City from "@/components/input/city";

@Component
export default class SimulationInputCityView extends Vue {
  @Prop({ required: true, type: Number })
  readonly taxYear!: number;

  @Prop({ type: Object })
  readonly city!: City | null;

  @Prop({ default: undefined, type: Object })
  readonly cityForComparing!: City | null | undefined;

  @Prop({ type: Map })
  readonly cities!: Map<string, City> | null;

  @Prop({ required: true, type: Boolean })
  readonly hasError!: boolean;

  @Prop({ default: true, type: Boolean })
  readonly editable!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly showDiff!: boolean;

  editMode = false;

  mdiCity = mdiCity;

  mdiArrow = mdiArrowRightBoldBox;

  get diff(): number {
    if (this.city === null && this.cityForComparing === null) {
      return 0;
    } else if (this.city === null && this.cityForComparing !== null) {
      return -1;
    } else if (this.city !== null && this.cityForComparing === null) {
      return 1;
    } else {
      return parseInt(this.city!.postcode, 10) - parseInt(this.cityForComparing!.postcode, 10);
    }
  }

  enableEditMode() {
    this.editMode = true;
    if (this.$refs.autocomplete) {
      this.$nextTick(() => {
        (this.$refs.autocomplete as any).focus({});
        (this.$refs.autocomplete as any).isMenuActive = true;
      });
    }
  }

  get cityList(): City[] | null {
    return this.cities ? Array.from(this.cities.values()) : null;
  }

  filterCity: Object = (_item: City, queryText: string, itemText: string) =>
    deburr(itemText.toLocaleLowerCase()).includes(deburr(queryText.toLocaleLowerCase()));

  updateCity(city: City) {
    this.$emit("update:city", city);
    (this.$refs.autocomplete as any).isMenuActive = false;
    (this.$refs.autocomplete as any).blur();
  }
}
