
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";
import deburr from "lodash.deburr";
import { mdiCity, mdiCalendar } from "@mdi/js";
import SimulationInput from "@/components/input/simulationInput";
import FileParser from "@/components/input/fileParser";
import City from "@/components/input/city";
import StaticInfoContainer from "@/components/staticInfoContainer";
import Simulation from "@/components/simulation";

@Component({
  components: {},
})
export default class NewInputDialog extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly active!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly comparison!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly hasActiveSimulation!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly showKluwerImport!: boolean;

  readonly defaultYear: number = SimulationInput.validYears[1];

  readonly validYears: number[] = SimulationInput.validYears;

  newInputOption = "readFromDisk";

  newInputName: string | null = null;

  onlyImportWizards: boolean = false;

  staticInfo: any | null = null;

  async mounted() {
    this.prepareInitial();
    StaticInfoContainer.getStaticInfo(this.defaultYear, this.$i18n.locale, this.$i18n).then((info: any) => {
      this.staticInfo = info;
    });
  }

  capitalize(str: string): string {
    return str.replace(/^\w/, (c) => c.toUpperCase());
  }

  save(): void {
    if (this.saveable) {
      if (this.newInputOption === "empty") {
        const name = [null, ""].includes(this.newInputName) ? "new" : this.newInputName!;
        const { taxCalcId } = this.staticInfo ? this.staticInfo.regions.get(this.selectedCity!.region)! : 1;
        const codes = {
          1090: parseInt(taxCalcId, 10),
          1061: this.selectedCity!.rate,
          // @ts-ignore
          [this.maritalStatusMappedCodes[this.selectedMaritalStatus]]: 1,
        };
        this.addState(
          name,
          Simulation.fromInput(
            new SimulationInput(
              this.selectedTaxYear,
              this.selectedCity!.postcode,
              codes,
              [],
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              [],
              null
            )
          )
        );
      } else if (this.newInputOption === "readFromDisk") {
        if (this.simulationsReadFromDisk.size > 0) {
          this.addState("new", this.simulationsReadFromDisk.get("new"));
        }
      } else if (this.newInputOption === "readFromDiskKluwer") {
        if (this.simulationsReadFromDiskKluwer.size > 0) {
          if (this.onlyImportWizards) {
            this.addWizards("new", this.simulationsReadFromDiskKluwer.get("new"));
          } else {
            this.addState("new", this.simulationsReadFromDiskKluwer.get("new"));
          }
        }
      }
      this.close();
    }
  }

  get saveable(): boolean {
    if (this.newInputOption === "empty") {
      return this.selectedCity !== null;
    } else if (this.newInputOption === "readFromDisk") {
      return this.simulationsReadFromDisk.size > 0;
    } else if (this.newInputOption === "readFromDiskKluwer") {
      if (this.simulationsReadFromDiskKluwer.size > 0 && this.onlyImportWizards) {
        const simulation = this.simulationsReadFromDiskKluwer.get("new");
        return !!(simulation && simulation.input && (simulation.input.realEstate || simulation.input.loans));
      }
      return this.simulationsReadFromDiskKluwer.size > 0;
    } else {
      throw new ReferenceError(`Option ${this.newInputOption} not implemented`);
    }
  }

  @Watch("newInputOption")
  onNewInputOptionChange(newOption: string) {
    if (newOption === "empty") {
      this.inputFiles = [];
      this.simulationsReadFromDisk = new Map();
      this.simulationsReadFromDiskKluwer = new Map();
    } else if (newOption === "readFromDisk") {
      this.selectedCity = null;
      this.selectedMaritalStatus = "single_or_divorced";
      this.selectedTaxYear = this.defaultYear;
    } else if (newOption === "readFromDiskKluwer") {
      this.selectedCity = null;
      this.selectedMaritalStatus = "single_or_divorced";
      this.selectedTaxYear = this.defaultYear;
    } else {
      throw new ReferenceError(`Option ${this.newInputOption} not implemented`);
    }
  }

  addState(_id: string, simulation: Simulation | undefined): void {
    if (simulation) {
      this.$emit("add-simulation", simulation);
    }
  }

  addWizards(_id: string, simulation: Simulation | undefined): void {
    if (simulation && simulation.input && (simulation.input.realEstate || simulation.input.loans)) {
      this.$emit("add-wizards", simulation);
    }
  }

  @Watch("active")
  async onActiveChange() {
    if (!this.active) {
      this.close();
    } else {
      this.prepareInitial();
    }
  }

  prepareInitial() {
    this.newInputOption = "readFromDisk";
    this.newInputName = null;
    this.inputFiles = [];
    this.fileParser = new FileParser(this.$i18n, this.validYears);
    this.simulationsReadFromDisk = new Map();
    this.inputFilesKluwer = [];
    this.fileParserKluwer = new FileParser(this.$i18n, this.validYears);
    this.simulationsReadFromDiskKluwer = new Map();
    this.selectedCity = null;
    this.selectedMaritalStatus = this.selectedMaritalStatus = "single_or_divorced";
    this.selectedTaxYear = this.defaultYear;
    this.onlyImportWizards = false;
    this.problemWithKluwerImport = false;
  }

  close(): void {
    this.$emit("close");
    // avoid pressing enter again on uploading screen (after instant prepareInitial and focus on upload field) when pressing enter elsewhere
    setTimeout(() => {
      this.prepareInitial();
    }, 500);
  }

  /*
   * NEW EMPTY RETURN
   */

  selectedTaxYear: number = this.defaultYear;

  selectedCity: City | null = null;

  selectedMaritalStatus = "single_or_divorced";

  maritalStatusMappedCodes: Object = {
    single_or_divorced: "1001",
    married: "1002",
    widowed: "1010",
    actually_divorced: "1018",
  };

  mdiCity = mdiCity;

  mdiCalendar = mdiCalendar;

  filterCity: Object = (_item: City, queryText: string, itemText: string) =>
    deburr(itemText.toLocaleLowerCase()).includes(deburr(queryText.toLocaleLowerCase()));

  /*
   * FILE PARSING
   */

  fileParser: FileParser = new FileParser(this.$i18n, this.validYears);

  inputFiles: any[] | null = [];

  simulationsReadFromDisk: Map<string, Simulation> = new Map();

  fileParserKluwer: FileParser = new FileParser(this.$i18n, this.validYears);

  inputFilesKluwer: any[] | null = [];

  simulationsReadFromDiskKluwer: Map<string, Simulation> = new Map();

  problemWithKluwerImport: boolean = false;

  fileSelect(file: any) {
    if (file) {
      if (file.name && file.name.toLowerCase().endsWith(".pit")) {
        this.fileParser.parseFileLocally(file);
      } else {
        this.fileParser.parseFileRemotely(file);
      }
    }
  }

  fileSelectKluwer(file: any) {
    if (file) {
      this.fileParserKluwer.parseFileKluwer(file);
    }
  }

  @Watch("fileParser.parseInputWarning")
  onParseInputWarning() {
    window.setTimeout(() => {
      if (this.fileParser.parseInputWarning && this.fileParser.parseInputWarning.length > 0) {
        this.fileParser.parseInputWarning = null;
        this.inputFiles = [];
      }
    }, 10000);
  }

  @Watch("fileParser.parseInputError")
  onParseInputError() {
    window.setTimeout(() => {
      if (this.fileParser.parseInputError && this.fileParser.parseInputError.length > 0) {
        this.fileParser.parseInputError = null;
        this.inputFiles = [];
      }
    }, 10000);
  }

  @Watch("fileParserKluwer.parseInputError")
  onParseInputErrorKluwer() {
    window.setTimeout(() => {
      if (this.fileParserKluwer.parseInputError && this.fileParserKluwer.parseInputError.length > 0) {
        this.fileParserKluwer.parseInputError = null;
        this.inputFilesKluwer = [];
      }
    }, 10000);
  }

  @Watch("fileParser.simulation")
  onParsedSimulationInputChange(simulation: Simulation | null) {
    if (simulation) {
      this.simulationsReadFromDisk = new Map([["new", simulation]]);
    }
  }

  @Watch("fileParserKluwer.simulation")
  onParsedSimulationInputKluwerChange(simulation: Simulation | null) {
    if (simulation) {
      this.simulationsReadFromDiskKluwer = new Map([["new", simulation]]);
    }
  }

  get fileSuccessfullyRead(): boolean {
    return this.simulationsReadFromDisk.size > 0;
  }

  get fileSuccessfullyReadKluwer(): boolean {
    return this.simulationsReadFromDiskKluwer.size > 0;
  }

  updateSelectedTaxYear(year: number) {
    StaticInfoContainer.getStaticInfo(year, this.$i18n.locale, this.$i18n).then((info: any) => {
      this.staticInfo = info;
    });
  }
}
