import SectionInfo from "@/components/input/sectionInfo";
import ValidationRule from "@/components/input/validation/validationRule";
import ValidationRuleFactory from "@/components/input/validation/validationRuleFactory";
import RegularCodeStrategy from "@/components/input/validation/regularCodeStrategy";
import ReverseCodeStrategy from "@/components/input/validation/reverseCodeStrategy";
import Contracts2Type from "@/components/input/complexTypes/contracts2Type";
import Country from "@/components/input/country";
import Titulist3TypeWithCheckBox from "@/components/input/complexTypes/titulist3TypeWithCheckBox";
import TitulistInsur3Type from "@/components/input/complexTypes/titulistInsur3Type";
import JuridiqueType from "@/components/input/complexTypes/juridiqueType";
import BenefRenteAlimType from "@/components/input/complexTypes/benefRenteAlimType";
import LooseType from "@/components/input/complexTypes/looseType";
import Code10Type from "@/components/input/complexTypes/code10Type";
import Naturecode10Type from "@/components/input/complexTypes/naturecode10Type";
import RequiresStrategy from "@/components/input/validation/requiresStrategy";
import RequiresReverseStrategy from "@/components/input/validation/requiresReverseStrategy";
import BenefRenteAlimList from "~/components/input/complexTypes/benefRenteAlimList";
import DebAlimList from "~/components/input/complexTypes/debAlimList";
import JuridiqueTypeOld from "~/components/input/complexTypes/juridiqueTypeOld";
import ElectricChargingStationType from "~/components/input/complexTypes/electricChargingStationType";

type CodeItem = import("@/components/input/codeItem").default;

export default class CodeInfo {
  constructor(
    i18n: any,
    taxYear: number,
    language: string,
    region: string,
    code: string,
    section: SectionInfo,
    index: number,
    description: string[],
    label: string,
    type: string,
    foreignPossible: boolean,
    partnerPossible: boolean,
    notImplemented: boolean,
    invisible: boolean,
    removable: boolean,
    foreignTaxed: boolean,
    sumFieldTotal: boolean,
    addendum: string,
    rulesList: any[],
    wizards: string[]
  ) {
    this.i18n = i18n;
    this.validationRuleFactory = new ValidationRuleFactory(i18n);
    this.taxYear = taxYear;
    this.language = language;
    this.region = region;

    this.code = code;
    this.section = section;
    this.index = index;
    this.description = description;
    this.label = label;
    this.type = type;
    this.foreignPossible = foreignPossible;
    this.partnerPossible = partnerPossible;
    this.notImplemented = notImplemented;
    this.invisible = invisible;
    this.removable = removable;
    this.foreignTaxed = foreignTaxed;
    this.sumFieldTotal = sumFieldTotal;
    this.addendum = addendum;
    this.rulesList = rulesList;
    this.wizards = wizards;

    this.sumTermCodesInfo = [];

    this.rules = [];
    this.rulesReverse = [];
    this.customRules = [];
  }

  initRules(codeInfo: Map<string, CodeInfo>, countries?: Map<string, Country>): void {
    this.addCustomRulesForComplexFields(countries);
    this.rules = this.rulesList
      .filter((x) => Object.keys(x)[0] !== "max_value_30" && Object.keys(x)[0] !== "front_end_static_info") // Exclude a specific rule by name
      .map((x) => {
        const name: string = Object.keys(x)[0];
        const params: any = x[name];
        return this.validationRuleFactory.createRegularRule(name, params, codeInfo, this.isPartner);
      });
    this.rules.forEach((x) => {
      if (x instanceof RegularCodeStrategy) {
        const reverseRule = x.getReverseRule(this);
        x.necessaryCodes.forEach((info) => info.rulesReverse.push(reverseRule));
      }
    });
    if (this.foreignPossible) {
      this.rules.push(this.validationRuleFactory.createRegularRule("foreignmax", [], codeInfo, this.isPartner));
      this.rules.push(this.validationRuleFactory.createRegularRule("foreigncomplete", [], codeInfo, this.isPartner));
    }
  }

  readonly i18n: any;

  readonly validationRuleFactory: ValidationRuleFactory;

  readonly taxYear!: number;

  readonly language!: string;

  readonly region!: string;

  code!: string;

  readonly section!: SectionInfo;

  readonly index!: number;

  readonly description!: string[];

  readonly label!: string;

  readonly type!: string;

  readonly foreignPossible: boolean;

  readonly sumFieldTotal: boolean;

  readonly partnerPossible: boolean;

  readonly notImplemented: boolean;

  readonly invisible: boolean;

  readonly rulesList!: any[];

  readonly wizards!: string[];

  readonly removable!: boolean;

  readonly foreignTaxed!: boolean;

  readonly addendum!: string;

  sumTermCodesInfo: CodeInfo[];

  rules!: ValidationRule[];

  rulesReverse!: ReverseCodeStrategy[];

  customRules!: Function[];

  get descriptionWithoutAnchor(): string[] {
    return this.description.map((line) => line.replace(/^\$\$\$anchor[a-zA-Z0-9-_]*\$\$\$/, ""));
  }

  get isComplexCode(): boolean {
    return !this.code.match(/^\d{4}(_[0-9]+)?$/);
  }

  get isSumTerm(): boolean {
    return !!this.code.match(/^\d{4}_[0-9]+$/);
  }

  get sumTermIndex(): number | null {
    if (!this.isSumTerm) {
      return null;
    }
    return parseInt(this.code.split("_")[1], 10);
  }

  get isPartnerOrDoesNotHavePartnerCode(): boolean {
    return this.isPartner || !this.partnerPossible;
  }

  get isNotPartnerOrDoesNotHavePartnerCode(): boolean {
    return !this.isPartner || !this.partnerPossible;
  }

  get isPartner(): boolean {
    if (this.code && !this.isComplexCode) {
      const baseCodeInt = parseInt(this.code.substring(0, 4));
      return (baseCodeInt >= 2000 && baseCodeInt <= 2999) || (baseCodeInt >= 4000 && baseCodeInt <= 4999);
    }
    return !!this.code.match(/((B(-\d)?)|partner)$/);
  }

  get partnerCode(): string {
    if (!this.partnerPossible) {
      return this.code;
    }
    if (this.isPartner) {
      return this.regularCode;
    } else {
      if (this.code && this.isSumTerm) {
        const baseCodeInt = parseInt(this.code.substring(0, 4), 10);
        return `${baseCodeInt + 1000}${this.code.substring(4)}`;
      }
      if (this.code && !this.isComplexCode) {
        return (parseInt(this.code, 10) + 1000).toString();
      }
      if (this.code && !!this.code.match(/A(-\d)?$/)) {
        return this.code.replace(/(A)(-\d)?$/, "B$2");
      }
      return this.code + "partner";
    }
  }

  get parentSumCode(): string | null {
    return this.isSumTerm || this.sumFieldTotal ? this.code.substring(0, 4) : null;
  }

  get parentRegularSumCode(): string | null {
    const parentSumCode = this.parentSumCode;
    if (parentSumCode) {
      const codeNumber = parseInt(parentSumCode, 10);
      return (codeNumber >= 2000 && codeNumber < 3000) || codeNumber >= 4000
        ? (codeNumber - 1000).toString()
        : codeNumber.toString();
    }
    return null;
  }

  // returns code that corresponds with the first declarant
  get regularCode(): string {
    if (!this.isPartner) {
      return this.code;
    }
    if (this.code && this.isSumTerm) {
      const baseCodeInt = parseInt(this.code.substring(0, 4), 10);
      return `${baseCodeInt - 1000}${this.code.substring(4)}`;
    }
    if (this.code && !this.isComplexCode) {
      return (parseInt(this.code, 10) - 1000).toString();
    }
    if (this.code && !!this.code.match(/B(-\d)?$/)) {
      return this.code.replace(/(B)(-\d)?$/, "A$2");
    }
    if (this.code.endsWith("partner")) {
      return this.code.substring(0, this.code.length - 7);
    }
    throw new Error(`code ${this.code} is not a partner code`);
  }

  get searchBarString() {
    if (!this.section) {
      console.log(`code has no section defined!: ${this.code}`);
    }
    return `${this.code}: | ${this.section.label} | ${this.label}
    | ${this.section.description} | ${this.description}`;
  }

  // duplicate in codeInfo.ts and foreignIncome.ts
  convertToFloat(input: string | null): number | undefined {
    if (input === null || input === undefined || input === "") {
      return undefined;
    }

    // Remove spaces as they could be used as thousand separators
    let sanitizedInput = input.replace(/\s/g, "");

    // Check if the input contains both ',' and '.'
    const containsComma = sanitizedInput.includes(",");
    const containsDot = sanitizedInput.includes(".");

    // Determine the last occurrence of comma and dot
    const lastCommaIndex = sanitizedInput.lastIndexOf(",");
    const lastDotIndex = sanitizedInput.lastIndexOf(".");

    if (containsComma && containsDot) {
      if (lastCommaIndex > lastDotIndex) {
        // Comma is the decimal separator, dot is the thousand separator
        sanitizedInput = sanitizedInput.replace(/\./g, "").replace(/,/g, ".");
      } else {
        // Dot is the decimal separator, comma is the thousand separator
        sanitizedInput = sanitizedInput.replace(/,/g, "");
      }
    } else if (containsComma) {
      // If it contains only commas, determine its role
      const parts = sanitizedInput.split(",");
      if (parts.length > 2 || parts[1].length === 3) {
        // Comma is the thousand separator
        sanitizedInput = sanitizedInput.replace(/,/g, "");
      } else {
        // Comma is the decimal separator
        sanitizedInput = sanitizedInput.replace(/,/g, ".");
      }
    } else if (containsDot) {
      // If it contains only dots, determine its role
      const parts = sanitizedInput.split(".");
      if (parts.length > 2 || parts[1].length === 3) {
        // Dot is the thousand separator
        sanitizedInput = sanitizedInput.replace(/\./g, "");
      }
    }

    return parseFloat(sanitizedInput);
  }

  parse(value: any, roundingDecimals: number = 2): any {
    switch (this.type) {
      case "string":
        return value;
      case "bool":
        return [true, 1].includes(value as number) ? 1 : null;
      case "date":
        return value;
      case "float":
        value = typeof value === "string" ? this.convertToFloat(value) : value;
        return Math.round(value * Math.pow(10, roundingDecimals)) / Math.pow(10, roundingDecimals);
      case "int":
        return typeof value === "string" ? parseInt(value, 10) : value;
      case "any":
        return value;
      default:
        return value;
    }
  }

  format(value: any, countries?: Map<string, Country>) {
    switch (this.type) {
      case "string":
        return value;
      case "bool":
        return value !== 0;
      case "date":
        return value;
      case "float":
        return parseFloat(value as string).toLocaleString(...this.formatArgs);
      case "int":
        return parseInt(value as string, 10);
      case "any":
        return value;
      case "contracts2Type":
        const obj = Contracts2Type.fromObject(value);
        return obj ? obj.toString() : "";
      case "titulist3TypeWithCheckBox":
        const obj2 = Titulist3TypeWithCheckBox.fromObject(value, countries);
        return obj2 ? obj2.toString() : "";
      case "titulistInsur3Type":
        const obj3 = TitulistInsur3Type.fromObject(value, countries);
        return obj3 ? obj3.toString() : "";
      case "juridiqueType":
        const obj4 = this.taxYear < 2023 ? JuridiqueTypeOld.fromObject(value) : JuridiqueType.fromObject(value);
        return obj4 ? obj4.toString(this.i18n) : "";
      case "benefRenteAlimType":
        const obj5 = BenefRenteAlimType.fromObject(value, countries);
        return obj5 ? obj5.toString() : "";
      case "looseType":
        const obj6 = LooseType.fromObject(value);
        return obj6 ? obj6.toString() : "";
      case "code10Type":
        const obj7 = Code10Type.fromObject(value);
        return obj7 ? obj7.toString(this.i18n) : "";
      case "naturecode10Type":
        const obj8 = Naturecode10Type.fromObject(value);
        return obj8 ? obj8.toString(this.i18n) : "";
      case "benefRenteAlimList":
        const obj9 = BenefRenteAlimList.fromObject(value, countries);
        return obj9 ? obj9.toString() : "";
      case "debAlimList":
        const obj10 = DebAlimList.fromObject(value);
        return obj10 ? obj10.toString() : "";
      case "electricChargingStationType":
        const obj11 = ElectricChargingStationType.fromObject(value);
        return obj11 ? obj11.toString() : "";
      default:
        return value;
    }
  }

  addCustomRulesForComplexFields(countries?: Map<string, Country>) {
    if (this.type == "benefRenteAlimType") {
      this.customRules.push((parsedValue: Object | undefined) => {
        if (parsedValue === undefined) {
          return true;
        }
        const errors = BenefRenteAlimType.fromObject(parsedValue, countries).customErrors(this.i18n);
        return errors.length > 0 ? errors.join(", ") : true;
      });
    }
    if (this.type == "benefRenteAlimList") {
      this.customRules.push((parsedValue: Object | undefined) => {
        if (parsedValue === undefined) {
          return true;
        }
        const errors = BenefRenteAlimList.fromObject(parsedValue, countries).customErrors(this.i18n);
        return errors.length > 0 ? errors.join(", ") : true;
      });
    }
    if (this.type == "titulist3TypeWithCheckBox") {
      this.customRules.push((parsedValue: Object | undefined) => {
        if (parsedValue === undefined) {
          return true;
        }
        const errors = Titulist3TypeWithCheckBox.fromObject(parsedValue, countries).customErrors(this.i18n);
        return errors.length > 0 ? errors.join(", ") : true;
      });
    }
    if (this.type == "titulistInsur3Type") {
      this.customRules.push((parsedValue: Object | undefined) => {
        if (parsedValue === undefined) {
          return true;
        }
        const errors = TitulistInsur3Type.fromObject(parsedValue, countries).customErrors(this.i18n);
        return errors.length > 0 ? errors.join(", ") : true;
      });
    }
    if (this.type == "electricChargingStationType") {
      this.customRules.push((parsedValue: Object | undefined) => {
        if (parsedValue === undefined) {
          return true;
        }
        const errors = ElectricChargingStationType.fromObject(parsedValue).customErrors(this.i18n);
        return errors.length > 0 ? errors.join(", ") : true;
      });
    }
  }

  validateType(codeItem: CodeItem): string[] {
    const errors: string[] = [];
    if (["", null, undefined].includes(codeItem.value)) return errors;
    switch (this.type) {
      case "string": {
        if (typeof codeItem.value !== "string") {
          errors.push(this.i18n.t("input.codeInfo.validation_error_string") as string);
        }
        break;
      }
      case "bool":
        if (![true, false, 1, 0, null].includes(codeItem.value)) {
          errors.push(this.i18n.t("input.codeInfo.validation_error_boolean") as string);
        }
        break;
      case "date": {
        const validDateRegex =
          /^(19|20|21)\d\d[- \/.](0?[1-9]|1[012])[- \/.](0?[1-9]|[12][0-9]|3[01])|(0?[1-9]|[12][0-9]|3[01])[- \/.](0?[1-9]|1[012])[- \/.](19|20|21)\d\d$/;
        if (typeof codeItem.value !== "string" || !validDateRegex.test(codeItem.value)) {
          errors.push(this.i18n.t("input.codeInfo.validation_error_date") as string);
        }
        break;
      }
      case "float": {
        if (
          typeof codeItem.value !== "number" &&
          typeof codeItem.value === "string" &&
          !/^\d{1,3}(?:[ .,]?\d{3})*(?:[.,]\d+)?$/.test(codeItem.value)
        ) {
          errors.push(this.i18n.t("input.codeInfo.validation_error_float") as string);
        }
        if (codeItem.foreign.length > 0) {
          if (
            codeItem.foreign.some(
              (foreignIncome) =>
                ![null, ""].includes(foreignIncome.value) &&
                typeof foreignIncome.value !== "number" &&
                typeof foreignIncome.value === "string" &&
                !/^\d{1,3}(?:[ .,]?\d{3})*(?:[.,]\d+)?$/.test(foreignIncome.value)
            )
          ) {
            errors.push(this.i18n.t("input.codeInfo.validation_error_foreign_float") as string);
          }
        }
        break;
      }
      case "int": {
        if (typeof codeItem.value !== "number" && typeof codeItem.value === "string" && !/^\d+$/.test(codeItem.value)) {
          errors.push(this.i18n.t("input.codeInfo.validation_error_integer") as string);
        }
        if (codeItem.foreign.length > 0) {
          if (
            codeItem.foreign.some(
              (foreignIncome) =>
                ![null, ""].includes(foreignIncome.value) &&
                typeof foreignIncome !== "number" &&
                typeof foreignIncome === "string" &&
                !/^\d+$/.test(foreignIncome)
            )
          ) {
            errors.push(this.i18n.t("input.codeInfo.validation_error_foreign_integer") as string);
          }
        }
        break;
      }
      case "any":
        break;
      // default: {
      //   errors.push(this.i18n.t('input.codeInfo.validation_error_no_typeinfo') as string);
      // }
      default:
        break;
    }
    return errors;
  }

  validateRegularRules(
    codeItem: CodeItem,
    codeItems: Map<string, any>,
    validateRequiredAdditionalInfo: boolean
  ): string[] {
    const errors: string[] = [];
    this.rules.forEach((rule: ValidationRule) => {
      let validationResult;
      if (
        !validateRequiredAdditionalInfo &&
        rule instanceof RequiresStrategy &&
        rule.necessaryCodes.every((x) => x.isComplexCode)
      ) {
        validationResult = true;
      } else {
        validationResult = rule.validate(codeItem, codeItems);
      }
      if (validationResult !== true) errors.push(validationResult as string);
    });
    return errors;
  }

  validateCustomRules(codeItem: CodeItem, codeItems: Map<string, any>): string[] {
    const errors: string[] = [];
    this.customRules.forEach((fn: any) => {
      const validationResult = fn(codeItem.parsedValue, codeItems);
      if (validationResult !== true) errors.push(validationResult as string);
    });
    return errors;
  }

  validateReverseRules(
    codeItem: CodeItem,
    codeItems: Map<string, any>,
    validateRequiredAdditionalInfo: boolean
  ): string[] {
    const errors: string[] = [];
    this.rulesReverse.forEach((rule: ValidationRule) => {
      let validationResult;
      if (!validateRequiredAdditionalInfo && rule instanceof RequiresReverseStrategy && this.isComplexCode) {
        validationResult = true;
      } else {
        validationResult = rule.validate(codeItem, codeItems);
      }
      if (validationResult !== true) errors.push(validationResult as string);
    });
    return errors;
  }

  references(onlyOwnSection: boolean = true, includeGlobal: boolean = false): CodeInfo[] {
    const referencedCodes: CodeInfo[] = [];
    this.rules.forEach((x) => {
      if (x instanceof RegularCodeStrategy) {
        referencedCodes.push(...x.necessaryCodes.filter((y: CodeInfo) => !referencedCodes.includes(y)));
      }
    });
    this.rulesReverse.forEach((x) => {
      if ((!includeGlobal && x.inverseCode.code !== "global") || includeGlobal) {
        if (!onlyOwnSection || this.section === x.inverseCode.section) {
          if (!referencedCodes.includes(x.inverseCode)) referencedCodes.push(x.inverseCode);
          referencedCodes.push(...x.necessaryCodes.filter((y: CodeInfo) => !referencedCodes.includes(y)));
        }
      }
    });
    return referencedCodes;
  }

  referencesDeepWithItems(
    items: Map<string, CodeItem>,
    deep: number = 1000,
    onlyOwnSection: boolean = true,
    processed: CodeInfo[] = [],
    includeGlobal: boolean = false
  ): CodeInfo[] {
    const toProcess = this.references(onlyOwnSection, includeGlobal).filter(
      (x) => !processed.includes(x) && (!onlyOwnSection || x.section === this.section)
    );

    if (
      toProcess.length === 0 ||
      (deep <= 0 && !(items.has(this.code) && items.get(this.code)!.parsedValue !== null))
    ) {
      return processed;
    }
    return toProcess.flatMap((x) =>
      x.referencesDeepWithItems(items, deep - 1, onlyOwnSection, processed.concat(toProcess), includeGlobal)
    );
  }

  relevantSumTermsCodeInfo(codeItems: Map<string, CodeItem>, codeInfo: Map<string, CodeInfo>): CodeInfo[] {
    const sumTermsCodeInfo = new Set(this.sumTermCodesInfo);
    const sortedSumTermItems = Array.from(codeItems.entries())
      .filter(
        ([, item]) => item.value !== null && item.value !== "" && sumTermsCodeInfo.has(item.info) && item.info.isSumTerm
      )
      .map(([code, item]) => item.info.sumTermIndex!)
      .sort();
    const latestIndex = sortedSumTermItems.length > 0 ? sortedSumTermItems[sortedSumTermItems.length - 1] : 0;
    return this.sumTermCodesInfo
      .filter((info) => info.sumTermIndex !== null && info.sumTermIndex <= latestIndex + 1)
      .flatMap((info: CodeInfo) => [codeInfo.get(info.partnerCode)!].concat(info));
  }

  get formatArgs(): any {
    return [this.i18n.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }];
  }

  get foreignHasNature(): boolean {
    return this.foreignPossible && this.section.id === "7";
  }
}
