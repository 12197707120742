import Country from "@/components/input/country";

export default class ForeignInsuranceType {
  constructor(holder: string | undefined, country: Country | undefined) {
    this.holder = holder;
    this.country = country;
  }

  holder: string | undefined;

  country: Country | undefined;

  isFilled() {
    return this.holder != undefined && this.country != undefined;
  }

  isEmpty() {
    return this.holder == undefined && this.country == undefined;
  }

  toString(): string {
    return this.holder || this.country ? `${this.holder} (${this.country?.id})` : "";
  }

  customErrors(i18n: any): string[] {
    const customErrors = [];

    if (this.holder == undefined && this.country != undefined) {
      customErrors.push(
        `${i18n.t("input.complexTypes.foreigninsuranceType.holder").toString().toLowerCase()} ${i18n.t(
          "input.validation.required"
        )}`
      );
    }

    if (this.country == undefined && this.holder != undefined) {
      customErrors.push(
        `${i18n.t("input.complexTypes.foreigninsuranceType.country").toString().toLowerCase()} ${i18n.t(
          "input.validation.required"
        )}`
      );
    }

    return customErrors;
  }

  toObject(): Object | undefined {
    if (this.holder || this.country) {
      return {
        titulaire: this.holder ? this.holder?.substring(0, 60) : null,
        pays: this.country ? this.country.id : null,
      };
    }
    return undefined;
  }

  static fromObject(obj: any, countries?: Map<string, Country>): ForeignInsuranceType {
    const country = obj?.pays && countries ? countries.get(obj.pays) : undefined;
    return new ForeignInsuranceType(obj?.titulaire, country);
  }
}
