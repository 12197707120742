import CollectionType from "@/components/input/complexTypes/collectionType";
import ItemType from "@/components/input/complexTypes/codeType";

const ITEM_NAME = "ipcode";
const INITIAL_ITEMS = 1;
const MAX_ITEMS = 10;

export default class Code10Type extends CollectionType<ItemType> {
  constructor(items: ItemType[] | undefined) {
    super(items, ItemType, ITEM_NAME, INITIAL_ITEMS, MAX_ITEMS);
  }

  static fromObject(obj: any): Code10Type {
    return CollectionType.fromObjectHelper(obj, ItemType, ITEM_NAME, INITIAL_ITEMS, MAX_ITEMS);
  }
}
