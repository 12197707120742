import ValidationRule from "@/components/input/validation/validationRule";

type CodeInfo = import("@/components/input/codeInfo").default;

export default abstract class ReverseCodeStrategy extends ValidationRule {
  constructor(i18n: any, inverseCode: CodeInfo, necessaryCodes: CodeInfo[]) {
    super(i18n);
    this.inverseCode = inverseCode;
    this.necessaryCodes = necessaryCodes;
  }

  inverseCode: CodeInfo;

  necessaryCodes: CodeInfo[];
}
