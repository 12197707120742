import Validatable from "@/components/input/wizards/validatable";
import Assert from "@/components/input/wizards/assert";
import Income from "./income";
import Kinship from "./kinship";
import { prorateStatic, schoolRoundSafe } from "~/components/input/wizards/household/roundingAndProRating";
import Period from "~/components/input/wizards/household/period";
import { v4 as uuidv4 } from "uuid";

export default class Person extends Validatable {
  _taxYear: number;
  personId: string;
  kinship?: Kinship;
  birthDate?: Date;
  firstName?: string;
  lastName?: string;
  deceaseDate?: Date;
  dependentFrom?: Date;
  dependentTo?: Date;
  legalDomicile?: boolean;
  coParenting?: boolean;
  hasHandicap?: boolean;
  needsCare?: boolean;
  hasGraveHandicap?: boolean;
  childCareExpenses?: Period[];
  forceCode1038?: boolean;
  studentIncome?: Income;
  childSupport?: Income;
  pensionIncome?: Income;
  otherSalary?: Income;
  otherIncome?: Income;

  constructor(
    taxYear: number,
    personId: string,
    kinship?: Kinship,
    birthDate?: Date,
    firstName?: string,
    lastName?: string,
    deceaseDate?: Date,
    dependentFrom?: Date,
    dependentTo?: Date,
    legalDomicile?: boolean,
    coParenting?: boolean,
    hasHandicap?: boolean,
    needsCare?: boolean,
    hasGraveHandicap?: boolean,
    childCareExpenses?: Period[],
    forceCode1038?: boolean,
    studentIncome?: Income,
    childSupport?: Income,
    pensionIncome?: Income,
    otherSalary?: Income,
    otherIncome?: Income
  ) {
    const mandatoryFields = ["firstName", "kinship", "birthDate"];
    const constraints = {
      kinship: [() => Assert.isEnum(this.kinship, Kinship)],
      birthDate: [() => Assert.lessOrEqualThanDate(this.birthDate, this.deceaseDate)],
      deceaseDate: [() => Assert.greaterOrEqualThanDate(this.deceaseDate, this.birthDate)],
      dependentFrom: [
        () => Assert.lessOrEqualThanDate(this.dependentFrom, this.dependentTo),
        () => Assert.greaterOrEqualThanDate(this.dependentFrom, this.birthDate),
      ],
      dependentTo: [
        () => Assert.greaterOrEqualThanDate(this.dependentTo, this.dependentFrom),
        () => Assert.lessOrEqualThanDate(this.dependentTo, this.deceaseDate),
      ],
      coParenting: [
        () => {
          return this.coParenting && this.kinship !== Kinship.CHILD
            ? "co_parenting can only be set for children"
            : true;
        },
      ],
      childCareExpenses: [() => this.validateChildCareExpenses()],
      hasGraveHandicap: [
        () => {
          return this.hasGraveHandicap && !this.hasHandicap
            ? "has_grave_handicap can only be set if hasHandicap is true"
            : true;
        },
      ],
    };
    const nestedValidatables = ["studentIncome", "childSupport", "pensionIncome", "otherSalary", "otherIncome"];
    super(mandatoryFields, constraints, nestedValidatables);

    this._taxYear = taxYear;
    this.personId = personId;
    this.kinship = kinship;
    this.birthDate = birthDate;
    this.firstName = firstName;
    this.lastName = lastName;
    this.deceaseDate = deceaseDate;
    this.dependentFrom = dependentFrom;
    this.dependentTo = dependentTo;
    this.legalDomicile = legalDomicile;
    this.coParenting = coParenting;
    this.hasHandicap = hasHandicap;
    this.needsCare = needsCare;
    this.hasGraveHandicap = hasGraveHandicap;
    this.childCareExpenses = childCareExpenses;
    this.forceCode1038 = forceCode1038;
    this.studentIncome = studentIncome;
    this.childSupport = childSupport;
    this.pensionIncome = pensionIncome;
    this.otherSalary = otherSalary;
    this.otherIncome = otherIncome;
  }

  get maxOneAmountForEachDay(): boolean {
    const daysInYear = (year: number) => ((year % 4 === 0 && year % 100 > 0) || year % 400 == 0 ? 366 : 365);
    return !(
      this.childCareExpenses &&
      this.childCareExpenses.map((i) => i.numberOfDays ?? 0).reduce((partialSum, a) => partialSum + a, 0) >
        daysInYear(this._taxYear - 1)
    );
  }

  validateChildCareExpenses(): boolean | string {
    if (!this.childCareExpenses || this.childCareExpenses.length === 0) {
      return true;
    }
    if (!this.childCareExpenses.every((i) => i.isValid())) {
      return "not all periods are valid";
    }
    if (
      this.kinship &&
      [Kinship.CHILD, Kinship.GRANDCHILD].includes(this.kinship) &&
      this.age &&
      (this.age >= 22 || (this.age >= 15 && !this.hasGraveHandicap))
    ) {
      return "child_care_expenses can only be set for children under 14y or under 21y with grave handicap";
    }
    return true;
  }

  get childCareExpensesIsEnabled(): boolean {
    return !this.forceCode1038 && !!this.childCareExpenses && this.childCareExpenses.length > 0;
  }

  get totalChildCareExpenses(): number {
    if (this.childCareExpensesIsEnabled) {
      return this.totalChildCareExpensesEvenIfDisabled;
    }
    return 0.0;
  }

  get totalChildCareExpensesEvenIfDisabled(): number {
    if (this.childCareExpenses) {
      const childCareExpensesMaximumDailyExemption = this.staticAmounts.childCareExpensesMaximumDailyExemption;
      const expenses = this.childCareExpenses!.map((i) =>
        i.totalDeductibleCostCalculated(childCareExpensesMaximumDailyExemption)
      ).reduce((partialSum, a) => partialSum + a, 0.0);
      return schoolRoundSafe(expenses, 2, false)!;
    }
    return 0.0;
  }

  codes(doubleReturn: boolean, taxablePeriodInMonths: number = 12): { [key: string]: number | null } {
    if (!this.dependent(doubleReturn, taxablePeriodInMonths)) {
      return {};
    }

    const codes: { [key: string]: number | null } = {};

    if ([Kinship.CHILD, Kinship.GRANDCHILD].includes(this.kinship!)) {
      if (this.childCareExpensesIsEnabled) {
        codes["1384"] = this.totalChildCareExpenses;
      }
      if (!this.coParenting) {
        codes["1030"] = 1;
        codes["1031"] = null;
        codes["1038"] = null;
        codes["1039"] = null;
        if (this.hasHandicap) {
          codes["1031"] = 1;
        }
        if (this.age !== undefined && this.age < 3 && (!this.childCareExpensesIsEnabled || this.forceCode1038)) {
          codes["1038"] = 1;
          if (this.hasHandicap) {
            codes["1039"] = 1;
          }
        }
      } else if (this.legalDomicile) {
        codes["1034"] = 1;
        codes["1035"] = null;
        codes["1054"] = null;
        codes["1055"] = null;
        if (this.hasHandicap) {
          codes["1035"] = 1;
        }
        if (this.age !== undefined && this.age < 3 && !this.childCareExpensesIsEnabled) {
          codes["1054"] = 1;
          if (this.hasHandicap) {
            codes["1055"] = 1;
          }
        }
      } else {
        codes["1036"] = 1;
        codes["1037"] = null;
        codes["1058"] = null;
        codes["1059"] = null;
        if (this.hasHandicap) {
          codes["1037"] = 1;
        }
        if (this.age !== undefined && this.age < 3 && !this.childCareExpenses) {
          codes["1058"] = 1;
          if (this.hasHandicap) {
            codes["1059"] = 1;
          }
        }
      }
    } else if (this.isFamilyOver65WithCareNeeds) {
      codes["1027"] = 1;
      codes["1029"] = null;
      if (this.hasHandicap && this.isFamilyOver65AndDependentSince2021) {
        codes["1029"] = 1;
      }
    } else if (this.isFamilyOver65AndDependentSince2021) {
      codes["1043"] = 1;
      codes["1044"] = null;
      if (this.hasHandicap) {
        codes["1044"] = 1;
      }
    } else if (this.kinship !== Kinship.OTHER) {
      codes["1032"] = 1;
      codes["1033"] = null;
      if (this.hasHandicap) {
        codes["1033"] = 1;
      }
    }

    return codes;
  }

  get staticAmounts(): any {
    return require(`@/assets/${this._taxYear}/household_info.json`);
  }

  get isFamilyOver65AndDependentSince2021(): boolean {
    return (
      [Kinship.PARENT, Kinship.SIBLING, Kinship.GRANDPARENT].includes(this.kinship!) &&
      this.dependentFrom !== undefined &&
      this.dependentFrom <= new Date(2021, 0, 1) &&
      !!this.age &&
      this.age >= 65 + (this._taxYear - 2021)
    );
  }

  get isFamilyOver65WithCareNeeds(): boolean {
    return (
      [Kinship.PARENT, Kinship.SIBLING, Kinship.GRANDPARENT].includes(this.kinship!) &&
      !!this.age &&
      this.age >= 65 &&
      !!this.needsCare
    );
  }

  dependent(doubleReturn: boolean, taxablePeriodInMonths: number = 12): boolean {
    const dependentDate = new Date(this._taxYear, 0, 1);
    if (!this.birthDate || (this.birthDate && this.birthDate > new Date(`${this._taxYear}-01-01`))) {
      return false;
    }
    if (this.deceaseDate && this.deceaseDate.getFullYear() < this._taxYear - 1) {
      return false;
    }
    if (this.dependentFrom && this.dependentFrom > dependentDate) {
      return false;
    }
    if (this.dependentTo && this.dependentTo < dependentDate) {
      if (!this.deceaseDate || this.deceaseDate.getTime() !== this.dependentTo.getTime()) {
        return false;
      }
    }
    return this.dependentByNetMeansOfSubsistence(doubleReturn, taxablePeriodInMonths);
  }

  dependentByNetMeansOfSubsistence(doubleReturn: boolean, taxablePeriodInMonths: number = 12): boolean {
    return (
      this.netMeansOfSubsistence(taxablePeriodInMonths) <=
      this.maxNetMeansOfSubsistence(doubleReturn, taxablePeriodInMonths)
    );
  }

  maxNetMeansOfSubsistence(doubleReturn: boolean, taxablePeriodInMonths: number = 12): number {
    let info = this.staticAmounts.maxNetMeansOfSubsistence;
    let maxAmount = info.otherPerson;
    if ([Kinship.CHILD, Kinship.GRANDCHILD].includes(this.kinship!)) {
      info = this.hasHandicap ? info.child.hasHandicap : info.child.hasNoHandicap;
      maxAmount = doubleReturn ? info.doubleTaxReturn : info.singleTaxReturn;
    }
    return this.prorate(maxAmount, taxablePeriodInMonths);
  }

  netMeansOfSubsistence(taxablePeriodInMonths: number = 12): number {
    const info = this.staticAmounts;

    // Determine gross income after deductions
    const studentIncome = Math.max((this.studentIncome?.income || 0.0) - info.exemptions.studentIncome, 0.0);
    const childSupport = Math.max(
      (this.childSupport?.income || 0.0) -
        info.exemptions.childSupport * ([Kinship.CHILD, Kinship.GRANDCHILD].includes(this.kinship!) ? 1 : 0),
      0.0
    );
    const pensionIncome = Math.max(
      (this.pensionIncome?.income || 0.0) -
        info.exemptions.pensionsOlderRelatives *
          (this.isFamilyOver65WithCareNeeds || this.isFamilyOver65AndDependentSince2021 ? 1 : 0),
      0.0
    );
    const otherSalary = this.otherSalary?.income || 0.0;
    const otherIncome = this.otherIncome?.income || 0.0;

    const salaryGross = Math.max(studentIncome, 0.0) + Math.max(otherSalary, 0.0);
    const otherGross = Math.max(childSupport, 0.0) + Math.max(pensionIncome, 0.0) + Math.max(otherIncome, 0.0);

    let salaryNet = 0.0;
    if (salaryGross) {
      const salaryCosts = (this.studentIncome?.costs || 0.0) + (this.otherSalary?.costs || 0.0);
      const salaryFixesCostsMin = this.prorate(
        info.fixedCosts.minAmountForCompensationAndBenefits,
        taxablePeriodInMonths
      );
      const salaryFixedCosts = Math.max(info.fixedCosts.rate * salaryGross, salaryFixesCostsMin);
      const salaryCostsFinal = Math.max(salaryCosts, salaryFixedCosts);
      salaryNet = salaryGross - salaryCostsFinal;
    }

    let otherNet = 0.0;
    if (otherGross) {
      const otherCosts =
        (this.childSupport?.costs || 0.0) + (this.pensionIncome?.costs || 0.0) + (this.otherIncome?.costs || 0.0);
      const otherFixedCosts = info.fixedCosts.rate * otherGross;
      const otherCostsFinal = Math.max(otherCosts, otherFixedCosts);
      otherNet = otherGross - otherCostsFinal;
    }

    return Math.max(otherNet + salaryNet, 0.0);
  }

  get age(): number | undefined {
    return this.birthDate ? this._taxYear - this.birthDate.getFullYear() - 1 : undefined;
  }

  private prorate(amount: number, taxablePeriodInMonths: number): number {
    return prorateStatic(amount, taxablePeriodInMonths / 12);
  }

  toObject(removeNulls: boolean = true): { [key: string]: any } {
    const fieldsToIgnoreIfFalse = [
      "legal_domicile",
      "co_parenting",
      "has_handicap",
      "needs_care",
      "has_grave_handicap",
      "force_code_1038",
    ];
    const result = Validatable.toObjectHelper(this, [], removeNulls);
    if (result.child_care_expenses) {
      result.child_care_expenses = result.child_care_expenses.filter((i: any) => Object.keys(i).length > 0);
      if (result.child_care_expenses.length === 0) {
        delete result.child_care_expenses;
      }
    }
    fieldsToIgnoreIfFalse.forEach((field) => {
      if (!result[field]) {
        delete result[field];
      }
    });
    return result;
  }

  clone() {
    return this.cloneHelper(new Person(0, ""));
  }

  static fromObject(obj: { [key: string]: any }, taxYear: number): Person {
    const result = Validatable.fromObjectHelper(obj, new this(taxYear, ""));
    if (obj.child_care_expenses) {
      if (Array.isArray(obj.child_care_expenses)) {
        result.childCareExpenses = obj.child_care_expenses.map((i: any) => Period.fromObject(i));
      } else {
        result.childCareExpenses = [new Period(1, obj.child_care_expenses)];
      }
    }
    if (obj.student_income) {
      result.studentIncome = Income.fromObject(obj.student_income);
    }
    if (obj.child_support) {
      result.childSupport = Income.fromObject(obj.child_support);
    }
    if (obj.pension_income) {
      result.pensionIncome = Income.fromObject(obj.pension_income);
    }
    if (obj.other_salary) {
      result.otherSalary = Income.fromObject(obj.other_salary);
    }
    if (obj.other_income) {
      result.otherIncome = Income.fromObject(obj.other_income);
    }

    if (!obj.person_id) {
      result.personId = uuidv4();
    }

    return result;
  }
}
