import Loan from "@/components/input/wizards/realEstateAndLoans/loans/loan";
import Property from "@/components/input/wizards/realEstateAndLoans/realEstate/property";
import Validatable from "@/components/input/wizards/validatable";
import TaxReturnCodes from "@/components/input/wizards/realEstateAndLoans/taxReturnCodes";
import PropertyType from "@/components/input/wizards/realEstateAndLoans/realEstate/propertyType";
import LongTermSavingsWithoutHouse from "@/components/input/wizards/savings/longTermSavingsWithoutHouse";
import Refinancing from "~/components/input/wizards/realEstateAndLoans/loans/refinancing";

export default class RealEstateAndLoansInput extends Validatable {
  _taxYear: number;

  realEstate: Property[];

  loans: Loan[];

  enabled: boolean;

  currentReturn: TaxReturnCodes;

  longTermSavingsDeclarant: LongTermSavingsWithoutHouse | undefined;

  longTermSavingsPartner: LongTermSavingsWithoutHouse | undefined;

  constructor(
    taxYear: number,
    realEstate: Property[],
    loans: Loan[],
    enabled: boolean,
    currentReturn: TaxReturnCodes,
    longTermSavingsDeclarant: LongTermSavingsWithoutHouse | undefined = undefined,
    longTermSavingsPartner: LongTermSavingsWithoutHouse | undefined = undefined
  ) {
    const mandatoryFields = ["realEstate", "loans", "currentReturn"];
    const nestedValidatables = [...mandatoryFields, "longTermSavingsDeclarant", "longTermSavingsPartner"];
    const constraints = {};
    super(mandatoryFields, constraints, nestedValidatables);
    this._taxYear = taxYear;
    this.realEstate = realEstate;
    this.loans = loans;
    this.enabled = enabled;
    this.currentReturn = currentReturn;
    this.longTermSavingsDeclarant = longTermSavingsDeclarant;
    this.longTermSavingsPartner = longTermSavingsPartner;
  }

  get loansSortedByDate(): Loan[] {
    return [...this.loans].sort((a, b) => a.loanDateNotUndefined.getTime() - b.loanDateNotUndefined.getTime());
  }

  get loansAndRefinancings(): (Loan | Refinancing)[] {
    const result: (Loan | Refinancing)[] = [];
    this.loansSortedByDate.forEach((loan) => {
      let l: Loan | Refinancing = loan;
      result.push(l);
      while (l.refinancing) {
        result.push(l.refinancing);
        l = l.refinancing;
      }
    });
    return result;
  }

  get loansThatQualifyForRefinancing(): (Loan | Refinancing)[] {
    return this.loansAndRefinancings.filter((l) => l.refinancing === undefined);
  }

  isValid(variables?: any): boolean {
    return this.validate({ properties: this.realEstate, isDoubleReturn: this.currentReturn.isDoubleReturn }) === true;
  }

  validate(facts: Object): Map<string, string[]> | boolean {
    return super.validate({
      properties: this.realEstate,
      loans: this.loans,
      isDoubleReturn: this.currentReturn.isDoubleReturn,
    });
  }

  public get validationErrors(): Map<string, string[]> | null {
    const validateResult = this.validate({});
    // @ts-ignore
    return validateResult === true ? null : validateResult;
  }

  get realEstateIdsSet(): Set<string> {
    // @ts-ignore
    return this.realEstate.map((p) => p.propertyId).filter((i) => i !== undefined);
  }

  propertyById(propertyId: string): Property | undefined {
    return this.realEstate.find((p) => p.propertyId === propertyId);
  }

  loansForPropertyId(propertyId: string): Loan[] {
    return this.loans.filter((l) => l.properties.includes(propertyId));
  }

  loansAndRefinancingsForPropertyId(propertyId: string): (Loan | Refinancing)[] {
    return this.loansAndRefinancings.filter((l) => l.properties.includes(propertyId));
  }

  get hasMultipleOwnHouses(): boolean {
    function sumArrays(...arrays: any[]) {
      const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
      const result = Array.from({ length: n });
      return result.map((_, i) => arrays.map((xs) => xs[i] || 0).reduce((sum, x) => sum + x, 0));
    }

    const ownHouseYearRateLists = this.realEstate
      .filter((property) => property.propertyType === PropertyType.HOUSE)
      .map((property) => property.yearRateList(true, false));

    const totalOwnHouseYearRateList = sumArrays(...ownHouseYearRateLists);

    // ignore overlapping buy and sell dates, so ignore 1 occurrence > 1
    return totalOwnHouseYearRateList.filter((rate) => rate > 1).length > 1;
  }

  get requestHash(): string {
    return this.hash;
  }

  get hashWithoutCurrentReturn(): string {
    const obj = this.toObject();
    delete obj.current_return;
    return Validatable.hashObject(obj);
  }

  clone() {
    return this.cloneHelper(new RealEstateAndLoansInput(this._taxYear, [], [], this.enabled, this.currentReturn));
  }

  static fromObject(obj: { [key: string]: any }, taxYear: number): RealEstateAndLoansInput {
    const result = Validatable.fromObjectHelper(obj, new this(taxYear, [], [], false, new TaxReturnCodes({}, [])));
    result.realEstate = result.realEstate.map((property: { [key: string]: any }) =>
      Property.fromObject(property, taxYear)
    );
    result.loans = result.loans.map((loan: { [key: string]: any }) => Loan.fromObject(loan, taxYear));
    if (result.currentReturn) {
      result.currentReturn = TaxReturnCodes.fromObject(result.currentReturn);
    }
    if (result.longTermSavingsDeclarant) {
      result.longTermSavingsDeclarant = LongTermSavingsWithoutHouse.fromObject(result.longTermSavingsDeclarant);
    }
    if (result.longTermSavingsPartner) {
      result.longTermSavingsPartner = LongTermSavingsWithoutHouse.fromObject(result.longTermSavingsPartner);
    }
    return result;
  }
}
