
import { Component, Vue, Prop } from "nuxt-property-decorator";
import {
  mdiAlertCircle,
  mdiMap,
  mdiDelete,
  mdiFileDocumentEditOutline,
  mdiArrowRightBoldBox,
  mdiLockOutline,
  mdiLockOpenOutline,
} from "@mdi/js";
import Country from "@/components/input/country";
import CodeItem from "@/components/input/codeItem";
import ForeignIncome from "@/components/input/foreignIncome";
import UserCommentEditorPopupView from "@/components/input/UserCommentEditorPopupView.vue";

@Component({
  components: {
    UserCommentEditorPopupView,
  },
})
export default class SimulationInputCodeView extends Vue {
  @Prop({ default: null, type: Object })
  readonly codeItem!: CodeItem | null;

  @Prop({ default: undefined, type: Object })
  // null if should be compared, but no previous value
  // undefined if no compare
  readonly codeItemForComparing!: CodeItem | null | undefined;

  @Prop({ required: true, type: Boolean })
  readonly isDoubleReturn!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly editable!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly showDiff!: boolean;

  @Prop({ type: Map })
  readonly countries: Map<string, Country> | undefined;

  @Prop({ default: false, type: Boolean })
  readonly hideComments!: boolean;

  @Prop({ required: true, type: Array })
  readonly enabledWizards!: Array<string>;

  @Prop({ type: Array })
  readonly warnings!: Array<string> | undefined;

  mdiAlertCircle = mdiAlertCircle;

  mdiMap = mdiMap;

  mdiDelete = mdiDelete;

  mdiFileDocumentEditOutline = mdiFileDocumentEditOutline;

  mdiArrow = mdiArrowRightBoldBox;

  mdiLockOutline = mdiLockOutline;

  mdiLockOpenOutline = mdiLockOpenOutline;

  get relevantForEnabledWizard(): boolean {
    return this.codeItem ? this.codeItem.info.wizards.some((i) => this.enabledWizards.includes(i)) : false;
  }

  get diff(): number | string | null | boolean {
    if (this.codeItemForComparing) {
      if (this.codeItem && this.codeItemForComparing && this.codeItem.value === this.codeItemForComparing.value) {
        return null;
      }
      if (!this.codeItem || this.codeItem.value === null) {
        if (this.codeItemForComparing.info.type === "float") {
          return -this.codeItemForComparing.value;
        } else {
          return -1;
        }
      } else if (this.codeItem.info.type === "float" && this.codeItemForComparing.info.type === "float") {
        return this.codeItem.value - this.codeItemForComparing.value;
      } else if (this.codeItem.info.type === "int" && this.codeItemForComparing.info.type === "int") {
        return this.codeItem.value - this.codeItemForComparing.value;
      }
      return "✗";
    } else if (this.codeItem && this.codeItem.value !== null) {
      return this.codeItem.value;
    }
    return null;
  }

  get diffType(): string {
    if (this.codeItem) {
      return this.codeItem.info.type;
    } else if (this.codeItemForComparing) {
      return this.codeItemForComparing.info.type;
    } else {
      throw new EvalError("one codeItem should not be null");
    }
  }

  get diffClass(): string {
    if (typeof this.diff === "number") {
      if (this.diff > 0.01) {
        return "positive";
      } else if (this.diff < -0.01) {
        return "negative";
      }
      return "";
    } else if (!this.codeItemForComparing) {
      return "negative";
    } else if (!this.codeItem || this.codeItem.value === null) {
      return "positive";
    }
    return "";
  }

  get valuesAreTheSame(): boolean {
    return !!(
      this.codeItem &&
      this.codeItemForComparing &&
      JSON.stringify(this.codeItem.value) === JSON.stringify(this.codeItemForComparing.value)
    );
  }

  get relevantCodeItem(): CodeItem {
    if (!this.codeItem && !this.codeItemForComparing) {
      throw new EvalError("one codeItem should be present");
    }
    return this.codeItem ? this.codeItem : this.codeItemForComparing!;
  }

  get foreignItems(): ForeignIncome[] {
    return this.codeItem ? this.codeItem.foreign : [];
  }

  get codeDisplayed(): string {
    if (this.relevantCodeItem.info.invisible) {
      return "-----";
    }
    if (this.relevantCodeItem.info.code.match("^\\d{4}$")) {
      return this.relevantCodeItem.info.code;
    }
    return "extra";
  }

  get valueFormatted(): any {
    const valueFormatted =
      this.codeItem && this.codeItem.value !== null
        ? this.codeItem.info.format(this.codeItem.value, this.countries)
        : null;

    // make sure that the first character is a bullet point
    if (
      this.codeItem?.info.isComplexCode &&
      typeof valueFormatted === "string" &&
      valueFormatted.length > 0 &&
      valueFormatted[0] !== "•"
    ) {
      const lines = valueFormatted.split("\n");
      return lines.map((line: string, index: number) => (index === 0 ? "• " : "   ") + line).join("\n");
    }

    return valueFormatted;
  }

  get valueFormattedTruncated(): string {
    const maxChars = this.codeItemForComparing !== undefined ? 55 : 65;
    const ellipsis = "...";
    const valueFormatted = this.valueFormatted;

    return valueFormatted
      ? valueFormatted
          .split("\n")
          .map((line: string) => {
            if (line.length > maxChars) {
              return line.substring(0, maxChars - ellipsis.length) + ellipsis;
            }
            return line;
          })
          .join("\n")
      : null;
  }

  get valueFormattedForComparing(): any {
    return this.codeItemForComparing
      ? this.codeItemForComparing.info.format(this.codeItemForComparing.value, this.countries)
      : null;
  }

  get ownErrors() {
    return this.codeItem
      ? this.codeItem.typeErrors.concat(this.codeItem.customErrors).concat(this.codeItem.regularErrors)
      : [];
  }

  errors(
    includeTypeErrors = true,
    includeCustomErrors = true,
    includeRegularErrors = true,
    includeReverseErrors = true
  ): string[] {
    const result: string[] = [];
    if (this.codeItem) {
      if (includeTypeErrors) {
        result.push(...this.codeItem.typeErrors);
      }
      if (includeCustomErrors) {
        result.push(...this.codeItem.customErrors);
      }
      if (includeRegularErrors) {
        result.push(...this.codeItem.regularErrors);
      }
      if (includeReverseErrors) {
        result.push(...this.codeItem.reverseErrors);
      }
    }
    return result;
  }

  get formatArgs(): any {
    return [this.$i18n.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }];
  }

  setValueFromCodeItemForComparing(): void {
    if (this.codeItemForComparing && this.codeItem) {
      this.$emit(
        "update:code-item",
        new CodeItem(this.codeItem.info, this.codeItemForComparing.value, [...this.codeItemForComparing.foreign])
      );
    } else if (this.codeItemForComparing === null) {
      this.$emit("remove");
    }
  }

  saveComments(codeItems: CodeItem[]): void {
    if (this.codeItem && codeItems.length === 1) {
      const item = codeItems[0];
      if (item.info.code === this.codeItem.info.code) {
        if (
          item.userComment !== this.codeItem.userComment ||
          JSON.stringify(item.tags) !== JSON.stringify(this.codeItem.tags)
        ) {
          item.userComment = item.userComment && item.userComment.length > 0 ? item.userComment : undefined;
          this.$emit("save-comment", item);
        }
      }
    }
  }

  get codeIsEditable(): boolean {
    return this.editable && this.codeItem?.fromEnabledWizards(this.enabledWizards).length === 0;
  }
}
