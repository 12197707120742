
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";
import { mdiAlert, mdiCheck, mdiMenuDown, mdiMenu, mdiUndo, mdiRedo } from "@mdi/js";
import SimulationInput from "@/components/input/simulationInput";
import SimulationInputView from "@/components/input/SimulationInputView.vue";
import SimulationResultView from "@/components/result/SimulationResultView.vue";
import VerifyResultView from "@/components/VerifyResultView.vue";
import NewInputDialog from "@/components/NewInputDialog.vue";
import SaveInputDialog from "@/components/SaveInputDialog.vue";
import PrintDialog from "@/components/PrintDialog.vue";
import Simulation from "@/components/simulation";
import SimpleSimulationEditorDialog from "@/components/SimpleSimulationEditorDialog.vue";
import RealEstateAndLoans from "~/components/input/wizards/realEstateAndLoans/realEstateAndLoans";
import Savings from "./input/wizards/savings/savings";
import Household from "~/components/input/wizards/household/household";
import ResultSection from "~/components/result/resultSection";
import Logo from "~/components/Logo.vue";
import { AuthInfo } from "~/components/authInfo";

const LATEST_TAXCALC_YEAR = 2022;

@Component({
  computed: {
    ResultSection() {
      return ResultSection;
    },
  },
  components: {
    Logo,
    SimulationInputView,
    SimulationResultView,
    VerifyResultView,
    NewInputDialog,
    SaveInputDialog,
    PrintDialog,
    SimpleSimulationEditorDialog,
  },
})
export default class PersonalIncomeTaxReturn extends Vue {
  @Prop({ default: false, type: Boolean })
  readonly initEmbedded!: boolean;

  @Prop({ type: Object })
  readonly initialSimulation: Simulation | undefined;

  @Prop({ default: false, type: Boolean })
  readonly initReadOnly!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly initHideComments!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly initValidateRequiredAdditionalInfo!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly initHideMenu!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly initHideMenuItemNew!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly initHideMenuItemClose!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly initHideMenuItemPrint!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly initHideMenuItemExportTow!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly initCollapseAdministrativeInfo!: boolean;

  @Prop({ type: String })
  readonly postMessageTargetOrigin!: string | undefined;

  @Prop({ default: false, type: Boolean })
  readonly showOnlyCodesWithValueIfNotEditable!: boolean;

  @Prop({ default: true, type: Boolean })
  readonly showDiff!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly showWizards!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly showKluwerImport!: boolean;

  /*
   * INITIALIZATION
   */

  tabs: any = null;

  loaded = false;

  embedded = false;

  hideComments = false;

  readOnly = false;

  validateRequiredAdditionalInfo = false;

  hideMenu = false;

  hideMenuItemNew = false;

  hideMenuItemClose = false;

  simulation: Simulation | null = null;

  simulationForComparison: Simulation | null = null;

  simulationForComparisonPreparation: Simulation | null = null;

  simulationForComparisonEditor = false;

  localStorageName = "pitSimulation";

  localStorageForComparisonName = "pitSimulationForComparison";

  realEstateAndLoans: RealEstateAndLoans | null = null;

  savings: Savings | null = null;

  household: Household | null = null;

  askToSwapCodes: boolean = false;

  onlineStatus: boolean = process.browser ? navigator.onLine : true;

  internetConnectionWarning: boolean = false;

  mounted() {
    SimulationInput.defaultI18n = this.$i18n;

    this.embedded = this.initEmbedded;
    this.readOnly = this.initReadOnly;
    this.hideMenu = this.initHideMenu;
    this.hideMenuItemNew = this.initHideMenuItemNew;
    this.hideMenuItemClose = this.initHideMenuItemClose;
    this.hideComments = this.initHideComments;
    this.validateRequiredAdditionalInfo = this.initValidateRequiredAdditionalInfo;

    let simulation: Simulation | null = null;
    let simulationForComparison: Simulation | null = null;
    try {
      if (this.initialSimulation !== undefined) {
        simulation = this.initialSimulation;
      } else if (localStorage[this.localStorageName] && !this.embedded) {
        simulation = Simulation.fromLocalStorage(this.localStorageName);
      }

      if (localStorage[this.localStorageForComparisonName] && !this.embedded) {
        simulationForComparison = Simulation.fromLocalStorage(this.localStorageForComparisonName);
      }
    } catch (_) {}
    this.setSimulation(simulation);

    if (simulationForComparison) {
      simulationForComparison.input.update(() => this.setSimulationForComparison(simulationForComparison, true));
    }

    this.loaded = true;

    if (this.embedded && this.postMessageTargetOrigin) {
      window.addEventListener(
        "message",
        (event) => {
          if (event.origin === this.postMessageTargetOrigin && event.data.name) {
            switch (event.data.name) {
              case "setSimulation":
                if (event.data.data) {
                  this.setSimulation(Simulation.fromObject(event.data.data.simulation));
                }
                break;
              case "removeSimulation":
                this.simulation = null;
                break;
              case "openRealEstateAndLoansWizard":
                (this.$refs.simulationView as SimulationInputView).realEstateAndLoansWizard = true;
                break;
              case "setSecondarySimulation":
                if (event.data.data) {
                  const simulation = Simulation.fromObject(event.data.data.simulation);
                  simulation.input.update(() => this.setSimulationForComparison(simulation, true));
                }
                break;
              case "removeSecondarySimulation":
                this.closeSimulationForComparison();
                break;
              case "showTab":
                if (event.data.data) {
                  this.tabs = `tab-${event.data.data}`;
                }
                break;
              case "setReadOnly":
                if (event.data.data !== undefined) {
                  this.readOnly = event.data.data;
                }
                break;
              case "jwt":
                if (event.data.data !== undefined) {
                  AuthInfo.setToken(event.data.data);
                }
                break;
              case "caseIdEncrypted":
                if (event.data.data !== undefined) {
                  AuthInfo.setCaseIdEncrypted(event.data.data);
                }
                break;
            }
          }
        },
        false
      );
      this.sendMessageReadyToReceive();
    }

    window.addEventListener("keydown", (event) => {
      if (this.simulation && this.simulation.input) {
        if (event.key === "Enter" && event.ctrlKey) {
          if (this.simulation.isValid) {
            this.simulation.updateResult();
          }
        } else if (event.ctrlKey && event.key === "z") {
          this.undo();
        } else if (event.ctrlKey && event.shiftKey && ["z", "Z"].includes(event.key)) {
          this.redo();
        }
      }
    });

    this.onlineStatus = process.browser ? navigator.onLine : false;
    window.addEventListener("online", (e) => {
      this.onlineStatus = e.type === "online";
    });
    window.addEventListener("offline", (e) => {
      this.onlineStatus = e.type === "online";
    });
  }

  get targetLanguage(): string {
    return this.$i18n.locale === "nl" ? "nl" : "en";
  }

  get taxMachineUrl(): string {
    return this.$config.urls.taxMachineLanding.replace("{lang}", this.targetLanguage);
  }

  @Watch("onlineStatus")
  onOnlineStatusChange(online: boolean) {
    if (online) {
      this.internetConnectionWarning = false;
      if (this.simulation && this.simulation.isReady && !this.simulation.isUpToDate) {
        this.simulation.updateResult();
      }
      if (this.realEstateAndLoans && this.realEstateAndLoans.input.isValid() && !this.realEstateAndLoans.isUpToDate) {
        this.realEstateAndLoans.updateResult(undefined, () => {
          if (this.simulation) {
            const newSimulationInput = this.simulation.input.clone();
            this.realEstateAndLoans!.applyOnSimulationInput(newSimulationInput);
            if (!newSimulationInput.equals(this.simulation.input)) {
              this.updateInput(newSimulationInput, this.realEstateAndLoans!, true);
            }
          }
        });
      }
    } else {
      this.internetConnectionWarning = true;
    }
  }

  get acceptedPostMessageTargetOrigin(): boolean {
    const acceptedOriginRegexes = this.$config
      .acceptedPostMessageTargetOrigins!.split(",")
      .map((origin: string) => new RegExp(`^${origin.replace(/\*/g, ".*")}$`));
    return !!(
      this.postMessageTargetOrigin &&
      acceptedOriginRegexes.some((regex: RegExp) => regex.test(this.postMessageTargetOrigin!))
    );
  }

  simulationInputHistory: SimulationInput[] = [];

  // index currently on, if exists
  simulationInputHistoryIndex = 0;

  @Watch("simulation.isValidAndUpToDate")
  onIsValidAndUpToDatesChange(isValidAndUpToDate: boolean) {
    if (!this.simulation?.isValid) {
      this.readOnly = false;
      this.tabs = "tab-input";
    }
    if (!this.embedded) {
      this.simulation?.toLocalStorage(this.localStorageName);
    } else if (this.acceptedPostMessageTargetOrigin) {
      this.notifyParent();
    }
  }

  get simulationObj() {
    return this.simulation ? this.simulation.toPostMessageState() : null;
  }

  @Watch("simulationObj", { deep: true })
  onSimulationChange() {
    this.sendMessageSimulationUpdated();
  }

  get simulationForComparisonObj() {
    return this.simulationForComparison ? this.simulationForComparison.toPostMessageState() : null;
  }

  @Watch("simulationForComparisonObj", { deep: true })
  onSecondarySimulationChange() {
    this.sendMessageSecondarySimulationUpdated();
  }

  get taxPayable(): number | null {
    return this.simulation && this.simulation.result ? this.simulation.result.taxPayable : null;
  }

  get realTaxTotal(): number | null {
    return this.simulation && this.simulation.result ? this.simulation.result.realTaxTotal : null;
  }

  setSimulation(simulation: Simulation | null, realEstateAndLoans: RealEstateAndLoans | null = null) {
    this.simulation = simulation;
    if (this.simulation) {
      this.simulation.input.update(() => this.simulation!.input.applyFixes(true));
      this.simulation.input._validateRequiredAdditionalInfo = this.validateRequiredAdditionalInfo;

      if (!realEstateAndLoans) {
        this.realEstateAndLoans = RealEstateAndLoans.fromSimulation(this.simulation);
        if (!this.realEstateAndLoans.isUpToDate && this.realEstateAndLoans.input.enabled) {
          this.realEstateAndLoans.updateResult(undefined, () => {
            if (this.simulation) {
              const newSimulationInput = this.simulation.input.clone();
              this.realEstateAndLoans!.applyOnSimulationInput(newSimulationInput);
              if (!newSimulationInput.equals(this.simulation.input)) {
                // todo: check if isHistory=true avoids extra history item
                this.updateInput(newSimulationInput, this.realEstateAndLoans!, true);
              }
            }
          });
        }
      } else {
        this.realEstateAndLoans = realEstateAndLoans;
      }
      this.savings = Savings.fromSimulation(this.simulation);
      this.household = Household.fromSimulation(this.simulation);

      if (!this.embedded) {
        this.simulation.toLocalStorage(this.localStorageName);
      } else if (this.acceptedPostMessageTargetOrigin) {
        this.notifyParent();
      }
      if (!this.simulation.isUpToDate) {
        this.simulation.input.update(() => this.simulation!.updateResult());
      }
      this.checkSwapCodes();
    }
  }

  setWizards(simulation: Simulation | null) {
    if (this.simulation && this.simulation.input && simulation && simulation.input) {
      const newSimulation = this.simulation.clone();
      if (simulation.input.realEstate !== null) {
        newSimulation.input.realEstate = simulation.input.realEstate;
      }
      if (simulation.input.loans !== null) {
        newSimulation.input.loans = simulation.input.loans;
      }
      if (simulation.input.savings !== null) {
        newSimulation.input.savings = simulation.input.savings;
      }
      if (simulation.input.household !== null) {
        newSimulation.input.household = simulation.input.household;
      }
      this.updateInput(newSimulation.input);
    }
  }

  prepareSimulationForComparison(simulation: Simulation | null) {
    this.simulationForComparisonPreparation = simulation;
    this.simulationForComparisonEditor = true;
  }

  closeSimulationForComparison() {
    if (this.simulationForComparison) {
      this.simulationForComparison.removeFromLocalStorage(this.localStorageForComparisonName);
    }
    this.simulationForComparisonPreparation = null;
    this.simulationForComparison = null;
    this.simulationForComparisonEditor = false;
  }

  setSimulationForComparison(simulation: Simulation | null, update: boolean = true) {
    this.simulationForComparison = simulation;
    this.simulationForComparisonEditor = false;
    this.simulationForComparisonPreparation = null;

    this.checkAndSwapCodesForComparison();

    if (this.simulationForComparison) {
      this.simulationForComparison.input.update(() => this.simulationForComparison!.input.applyFixes(true));
      if (update && !this.simulationForComparison.isUpToDate) {
        this.simulationForComparison.updateResult(undefined, false, false);
      }
    }
  }

  updateInput(input: SimulationInput, realEstateAndLoans?: RealEstateAndLoans, isHistory = false) {
    const taxYearChanged = !this.simulation || this.simulation.input.taxYear !== input.taxYear;
    if (taxYearChanged) {
      input.correctComplexTypeValue();
      // makes sure the new maxima are respected
      if (this.household && this.household.people.length > 0) {
        this.household = Household.fromSimulationInput(input);
        this.household.applyOnSimulationInput(input);
      }
      // todo: update realEstateAndLoans
    }

    if (this.simulation) {
      if (!isHistory) {
        if (this.simulationInputHistoryIndex < this.simulationInputHistory.length) {
          this.simulationInputHistory = this.simulationInputHistory.slice(0, this.simulationInputHistoryIndex);
        }
        this.simulationInputHistory.push(this.simulation.input.toObject());
        this.simulationInputHistoryIndex += 1;
      }
    }

    const previousNumberOfErrors =
      this.simulation && this.simulation.input.validationErrors ? this.simulation.input.validationErrors.size : 0;
    const currentNumberOfErrors = input && input.validationErrors ? input.validationErrors.size : 0;

    const oldInput = this.simulation?.input;
    if (this.simulation) {
      this.simulation.input = input;
    } else {
      this.simulation = Simulation.fromInput(input);
    }

    if (!realEstateAndLoans) {
      // realEstateAndLoans won't be given if it's not updated
      // recalculation only necessary when completely valid and double return
      const realEstateAndLoans_ = RealEstateAndLoans.fromSimulation(this.simulation);
      this.simulation.input.realEstateAndLoansResult = null;

      if (
        (realEstateAndLoans_.input.currentReturn.isDoubleReturn ||
          oldInput?.codes["1199"] !== input.codes["1199"] ||
          oldInput?.codes["1090"] !== input.codes["1090"]) &&
        realEstateAndLoans_.primedForCalculation &&
        realEstateAndLoans_.input.loans.length > 0 &&
        oldInput?.requestHash !== input.requestHash
      ) {
        if (
          !(
            this.realEstateAndLoans?.apiMissingFields &&
            this.realEstateAndLoans?.input.hashWithoutCurrentReturn ===
              realEstateAndLoans_.input.hashWithoutCurrentReturn
          )
        ) {
          realEstateAndLoans_.updateResult(undefined, () => {
            if (this.simulation) {
              const newSimulationInput = this.simulation.input.clone();
              realEstateAndLoans_.applyOnSimulationInput(newSimulationInput);
              if (!newSimulationInput.equals(this.simulation.input)) {
                // todo: check if isHistory=true avoids extra history item
                this.updateInput(newSimulationInput, realEstateAndLoans_, true);
              }
            }
          });
        }
      }
      this.realEstateAndLoans = realEstateAndLoans_;
    } else {
      this.realEstateAndLoans = realEstateAndLoans;
    }
    this.savings = Savings.fromSimulation(this.simulation);
    this.household = Household.fromSimulation(this.simulation);

    this.undoSnackActive = false;
    this.$nextTick(() => {
      this.activateUndoSnack(previousNumberOfErrors, currentNumberOfErrors);
    });

    if (!this.embedded) {
      this.simulation.toLocalStorage(this.localStorageName);
    }
    if (this.acceptedPostMessageTargetOrigin) {
      this.notifyParent();
    }
    if (!this.simulation.isUpToDate || this.simulation.apiExceptions) {
      this.simulation.updateResultDebounced();
    }
    this.checkSwapCodes();
  }

  undo() {
    if (this.simulationInputHistoryIndex > 0) {
      // undo from newest
      if (this.simulation && this.simulationInputHistoryIndex == this.simulationInputHistory.length) {
        this.simulationInputHistory.push(this.simulation.input.toObject());
        this.simulationInputHistoryIndex -= 1;
        this.updateInput(
          SimulationInput.fromObject(this.simulationInputHistory[this.simulationInputHistoryIndex]),
          undefined,
          true
        );
      } else if (this.simulationInputHistoryIndex !== 0) {
        this.simulationInputHistoryIndex -= 1;
        this.updateInput(
          SimulationInput.fromObject(this.simulationInputHistory[this.simulationInputHistoryIndex]),
          undefined,
          true
        );
      }
    }
  }

  redo() {
    if (this.simulationInputHistoryIndex < this.simulationInputHistory.length - 1) {
      this.simulationInputHistoryIndex += 1;
      this.updateInput(
        SimulationInput.fromObject(this.simulationInputHistory[this.simulationInputHistoryIndex]),
        undefined,
        true
      );
    }
  }

  closeReturn() {
    if (this.simulationForComparison) {
      this.closeSimulationForComparison();
    }

    if (this.simulation) {
      if (!this.embedded) {
        this.simulation.removeFromLocalStorage(this.localStorageName);
      } else {
        this.sendMessageCloseReturn();
      }
      this.simulation = null;
    }
  }

  sendMessageCloseReturn() {
    if (this.acceptedPostMessageTargetOrigin) {
      window.parent.postMessage(
        {
          name: "closeReturn",
        },
        this.postMessageTargetOrigin!
      );
    }
  }

  sendMessageSimulationUpdated() {
    if (this.acceptedPostMessageTargetOrigin) {
      this.$nextTick(() => {
        window.parent.postMessage(
          {
            name: "simulationUpdated",
            data: this.simulation ? this.simulation.toPostMessageState() : null,
          },
          this.postMessageTargetOrigin!
        );
      });
    }
  }

  sendMessageSecondarySimulationUpdated() {
    if (this.acceptedPostMessageTargetOrigin) {
      this.$nextTick(() => {
        window.parent.postMessage(
          {
            name: "secondarySimulationUpdated",
            data: this.simulationForComparison ? this.simulationForComparison.toPostMessageState() : null,
          },
          this.postMessageTargetOrigin!
        );
      });
    }
  }

  sendMessageTabSelected(tabName: string) {
    if (this.acceptedPostMessageTargetOrigin) {
      window.parent.postMessage(
        {
          name: "tabSelected",
          data: tabName && tabName.includes("-") ? tabName.split("-")[1] : tabName,
        },
        this.postMessageTargetOrigin!
      );
    }
  }

  sendMessageReadyToReceive() {
    if (this.acceptedPostMessageTargetOrigin) {
      window.parent.postMessage(
        {
          name: "readyToReceive",
        },
        this.postMessageTargetOrigin!
      );
    }
  }

  sendMessageClickedOnHelp() {
    if (this.acceptedPostMessageTargetOrigin) {
      window.parent.postMessage(
        {
          name: "clickedOnHelp",
        },
        this.postMessageTargetOrigin!
      );
    }
  }

  sendMessageDocumentLinkClicked(documentId: string) {
    if (this.acceptedPostMessageTargetOrigin) {
      window.parent.postMessage(
        {
          name: "documentLinkClicked",
          data: documentId,
        },
        this.postMessageTargetOrigin!
      );
    }
  }

  notifyParent() {
    if (this.acceptedPostMessageTargetOrigin) {
      this.$nextTick(() => {
        if (this.simulation) {
          window.parent.postMessage(
            {
              method: "update",
              args: {
                valid: this.simulation.isValidAndUpToDate, // validation and calculation was successful
                taxYear: this.simulation.input.taxYear,
                codes: this.simulation.input.codesWithoutSumField, // for now, remove sum-fields
                foreignCodes: this.simulation.input.foreignCodes,
                city: this.simulation.input.city,
                result: this.simulation.result
                  ? {
                      tax_payable: this.simulation.result.taxPayable,
                      real_tax_total: this.simulation.result.realTaxTotal,
                    }
                  : null,
              },
            },
            this.postMessageTargetOrigin as string
          );
        }
      });
    }
  }

  saveInputDialog = false;

  helpDialog = false;

  disableCopyBar = false;

  mdiAlert = mdiAlert;

  mdiCheck = mdiCheck;

  mdiMenuDown = mdiMenuDown;

  mdiMenu = mdiMenu;

  verificationDialog = false;

  newInputDialog = false;

  newInputForComparisonDialog = false;

  printDialog = false;

  scrollPositions: Map<string, number> = new Map<string, number>();

  get headerHeight() {
    return 48;
  }

  doScrollToTop(element: string) {
    if (element === this.tabs) {
      (this.$refs.top as HTMLElement).scrollIntoView();
    }
  }

  get formatArgs(): any {
    return [this.$i18n.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }];
  }

  get formatCurrencyArgs2(): any {
    return [
      this.$i18n.locale,
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: "currency",
        currency: "EUR",
      },
    ];
  }

  @Watch("tabs", { immediate: true })
  onTabChange(newTab: string, oldTab: string) {
    if (process.client) {
      this.scrollPositions.set(oldTab, window.scrollY);
      if (this.scrollPositions.has(newTab)) {
        this.$nextTick(() => {
          if (
            this.scrollPositions.get(newTab)! >
            document.documentElement.scrollHeight - document.documentElement.clientHeight
          ) {
            setTimeout(() => {
              this.$vuetify.goTo(this.scrollPositions.get(newTab)!, {
                duration: 0,
              });
            }, 100);
          } else {
            this.$vuetify.goTo(this.scrollPositions.get(newTab)!, {
              duration: 0,
            });
          }
        });
      } else {
        this.$vuetify.goTo(0, { duration: 0 });
      }
    }
    this.sendMessageTabSelected(newTab);
  }

  /*
   * UI Functions: undo snackbar
   */

  undoSnackActive = false;

  undoSnackColor = "green";

  undoSnackMessage = "";

  undoSnackTimeout = 5000;

  undoSnackShowUndo = true;

  mdiUndo = mdiUndo;

  mdiRedo = mdiRedo;

  activateCancelledSnack() {
    this.undoSnackActive = false;
    this.undoSnackColor = "grey";
    this.undoSnackMessage = this.$t("input.view.no_changes_saved") as string;
    this.undoSnackTimeout = 2000;
    this.undoSnackShowUndo = false;
    this.undoSnackActive = true;
  }

  activateUndoSnack(previousNumberOfErrors: number, currentNumberOfErrors: number) {
    if (this.loaded) {
      this.undoSnackActive = false;
      this.undoSnackTimeout = 7500;
      this.undoSnackShowUndo = true;
      if (currentNumberOfErrors === 0) {
        this.undoSnackColor = "green";
        this.undoSnackMessage = this.$t("input.view.return_updated_success") as string;
      } else if (currentNumberOfErrors <= previousNumberOfErrors) {
        this.undoSnackColor = "orange";
        this.undoSnackMessage = this.$t("input.view.return_updated_errors_present") as string;
      } else {
        this.undoSnackColor = "red";
        this.undoSnackMessage = this.$t("input.view.return_updated_new_errors") as string;
      }
      this.undoSnackActive = true;
    }
  }

  get remoteError(): string | null {
    return this.simulation && this.simulation.apiExceptions
      ? `${this.simulation.apiSyntaxException ? this.simulation.apiSyntaxException : ""}
        ${this.simulation.apiOtherException ? this.simulation.apiOtherException : ""}`
      : null;
  }

  @Watch("remoteError")
  onRemoteErrorChange(remoteError: string | null) {
    const previousNumberOfErrors =
      this.simulation && this.simulation.input.validationErrors ? this.simulation.input.validationErrors.size : 0;
    const extraErrors = remoteError ? 1 : -1;
    this.activateUndoSnack(previousNumberOfErrors, Math.max(previousNumberOfErrors + extraErrors, 0));
  }

  private static toIsoStr(val: string): string {
    const dateRegex = new RegExp(/^([0-2]\d|3[01])\/([0]\d|1[0-2])\/(\d{4})$/);
    const match = val.match(dateRegex);
    if (!match) {
      throw EvalError(`invalid date: ${val}`);
    }
    return `${match[3]}-${match[2]}-${match[1]}`;
  }

  checkAndSwapCodesForComparison(update: boolean = true) {
    const toIsoStr = PersonalIncomeTaxReturn.toIsoStr;
    if (
      this.simulation &&
      this.simulationForComparison &&
      this.simulationForComparison.input.isDoubleReturn &&
      this.simulation.input.isDoubleReturn &&
      ((this.simulationForComparison.input.taxYear >= 2023 && this.simulation.input.taxYear < 2023) ||
        (this.simulationForComparison.input.taxYear < 2023 && this.simulation.input.taxYear >= 2023))
    ) {
      if (
        this.simulation.input.codes["1086"] &&
        this.simulation.input.codes["2086"] &&
        this.simulation.input.codes["1086"] == this.simulationForComparison.input.codes["2086"] &&
        this.simulation.input.codes["2086"] == this.simulationForComparison.input.codes["1086"]
      ) {
        if (
          (toIsoStr(this.simulation.input.codes["1086"]) >=
            toIsoStr(this.simulationForComparison.input.codes["1086"]) &&
            this.simulation.input.taxYear < 2023) ||
          (toIsoStr(this.simulation.input.codes["1086"]) < toIsoStr(this.simulationForComparison.input.codes["1086"]) &&
            this.simulation.input.taxYear >= 2023)
        ) {
          this.simulationForComparison = this.simulationForComparison.cloneWithSwappedCodes();
          if (this.simulationForComparison) {
            if (update && !this.simulationForComparison.isUpToDate && this.simulationForComparison.isValid) {
              this.simulationForComparison.updateResult();
            }
          }
        }
      }
    }
  }

  swapCodesSimulation() {
    if (this.simulation && this.simulation.input && this.simulation.input.isDoubleReturn) {
      this.setSimulation(this.simulation.cloneWithSwappedCodes());
    }
  }

  checkSwapCodes() {
    const toIsoStr = PersonalIncomeTaxReturn.toIsoStr;
    if (
      this.simulation &&
      this.simulation.input &&
      this.simulation.input.isDoubleReturn &&
      this.simulation.input.taxYear >= 2023 &&
      this.simulation.input.codes["1086"] &&
      this.simulation.input.codes["2086"]
    ) {
      try {
        if (toIsoStr(this.simulation.input.codes["1086"]) > toIsoStr(this.simulation.input.codes["2086"])) {
          this.askToSwapCodes = true;
        }
      } catch (_) {}
    }
  }

  swapCodes() {
    const toIsoStr = PersonalIncomeTaxReturn.toIsoStr;
    if (
      this.simulation &&
      this.simulation.input &&
      this.simulation.input.isDoubleReturn &&
      this.simulation.input.taxYear >= 2023 &&
      this.simulation.input.codes["1086"] &&
      this.simulation.input.codes["2086"]
    ) {
      try {
        if (toIsoStr(this.simulation.input.codes["1086"]) > toIsoStr(this.simulation.input.codes["2086"])) {
          this.setSimulation(this.simulation.cloneWithSwappedCodes());
          this.checkAndSwapCodesForComparison();
        }
      } catch (_) {}
    }
    this.askToSwapCodes = false;
  }

  getFullTextForSimulation() {
    if (this.simulation) {
      this.simulation.updateResultDebounced(() => {
        if (!this.embedded && this.simulation) {
          this.simulation.toLocalStorage(this.localStorageName);
        }
      }, true);
    }
  }
}
