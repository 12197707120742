import LongTermSavingsWithoutHouse from "~/components/input/wizards/savings/longTermSavingsWithoutHouse";
import Validatable from "~/components/input/wizards/validatable";
import SimulationInput from "~/components/input/simulationInput";
import Simulation from "~/components/simulation";

export default class Savings extends Validatable {
  longTermSavingsDeclarant: LongTermSavingsWithoutHouse | undefined;
  longTermSavingsPartner: LongTermSavingsWithoutHouse | undefined;

  constructor(
    longTermSavingsDeclarant: LongTermSavingsWithoutHouse | undefined = undefined,
    longTermSavingsPartner: LongTermSavingsWithoutHouse | undefined = undefined
  ) {
    super([], {}, ["longTermSavingsDeclarant", "longTermSavingsPartner"]);
    this.longTermSavingsDeclarant = longTermSavingsDeclarant;
    this.longTermSavingsPartner = longTermSavingsPartner;
  }

  get codes(): { [key: string]: any } {
    const codes: { [key: string]: any } = {};
    if (this.hasValue && this.validate()) {
      if (this.longTermSavingsDeclarant) {
        if (this.longTermSavingsDeclarant.paymentsContractAfter1989) {
          codes["1353"] = this.longTermSavingsDeclarant.paymentsContractAfter1989;
        }
        if (this.longTermSavingsDeclarant.paymentsContractBefore1989) {
          codes["1354"] = this.longTermSavingsDeclarant.paymentsContractBefore1989;
        }
        if (this.longTermSavingsDeclarant.contractReference && this.longTermSavingsDeclarant.institutionName) {
          const contract = {
            numero: this.longTermSavingsDeclarant.contractReference.substring(0, 35),
            organisme: this.longTermSavingsDeclarant.institutionName.substring(0, 35),
          };
          codes["contIndivAssVie"] = { contrat: [contract] };
          if (this.longTermSavingsDeclarant.contractReference_2 && this.longTermSavingsDeclarant.institutionName_2) {
            codes["contIndivAssVie"]["contrat"].push({
              numero: this.longTermSavingsDeclarant.contractReference_2.substring(0, 35),
              organisme: this.longTermSavingsDeclarant.institutionName_2.substring(0, 35),
            });
          }
        }
      }
      if (this.longTermSavingsPartner) {
        if (this.longTermSavingsPartner.paymentsContractAfter1989) {
          codes["2353"] = this.longTermSavingsPartner.paymentsContractAfter1989;
        }
        if (this.longTermSavingsPartner.paymentsContractBefore1989) {
          codes["2354"] = this.longTermSavingsPartner.paymentsContractBefore1989;
        }
        if (this.longTermSavingsPartner.contractReference && this.longTermSavingsPartner.institutionName) {
          if (codes["contIndivAssVie"]) {
            codes["contIndivAssVie"] = { contrat: [codes["contIndivAssVie"]["contrat"][0]] };
          }
          const contract = {
            numero: this.longTermSavingsPartner.contractReference.substring(0, 35),
            organisme: this.longTermSavingsPartner.institutionName.substring(0, 35),
          };
          if (codes["contIndivAssVie"]) {
            codes["contIndivAssVie"]["contrat"].push(contract);
          } else {
            codes["contIndivAssVie"] = { contrat: [contract] };
          }
          if (
            this.longTermSavingsPartner.contractReference_2 &&
            this.longTermSavingsPartner.institutionName_2 &&
            codes["contIndivAssVie"]["contrat"].length == 1
          ) {
            codes["contIndivAssVie"]["contrat"].push({
              numero: this.longTermSavingsPartner.contractReference_2.substring(0, 35),
              organisme: this.longTermSavingsPartner.institutionName_2.substring(0, 35),
            });
          }
        }
      }
    }
    return codes;
  }

  applyOnSimulationInput(simulationInput: SimulationInput): void {
    const oldSavings = simulationInput.savings ? Savings.fromObject(simulationInput.savings) : null;
    simulationInput.savings = this.toObject();

    if (Object.keys(this.codes).length > 0) {
      simulationInput.enableWizard("savings");
      Object.entries(this.codes).map(([code, value]) => {
        simulationInput.setCode(code, value);
        simulationInput.addTagForCode(code, "from_wizard:savings");
      });
    } else {
      simulationInput.disableWizard("savings");
      simulationInput.update();
    }
    if (oldSavings) {
      if (Object.keys(oldSavings.codes).length > 0) {
        Object.entries(oldSavings.codes)
          .filter(([code, value]) => this.codes[code] === undefined)
          .map(([code, value]) => {
            if (simulationInput.codes[code]) {
              if (isNaN(value) || Math.abs(simulationInput.codes[code] - value) < 0.01) {
                simulationInput.removeCode(code);
                simulationInput.removeTagForCode(code, "from_wizard:savings");
              } else {
                simulationInput.setCode(code, simulationInput.codes[code] - value);
                simulationInput.addTagForCode(code, "from_wizard:savings");
              }
            }
          });
      }
    }
  }

  get enabled(): boolean {
    return !!this.hasValue;
  }

  get hasValue() {
    return (
      (this.longTermSavingsDeclarant && this.longTermSavingsDeclarant.hasValue()) ||
      (this.longTermSavingsPartner && this.longTermSavingsPartner.hasValue())
    );
  }

  clone() {
    return this.cloneHelper(new Savings());
  }

  static fromObject(obj: { [key: string]: any }): Savings {
    const result = Validatable.fromObjectHelper(obj, new this());
    if (result.longTermSavingsDeclarant) {
      result.longTermSavingsDeclarant = LongTermSavingsWithoutHouse.fromObject(result.longTermSavingsDeclarant);
    }
    if (result.longTermSavingsPartner) {
      result.longTermSavingsPartner = LongTermSavingsWithoutHouse.fromObject(result.longTermSavingsPartner);
    }
    return result;
  }

  static cloneObjectWithSwappedCodes(obj: { [key: string]: any }): { [key: string]: any } {
    const result = JSON.parse(JSON.stringify(obj));
    const longTermSavingsDeclarant = obj["long_term_savings_declarant"];
    result["long_term_savings_declarant"] = obj["long_term_savings_partner"];
    result["long_term_savings_partner"] = longTermSavingsDeclarant;
    return result;
  }

  static fromSimulation(simulation: Simulation): Savings {
    return Savings.fromObject(simulation.input.savings ? simulation.input.savings : {});
  }
}
