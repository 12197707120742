import { render, staticRenderFns } from "./SaveInputDialog.vue?vue&type=template&id=23c060a8&scoped=true&"
import script from "./SaveInputDialog.vue?vue&type=script&lang=ts&"
export * from "./SaveInputDialog.vue?vue&type=script&lang=ts&"
import style0 from "./SaveInputDialog.vue?vue&type=style&index=0&id=23c060a8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23c060a8",
  null
  
)

export default component.exports