import RegularCodeStrategy from "@/components/input/validation/regularCodeStrategy";
import NonZeroRequiresNullReverseStrategy from "@/components/input/validation/nonZeroRequiresNullReverseStrategy";

type CodeInfo = import("@/components/input/codeInfo").default;
type CodeItem = import("@/components/input/codeItem").default;

export default class NonZeroRequiresNullStrategy extends RegularCodeStrategy {
  validate(primary: CodeItem, state: Map<string, CodeItem>): string | boolean {
    return [0.0, null].includes(primary.parsedValue) ||
      this.necessaryCodes.some((x) => !state.has(x.code) || state.get(x.code)!.parsedValue === null)
      ? true
      : (this.i18n.t("input.validation.requiresNull", [
          this.necessaryCodes.map((x) => x.code).join(` ${this.i18n.t("input.validation.or")} `),
        ]) as string);
  }

  getReverseRule(primary: CodeInfo): NonZeroRequiresNullReverseStrategy {
    return new NonZeroRequiresNullReverseStrategy(this.i18n, primary, this.necessaryCodes);
  }
}
