
import { Component, Vue, Prop, Watch } from "nuxt-property-decorator";
import { mdiPlus, mdiMap, mdiCalendar, mdiChevronDown, mdiClose, mdiOpenInNew } from "@mdi/js";
import debounce from "lodash.debounce";
import CodeInfo from "@/components/input/codeInfo";
import ForeignIncome from "@/components/input/foreignIncome";
import CodeItem from "@/components/input/codeItem";
import Country from "@/components/input/country";
import CodeItemEditorView from "@/components/input/CodeItemEditorView.vue";
import ErrorCollectionView from "~/components/input/ErrorCollectionView.vue";
import City from "~/components/input/city";

@Component({
  components: { ErrorCollectionView, CodeItemEditorView },
})
export default class CodeItemsEditorView extends Vue {
  @Prop({ required: true, type: Array })
  readonly codeIdsToEdit!: Array<string>;

  @Prop({ required: true, type: Map })
  readonly codeItems!: Map<string, CodeItem>;

  @Prop({ required: true, type: Boolean })
  readonly isDoubleReturn!: boolean;

  @Prop({ required: true, type: Boolean })
  readonly displaySection!: boolean;

  @Prop({ required: true, type: Map })
  readonly codeInfo!: Map<string, CodeInfo>;

  @Prop({ required: true, type: Map })
  readonly countries!: Map<string, Country>;

  @Prop({ required: true, type: Map })
  readonly cities!: Map<string, City>;

  @Prop({ required: true, type: Array })
  readonly wizardsEnabled!: Array<string>;

  @Prop({ required: true, type: Boolean })
  readonly closed!: boolean;

  @Prop({ required: false, type: String })
  readonly codeToFocus!: string | null;

  @Prop({ required: false, type: Number })
  readonly foreignIndexToFocus!: number | null;

  @Prop({ required: false, type: String })
  readonly infoUrlTaxCalc!: string | null;

  @Prop({ type: Boolean })
  readonly visible!: boolean;

  @Prop({
    required: false,
    type: String,
    validator(value: string) {
      return ["value", "country"].includes(value);
    },
  })
  readonly foreignTypeToFocus!: string | null;

  @Prop({ default: false, type: Boolean })
  readonly editable!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly validateRequiredAdditionalInfo!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly showOnlyCodesWithValueIfNotEditable!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly findDeep!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly hideComments!: boolean;

  /*
   * INITIALIZATION
   */

  created() {
    this.$router.push({
      query: {
        dialog: this.codeToFocus,
        section: `${this.displaySection}`,
        editable: `${this.editable}`,
      },
    });

    const unregisterRouterGuard = this.$router.beforeEach((_to, _from, next) => {
      this.close();
      next(false);
    });

    this.$once("hook:destroyed", () => {
      unregisterRouterGuard();
      this.$router.go(-1);
    });
  }

  mounted() {
    this.validateInput();
    if (this.codeIdsToEditExtended.length > (this.isDoubleReturn ? 40 : 20)) {
      this.showEntireSection();
    }
    setTimeout(() => {
      this.$emit("loaded");
    }, this.codesToEditExtended.length / 5);
    this.wizardsEnabledEditable = [...this.wizardsEnabled];
  }

  @Watch("visible")
  onVisibleChange(visible: boolean) {
    if (visible) {
      this.$nextTick(() => {
        this.focusPrimaryField();
      });
    }
  }

  focusPrimaryField() {
    if (this.editable && this.codeToFocus !== null) {
      if (this.foreignIndexToFocus !== null && this.foreignTypeToFocus !== null) {
        this.focusForeignItem(this.codeToFocus, this.foreignIndexToFocus, this.foreignTypeToFocus);
      } else {
        this.focusCodeValue(this.codeToFocus, false);
      }
    } else {
      this.$nextTick(() => {
        const { codeEditContentPane } = this.$refs;
        if (codeEditContentPane) {
          (codeEditContentPane as HTMLElement).focus();
        }
      });
    }
  }

  focusCodeValue(code: string | undefined, scrollTo = true) {
    if (code !== undefined) {
      this.$nextTick(() => {
        if (this.codeInfo && this.codeInfo.has(code) && !this.codeInfo.get(code)!.invisible) {
          const code_ = this.codeInfo.get(code)!.sumFieldTotal ? `${code}_1` : code;
          const codeItemCode = this.codeInfo.get(code)!.sumFieldTotal
            ? `${this.codeInfo.get(code)!.regularCode}_1`
            : this.codeInfo.get(code)!.regularCode;
          // focus -->
          if (
            this.$refs[`codeItem_${codeItemCode}`] &&
            (this.$refs[`codeItem_${codeItemCode}`] as HTMLElement[]).length > 0
          ) {
            const codeItemRef = (this.$refs[`codeItem_${codeItemCode}`] as HTMLElement[])[0];
            if ((codeItemRef as any).$refs[`codeInputField_${code_}`]) {
              const codeInputFieldRef = (codeItemRef as any).$refs[`codeInputField_${code_}`];
              // for regular fields
              if ((codeInputFieldRef as any).$refs && (codeInputFieldRef as any).$refs.inputField) {
                const inputFieldRef = (codeInputFieldRef as any).$refs.inputField;
                if (inputFieldRef && (inputFieldRef.hasOwnProperty("focus") || inputFieldRef instanceof HTMLElement)) {
                  // don't focus calendar fields when not explicitly clicked
                  if (!this.displaySection || !inputFieldRef.hasOwnProperty("focus")) {
                    inputFieldRef.focus({ preventScroll: scrollTo });
                  }
                } else if (inputFieldRef && (inputFieldRef as any).$refs && (inputFieldRef as any).$refs.input) {
                  const checkboxInputRef = (inputFieldRef as any).$refs.input;
                  checkboxInputRef.scrollIntoView({ behavior: "smooth" });
                  checkboxInputRef.focus({ preventScroll: scrollTo });
                }
              }
              // for complex fields
              if (codeInputFieldRef && codeInputFieldRef.hasOwnProperty("focus")) {
                if (typeof codeInputFieldRef.scrollIntoView === "function") {
                  codeInputFieldRef.scrollIntoView({ behavior: "smooth" });
                }
                codeInputFieldRef.focus({ preventScroll: scrollTo });
              }
            }
          }
          // scroll -->
          if (
            ((codeItemCode && codeItemCode.length > 4) || scrollTo) &&
            (this.$refs[`codeItem_${codeItemCode}`] as HTMLElement[])?.length > 0
          ) {
            ((this.$refs[`codeItem_${codeItemCode}`] as HTMLElement[])[0] as any).$el.scrollIntoView({
              behavior: "smooth",
            });
          }
        }
      });
    }
  }

  focusForeignItem(code: string, index: number, type: string) {
    this.$nextTick(() => {
      if (!(this.codeInfo && this.codeInfo.has(code) && this.codeInfo.get(code)!.invisible)) {
        const regularCode = this.codeInfo.get(code)!.regularCode;
        if ((this.$refs[`codeItem_${regularCode}`] as HTMLElement[]).length > 0) {
          const codeItemRef = (this.$refs[`codeItem_${regularCode}`] as HTMLElement[])[0];
          if ((codeItemRef as any).$refs.foreignInput) {
            const foreignInput = (codeItemRef as any).$refs.foreignInput;
            if ((foreignInput.$refs[`foreignItem_${code}_${index}`] as HTMLElement[]).length > 0) {
              const foreignItem = (foreignInput.$refs[`foreignItem_${code}_${index}`] as any[])[0];
              if (foreignItem && foreignItem.hasOwnProperty("focus")) {
                foreignItem.focus(type);
              }
            }
          }
        }
      }
    });
  }

  /*
   * VALIDATION
   */

  validateInput() {
    this.stateForValidation.forEach((item) => {
      item.validate(this.stateForValidation, true, this.validateRequiredAdditionalInfo);
    });
  }

  onCodeItemChangeDebounced: Function = debounce(this.onCodeItemChange, 0);

  onCodeItemChange(codeItem: CodeItem) {
    this.codeLastChanged = codeItem.info.code;

    if (this.findDeep) {
      this.updateState();
    }
    // after updateState, codeItem can be different

    if (codeItem.info.validateType(codeItem)) {
      this.updateSumParent(codeItem);
      this.stateForValidation.forEach((item, id) => {
        if (id !== codeItem.info.code) {
          item.validate(this.stateForValidation, true, this.validateRequiredAdditionalInfo);
        }
      });
    }
    codeItem.validate(this.stateForValidation, true, this.validateRequiredAdditionalInfo);
  }

  private updateSumParent(termItem: CodeItem) {
    if (termItem.info.isSumTerm) {
      const sumCodeParent = this.stateForValidation.get(termItem.info.parentSumCode!);
      if (!sumCodeParent) {
        throw new EvalError(`parent of sumTerm not available: ${termItem.info.parentSumCode}`);
      }
      const allTerms = Array.from(this.stateForValidation.entries())
        .filter(
          ([, item]) =>
            item.value !== null &&
            item.info.isSumTerm &&
            item.info.parentSumCode === sumCodeParent.info.code &&
            item.parsedValue !== null &&
            !isNaN(item.parsedValue)
        )
        .map(([, item]) => item.parsedValue);
      if (allTerms.length === 0) {
        sumCodeParent.value = null;
      } else {
        sumCodeParent.value = allTerms
          .reduce((a, b) => a + b, 0)
          .toFixed(2)
          .replace(".", ",");
      }
    }
  }

  get typeCustomRegularErrors(): Map<string, string[]> {
    return new Map(
      Array.from(this.state.values())
        .filter((x) => x.info.section.id !== "0")
        .filter((x) => x.typeCustomRegularErrors.length !== 0)
        .map((codeItem: CodeItem) => [codeItem.info.code, codeItem.typeCustomRegularErrors])
    );
  }

  get fatalErrors(): Map<string, string[]> {
    return new Map(
      Array.from(this.state.values())
        .filter((x) => x.info.section.id !== "0")
        .filter((x) => x.typeErrors.length !== 0)
        .map((codeItem: CodeItem) => [codeItem.info.code, codeItem.typeErrors])
    );
  }

  /*
   * MANIPULATION
   */

  codeLastChanged: string | null = null;

  codeIdsToEditExtended: string[] = this.calculateCodeIdsToEditExtended(this.codeIdsToEdit, this.codeItems);

  state: Map<string, CodeItem> = this.calculateState(this.codeItems, this.codeIdsToEditExtended, true);

  wizardsEnabledEditable: Array<string> = [...this.wizardsEnabled];

  wizardsToDisable: Array<string> = [];

  get askDisableWizardsDialog(): boolean {
    return this.wizardsToDisable.length > 0;
  }

  set askDisableWizardsDialog(val: boolean) {
    if (!val) {
      this.wizardsToDisable = [];
    }
  }

  askDisableWizards(wizardNames: Array<string>) {
    this.wizardsToDisable = wizardNames;
  }

  confirmDisableWizards() {
    if (this.wizardsToDisable) {
      this.disableWizards(this.wizardsToDisable);
      this.wizardsToDisable = [];
    }
  }

  disableWizards(wizardNames: Array<string>) {
    if (this.wizardsEnabledEditable.some((r) => wizardNames.includes(r))) {
      this.wizardsEnabledEditable = this.wizardsEnabledEditable.filter((i) => !wizardNames.includes(i));
    }
    this.state.forEach((codeItem, code) => {
      if (codeItem.tags) {
        codeItem.tags = codeItem.tags.filter(
          (tag) => !tag.startsWith("from_wizard:") || this.wizardsEnabledEditable.includes(tag.slice(12))
        );
      }
    });
  }

  get codesToEditExtended(): CodeItem[] {
    return this.codeIdsToEditExtended.map((x) => this.state.get(x)!);
  }

  get codesToEditExtendedShown(): CodeItem[] {
    const showCode = (item: CodeItem) => {
      if (item.info.isSumTerm && !item.info.invisible) {
        if (item.info.sumTermIndex! <= 1) {
          return true;
        }
        const sortedSumTermItems = this.codesToEditExtended
          .filter(
            (i) =>
              i.info.isSumTerm &&
              item.info.parentRegularSumCode === i.info.parentRegularSumCode &&
              i.value !== null &&
              i.value !== ""
          )
          .map((i) => i.info.sumTermIndex!)
          .sort();
        const latestIndex = sortedSumTermItems.length > 0 ? sortedSumTermItems[sortedSumTermItems.length - 1] : 0;
        return item.info.sumTermIndex! < latestIndex + 2;
      }
      return !item.info.invisible;
    };
    return this.codesToEditExtended.filter((i) => showCode(i));
  }

  get codesToEditExtendedIsDouble(): boolean {
    return (
      this.codesToEditExtended.some((i: CodeItem) => i.info.isPartner) &&
      this.codesToEditExtended.some((i: CodeItem) => !i.info.isPartner)
    );
  }

  get codesToEditExtendedPartnerSplitShown(): [CodeItem, CodeItem | undefined][] {
    // if for all codes, only partner or declarant is present -> everything = declarant
    // partner code can't exist without regular code
    return this.codesToEditExtendedOnlyDeclarantShown.map((item: CodeItem) => [
      item,
      item.info.partnerCode !== item.info.code ? this.state.get(item.info.partnerCode) : undefined,
    ]);
  }

  get codesToEditExtendedOnlyDeclarantShown(): CodeItem[] {
    if (!this.codesToEditExtendedIsDouble) {
      return this.codesToEditExtendedShown;
    }
    return this.codesToEditExtendedShown.filter((i) => !i.info.isPartner);
  }

  get stateForValidation(): Map<string, CodeItem> {
    return new Map(
      Array.from(this.state.entries()).filter(
        ([id]) => this.codeItems.has(id) || this.codeIdsToEditExtended.includes(id)
      )
    );
  }

  calculateCodeIdsToEditExtended(codesToEdit: string[], state: Map<string, CodeItem>): string[] {
    const codes = Array.from(
      new Set(
        Array.from(
          new Set(
            Array.from(
              new Set(
                codesToEdit
                  .map((x: string) => (this.codeInfo.has(x) ? this.codeInfo.get(x)! : this.codeInfo.get("unknown")!))
                  .flatMap((x: CodeInfo) => (this.findDeep ? x.referencesDeepWithItems(state, 1).concat(x) : x))
              )
            ).flatMap((x: CodeInfo) =>
              x.partnerPossible && this.isDoubleReturn ? [this.codeInfo.get(x.partnerCode)!].concat(x) : x
            )
          )
        ).flatMap((x: CodeInfo) => x.sumTermCodesInfo.concat(x))
      )
    )
      .filter((x) => !this.showOnlyCodesWithValueIfNotEditable || this.editable || this.codeItems.get(x.code)?.value)
      // .filter((x) => this.displaySection || (isPartnerCode && x.isPartnerOrDoesNotHavePartnerCode) || (!isPartnerCode && x.isNotPartnerOrDoesNotHavePartnerCode))
      .sort((a: CodeInfo, b: CodeInfo) => a.index - b.index)
      .map((x: CodeInfo) => x.code);

    return codes;
    // .filter(x => !['global1', 'global2'].includes(x));
  }

  calculateState(existing: Map<string, CodeItem>, codeIdsToEdit: string[], onInit = false): Map<string, CodeItem> {
    function formatValue(value: any, typeName: string) {
      return typeName === "float" ? parseFloat(value).toFixed(2).replace(".", ",") : value;
    }

    // add all existing codes to state
    const allIdsForState = Array.from(new Set([...codeIdsToEdit, ...existing.keys()]));

    const result = new Map<string, CodeItem>();
    allIdsForState.forEach((codeId) => {
      let codeItem;
      if (existing.has(codeId)) {
        const existingCode = existing.get(codeId)!;
        const formattedValue =
          onInit && !existingCode.typeErrors.length && !existingCode.value === null
            ? formatValue(existingCode.value, existingCode.info.type)
            : existingCode.value;
        const foreignIncome =
          onInit && !existingCode.typeErrors.length
            ? existingCode.foreign.map((x) =>
                Vue.observable(
                  new ForeignIncome(x.country, formatValue(x.value, existingCode.info.type), x.nature, x.taxed)
                )
              )
            : existingCode.foreign.map((x) => Vue.observable(new ForeignIncome(x.country, x.value, x.nature, x.taxed)));
        if (this.codeItems.has(codeId) && this.codeItems.get(codeId) === existingCode) {
          codeItem = Vue.observable(
            new CodeItem(existingCode.info, formattedValue, foreignIncome, existingCode.userComment, existingCode.tags)
          );
        } else {
          existingCode.value = formattedValue;
          existingCode.foreign = foreignIncome;
          codeItem = existingCode;
        }
      } else if (this.codeInfo.has(codeId)) {
        codeItem = Vue.observable(new CodeItem(this.codeInfo.get(codeId)!, null, [], undefined, undefined));
      } else {
        codeItem = Vue.observable(new CodeItem(this.codeInfo.get("unknown")!, null, [], undefined, undefined));
      }
      result.set(codeId, codeItem!);
    });

    return result;
  }

  updateState() {
    const oldCodesToEditExtended = this.codeIdsToEditExtended;
    this.codeIdsToEditExtended = this.calculateCodeIdsToEditExtended(this.codeIdsToEdit, this.state);
    if (oldCodesToEditExtended !== this.codeIdsToEditExtended) {
      this.state = this.calculateState(this.state, this.codeIdsToEditExtended);
    }
  }

  save() {
    if (this.editable) {
      this.validateInput();
      if (this.fatalErrors.size === 0) {
        // only save items that are currently visible
        this.$emit("save-collection", [
          Array.from(this.state.values()).filter((x) => this.codeIdsToEditExtended.includes(x.info.code)),
          this.wizardsEnabledEditable,
        ]);
        this.close();
      }
    }
    const { editActions } = this.$refs;
    if (editActions) {
      (editActions as HTMLElement).scrollIntoView({ behavior: "smooth" });
    }
  }

  close() {
    this.$emit("close");
  }

  /*
   * FOREIGN INCOME
   */

  countriesForSection(sectionId: string): Map<string, Country> {
    if (sectionId === "3") {
      return new Map(
        Array.from(this.countries.values())
          .filter((c) => c.isRealEstate)
          .filter((c) => c.id !== "BE")
          .map((c) => [c.id, c])
      );
    }
    if (sectionId === "4") {
      return new Map(
        Array.from(this.countries.values())
          .filter((c) => !c.isRealEstate)
          .filter((c) => c.id !== "BE")
          .map((c) => [c.id, c])
      );
    }
    return new Map(
      Array.from(this.countries.values())
        .filter((c) => c.isRegular)
        .filter((c) => c.id !== "BE" || sectionId === "8")
        .map((c) => [c.id, c])
    );
  }

  /*
   * Application Help
   */

  helpActive: boolean = false;
  helpActiveTime: number = 0;

  helpTitle: string | null = null;
  helpUrl: string | null = null;

  mdiClose = mdiClose;
  mdiOpenInNew = mdiOpenInNew;

  onIframeUrlChange() {
    if (Date.now() - this.helpActiveTime > 1000) {
      this.helpTitle = this.$t("input.editor.taxcalc_help").toString();
    }
  }

  openHelpDialog(descriptionItem: string, codeItem: CodeItem) {
    const anchorMatches = descriptionItem.match(/^[$]{3}.*[$]{3}/);
    const anchor = anchorMatches ? anchorMatches[0].substring(3, anchorMatches[0].length - 3) : null;
    const region = codeItem.info.region === "shared" ? "flanders" : codeItem.info.region;
    this.helpUrl = `/help/${this.$i18n.locale}/${codeItem.info.taxYear}/${region}/section_${codeItem.info.section.id}.html#${anchor}`;
    this.helpTitle = codeItem.info.section.description;
    this.helpActive = true;
    this.helpActiveTime = Date.now();

    // window.open(url, `popup${anchor}`, "width=860,height=860");
  }

  closeHelpDialog() {
    this.helpUrl = null;
    this.helpTitle = null;
    this.helpActive = false;
  }

  openHelpPopup(url: string) {
    const anchor = url.split("#")[1];
    window.open(url, `popup${anchor}`, "width=860,height=860");
    this.closeHelpDialog();
  }

  /*
   * UI
   */

  mdiPlus = mdiPlus;

  mdiMap = mdiMap;

  mdiCalendar = mdiCalendar;

  mdiChevronDown = mdiChevronDown;

  allowTransitions = true;

  showEntireSection(): void {
    if (!this.displaySection && this.codeToFocus && this.codeInfo.has(this.codeToFocus)) {
      this.allowTransitions = false;
      this.$emit("show-entire-section", this.codeInfo.get(this.codeToFocus!)!.section.id);
      this.$nextTick(() => {
        this.codeIdsToEditExtended = this.calculateCodeIdsToEditExtended(this.codeIdsToEdit, this.codeItems);
        this.state = this.calculateState(this.codeItems, this.codeIdsToEditExtended, true);
        this.validateInput();
        this.focusCodeValue(this.codeToFocus!, true);
        setTimeout(() => {
          this.allowTransitions = true;
        }, 1000);
      });
    }
  }

  formatCode(codeInError: string): string {
    if (codeInError.match(/^\d{4}$/)) {
      return codeInError;
    } else if (["global1", "global2"].includes(codeInError)) {
      return "****";
    } else {
      return "extra";
    }
  }

  prepaymentsTotalFunc(partner = false): number {
    const codeItems = this.state;
    function numberValue(code: string): number {
      const value = codeItems.get(code);
      return value && typeof value.parsedValue === "number" ? value.parsedValue : 0.0;
    }
    if (!partner) {
      return numberValue("1571") + numberValue("1572") + numberValue("1573") + numberValue("1574");
    }
    return numberValue("2571") + numberValue("2572") + numberValue("2573") + numberValue("2574");
  }

  lockedByWizards(codeItem: CodeItem | null | undefined): string[] {
    return codeItem?.tags.filter((tag) => tag.startsWith("from_wizard:")).map((tag) => tag.slice(12)) || [];
  }

  possiblyImpactedByWizard(codeItem: CodeItem): string[] {
    if (this.wizardsEnabledEditable.length === 0 || codeItem.info.wizards.length === 0) {
      return [];
    }
    const result = [];
    for (const wizard of this.wizardsEnabledEditable) {
      if (codeItem.info.wizards.includes(wizard)) {
        result.push(wizard);
      }
    }
    return result;
  }
}
