
import { Component, Model, Prop, Vue, Watch } from "nuxt-property-decorator";
import { mdiCalendar, mdiDelete, mdiArrowRightBottom, mdiInformationOutline, mdiClose } from "@mdi/js";
import VNumberField from "@/components/input/wizards/VNumberField";
import VDateField from "@/components/input/wizards/VDateField.vue";
import Payment from "./payment";

@Component({
  components: {
    VNumberField,
    VDateField,
  },
})
export default class PaymentsView extends Vue {
  @Model("update:value", {}) readonly value: number | Payment[] | undefined | null;

  @Prop({ required: true, type: Number }) readonly incomeYear: number | undefined;
  @Prop({ default: false, type: Boolean }) readonly initialShowPayments!: boolean;
  @Prop({ type: String }) readonly label: string | undefined;
  @Prop({ default: false, type: Boolean }) readonly disabled!: boolean;
  @Prop({ default: false, type: Boolean }) readonly autofocus!: boolean;
  @Prop({ default: false, type: Boolean }) readonly readonly!: boolean;
  @Prop({ default: false, type: Boolean }) readonly hideDetails!: boolean;
  @Prop({ default: false, type: Boolean }) readonly clearable!: boolean;
  @Prop({ default: false, type: Boolean }) readonly error!: boolean;
  @Prop({ type: Array<String> }) readonly errorMessages!: string[] | undefined;
  @Prop({}) readonly rules!: any | undefined;

  // @ts-ignore
  // just take a copy of the value (don't clone)
  localValue: number | Payment[] | null = this.value !== undefined ? this.value : null;

  showPayments: boolean =
    // @ts-ignore
    (this.value !== undefined && Array.isArray(this.value)) || (this.initialShowPayments && this.value === undefined);

  mdiCalendar = mdiCalendar;

  mdiDelete = mdiDelete;

  mdiArrowRightBottom = mdiArrowRightBottom;

  mdiInformationOutline = mdiInformationOutline;

  mdiClose = mdiClose;

  mounted() {
    if (this.showPayments && !Array.isArray(this.localValue)) {
      this.localValue = [new Payment()];
    }
  }

  @Watch("showPayments")
  onShowPaymentsChange(enabled: boolean) {
    if (enabled && !Array.isArray(this.localValue)) {
      this.localValue = [new Payment()];
    } else {
      this.localValue = this.localTotal;
    }
  }

  @Watch("value", { deep: true })
  onValueChange(value: number | Payment[] | undefined | null) {
    this.localValue = value !== undefined ? value : null;
  }

  get localTotal(): number | null {
    if (Array.isArray(this.localValue)) {
      const validItems = this.localValue.filter(
        (item) =>
          item.date && item.date.getFullYear() === this.incomeYear && item.amount !== undefined && item.amount !== null
      );
      if (validItems.length === 0) {
        return null;
      }
      return validItems.reduce((a, b) => a + b.amount!, 0.0);
    }
    return this.localValue;
  }

  set localTotal(total: number | null) {
    if (!this.showPayments) {
      this.localValue = total;
    }
  }

  @Watch("localValue", { deep: true })
  onLocalValueChange(value: number | Payment[] | null, old: number | Payment[] | null) {
    this.$emit("update:value", value);
  }

  removePayment(payment: Payment) {
    if (this.showPayments && Array.isArray(this.localValue)) {
      this.localValue = this.localValue.filter((p) => p.uuid !== payment.uuid);
      if (this.localValue.length === 0) {
        this.localValue = 0.0;
        this.showPayments = false;
      }
    }
  }

  addPayment() {
    if (
      this.showPayments &&
      Array.isArray(this.localValue) &&
      !this.localValue.some((i) => i.amount === undefined && i.date === undefined)
    ) {
      const completePayments = this.localValue.filter(
        (p) => p.amount != null && p.date != null && p.date.getFullYear() === this.incomeYear
      );
      completePayments.sort((a, b) => a.date!.getTime() - b.date!.getTime());
      let amount = undefined;
      let date = undefined;

      if (completePayments.length > 1 && new Set(completePayments.map((i) => i.amount)).size === 1) {
        amount = this.localValue[0].amount;
      }
      if (
        completePayments.length > 1 &&
        new Set(completePayments.map((i) => Math.min(28, i.date!.getDate()))).size === 1
      ) {
        const lastday = function (y: number, m: number) {
          return new Date(y, m + 1, 0).getDate();
        };

        const dayOfMonth = completePayments[0].date!.getDate();
        const month =
          completePayments[completePayments.length - 1].date!.getMonth() +
          (completePayments[completePayments.length - 1].date!.getMonth() -
            completePayments[completePayments.length - 2].date!.getMonth());
        const day = Math.min(lastday(this.incomeYear!, month), dayOfMonth);
        date = new Date(
          `${this.incomeYear!}-${Math.min(month, 11) + 1 < 10 ? "0" : ""}${Math.min(month, 11) + 1}-${
            day < 10 ? "0" : ""
          }${day}`
        );
      }
      this.localValue.push(new Payment(amount, date));
    }
  }
}
