
import {
  mdiAlertCircle,
  mdiCheck,
  mdiClose,
  mdiDelete,
  mdiInformationOutline,
  mdiPlus,
  mdiHumanChild,
  mdiHumanCane,
  mdiHuman,
} from "@mdi/js";
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";
import Household from "@/components/input/wizards/household/household";
import PersonView from "@/components/input/wizards/household/PersonView.vue";
import Person from "./person";
import { v4 as uuidv4 } from "uuid";
import CodeItem from "~/components/input/codeItem";
import Simulation from "~/components/simulation";
import Kinship from "~/components/input/wizards/household/kinship";
import CodeChangesView from "~/components/input/wizards/CodeChangesView.vue";

@Component({
  components: {
    CodeChangesView,
    PersonView,
  },
})
export default class HouseholdWizardView extends Vue {
  @Prop({ required: true, type: Object })
  readonly household!: Household;

  @Prop({ required: true, type: Object })
  readonly simulation!: Simulation;

  @Prop({ default: true, type: Boolean })
  readonly editable!: boolean;

  householdEditable: Household = this.household.clone();

  mdiAlertCircle = mdiAlertCircle;

  mdiCheck = mdiCheck;

  mdiClose = mdiClose;

  mdiDelete = mdiDelete;

  mdiPlus = mdiPlus;

  mdiInformationOutline = mdiInformationOutline;

  mdiHumanChild = mdiHumanChild;

  mdiHumanCane = mdiHumanCane;

  mdiHuman = mdiHuman;

  personView: boolean = false;

  personToEdit: Person | null = null;

  showCodeChangesView: boolean = false;

  dateFormatOptions: any = { month: "2-digit", day: "2-digit", year: "numeric" };

  mounted() {
    this.householdEditable = this.household.clone();
  }

  get codeItems(): Map<string, CodeItem> {
    return this.simulation.input.codeItems!;
  }

  get taxablePeriodInMonths(): number {
    return this.simulation.input.codes["1199"] ?? 12;
  }

  @Watch("household", { deep: true })
  onHouseholdChange(household: Household) {
    this.householdEditable = household.clone();
  }

  addPerson() {
    this.personToEdit = new Person(this.householdEditable._taxYear, uuidv4(), Kinship.CHILD);
    this.personView = true;
  }

  disableWizard() {
    this.householdEditable.enabled = false;
    this.saveSimulation();
  }

  enableWizard() {
    this.householdEditable.enabled = true;
    this.attemptSave();
  }

  onWizardEnabledChange() {
    if (this.household.enabled) {
      this.disableWizard();
    } else if (!this.householdEditable.enabled && this.householdEditable.people.length > 0) {
      this.enableWizard();
    }
  }

  openPersonView(person: Person) {
    this.personToEdit = person;
    this.personView = true;
  }

  closePersonView() {
    this.personView = false;
    this.personToEdit = null;
  }

  savePerson(person: Person | null) {
    if (person === null) {
      if (this.personToEdit) {
        this.household.removePerson(this.personToEdit);
      }
    } else {
      this.householdEditable.savePerson(person);
      this.attemptSave();
    }
  }

  removePerson(person: Person) {
    this.householdEditable.removePerson(person);
    this.attemptSave();
  }

  close() {
    this.$emit("close");
  }

  attemptSave() {
    if (this.householdEditable.isValid()) {
      this.askToSave();
    } else {
      this.onlySaveInput();
    }
  }

  askToSave() {
    // if there is an input change
    if (JSON.stringify(this.householdEditable) !== JSON.stringify(this.household)) {
      this.showCodeChangesView = true;
    }
  }

  confirmSaveInputAndResult() {
    this.householdEditable.enabled = true;
    this.showCodeChangesView = false;
    this.saveSimulation();
  }

  onlySaveInput() {
    this.householdEditable.enabled = false;
    this.showCodeChangesView = false;
    this.saveSimulation();
  }

  saveSimulation() {
    const newSimulationInput = this.simulation.input.clone();
    this.householdEditable.applyOnSimulationInput(newSimulationInput);
    if (this.householdEditable.enabled && this.householdEditable.people.length === 0) {
      this.householdEditable.enabled = false;
      newSimulationInput.disableWizard("household");
    }
    this.$emit("save-simulation-input", newSimulationInput);
  }
}
