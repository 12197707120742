import ResultListItem from "@/components/result/resultListItem";

export default class ResultListItemReduction extends ResultListItem {
  constructor(name: string, value: number, basis: number, rate: number) {
    super(name, value);
    this.basis = basis;
    this.rate = rate;
  }

  readonly basis: number;

  readonly rate: number;

  static fromMapping(mapping: any): ResultListItemReduction {
    return new ResultListItemReduction(mapping.name, mapping.value, mapping.basis, mapping.rate);
  }

  toMapping(): any {
    return {
      name: this.name,
      value: this.value,
      basis: this.basis,
      rate: this.rate,
    };
  }
}
