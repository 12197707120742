
import { Component, Vue, Prop } from "nuxt-property-decorator";
import { mdiDelete, mdiMap, mdiPlus } from "@mdi/js";
import deburr from "lodash.deburr";
import Country from "@/components/input/country";
import ForeignIncome from "~/components/input/foreignIncome";

@Component
export default class CodeItemEditorForeignInputLineView extends Vue {
  @Prop({ type: String })
  readonly code!: string | null;

  @Prop({ required: true, type: Array<String> })
  readonly codes!: string[];

  @Prop({ default: false, type: Boolean })
  readonly isForeignTaxed!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly hasNature!: boolean;

  @Prop({ type: Object })
  readonly foreignIncome!: ForeignIncome;

  @Prop({ required: true, type: Map })
  readonly countries!: Map<string, Country>;

  @Prop({ required: true, type: Array })
  readonly lockedByWizards!: Array<string>;

  @Prop({ default: true, type: Boolean })
  readonly editable!: boolean;

  @Prop({ default: true, type: Boolean })
  readonly showCodeSelector!: boolean;

  mdiPlus = mdiPlus;

  mdiDelete = mdiDelete;

  mdiMap = mdiMap;

  mounted() {
    setTimeout(() => {
      this.setForeignValueInputMode();
    }, 10);
  }

  get locked(): boolean {
    return this.lockedByWizards.length > 0;
  }

  get countriesList(): Country[] {
    return Array.from(this.countries.values());
  }

  get localCode(): string | null {
    return this.code;
  }

  set localCode(code: string | null) {
    this.$emit("changeCode", code);
  }

  filterCountry: Object = (item: Country, queryText: string) =>
    deburr(item.description.toLocaleLowerCase()).includes(deburr(queryText.toLocaleLowerCase()));

  setForeignValueInputMode() {
    this.$nextTick(() => {
      (this.$refs.value! as any).$el.setAttribute("inputmode", "decimal");
    });
  }

  validateForeignIncome: Function = (value: string) =>
    [null, ""].includes(value) || /^\d+[.,]?\d*$/.test(value) ? true : "no valid float";

  focus(type: string) {
    if (type === "country") {
      (this.$refs.country! as any).$refs.input.focus();
    } else {
      (this.$refs.value! as any).$refs.input.focus();
    }
  }
}
