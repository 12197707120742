import CollectionType from "@/components/input/complexTypes/collectionType";
import ItemType from "@/components/input/complexTypes/juridType";
import JuridType from "@/components/input/complexTypes/juridType";

const ITEM_NAME = "juridique";
const INITIAL_ITEMS = 2;
const MAX_ITEMS = 2;

export default class JuridiqueType extends CollectionType<ItemType> {
  constructor(items: ItemType[] | undefined) {
    super(items, ItemType, ITEM_NAME, INITIAL_ITEMS, MAX_ITEMS);
  }

  static fromObject(obj: any): JuridiqueType {
    return CollectionType.fromObjectHelper(obj, ItemType, ITEM_NAME, INITIAL_ITEMS, MAX_ITEMS);
  }

  static fixedObject(obj: any): any {
    if (obj && obj.juridique && Array.isArray(obj.juridique)) {
      return { juridique: obj.juridique.map((item: any) => JuridType.fixedObject(item)) };
    }
    return obj;
  }
}
