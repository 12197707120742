
import { Component, Prop, Vue } from "nuxt-property-decorator";
import RealEstateAndLoans from "@/components/input/wizards/realEstateAndLoans/realEstateAndLoans";
import CodesTable from "@/components/result/CodesTable.vue";
import Simulation from "~/components/simulation";

@Component({
  components: {
    CodesTable,
  },
})
export default class RealEstateSummary extends Vue {
  @Prop({ required: true, type: Object })
  readonly simulation!: Simulation;

  @Prop({ required: true, type: Object })
  readonly realEstateAndLoans!: RealEstateAndLoans;

  codesForProperty(propertyId: string | undefined): { [index: string]: any } | null {
    if (!propertyId) {
      return null;
    }
    const codes = this.realEstateAndLoans.result?.realEstateCodesPerPropertyMap.get(propertyId);
    return codes && codes.realEstateCodes ? codes.realEstateCodes.codes : null;
  }

  printAsCurrency(value: number | undefined) {
    return value
      ? value.toLocaleString(this.$i18n.locale, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          style: "currency",
          currency: "EUR",
        })
      : "";
  }

  printAsNumber(value: number | undefined) {
    return value
      ? value.toLocaleString(this.$i18n.locale, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : "";
  }

  printAsDate(value: Date, delimiter = "/") {
    const localeDateString = value.toLocaleDateString(this.$i18n.locale, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    return localeDateString.replace(/[-.]/g, delimiter);
  }
}
