import RequiresStrategy from "@/components/input/validation/requiresStrategy";
import RequiresNullStrategy from "@/components/input/validation/requiresNullStrategy";
import RequiresNullOrZeroStrategy from "@/components/input/validation/requiresNullOrZeroStrategy";
import NonZeroRequiresNullStrategy from "@/components/input/validation/nonZeroRequiresNullStrategy";
import MaxCodeStrategy from "@/components/input/validation/maxCodeStrategy";
import MinCodeStrategy from "@/components/input/validation/minCodeStrategy";
import MaxValueStrategy from "@/components/input/validation/maxValueStrategy";
import MinValueStrategy from "@/components/input/validation/minValueStrategy";
import ForeignMaxStrategy from "@/components/input/validation/foreignMaxStrategy";
import ForeignCompleteStrategy from "@/components/input/validation/foreignCompleteStrategy";
import PatternStringStrategy from "@/components/input/validation/patternStringStrategy";
import MaxLengthStringStrategy from "@/components/input/validation/maxLengthStringStrategy";
import NonZeroRequiresStrategy from "~/components/input/validation/nonZeroRequiresStrategy";

type CodeInfo = import("@/components/input/codeInfo").default;
type ValidationRule = import("@/components/input/validation/validationRule").default;

export default class ValidationRuleFactory {
  i18n: any;

  constructor(i18n: any) {
    this.i18n = i18n;
  }

  createRegularRule(name: string, params: any, codeInfo: Map<string, CodeInfo>, isPartner: boolean): ValidationRule {
    switch (name.toLowerCase().replaceAll("_", "")) {
      case "requires":
        return new RequiresStrategy(this.i18n, this.transformParamsToCodes(params, codeInfo, isPartner));
      case "nonzerorequires":
        return new NonZeroRequiresStrategy(this.i18n, this.transformParamsToCodes(params, codeInfo, isPartner));
      case "requiresnull":
        return new RequiresNullStrategy(this.i18n, this.transformParamsToCodes(params, codeInfo, isPartner));
      case "nonzerorequiresnull":
        return new NonZeroRequiresNullStrategy(this.i18n, this.transformParamsToCodes(params, codeInfo, isPartner));
      case "requiresnullorzero":
        return new RequiresNullOrZeroStrategy(this.i18n, this.transformParamsToCodes(params, codeInfo, isPartner));
      case "maxcode":
        return new MaxCodeStrategy(this.i18n, this.transformParamsToCodes(params, codeInfo, isPartner));
      case "mincode":
        return new MinCodeStrategy(this.i18n, this.transformParamsToCodes(params, codeInfo, isPartner));
      case "maxvalue":
        return new MaxValueStrategy(this.i18n, this.transformParamsToNumbers(params));
      case "minvalue":
        return new MinValueStrategy(this.i18n, this.transformParamsToNumbers(params));
      case "foreignmax":
        return new ForeignMaxStrategy(this.i18n);
      case "foreigncomplete":
        return new ForeignCompleteStrategy(this.i18n);
      case "pattern":
        return new PatternStringStrategy(this.i18n, params);
      case "maxlength":
        return new MaxLengthStringStrategy(this.i18n, params);
      default:
        throw new SyntaxError(this.i18n.t("input.validation.factory.unknown_function", [name]) as string);
    }
  }

  transformParamsToCodes(codes: string[], codeInfo: Map<string, CodeInfo>, isPartner: boolean): CodeInfo[] {
    if (!codes.every((x) => codeInfo.has(x))) {
      const missing = codes.filter((x) => !codeInfo.has(x));
      throw new ReferenceError(
        this.i18n.t("input.validation.factory.not_all_codes_present", [missing.join(", ")]) as string
      );
    }
    const correctedCodes = isPartner ? codes.map((x) => codeInfo.get(x)!.partnerCode) : codes;
    return correctedCodes.map((x) => codeInfo.get(x)!);
  }

  transformParamsToNumbers(codes: (string | number)[]): number[] {
    if (!codes.every((x) => typeof x === "number")) {
      const wrong = codes.filter((x) => typeof x !== "number");
      throw new TypeError(this.i18n.t("input.validation.factory.not_all_codes_numbers", [wrong.join(", ")]) as string);
    }
    return codes as number[];
  }
}
