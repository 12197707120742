import Validatable from "@/components/input/wizards/validatable";
import Assert from "@/components/input/wizards/assert";

export default class Period extends Validatable {
  numberOfDays?: number;
  dailyCost?: number;

  totalDeductibleCost?: number;

  documentId?: string;

  constructor(numberOfDays?: number, dailyCost?: number, totalDeductibleCost?: number, documentId?: string) {
    super([], {
      numberOfDays: [
        () => Assert.greaterOrEqualThan(this.numberOfDays, 0.0),
        () => Assert.lessOrEqualThan(this.numberOfDays, 366.0),
        () => {
          if (this.dailyCost !== undefined && this.numberOfDays === undefined) {
            return "dailyCost should be defined if numberOfDays is defined";
          }
          return true;
        },
      ],
      dailyCost: [
        () => Assert.greaterOrEqualThan(this.dailyCost, 0.0),
        () => {
          if (this.numberOfDays !== undefined && this.dailyCost === undefined) {
            return "numberOfDays should be defined if dailyCost is defined";
          }
          return true;
        },
      ],
      totalDeductibleCost: [
        () => Assert.greaterOrEqualThan(this.totalDeductibleCost, 0.0),
        () => {
          if (
            this.totalDeductibleCost !== undefined &&
            this.numberOfDays !== undefined &&
            this.dailyCost !== undefined
          ) {
            return "totalDeductibleCost or (numberOfDays and dailyCost) should be defined, not both";
          }
          return true;
        },
      ],
    });
    this.numberOfDays = numberOfDays;
    this.dailyCost = dailyCost;
    this.totalDeductibleCost = totalDeductibleCost;
    this.documentId = documentId;
  }

  get isEmpty(): boolean {
    return this.numberOfDays === undefined && this.dailyCost === undefined && this.totalDeductibleCost === undefined;
  }

  get uuid(): string {
    return this._internal_uuid;
  }

  totalDeductibleCostCalculated(maxDailyDeductible: number): number {
    return this.totalDeductibleCost
      ? this.totalDeductibleCost
      : Math.floor(this.numberOfDays ?? 0) * Math.min(maxDailyDeductible, this.dailyCost ?? 0);
  }

  clone() {
    return new Period(this.numberOfDays, this.dailyCost, this.totalDeductibleCost, this.documentId);
  }

  static fromObject(obj: { [key: string]: any }): Period {
    return Validatable.fromObjectHelper(obj, new this());
  }
}
