import Validatable from "@/components/input/wizards/validatable";
import Assert from "@/components/input/wizards/assert";

export default class DateRange extends Validatable {
  dateFrom: Date | undefined;
  dateTo: Date | undefined;

  constructor(dateFrom: Date | undefined = undefined, dateTo: Date | undefined = undefined) {
    const mandatoryFields = ["dateFrom", "dateTo"];
    const constraints = {
      dateFrom: [() => Assert.lessOrEqualThanDate(this.dateFrom, this.dateTo)],
      dateTo: [() => Assert.greaterOrEqualThanDate(this.dateTo, this.dateFrom)],
    };
    super(mandatoryFields, constraints);
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
  }

  clone() {
    return this.cloneHelper(new DateRange());
  }

  static fromObject(obj: { [key: string]: any }): DateRange {
    return Validatable.fromObjectHelper(obj, new this());
  }
}
