import DateRange from "@/components/input/wizards/realEstateAndLoans/realEstate/dateRange";
import Validatable from "@/components/input/wizards/validatable";
import Assert from "@/components/input/wizards/assert";
import RealEstatePurpose from "~/components/input/wizards/realEstateAndLoans/realEstate/realEstatePurpose";

export default class RentalDetails extends Validatable {
  grossRent: number | undefined;
  furnitureRentRate: number | undefined;
  furnitureMaintenanceCost: number | undefined;

  declarantIsCompanyDirectorInRentingCompany: boolean | DateRange = false;
  partnerIsCompanyDirectorInRentingCompany: boolean | DateRange = false;

  constructor(
    grossRent: number | undefined = undefined,
    furnitureRentRate: number | undefined = undefined,
    furnitureMaintenanceCost: number | undefined = undefined,
    declarantIsCompanyDirectorInRentingCompany: boolean | DateRange = false,
    partnerIsCompanyDirectorInRentingCompany: boolean | DateRange = false
  ) {
    const mandatoryFields: string[] = [];
    const constraints = {
      grossRent: [
        (vars?: any) => Assert.minimum(this.grossRent, 0.0),
        (vars?: any) => {
          if (vars?.purpose === RealEstatePurpose.RENTED_OUT_PROFESSIONAL || this.furnitureRentRate) {
            return Assert.requires(this.grossRent);
          }
          return true;
        },
      ],
      furnitureRentRate: [() => Assert.posiviteRate(this.furnitureRentRate)],
      furnitureMaintenanceCost: [() => Assert.minimum(this.furnitureMaintenanceCost, 0.0)],
      partnerIsCompanyDirectorInRentingCompany: [
        (vars?: any) => {
          if (this.partnerIsCompanyDirectorInRentingCompany && !vars?.isDoubleReturn) {
            return "partnerIsCompanyDirectorInRentingCompany not possible in single return";
          }
          return true;
        },
      ],
    };
    super(mandatoryFields, constraints, [
      "declarantIsCompanyDirectorInRentingCompany",
      "partnerIsCompanyDirectorInRentingCompany",
    ]);
    this.grossRent = grossRent;
    this.furnitureRentRate = furnitureRentRate;
    this.furnitureMaintenanceCost = furnitureMaintenanceCost;
    this.declarantIsCompanyDirectorInRentingCompany = declarantIsCompanyDirectorInRentingCompany;
    this.partnerIsCompanyDirectorInRentingCompany = partnerIsCompanyDirectorInRentingCompany;
  }

  clone() {
    return this.cloneHelper(new RentalDetails());
  }

  static fromObject(obj: { [key: string]: any }): RentalDetails {
    const result = Validatable.fromObjectHelper(obj, new this());
    if (typeof result.declarantIsCompanyDirectorInRentingCompany === "object") {
      result.declarantIsCompanyDirectorInRentingCompany = DateRange.fromObject(
        result.declarantIsCompanyDirectorInRentingCompany
      );
    }
    if (typeof result.partnerIsCompanyDirectorInRentingCompany === "object") {
      result.partnerIsCompanyDirectorInRentingCompany = DateRange.fromObject(
        result.partnerIsCompanyDirectorInRentingCompany
      );
    }
    return result;
  }

  toObject(removeNulls: boolean = true): { [key: string]: any } {
    const result = Validatable.toObjectHelper(this, [], removeNulls);
    if (!result.declarant_is_company_director_in_renting_company) {
      delete result.declarant_is_company_director_in_renting_company;
    }
    if (!result.partner_is_company_director_in_renting_company) {
      delete result.partner_is_company_director_in_renting_company;
    }
    return result;
  }
}
