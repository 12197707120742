// Converted from Python code by ChatGPT

export function schoolRoundSafe(amount: number | null, decimals: number = 2, negate: boolean = false): number | null {
  if (amount === null) {
    return null;
  }

  // Python uses banking round; while this round 0.05 up
  if ((amount * Math.pow(10, decimals + 1)) % 10 === 5) {
    return (
      Math.round((amount + 1 / Math.pow(10, decimals + 1)) * (negate ? -1 : 1) * Math.pow(10, decimals)) /
      Math.pow(10, decimals)
    );
  } else {
    return Math.round(amount * (negate ? -1 : 1) * Math.pow(10, decimals)) / Math.pow(10, decimals);
  }
}

export function prorateStatic(amount: number, rate: number, roundFactor: number = 10.0): number {
  if (rate === 1) {
    return amount;
  }
  // @ts-ignore
  return schoolRoundSafe((amount * rate) / roundFactor, 0) * roundFactor;
}
