
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { mdiInformationOutline } from "@mdi/js";
@Component({})
export default class OptimizationOpportunityLinesView extends Vue {
  @Prop({ type: Boolean, default: false }) isForDoubleReturn!: boolean;
  @Prop({ type: Array, default: () => [] }) lines!: any[];

  get visibleLines() {
    return this.lines.filter((line) => !line.hide);
  }

  mdiInformationOutline = mdiInformationOutline;
};
