import ReverseCodeStrategy from "@/components/input/validation/reverseCodeStrategy";

type CodeItem = import("@/components/input/codeItem").default;

export default class RequiresNullReverseStrategy extends ReverseCodeStrategy {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(primary: CodeItem, state: Map<string, CodeItem>): string | boolean {
    if (!state.has(this.inverseCode.code) || state.get(this.inverseCode.code)!.parsedValue == null) {
      return true;
    }
    return this.necessaryCodes.some((x) => !state.has(x.code) || state.get(x.code)!.parsedValue === null)
      ? true
      : (this.i18n.t("input.validation.requiresNullReverse", [
          this.inverseCode.code,
          this.necessaryCodes.map((x) => x.code).join(` ${this.i18n.t("input.validation.or")} `),
        ]) as string);
  }
}
