
import axios from "axios";
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { mdiAlert, mdiCheck } from "@mdi/js";
import SimulationInput from "@/components/input/simulationInput";
import CodeItem from "./input/codeItem";
import { AuthInfo } from "~/components/authInfo";

@Component
export default class PersonalIncomeTaxReturn extends Vue {
  @Prop({ required: true, type: Object })
  readonly simulationInput!: SimulationInput;

  @Prop({ default: false, type: Boolean })
  readonly includeFullText!: boolean;

  supportedAdministrativeCodes = ["1086", "2086", "1061", "1090", "1288", "2288", "1619"];

  personalIncomeTaxEntry = this.$config.urls.api!.concat("personal-income-tax/v1/");

  pendingVerificationRequest = false;

  verificationInputError: string | null = "";

  verificationServerError: string | null = "";

  verificationResult: any | null = null;

  mdiAlert = mdiAlert;

  mdiCheck = mdiCheck;

  async mounted() {
    if (this.simulationInput) {
      const dt = {
        codes: this.codesForCalculation,
        foreign_codes: this.simulationInput.foreignCodes,
        level_of_detail: "medium",
        include_full_text: this.includeFullText,
      };

      this.pendingVerificationRequest = true;

      this.verificationResult = null;

      const headers = await AuthInfo.getAuthHeaders();

      axios
        .post(`${this.personalIncomeTaxEntry}${this.simulationInput.taxYear}/verify`, dt, {
          timeout: 10000,
          headers: headers,
        })
        .then((response) => {
          this.verificationInputError = "";
          this.verificationServerError = null;
          this.verificationResult = response.data;
        })
        .catch((error) => {
          this.verificationServerError = null;
          if (!error.response) {
            this.verificationServerError = error.message;
          } else if (error.status > 499) {
            this.verificationServerError = error.message();
          } else {
            this.verificationInputError =
              error.response && error.response.data && "message" in error.response.data
                ? error.response.data.message
                : error.toString();
          }
          console.error(error);
        })
        .finally(() => {
          this.pendingVerificationRequest = false;
        });
    }
  }

  get removedCodes(): CodeItem[] {
    if (this.simulationInput && this.simulationInput.codeItems) {
      return Array.from(this.simulationInput.codeItems.values()).filter(
        (codeItem) =>
          codeItem.info.section.id === "1" &&
          !this.supportedAdministrativeCodes.includes(codeItem.info.code) &&
          !codeItem.info.code.startsWith("global")
      );
    }
    return [];
  }

  get codesForCalculation() {
    if (this.simulationInput) {
      return Object.keys(this.simulationInput.codes)
        .filter((key) => key.match(/^\d{4}$/))
        .filter((key) => !this.removedCodes.map((codeItem) => codeItem.info.code).includes(key))
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: this.simulationInput!.codes[key],
          };
        }, {});
    }
    return {};
  }

  get formatArgs(): any {
    return [this.$i18n.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }];
  }
}
