var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.visibleLines),function(line){return _c('tr',{key:line.key},[_c('td',{staticClass:"line-label",class:{ 'indent-class': line.indent, bold: !line.indent, 'padding-top': line.paddingTop }},[_vm._v("\n        "+_vm._s(line.prefix ? line.prefix : "")+" "+_vm._s(_vm.$t(line.label))+"\n        "),(line.tooltip)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"30em"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"size":"16"}},on),[_vm._v(_vm._s(_vm.mdiInformationOutline))])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t(line.tooltip)))])]):_vm._e()],1),_vm._v(" "),(line.value !== undefined && line.value !== null)?_c('td',{staticClass:"item-value",class:{ 'padding-top': line.paddingTop }},[_c('span',{staticClass:"number"},[_vm._v(_vm._s(line.percentage ? "" : "€ ")+" "+_vm._s(line.value.toFixed(2))+" "+_vm._s(line.percentage ? "%" : ""))])]):_c('td'),_vm._v(" "),(
              line.value !== undefined &&
              line.value !== null &&
              line.partnerValue !== undefined &&
              line.partnerValue !== null &&
              _vm.isForDoubleReturn
            )?_c('td',{staticClass:"item-value",class:{ 'padding-top': line.paddingTop }},[_c('span',{staticClass:"number"},[_vm._v(_vm._s(line.percentage ? "" : "€ ")+" "+_vm._s(line.partnerValue.toFixed(2))+"\n              "+_vm._s(line.percentage ? "%" : ""))])]):_c('td')])}),0)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }