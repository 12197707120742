import Validatable from "@/components/input/wizards/validatable";
import Payment from "@/components/input/wizards/realEstateAndLoans/loans/payment";
import Assert from "@/components/input/wizards/assert";
import Refinancing from "~/components/input/wizards/realEstateAndLoans/loans/refinancing";
import Loan from "~/components/input/wizards/realEstateAndLoans/loans/loan";

export default class LifeInsurance extends Validatable {
  _taxYear: number;
  insuredAmount: number | undefined;
  payments: number | Payment[] | undefined;
  contractReference: string | undefined;
  institutionName: string | undefined;

  institutionIsCompanyInEea: boolean = true;

  constructor(
    taxYear: number,
    insuredAmount: number | undefined = undefined,
    payments: number | Payment[] | undefined = undefined,
    contractReference: string | undefined = undefined,
    institutionName: string | undefined = undefined,
    institutionIsCompanyInEea: boolean = true
  ) {
    const mandatoryFields = ["insuredAmount", "payments", "contractReference", "institutionName"];
    const constraints = {
      insuredAmount: [
        () => Assert.greaterOrEqualThan(this.insuredAmount, 0.0),
        (vars: any) => this.validateInsuredAmount(vars),
      ],
      payments: [
        (vars: any) => this.validatePayments(vars),
        (vars: any) => Assert.paymentsIsProperType(this.payments, vars),
      ],
    };
    super(mandatoryFields, constraints);
    this._taxYear = taxYear;
    this.insuredAmount = insuredAmount;
    this.payments = payments;
    this.contractReference = contractReference;
    this.institutionName = institutionName;
    this.institutionIsCompanyInEea = institutionIsCompanyInEea;
  }

  validateInsuredAmount(variables: any): boolean | string {
    const loan: Loan | Refinancing | undefined = variables.loan;

    if (!loan) {
      return true;
    }

    if (loan instanceof Loan) {
      return Assert.lessOrEqualThan(this.insuredAmount, loan.loanAmountTotal || 0.0);
    } else {
      if (loan.replacesOlderLifeInsurance) {
        return Assert.lessOrEqualThan(this.insuredAmount, loan.loanAmountTotal);
      }
      return Assert.lessOrEqualThan(this.insuredAmount, loan.loanedAmountExtra);
    }
  }

  validatePayments(variables: any): boolean | string {
    const loan: Loan | Refinancing | undefined = variables.loan;

    if (!loan || !loan.refinancing) {
      return Assert.hasPayments(this.payments, this._taxYear - 1);
    }

    if (loan.refinancing) {
      if (!loan.refinancing.replacesOlderLifeInsurance) {
        return Assert.hasPayments(this.payments, this._taxYear - 1);
      } else if (Payment.containsPositivePaymentsInYear(this.payments, this._taxYear - 1)) {
        if (
          loan.refinancing.loanDate?.getFullYear() !== this._taxYear - 1 ||
          (Array.isArray(this.payments) &&
            loan.refinancing!.loanDate &&
            this.payments.some((payment) => payment.date && payment.date > loan.refinancing!.loanDate!))
        ) {
          return "should not have payments made after the refinancing date";
        }
      }
    }

    return true;
  }

  clone() {
    return this.cloneHelper(new LifeInsurance(0));
  }

  static fromObject(obj: { [key: string]: any }, taxYear: number): LifeInsurance {
    const result = Validatable.fromObjectHelper(obj, new this(taxYear));
    if (Array.isArray(result.payments)) {
      result.payments = result.payments.map((payment: { [key: string]: any }) => Payment.fromObject(payment));
    }
    return result;
  }
}
