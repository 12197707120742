import Validatable from "@/components/input/wizards/validatable";
import Assert from "@/components/input/wizards/assert";

export default class Income extends Validatable {
  income?: number;
  costs?: number;

  constructor(income?: number, costs?: number) {
    super(["income"], {
      income: [() => Assert.greaterOrEqualThan(this.income, 0.0)],
      costs: [() => Assert.greaterOrEqualThan(this.costs, 0.0)],
    });
    this.income = income;
    this.costs = costs;
  }

  clone() {
    return new Income(this.income, this.costs);
  }

  static fromObject(obj: { [key: string]: any }): Income {
    return Validatable.fromObjectHelper(obj, new this());
  }
}
