
import { Component, Vue, Prop } from "nuxt-property-decorator";
import {
  mdiCalendar,
  mdiCogOffOutline,
  mdiMap,
  mdiPlus,
  mdiHelpCircleOutline,
  mdiArrowRightBottom,
  mdiLockOutline,
  mdiLockOpenOutline,
  mdiClose,
  mdiOpenInNew,
} from "@mdi/js";
import CodeItem from "./codeItem";
import Contracts2TypeView from "@/components/input/complexTypes/contracts2TypeView.vue";
import StringTypeView from "@/components/input/complexTypes/stringTypeView.vue";
import TextTypeView from "@/components/input/complexTypes/textTypeView.vue";
import Titulist3TypeWithCheckBoxView from "@/components/input/complexTypes/titulist3TypeWithCheckBoxView.vue";
import TitulistInsur3TypeView from "@/components/input/complexTypes/titulistInsur3TypeView.vue";
import JuridiqueTypeView from "@/components/input/complexTypes/juridiqueTypeView.vue";
import BenefRenteAlimTypeView from "@/components/input/complexTypes/benefRenteAlimTypeView.vue";
import LooseTypeView from "@/components/input/complexTypes/looseTypeView.vue";
import Naturecode10TypeView from "@/components/input/complexTypes/naturecode10TypeView.vue";
import Code10TypeView from "@/components/input/complexTypes/code10TypeView.vue";
import BenefRenteAlimListView from "@/components/input/complexTypes/benefRenteAlimListView.vue";
import DebAlimListView from "@/components/input/complexTypes/debAlimListView.vue";
import ElectricChargingStationTypeView from "~/components/input/complexTypes/electricChargingStationTypeView.vue";
import Country from "~/components/input/country";
import CodeItemEditorSimpleInputFieldView from "@/components/input/CodeItemEditorSimpleInputFieldView.vue";
import CodeItemEditorForeignInputView from "@/components/input/CodeItemEditorForeignInputView.vue";
import CodeItemErrorView from "@/components/input/CodeItemErrorView.vue";
import ForeignIncomeTypeView from "@/components/input/complexTypes/foreignIncomeTypeView.vue";
import UserCommentEditorPopupView from "@/components/input/UserCommentEditorPopupView.vue";
import City from "~/components/input/city";

@Component({
  components: {
    CodeItemErrorView,
    CodeItemEditorForeignInputView,
    Contracts2TypeView,
    StringTypeView,
    TextTypeView,
    Titulist3TypeWithCheckBoxView,
    TitulistInsur3TypeView,
    JuridiqueTypeView,
    BenefRenteAlimTypeView,
    LooseTypeView,
    Naturecode10TypeView,
    Code10TypeView,
    CodeItemEditorSimpleInputFieldView,
    ForeignIncomeTypeView,
    UserCommentEditorPopupView,
    DebAlimListView,
    BenefRenteAlimListView,
    ElectricChargingStationTypeView,
  },
})
export default class CodeItemEditorView extends Vue {
  @Prop({ required: true, type: Object })
  readonly codeItem!: CodeItem;

  @Prop({ required: false, type: Object })
  readonly codeItemPartner!: CodeItem | null;

  @Prop({ required: false, type: Object })
  readonly previousCodeItem!: CodeItem | null;

  @Prop({ required: true, type: Map })
  readonly countries!: Map<string, Country>;

  @Prop({ required: true, type: Map })
  readonly cities!: Map<string, City>;

  @Prop({ default: false, type: Boolean })
  readonly showReverseError!: boolean;

  @Prop({ required: true, type: Boolean })
  readonly isDoubleReturn!: boolean;

  @Prop({ default: true, type: Boolean })
  readonly editable!: boolean;

  @Prop({ required: true, type: Array })
  readonly enabledWizards!: Array<string>;

  @Prop({ required: false, type: Number })
  readonly prepaymentsTotalDeclarant!: number | null;

  @Prop({ required: false, type: Number })
  readonly prepaymentsTotalPartner!: number | null;

  @Prop({ default: false, type: Boolean })
  readonly hideComments!: boolean;

  mdiPlus = mdiPlus;

  mdiMap = mdiMap;

  mdiCalendar = mdiCalendar;

  mdiCogOff = mdiCogOffOutline;

  mdiHelp = mdiHelpCircleOutline;

  mdiArrow = mdiArrowRightBottom;

  mdiLockOutline = mdiLockOutline;

  mdiLockOpenOutline = mdiLockOpenOutline;

  mdiClose = mdiClose;

  mdiOpenInNew = mdiOpenInNew;

  allowTransitions = false;

  mounted() {
    setTimeout(() => {
      this.allowTransitions = true;
    }, 2000);
  }

  get locked(): boolean {
    return this.fromEnabledWizards.length > 0;
  }

  get fromEnabledWizards(): string[] {
    return Array.from(
      new Set(
        this.codeItem
          .fromEnabledWizards(this.enabledWizards)
          .concat(this.codeItemPartner ? this.codeItemPartner.fromEnabledWizards(this.enabledWizards) : [])
      )
    );
  }

  get hasValue(): boolean {
    return (
      (this.codeItem.value != null && this.codeItem.value != "") ||
      (this.codeItemPartner?.value != null && this.codeItemPartner?.value != "")
    );
  }

  get relevantForEnabledWizard(): boolean {
    return (
      (this.codeItem ? this.codeItem.info.wizards.some((i) => this.enabledWizards.includes(i)) : false) ||
      (this.codeItemPartner ? this.codeItemPartner.info.wizards.some((i) => this.enabledWizards.includes(i)) : false)
    );
  }

  removeAnchorPattern = /\$\$\$anchor_[0-9a-zA-Z-_]+\$\$\$(.*?)/g;

  removeAnchor(str: string | undefined): string | undefined {
    return str ? str.replace(this.removeAnchorPattern, "$1") : undefined;
  }

  saveComment(codeItems: CodeItem[]) {
    codeItems.forEach((item) => {
      if (item.info.code === this.codeItem.info.code) {
        if (
          item.userComment !== this.codeItem.userComment ||
          JSON.stringify(item.tags) !== JSON.stringify(this.codeItem.tags)
        ) {
          this.codeItem.userComment = item.userComment && item.userComment.length > 0 ? item.userComment : undefined;
          this.codeItem.tags = item.tags;
          this.onCodeItemChange(item);
        }
      }
      if (this.codeItemPartner && item.info.code === this.codeItemPartner.info.code) {
        if (
          item.userComment !== this.codeItemPartner.userComment ||
          JSON.stringify(item.tags) !== JSON.stringify(this.codeItemPartner.tags)
        ) {
          this.codeItemPartner.userComment =
            item.userComment && item.userComment.length > 0 ? item.userComment : undefined;
          this.codeItemPartner.tags = item.tags;
          this.onCodeItemChange(item);
        }
      }
    });
  }

  get itemHasCommentOrTag() {
    return (
      (this.codeItem &&
        (this.codeItem.tags.length > 0 || (this.codeItem.userComment && this.codeItem.userComment.length > 0))) ||
      (this.codeItemPartner &&
        (this.codeItemPartner.tags.length > 0 ||
          (this.codeItemPartner.userComment && this.codeItemPartner.userComment.length > 0)))
    );
  }

  getDescriptionLineClasses(descriptionItem: string, descriptionIndex: number): object {
    const descrItem = descriptionItem.replace(/^[$]{3}.*[$]{3}/, "");
    return {
      "code-description": true,
      "section-subtitle": descriptionIndex === 0 && /^[A-Z]+[.]+/.test(descrItem),
      "section-subsubtitle":
        (descriptionIndex < 3 && (/^[A-Z]+[.]+/.test(descrItem) || /^[0-9]+[.)]+/.test(descrItem))) ||
        descrItem.startsWith("* "),
    };
  }

  descriptionHasExtraPrefix(codeItem: CodeItem): string | boolean {
    if (codeItem.info.description.length > 0) {
      const lastDescription = codeItem.info.description[codeItem.info.description.length - 1];
      const bracesRegex = /^\((.*)\)$/;
      if (bracesRegex.test(lastDescription)) {
        const content = lastDescription.match(bracesRegex);
        return content ? content[1] : false;
      }
    }
    return false;
  }

  getIndentation(descriptions: string[], index: number): number {
    let result = -1;
    let previousIsDescription = false;
    for (let i = 0; i <= index; i++) {
      result += previousIsDescription ? 0 : 1;
      if (["3", "4", "5"].includes(this.codeItem.info.section.id)) {
        const descrItem = descriptions[i].replace(/^[$]{3}.*[$]{3}/, "");
        if (!/(^[A-Z]+[.]+)|(^[0-9a-z]+[.)]+)|(^[➔•]+.*)/.test(descrItem)) {
          previousIsDescription = true;
        }
      }
    }
    return result;
  }

  codeIsYesOrNo(codeItem: CodeItem): string | boolean {
    const yesNoWords = ["ja", "neen", "oui", "non", "nein"];
    if (codeItem.info.description.length > 0) {
      const lastDescription = codeItem.info.description[codeItem.info.description.length - 1];
      if (yesNoWords.includes(lastDescription.toLowerCase()) && codeItem.info.type === "bool") {
        return lastDescription;
      }
    }
    return false;
  }

  get showForeignButton(): boolean {
    return (
      (this.showForeignButtonFunction(this.codeItem) || this.showForeignButtonFunction(this.codeItemPartner)) &&
      !this.locked
    );
  }

  showForeignButtonFunction(codeItem: CodeItem | null): boolean {
    return codeItem
      ? codeItem.info.foreignPossible &&
          ![null, ""].includes(codeItem.value) &&
          codeItem.typeErrors.length === 0 &&
          ((this.editable && !this.locked) || codeItem.foreign.filter((x) => !x.isEmpty).length > 0)
      : false;
  }

  get foreignButtonTooltip(): string | null {
    if (this.codeItem || this.codeItemPartner) {
      if (this.foreignActivated) {
        return this.$t("input.editor.foreign_income_hide").toString();
      }
      return this.$t("input.editor.foreign_income_add").toString();
    }
    return null;
  }

  get hasForeignIncome(): boolean {
    return (
      this.codeItem.foreign.filter((x) => !x.isEmpty).length !== 0 ||
      (!!this.codeItemPartner && this.codeItemPartner.foreign.filter((x) => !x.isEmpty).length !== 0)
    );
  }

  changeItem(codeItem: CodeItem, value: any) {
    codeItem.value = value;
    this.onCodeItemChange(codeItem);
  }

  disableWizards() {
    this.$emit("disableWizards", this.fromEnabledWizards);
  }

  onCodeItemChange(codeItem: CodeItem) {
    codeItem.removeFromWizardTags();
    this.$emit("update:codeItem", codeItem);
  }

  get formatArgs(): any {
    return [
      this.$i18n.locale,
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false,
      },
    ];
  }

  openHelpDialog(descriptionItem: string, codeItem: CodeItem) {
    this.$emit("openHelp", descriptionItem, codeItem);
  }

  complexTypeView(codeItem: CodeItem): string {
    switch (codeItem.info.type) {
      case "contracts2Type":
        return "contracts2-type-view";
      case "string":
        return "string-type-view";
      case "text":
        return "text-type-view";
      case "titulist3TypeWithCheckBox":
        return "titulist3-type-with-check-box-view";
      case "titulistInsur3Type":
        return "titulist-insur3-type-view";
      case "juridiqueType":
        return "juridique-type-view";
      case "benefRenteAlimType":
        return "benef-rente-alim-type-view";
      case "looseType":
        return "loose-type-view";
      case "naturecode10Type":
        return "naturecode10-type-view";
      case "code10Type":
        return "code10-type-view";
      case "foreignIncomeType":
        return "foreign-income-type-view";
      case "benefRenteAlimList":
        return "benef-rente-alim-list-view";
      case "debAlimList":
        return "deb-alim-list-view";
      case "electricChargingStationType":
        return "electric-charging-station-type-view";
      default:
        console.log(codeItem.info);
        throw new Error(`unknown type: ${codeItem.info.type}`);
    }
  }

  foreignActivated: boolean =
    this.codeItem.foreign.filter((x) => !x.isEmpty).length > 0 ||
    !!(this.codeItemPartner && this.codeItemPartner.foreign.filter((x) => !x.isEmpty).length > 0);

  toggleForeignIncome() {
    if (this.foreignActivated) {
      if (this.codeItem.foreign.filter((x) => !x.isEmpty).length === 0) {
        this.foreignActivated = false;
      }
    } else {
      this.foreignActivated = true;
    }
  }
}
